import React, {useEffect, useState} from 'react';
import Logo from "../../../components/logo/Logo";
import useProfessional from "../../../hooks/useProfessional";
import SelectableNavButton from "../../../components/navigation/SelectableNavButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileSignature, faMapMarker, faUserNurse} from "@fortawesome/free-solid-svg-icons";
import {faIdBadge, faUserCircle} from "@fortawesome/free-regular-svg-icons";
import {useNavigate, useParams} from "react-router-dom";

export enum OnboardingSection {
    ProfessionalInformation,
    School,
    ProfilePhoto,
    FacultyID,
    ServiceAgreement,


}
function SideNav(props: { navItemClicked?: (navSectionClicked: OnboardingSection) => any; completedSections: OnboardingSection[] }) {
    const {navItemClicked: propNavItemClicked, completedSections} = props;
    const {professional} = useProfessional();
    const {stage} = useParams()
    const navigation = useNavigate();

    const [activePage, setActivePage] = useState<OnboardingSection>(+(stage || 0));

    function navItemClicked(navSectionClicked: OnboardingSection) {
        propNavItemClicked && propNavItemClicked(navSectionClicked);
        navigation(`/professional/onboarding/${navSectionClicked}`);
    }
    useEffect(()=>{
        const onboardingStage: OnboardingSection = +(stage || 0);
        setActivePage(onboardingStage);
    }, [stage]);

    return (
        <div className={''}>
            <div className={'flex flex-col py-2 px-10 '}>
                <div className={'mx-auto '}>
                <div className={'px-5 lg:block hidden'}>
                    <Logo/>
                </div>
                </div>

                <h1 className={'mx-auto font-semibold text-3xl mt-9'}>Hello, {professional?.name?.[0].given?.[0]}</h1>
                <p className={'mt-3 mb-6'}>For completing your professional profile, please provide some info about your practice:</p>
                <div className={'mb-3'}>
                    <SelectableNavButton visited={completedSections.includes(OnboardingSection.ProfessionalInformation)}
                                         active={activePage === OnboardingSection.ProfessionalInformation}
                                         icon={<FontAwesomeIcon className={'h-5'} icon={faUserNurse}/>}
                                         title={'Professional Information'}
                                         clicked={() => navItemClicked(OnboardingSection.ProfessionalInformation)}/>
                </div>
                <div className={'mb-3'}>
                    <SelectableNavButton visited={completedSections.includes(OnboardingSection.School)}
                                         active={activePage === OnboardingSection.School}
                                         icon={<FontAwesomeIcon className={'h-5'} icon={faMapMarker}/>} title={'School'}
                                         clicked={() => navItemClicked(OnboardingSection.School)}/>
                </div>
                <div className={'mb-3'}>
                    <SelectableNavButton visited={completedSections.includes(OnboardingSection.ProfilePhoto)}
                                         active={activePage === OnboardingSection.ProfilePhoto}
                                         icon={<FontAwesomeIcon className={'h-5'} icon={faUserCircle}/>}
                                         title={'Profile Photo'}
                                         clicked={() => navItemClicked(OnboardingSection.ProfilePhoto)}/>
                </div>
                <div className={'mb-3'}>
                    <SelectableNavButton visited={completedSections.includes(OnboardingSection.FacultyID)}
                                         active={activePage === OnboardingSection.FacultyID}
                                         icon={<FontAwesomeIcon className={'h-5'} icon={faIdBadge}/>}
                                         title={'Faculty ID'}
                                         clicked={() => navItemClicked(OnboardingSection.FacultyID)}/>
                </div>
                <div className={'mb-3'}>
                    <SelectableNavButton visited={completedSections.includes(OnboardingSection.ServiceAgreement)}
                                         active={activePage === OnboardingSection.ServiceAgreement}
                                         icon={<FontAwesomeIcon className={'h-5'} icon={faFileSignature}/>}
                                         title={'Service Agreement'}
                                         clicked={() => navItemClicked(OnboardingSection.ServiceAgreement)}/>
                </div>
            </div>
        </div>
    );
}

export default SideNav;