import React, {useMemo, useState} from 'react';
import CenterModal from "../dashboard-search/CenterModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faPen, faPlus, faSyringe} from "@fortawesome/free-solid-svg-icons";
import {Immunization, Status} from "../../types/database/SharedTypes";
import UploadImmunizationStatus from "./UploadImmunizationStatus";
import {DocumentProvider as PatientDocumentProvider} from "../../contexts/patient/DocumentProvider";
import ImmunizationEdit from "../modal/ImmunizationEdit";
import {ICalendar, IDossierLine} from "../Icons";
import {parseISOLocal} from "../../util/date/convertDateString";
import {DocumentProvider} from "../../contexts/DocumentProvider";
import DocumentOpenLink from "../link/DocumentOpenLink";
import PrimaryButton from "../button/PrimaryButton";
import ImmunizationView from "../modal/ImmunizationView";
import {useTranslation} from "react-i18next";
import {getImmunizationStatus, getOccurrenceHistoryDates} from "../../util/immunization/immunization";

function PatientImmunizationModal(props:{ showModal: boolean, immunization: Immunization, canAddDose?: boolean, onModalVisibilityChange: (modalViewState: boolean)=> void}) {
    const { showModal, immunization, onModalVisibilityChange, canAddDose=true } = props;
    const [showEdit, setShowEdit] = useState<boolean>(false)
    const [t, i18n] = useTranslation()

    const immunizationTitle = immunization.vaccineCode?.coding?.[0]?.display;

    const immunizationStatus = useMemo(()=> getImmunizationStatus(immunization), [immunization]);


    function handleUpdateModal(modalViewState: boolean) {
        onModalVisibilityChange(modalViewState);
        setShowEdit(false);
    }

    const occurrenceHistoryDates = getOccurrenceHistoryDates(immunization);

    return (
        <>
            <CenterModal isOpen={showModal} onClose={() => handleUpdateModal(false)}>
                <div className='relative bg-white w-screen sm:w-[500px] max-h-screen overflow-auto'>
                    <FontAwesomeIcon icon={faClose} className='absolute right-5 top-5 cursor-pointer' onClick={() => handleUpdateModal(false)} />
                    <div className='px-12 py-8'>
                        <div className='flex items-center'>
                            <FontAwesomeIcon icon={faSyringe} className={`text-violet-500 h-6 w-6 mr-3`} />
                            <span className='text-xl font-semibold'>{immunizationTitle}</span>
                        </div>
                        {immunizationStatus !== Status.MissingShot && (
                            <div className='pl-9'>
                                <UploadImmunizationStatus immunization={immunization} />
                            </div>
                        )}
                    </div>
                    {showEdit ? (
                        <PatientDocumentProvider>
                            <ImmunizationEdit
                                immunizationRecommendation={immunization}
                                updateModal={handleUpdateModal}
                                isEdit={[Status.Rejected, Status.EditedAndPending].includes(immunizationStatus)}
                                status={immunizationStatus}
                                immunizationId={immunization?.id}
                            />
                        </PatientDocumentProvider>
                    ) : (
                        <>
                            {![Status.Approved, Status.Rejected].includes(immunizationStatus) && (
                                <>
                                    <div className='px-12 py-[14px] bg-primary-bg'>
                                        <span className='uppercase text-sm font-medium'>{t('VACCINATION_HISTORY')}</span>
                                    </div>
                                    <div className='px-12 mb-5 max-h-[300px] overflow-auto'>
                                        {occurrenceHistoryDates.map((update, i) => (
                                                <div className='flex align-items-center py-7 border-b border-primary-bg' key={i}>
                                                    <ICalendar color='#F8A03A' />
                                                    <span className='text-base ml-2'>
                          {t('DATE_ADMINISTERED')}:
                          <span className='font-semibold'>
                            {update.toLocaleDateString(i18n.resolvedLanguage, {
                                month: 'numeric',
                                day: 'numeric',
                                year: 'numeric'
                            })}
                          </span>
                        </span>
                                                </div>
                                            )
                                        )}
                                        {occurrenceHistoryDates.length < 1 && (
                                            <div className='py-5 text-sm'>{t('NO_VACCINATION_RECORDS_FOUND')}</div>
                                        )}
                                    </div>
                                    { canAddDose &&
                                    <div className='px-12 py-[14px] bg-primary-bg'>
                                        <span className='uppercase text-sm font-medium'>{t('UPLOAD_MISSING_VACCINE')}</span>
                                    </div> }

                                    <div className='px-12'>
                                        {immunizationStatus === Status.MissingShot && canAddDose  && (
                                            <div className='pt-6 pb-[60px]'>
                                                <button
                                                    onClick={() => setShowEdit(true)}
                                                    className='border-dashed border-[1px] border-brand-color w-full flex items-center justify-center p-[14px]'
                                                >
                                                    <FontAwesomeIcon icon={faPlus} className={`text-brand-color h-4 w-4 mr-2`} />
                                                    <span className='text-sm font-medium text-brand-color'>{t('ADD_DOSE')}</span>
                                                </button>
                                            </div>
                                        )}


                                        {[Status.Pending, Status.EditedAndPending].includes(immunizationStatus) && (
                                            <div>
                                                <div className='flex align-items-center pt-6 pb-5'>
                                                    <ICalendar color='#F8A03A' />
                                                    <span className='text-base ml-2'>
                            {t('DATE_ADMINISTERED')}:
                            <span className='font-semibold'>
                              {parseISOLocal(immunization.occurrences?.slice(-1).pop() || "").toLocaleDateString(i18n.resolvedLanguage, {
                                  month: 'numeric',
                                  day: 'numeric',
                                  year: 'numeric'
                              })}
                            </span>
                          </span>
                                                </div>
                                                <div className='flex align-items-center pb-5'>
                                                    <IDossierLine color='#9662D7' />
                                                    <span className='font-semibold ml-2'>{immunization?.attachment?.[0]?.title}</span>
                                                </div>
                                                <div className='flex items-center border-b border-primary-bg pb-7 mb-7'>
                                                    {immunization?.attachment?.[0]?.url &&
                                                        <div className={'w-[152px]'}>
                                                            <DocumentProvider photoUrl={immunization?.attachment?.[0]?.url}>
                                                                <DocumentOpenLink contentType={immunization?.attachment?.[0]?.contentType}>
                                                                    <PrimaryButton outLine={true}>{t('OPEN_DOCUMENT')}</PrimaryButton>
                                                                </DocumentOpenLink>
                                                            </DocumentProvider>
                                                        </div>
                                                    }
                                                    { canAddDose &&
                                                    <div className='ml-3 w-[100px]'>
                                                        <PrimaryButton outLine onClick={() => setShowEdit(true)}>
                                                            <div className='flex items-center justify-center'>
                                                                <FontAwesomeIcon icon={faPen} className='text-brand-color h-3.5' />
                                                                <span className='ml-2'>{t('EDIT')}</span>
                                                            </div>
                                                        </PrimaryButton>
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                            {
                                [Status.Approved, Status.Rejected].includes(immunizationStatus) && (
                                    <PatientDocumentProvider>
                                        <ImmunizationView
                                            immunization={immunization}
                                            immunizationTitle={immunizationTitle}
                                            updateModal={handleUpdateModal}
                                            isProfessionalView={false}
                                            setShowEdit={setShowEdit}
                                        />
                                    </PatientDocumentProvider>
                                )
                            }

                        </>
                    )}
                </div>
            </CenterModal>
        </>
    );
}

export default PatientImmunizationModal;