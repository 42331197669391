import React, {ReactNode} from 'react';

function ExpandableTableRow(props: { children?: ReactNode, rowExpanded?: boolean, expandedRow?: ReactNode, onClick?:(e?: React.MouseEvent<HTMLElement>) => void }) {
    const {children, rowExpanded = false, expandedRow, onClick} = props;


    return (
        <>
            <tr
                className={!rowExpanded ? "border-b" : 'bg-neutral-100'}
                onClick={onClick && onClick}
            >
                {children}
            </tr>
            <tr className={(!(rowExpanded) ? 'hidden' : 'table-row') + " " + "bg-neutral-100 " + " " + (rowExpanded ? " border-b" : '')}>
                {expandedRow}
            </tr>
        </>
    );
}

export default ExpandableTableRow;