import React from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLink} from "@fortawesome/free-solid-svg-icons";
import {faFile} from "@fortawesome/free-regular-svg-icons";
import PrimaryLink from "../link/PrimaryLink";
import {Resource} from "./Resources";

function ResourceGridGroup(props: {resources: Resource[]}) {
    const {resources} = props;
    const [t] = useTranslation();
    return (

            <div className={'grid lg:grid-cols-3 gap-4 grid-flow-row mb-5'}>
                {resources.map(({type, title, link}, index) => (
                        <div key={index} className={'flex flex-row bg-brand-color bg-opacity-5 p-4'}>
                            <div className={'mr-4'}>
                                {type === "document" ? <FontAwesomeIcon icon={faLink} className={'h-5 text-brand-color'}/> : <FontAwesomeIcon icon={faFile} className={'h-5 text-brand-color'}/> }
                            </div>
                            <div className={'flex flex-col'}>
                                <span className={'font-semibold '}>{title}</span>
                                <PrimaryLink to={link}>{type === "document"? t('OPEN_DOCUMENT'): t('OPEN_LINK')}</PrimaryLink>
                            </div>
                        </div>
                    )
                )}
            </div>

    );
}

export default ResourceGridGroup;