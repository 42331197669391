export default function useScript(src?: string) {
    //src is script url/path
    // keep track of script status ("idle", "loading", "ready", "error")


    // if no url provided, set the state to be idle
    if (!src) {

        return;
    }

    // get the script to check if it is already sourced or not
    let script = document.querySelector(`script[src="${src}"]`);

    if (script) {
        // if script is already loaded, get its status and update.
        // status = script.getAttribute("data-status") || "idle";
    } else {
        // create script
        script = document.createElement<"script">("script");
        if (script) {
            // @ts-ignore
            script.src = src;
            // @ts-ignore
            script.async = true;
            console.log("Script Loading Now", script);
            script.setAttribute("data-status", "loading");

            // inject the script at the end of the body
            document.body.appendChild(script);

            // set the script status in a custom attribute
            const setAttributeFromEvent = (event: any) => {
                script!.setAttribute("data-status", event.type === "load" ? "ready" : "error");
            };

            // assign the event listeners to monitor if script is loaded properly
            script.addEventListener("load", setAttributeFromEvent);
            script.addEventListener("error", setAttributeFromEvent);
        }
    }

    // helper function to update the script status
    const setStateFromEvent = (event: any) => {
    };

    // setup
    script.addEventListener("load", setStateFromEvent);
    script.addEventListener("error", setStateFromEvent);

    // clean up
    return () => {
        if (script) {
            script.removeEventListener("load", setStateFromEvent);
            script.removeEventListener("error", setStateFromEvent);
        }
    };
}