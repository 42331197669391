import {useEffect} from 'react'
import {useTranslation} from 'react-i18next';
import {faMagnifyingGlass,} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import InputGroup from "../group/InputGroup";
import PrimaryButton from "../button/PrimaryButton";
import {FieldValues, useForm} from "react-hook-form";
import {IFilter} from '../Icons';

function ReviewHistorySearch(props: { onCriteriaChanged: (data: FieldValues) => void, updateVisibility?: (visibility: boolean) => void, searchValue?: string, isProfessional?: boolean, filterParams: Record<string,string> }) {
    const { onCriteriaChanged, updateVisibility, searchValue, isProfessional, filterParams } = props;
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [t] = useTranslation();

    function onSubmit(data: FieldValues) {
        onCriteriaChanged(data);
    }

    useEffect(() => {
        if (!searchValue) {
            (document?.getElementById?.('searchTerm') as HTMLInputElement).value = '';
        }
    }, [searchValue])

    return (
        <div>
            <InputGroup>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={'flex gap-5 flex-col lg:flex-row '}>

                        <div className={"lg:w-4/5 flex gap-3 items-center bg-slate-50 rounded-sm border-0 pl-5 outline-0"}>
                            <FontAwesomeIcon icon={faMagnifyingGlass} color={"#8c8c8c7f"} className='w-5 h-5' />
                            <input
                                className={"grow w-auto bg-transparent border-0 outline-0 py-3 pr-3"}
                                placeholder={t("SEARCH")}
                                id={'searchTerm'}
                                type={'text'} {...register("searchTerm", {
                                })}
                            />
                            <button className='flex items-center justify-center w-[60px] h-[60px] bg-brand-color'>
                                <FontAwesomeIcon icon={faMagnifyingGlass} color='white' className='w-6 h-6' />
                            </button>
                        </div>

                        <div className={'grow [&>button]:h-[60px]'}>
                            <PrimaryButton outLine={true} type={'button'}
                                onClick={() => updateVisibility ? updateVisibility(true) : null}>
                                <div className='flex items-center justify-center relative h-full'>
                                    {Object.keys(filterParams).length > 0 && <div className='absolute top-[-8px] right-[-8px] rounded-full w-4 h-4 bg-lime-green' />}
                                    <IFilter />
                                    <span className='ml-3'>{t("FILTERS")}</span>
                                </div>
                            </PrimaryButton>
                        </div>

                    </div>
                </form>
            </InputGroup>
        </div>
    );
}

export default ReviewHistorySearch;
