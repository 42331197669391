import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {Controller, FieldValues, useForm} from "react-hook-form";
import SelectInput from "../input/SelectInput";
import {useDocument} from "../../contexts/patient/DocumentProvider";
import usePatient from "../../hooks/usePatient";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SubmitPrimaryButton from "../button/SubmitPrimaryButton";
import {useImmunization} from "../../contexts/patient/ImmunizationProvider";
import Spinner from "../spinner/Spinner";
import Calendar from '../calendar';
import {IWarning} from '../Icons';
import {Immunization, Status} from '../../types/database/SharedTypes';
import DocumentUploadInput from '../input/DocumentUploadInput';
import {ampli} from "../../ampli";
import {getOccurrenceHistoryDates, isLastOccurrenceAddedByApplication} from "../../util/immunization/immunization";
import {datesAreOnSameDay} from "../../util/date/convertDateString";


function ImmunizationEdit(props: { isEdit?: boolean, immunizationRecommendation: Immunization, updateModal?: (modalState: boolean) => void, title?: string, immunizationId?: string, status?: Status }) {
  const { isEdit, immunizationRecommendation, updateModal, title, immunizationId, status } = props;
    const [t, i18n] = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string>();
  const { documents, loadingDocuments, getPatientDocuments } = useDocument();
  const { updateImmunizations, editImmunization } = useImmunization();
  const { patient, uploadDocument, uploadImmunizationDocumentMetadata } = usePatient();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    watch
  } = useForm({
    defaultValues: { dateAdministered: undefined, linkedDocument: undefined, linkedDocument1: undefined },
  });

  async function onSubmit(data: FieldValues) {
    console.log(data, 'data')
    if(!immunizationId)
      return;
    setLoading(true);
    setSubmitError(undefined);
    let newDocument = null
    if (data?.linkedDocument1 && uploadImmunizationDocumentMetadata && uploadDocument && getPatientDocuments && immunizationId ) {
      try {
        const submittingDocument: File = data.linkedDocument1[0];
          const fullFileName = new Date().toLocaleDateString(i18n.resolvedLanguage) + "-" + data.linkedDocument1[0].name;
        const metaData = await uploadImmunizationDocumentMetadata(fullFileName, submittingDocument.type)
        await uploadDocument(submittingDocument, metaData.body.path)
        ampli.track({event_type:"Upload document success"});
        const documentListState = await getPatientDocuments(patient?.subject)
        const documentList = documentListState?.body?.items
        documentList.sort((a: any, b: any) => Number(new Date(b?.created?.date)) - Number(new Date(a?.created?.date)))
        newDocument = documentList?.[0]
        data.linkedDocument = documentList?.[0]?.id
      } catch(error: any) {
        ampli.track({event_type:"Upload document fail"});
        console.error("Error uploading document: ", error);
        setSubmitError("Error uploading document: " + error.message);
        setLoading(false);
        return;
      }
    }
    const attachment = newDocument
      ? newDocument?.content?.[0]?.attachment
      : documents?.find((document) => document.id === data.linkedDocument)?.content?.[0]?.attachment
    const occurrenceLength = immunizationRecommendation.occurrences?.length || 0;
    let lastOccurrenceNumber: number;

    const lastOccurrenceAddedByApplication = isLastOccurrenceAddedByApplication(immunizationRecommendation); //true
    if(lastOccurrenceAddedByApplication){
      lastOccurrenceNumber = occurrenceLength - 1;
    } else {
      lastOccurrenceNumber = occurrenceLength;
    }
    editImmunization && editImmunization(immunizationId, [data.dateAdministered], attachment, lastOccurrenceNumber, !!immunizationRecommendation.attachment, lastOccurrenceAddedByApplication && occurrenceLength > 0 ).then(() => {
      setLoading(false);
      if (isEdit){
        ampli.track({event_type:"Immunization: Edit"});
      }else {
        ampli.track({event_type:"immnuization: Upload"});
      }
      updateImmunizations && updateImmunizations();
      updateModal && updateModal(false);
    }).catch((error) => {
      setLoading(false);
      setSubmitError(error.message);
    });

  }
  const sortedDocuments = useMemo(() => {
    const updatedDocuments = documents || patient?.documents || [];
    return updatedDocuments.sort((a: any, b: any) => Number(new Date(b?.date)) - Number(new Date(a?.date)));
  }, [documents, patient?.documents]);

  useEffect(() => {
    ampli.track({event_type:"Immunization Recommendation: Screen opened"})
  }, [])

  const occurrenceHistoryDates = useMemo( ()=> getOccurrenceHistoryDates(immunizationRecommendation), [immunizationRecommendation])

  function doesDateMatchHistoricDate(selectedDate: Date){
    return occurrenceHistoryDates.some((occurence) => datesAreOnSameDay(occurence, selectedDate));
  }

/*  const dateAdmin = watch('dateAdministered');
  useEffect(() => {

    // @ts-ignore
    console.log(errors,occurrenceHistoryDates.map(date=>date.toDateString()).join(','),dateAdmin?.toISOString(),dateAdmin? !doesDateMatchHistoricDate(dateAdmin): false, "errors.dateAdministered");
  }, [errors, dateAdmin]);*/

  return (
    <form onSubmit={handleSubmit(onSubmit)} >
      { errors.dateAdministered?.type === "doesDateMatchHistoricDate" && (
        <div className='bg-primary-bg py-4 px-5'>
          <div className='flex items-center'>
            <div className='mr-3 w-8 h-8 rounded-full bg-white flex items-center justify-center'>
              <IWarning />
            </div>
            <span>{t("WE_ALREADY_HAVE_A_RECORD_ON_FILE_THAT_MATCHES_THIS_ENTRY_PLEASE_TRY_AGAIN")}:</span>
          </div>
          <div className='pl-11'>
            {occurrenceHistoryDates.map((occurence, i) => (
              <div key={i} className='flex items-center mt-[10px]'>
                <FontAwesomeIcon icon={faCheck} className='w-4 mr-2 text-lime-green' />
                  <span>{occurence.toLocaleDateString(i18n.resolvedLanguage)}</span>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className='px-12 pt-9 pb-12'>
        <div className='pb-4'>
          <label htmlFor="datepicker" className="font-medium text-xs pb-2">{t('DATE_ADMINISTERED')}<span
            className="required text-red-600">*</span></label>
          <Controller
            name="dateAdministered"
            control={control}
            rules={{ required: true, validate: {doesDateMatchHistoricDate: (value?: Date) => value? !doesDateMatchHistoricDate(value): true}}}
            render={({
              field,
            }) => (
              <Calendar
                isModal
                onChange={field.onChange}
                inputRef={field.ref}
                defaultDate={field.value}
                onClickEvent={() => ampli.track({event_type:"Immunization Recommendation: Date selected"})}
              />)}
          />

          <span className={'text-red-600'}>{errors['dateAdministered']?.type === 'required' &&
            <p role="alert">{t('DATE_ADMINISTERED_IS_REQUIRED')}</p>}</span>
        </div>
        {!watch('linkedDocument1') && (
          <>
            <div>
              <SelectInput inputLabel={t('DOCUMENT')} id={'document'} placeholder={'MM/DD/YYYY'}
                arial-description={t('DOCUMENT_TO_ADD_TO_IMMUNIZATION')}
                required={true} defaultValue={''}
                aria-invalid={errors.linkedDocument ? "true" : "false"}
                {...register("linkedDocument", {
                  required: true,
                })}
                onChange={() => ampli.track({event_type:"Immunization Recommendation: Document selected"})}
              >
                <option disabled value={''}>{t('SELECT')}</option>
                {sortedDocuments.map(({ id, content }: any) => {
                  return (<option key={id} value={id}> {content?.[0]?.attachment?.title || '---'} </option>);
                })}
              </SelectInput>
              <span className={'text-red-600'}>
                {errors.linkedDocument?.type === 'required' && <p role="alert">{t('DOCUMENT_IS_REQUIRED')}</p>}
              </span>
            </div>
            {loadingDocuments && <Spinner />}
            <span className={'text-red-600'}>{submitError}</span>
          </>
        )}

            <p className='mb-5'>{t('PLEASE_SELECT_FILE_TO_UPLOAD_PDF_DOC_JPG_PNG')}</p>
            <div className={'h-50'}>
              <Controller
                control={control}
                name="linkedDocument1"
                render={({
                  field,
                }) => (
                  <DocumentUploadInput ref={field.ref} onChange={field.onChange} onBlur={field.onBlur} />
                )}
              />
            </div>

        <div className={'w-40 ml-auto border mt-14 '}>
          <SubmitPrimaryButton isLoading={loading}>{t('SAVE')}</SubmitPrimaryButton>
        </div>
      </div>
    </form>
  );
}

export default ImmunizationEdit;