import React, {ReactNode} from 'react';
import {Link, To} from "react-router-dom";

function PrimaryLink(props: { to: To; children: ReactNode; [x: string]: any }) {
    const {to, children, ...extraProps} = props;
    return (
        <Link to={to} {...extraProps}>
            <span className={'text-brand-color'}>{children}</span>
        </Link>
    );
}

export default PrimaryLink;