import {Immunization, ImmunizationRecommendation} from "../types/database/SharedTypes";

export function splitFileName(filename: string) {
  const dotIndex = filename.lastIndexOf(".");

  // If there's no dot or it's the first/last character, return the filename as is
  if (dotIndex <= 0 || dotIndex === filename.length - 1) {
    return {
      name: filename,
      extension: ''
    };
  }

  return {
    name: filename.substring(0, dotIndex),
    extension: filename.substring(dotIndex + 1)
  };
}

export function getFilteredImmunization(immunizationsRecommendations: ImmunizationRecommendation[] = [], patientImmunizations: Immunization[] = []) {
  const sortedRecommendations = immunizationsRecommendations.sort((a, b) =>
    new Date(b?.date ?? '').getTime() - new Date(a?.date ?? '').getTime()
  );

  // Extract the newest recommendation
  const newestRecommendation = sortedRecommendations[0];
  if (!newestRecommendation || !newestRecommendation.recommendation) {
    return [];
  }

  const mappedRecommendations = newestRecommendation.recommendation.map((imr: any) => ({
    ...imr,
    id: newestRecommendation.id,
  }));

  // Match the immunizations with patient immunizations
  return mappedRecommendations.map((immunization) => {
    const vaccineCodeToMatch = immunization?.vaccineCode?.[0]?.coding?.[0]?.code;
    const matchedImmunizations = patientImmunizations.filter(actualImmunization => actualImmunization?.vaccineCode?.coding?.[0]?.code === vaccineCodeToMatch)
    matchedImmunizations.sort((a, b) => new Date(b?.date ?? '').getTime() - new Date(a?.date ?? '').getTime());

    return matchedImmunizations?.[0] || immunization;
  });
}