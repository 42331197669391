import React, {ReactNode, useMemo} from 'react';
import {useDocument} from "../../contexts/DocumentProvider";
import {parseDocumentPhoto} from "../../util/photo/parseDatabasePhotos";

function PractitionerDocumentDownloadLink(props: { children?: ReactNode, contentType?: string }) {
    const {children, contentType} = props;
    const {document} = useDocument();
    const documentBlob = useMemo(() => parseDocumentPhoto(document, contentType), [document]);

    return (
        <a href={documentBlob} target={'_blank'}>
            {children}
        </a>
    );
}

export default PractitionerDocumentDownloadLink;