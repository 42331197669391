import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import useCapitalizePrototype from "../../hooks/useCapitalizePrototype";
import {VirusLine} from '../Icons';

function VaccinationStatus(props: {status?: {color: string, display: string}; isPatient?: boolean}) {
    const { status,isPatient}  = props;
    const [t] = useTranslation();
    useCapitalizePrototype();
    enum Status {
        NeedsReview,
        Compliant,
        NonCompliant,
        Default,
    }
    const [vacStatus, setVacStatus] = useState<Status>(Status.Default);

    function getCSSColor() {

      switch(vacStatus){
          case Status.NeedsReview:
              return 'text-blue-500';
          case Status.Compliant:
              return 'text-green-500'
          case Status.NonCompliant:
              return 'text-violet-500'
          default:
            return `text-yellow-500`;
      }
  }    function getCSSBGColor() {

      switch(vacStatus){
          case Status.NeedsReview:
              return 'bg-blue-50';
          case Status.Compliant:
              return 'bg-green-50'
          case Status.NonCompliant:
              return 'bg-violet-50'
          default:
            return `bg-yellow-50`;
      }
  }function getCSSBorderColor() {

      switch(vacStatus){
          case Status.NeedsReview:
              return 'border-blue-500';
          case Status.Compliant:
              return 'border-green-500'
          case Status.NonCompliant:
              return 'border-violet-500'
          default:
            return `border-yellow-500`;
      }
  }
    function getStatusName() {

      switch(`${status?.color}:${status?.display}`){
          case "yellow:pending":
              return setVacStatus( Status.NeedsReview);
          case "green:evaluation":
              return setVacStatus( Status.Compliant);
          case "red:recommendation":
          case "red:evaluation":
              return setVacStatus( Status.NonCompliant);
          default:
            return setVacStatus( Status.Compliant);
      }
  }
  function titleForStatus() {
    switch(vacStatus){
        case Status.NeedsReview:
            return t('NEEDS_REVIEW');
        case Status.Compliant:
            return t('VACCINATION_STATUS_APPROVED_TITLE');
        case Status.NonCompliant:
            return t('VACCINATION_STATUS_NONCOMPLIANT_TITLE');
    }
  }
  function DescriptionForStatus() {
        if(!isPatient)
            return undefined;
        switch(vacStatus){
            case Status.NeedsReview:
                return 'Your account is under review';
            case Status.Compliant:
                return 'The immunization record has been marked as \"Compliant\" by LAUSD staff. This record is now compliant and will be removed from the Non-Compliant list';
            case Status.NonCompliant:
                return 'The immunization record remains \"Non-Compliant\" per LAUSD staff review. Please correct and re-upload information or discuss it with your school nurse via the \"chat\" feature or by calling your child\'s school.';
        }
  }



    useEffect(() => {
        getStatusName();
    },[status]);


    return (
        <div>

                <div className={'group static text-center'}>
                    { DescriptionForStatus() &&
                        <div className={`z-50  hidden absolute group-hover:block px-4 py-3 text-center top-0 left-0  rounded-lg max-w-46 text-sm   border ${getCSSBorderColor()}  ${getCSSColor()} ${getCSSBGColor()}`}
                             role="tooltip" >
                            {DescriptionForStatus()}

                        </div>
                    }
                    <div className={'flex items-center gap-2' + ' ' + getCSSColor() }>
                            <VirusLine/>
                        <span className={`text-sm whitespace-nowrap  ` + getCSSColor()}>{titleForStatus()}</span>
                    </div>

                </div>


        </div>
    );
}

export default VaccinationStatus;