import React, {useEffect, useState} from "react";
import {ServerReply} from "../../types/database/SharedTypes";
import {API} from "aws-amplify";
import {usePatient} from "./PatientProvider";

type DocumentContextType = {
    documents?: any[],
    updateDocuments?: () => void,
    loadingDocuments: boolean,
};

const DocumentContext = React.createContext<DocumentContextType>({loadingDocuments: true});
export const useDocument = () => React.useContext(DocumentContext);

export function DocumentProvider (props: { children: React.ReactNode }) {
    const [documents, setDocuments] = useState<any[]>();
    const [loadingDocuments, setLoadingDocuments] = useState<boolean>(true);
    const {patient} = usePatient();

    async function getPatientDocuments(patientId: string): Promise<ServerReply<{items: any[]}>> {
        const apiName = 'LAUSDDocumentEndpoint';
        const path = `/patient/document/reference/${patientId}`;
        const myInit = {
            body: {},
            headers: {}
        };
        return API.get(apiName, path, myInit);
    }
    function updateDocuments(){
        if( patient.subject) {
            setLoadingDocuments(true);
            return getPatientDocuments(patient.subject).then((documents) => {
                let items = documents.body.items;
                setDocuments(items);
            }).finally(() => setLoadingDocuments(false));
        }
    }

    useEffect(() => {
        if( patient.subject) {
            setLoadingDocuments(true);
            getPatientDocuments(patient.subject).then((documents) => {
                setDocuments(documents.body.items);
            }).finally(() => setLoadingDocuments(false));
        }
    }, [patient]);

    return (
        <DocumentContext.Provider value={{
            documents,
            updateDocuments,
            loadingDocuments,
        }}>
            {props.children}
        </DocumentContext.Provider>
    );
};