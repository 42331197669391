import React from 'react';
import ReactApexChart from "react-apexcharts";
import {ApexOptions} from "apexcharts";
import PercentChange from "./PercentChange";
import {useTranslation} from "react-i18next";

function ImmunizationOverviewChart(props: { percentChange: number, percentCompliant: number }) {
    const {percentChange, percentCompliant} = props;
    const [t] = useTranslation();
    const options: ApexOptions = {

        chart: {
            // height: 200,
            id: "ImmunizationOverview",
            type: 'radialBar',
        },
        states: {
            hover: {
                filter: {
                    type: 'none',
                }
            },
            active: {
                filter: {
                    type: 'none',
                }
            }

        },
        plotOptions: {

            radialBar: {

                dataLabels: {
                    name: {
                        show: true,
                        color: '#454545',
                        fontSize: '14px',
                        fontWeight: 400,
                        offsetY: 20
                    },
                    value: {
                        show: true,
                        fontSize: '40px',
                        fontWeight: 700,
                        offsetY: -20,
                        formatter: (val: number) => {
                            return `${val}%`;
                        },
                    },
                },
                hollow: {
                    margin: 0,
                    size: '65px',
                },
                track: {
                    background: '#9662d71a'
                },
            }
        },
        labels: [t('NON_COMPLIANT_STUDENTS')],
        fill: {
            colors: ['#9662D7']
        },
        stroke: {
            lineCap: 'round'
        },
    };

    function round(v: number) {
        return Math.sign(v) * Math.round(Math.sign(v) * v);

    }


    return (
        <div className={'relative aspect-square '}>
            <ReactApexChart options={options} series={[percentCompliant]} type="radialBar" height='100%'/>
            <div className={'top-[61%] left-1/2 -translate-x-1/2 -translate-y-1/2  absolute'}>
                <PercentChange percentChange={percentChange} roundingFunction={round}/>
            </div>
        </div>
    );
}

export default ImmunizationOverviewChart;