import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {parseDocumentPhoto} from "../../../util/photo/parseDatabasePhotos";
import ProfileSummary from "../../status/ProfileSummary";
import {ICalendar} from '../../Icons';
import TableView from "../TableView";
import ExpandableTableRow from "../ExpandableTableRow";
import PrimaryButton from '../../button/PrimaryButton';
import BookAppointment from '../../patient-dashboard/BookAppointment';
import Spinner from '../../spinner/Spinner';
import useAuth from '../../../hooks/useAuth';
import {useRefer} from '../../../contexts/practitioner/ReferProvider';
import {sendAutomatedSendbirdMessage, updateBothUserProfiles} from "../../../util/sendBird";
import usePatient from "../../../hooks/usePatient";
import MessageLoadingButton from '../../button/MessageLoadingButton';

function ProfessionalsTable(props: { professionals: any[], studentId?: string, getPhoto?: any, appointId?: string }) {
  const { professionals, getPhoto, appointId } = props;
  return (
    <>
      <TableView showPagination={true}>
        {professionals.map((professional) =>
          <ProfessionalRow
            key={professional.id}
            professional={professional}
            getPhoto={getPhoto}
            appointId={appointId}
            studentId={props.studentId}
          />
        )}
      </TableView>
    </>
  );
}

function ProfessionalRow(props: { professional: any,studentId?: string,  getPhoto?: any, appointId?: string }) {
  const [t] = useTranslation();
  const { userProfile } = useAuth();
  const { createRefer } = useRefer()
  const navigate = useNavigate()

  const { professional, getPhoto, appointId } = props;
  const [showModal, setShowModal] = useState(false);
  const [profilePhotoSrc, setProfilePhotoSrc] = useState<any>();
  const [isReferLoading, setIsReferLoading] = useState<boolean>(false)
  const [messageNavigateLoading, setMessageNavigateLoading] = useState<string>();
  const {patient} = usePatient();


  const updateModal = useCallback(
    (visibility: boolean) => setShowModal(visibility),
    [showModal]);

  async function handleCreateRefer() {
    try {
      setIsReferLoading(true)
      if (createRefer && appointId && userProfile && props.studentId) {
        await sendAutomatedSendbirdMessage(userProfile, props.studentId, t('message_recommend_meet_professional', {
          '_professional_name_': professional.name,
          '_professional_organizationname_': professional.organizationname
        }));
        await createRefer(professional?.id, appointId)
        navigate(`/professional/dashboard/messages/${props.studentId}`)
      }
    } catch (error) {
      console.log("Unable to create a refer!", error)
    } finally {
      setIsReferLoading(false)
    }
  }

  useEffect(() => {
    if (userProfile && professional?.photo) {
      getPhoto && getPhoto(userProfile as string, professional?.photo).then((value: any) => {
        setProfilePhotoSrc(parseDocumentPhoto(value.file))
      }).catch((reason: Error) => {
        console.error('Unable to get the photo: ', reason);
      })
    }
  }, []);

  function initiateMessagesView() {
    setMessageNavigateLoading(`${t('LOADING_MESSAGES')}...`);
    updateBothUserProfiles(userProfile||'', patient.name?.[0].text, patient.photo?.[0].url,professional?.id || '',professional?.name|| "", professional?.photo|| '' )
        .then(() => {
          navigate(`/patient/dashboard/messages/${professional?.id}`)
        }).finally(() =>  setMessageNavigateLoading(undefined));
  }
  return (
    <>
      <ExpandableTableRow>
        <td>
          <ProfileSummary src={profilePhotoSrc} name={String(professional?.name)} />
        </td>
        <td>
          {appointId ? (
            <div className='flex justify-end'>
              <div className='w-[110px]'>
                <PrimaryButton
                  outLine
                  onClick={() => handleCreateRefer()}
                >
                  <div className='flex items-center justify-center w-full'>
                    {!isReferLoading && <div className='bg-share-icon w-4 min-w-4 h-4 bg-contain mr-2' />}
                    {t('REFER')}
                    {isReferLoading && (
                      <div className='ml-2'>
                        <Spinner customSize='h-6 w-6' />
                      </div>
                    )}
                  </div>
                </PrimaryButton>
              </div>
            </div>
          ) : (
            <div className='flex justify-end'>
              <div className='w-[110px]'>
                <PrimaryButton onClick={() => updateModal(true)}>
                  <div className='flex items-center justify-center'>
                    <ICalendar />
                    <span className='ml-3'>{t('BOOK')}</span>
                  </div>
                </PrimaryButton>
              </div>
              <div className='ml-4'>
                <MessageLoadingButton
                  handleNavigateMessage={initiateMessagesView}
                  loadingMessage={messageNavigateLoading}
                />
              </div>
            </div>
          )}
        </td >
      </ExpandableTableRow >
      {showModal && (
        <BookAppointment
          displayModal={showModal}
          modalVisibilityChanged={updateModal}
          practitioner={{
            subject: professional.id,
            name: [{ text: professional.name }],
            organization: { name: professional.organizationname }
          }}
          srcImg={profilePhotoSrc}
        />
      )
      }
    </>
  )
}

export default ProfessionalsTable;