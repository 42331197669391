import React, {useState} from 'react';
import RightModal from "../dashboard-search/RightModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import SchoolImmunization from "../table/schoolImmunization/SchoolImmunization";
import {SchoolReport} from "./ImmunizationOverview";
import SchoolNonCompliantDetailRightModal from "./SchoolNonCompliantDetailRightModal";
import PractitionerSearchProvider from "../../contexts/PractitionerSearchProvider";

function SchoolListRightModal(props: {
    schoolComplianceReport: SchoolReport[],
    showModal: boolean,
    setShowModal: (showModal: boolean) => void
}) {
    const {showModal, setShowModal, schoolComplianceReport} = props;
    const [t] = useTranslation();
    const [selectedSchoolId, setSelectedSchoolId] = useState<string>();
    const [showSchoolDetail, setShowSchoolDetail] = useState<boolean>(false);

    function handleSchoolClicked(schoolId: string) {
        console.log("School Clicked");
        setSelectedSchoolId(schoolId);
        setShowSchoolDetail(true);
    }

    return (
        <RightModal isOpen={showModal} onClose={() => setShowModal(false)}>
            <div className={"w-[70rem] max-w-full "}>
                <div className="flex flex-col px-3  ">
                    <div className="flex items-center justify-between py-4">
                        <h2 className="text-xl font-semibold">{t('SCHOOL_IMMUNIZATION_OVERVIEW')}</h2>

                        <button type={'button'}
                                className="text-slate-400 hover:text-slate-500 transition duration-300 ease-in-out "
                                onClick={() => setShowModal(false)}
                        >
                            <FontAwesomeIcon icon={faXmark}/>
                        </button>
                    </div>

                    <SchoolImmunization schoolData={schoolComplianceReport} schoolClicked={handleSchoolClicked}
                                        showPagination={true}/>
                    {selectedSchoolId &&
                        <PractitionerSearchProvider>
                            <SchoolNonCompliantDetailRightModal setShowModal={setShowSchoolDetail}
                                                                schoolId={selectedSchoolId}
                                                                showModal={showSchoolDetail}/>
                        </PractitionerSearchProvider>
                    }
                </div>
            </div>
        </RightModal>
    );
}

export default SchoolListRightModal;