import {PerformanceDashboard as PerformanceDashboardController} from '../../../components/performance-dashboard';
import {PerformanceProvider} from '../../../contexts/practitioner/PerformanceProvider';

function PerformanceDashboard() {
    return (
        <PerformanceProvider>
            <PerformanceDashboardController />
        </PerformanceProvider>
    );
}

export default PerformanceDashboard;