import React, {createContext} from 'react';
import {API} from "aws-amplify";

export const AmplifyAPIContext = createContext<{
    services?: {
        resendVerificationEmail: (email: string) => Promise<any>;
        registerNewUser: (firstName: string, middleName: string, lastName: string, email: string, group: UserGroup) => Promise<any>
        submitProfileInformation: (email: string) => Promise<any>,
    }
}>({})

export enum UserGroup {
    PATIENT = "PATIENT",
    PRACTITIONER = "PRACTITIONER",
    ADMINISTRATOR = "ADMINISTRATOR",
}

function AmplifyApiProvider(props: { children: React.ReactElement<any, any> }) {
    const {children} = props;

    async function registerNewUser(firstName: string, middleName: string, lastName: string, email: string, group: UserGroup) {
        const path = '/user';
        const apiName = 'LAUSDRegistrationEndpoint';
        const tenant = 'lausd';
        const myInit = {
            body: {
                firstName,
                lastName,
                email,
                group: group.toString(),
                tenant,
                proxy: group === UserGroup.PATIENT,
            }
        };
        return API.post(apiName, path, myInit);
    }

    async function submitProfileInformation(): Promise<any> {
        const apiName = 'LAUSDEndpoint';
        const path = '/user';
        const myInit = {
            body: {},
            headers: {
                'x-api-key': 'yNTmArffmE9WMVgxMnXC83STq1OfMm5V7VqoDQqG'
            }
        };
        return true;
        //return  api.post(path, myInit.body);
    }

    async function resendVerificationEmail(email: string): Promise<any> {
        const apiName = 'LAUSDRegistrationEndpoint';
        const tenant = 'lausd';
        const path = `/account/confirmation?username=${encodeURIComponent(email)}&tenant=${tenant}`;
        const myInit = {
            body: {},
            headers: {}
        };
        return API.get(apiName, path, myInit);
    }

    const services = {
        registerNewUser,
        submitProfileInformation,
        resendVerificationEmail,
    }


    return (
        <AmplifyAPIContext.Provider
            value={{
                services
            }}
        >
            {children}
        </AmplifyAPIContext.Provider>
    );
}

export default AmplifyApiProvider;