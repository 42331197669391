import React, {useEffect, useState} from 'react';
import './App.css';
import {Link, Outlet} from 'react-router-dom';
import AmplifyApiProvider from "./contexts/AmplifyApiProvider";
import {AuthProvider} from "./contexts/AuthContext";
import awsconfig from "./util/config/aws-exports";
import {Amplify, Auth, Hub} from "aws-amplify";
import isDev from "./util/config/envDetect";
import Logo from "./components/logo/Logo";
import PrimaryButton from "./components/button/PrimaryButton";
import LoadingSplash from "./components/spinner/LoadingSplash";
import {ampli} from "./ampli";
import {useTranslation} from "react-i18next";


export let AWSConfigS: any;
export let LAUNCH_CONFIG: any;

function App() {
    const [amplifyConfigLoading, setAmplifyConfigLoading] = useState<boolean>(true);
    const [amplifyConfigError, setAmplifyConfigError] = useState<string>();
    const [oauthError, setOauthError] = useState<string>();
    const [t] = useTranslation();


    useEffect(() => {
        let hostname = isDev() ? 'http://localhost:8000': '';

        Hub.listen('auth', ({payload: {event, data}}) => {

            switch (event) {
                case 'customState_failure':
                case 'signUp_failure':
                    if(!oauthError) {
                        ampli.track({event_type: "Login Fail"});
                    }
                    setOauthError(t("THERE_WAS_AN_ISSUE_ACCESSING_YOUR_ACCOUNT_PLEASE_CONTACT_LAUSD_FOR_MORE_INFORMATION"));

                    console.error('Sign in failure', data, event);
                    break;
            }
        });

        fetch(`${hostname}/api/app/launch/lausd`).then(r => {
            if (r.ok) {
                return r.json()
            }
        }).then((serverResponse) => {
            return serverResponse.body;
        }).then((configure) => {
            const config = awsconfig(configure, hostname);
            AWSConfigS = config;
            LAUNCH_CONFIG = configure;
            Amplify.configure(config);
            console.log("App Launched Successfully");

            // Configure Amplitude
            ampli.load({environment: !!configure.config?.monitorUsage ? "prod" : "default"});
            ampli.track({event_type: "Open app"});

            setAmplifyConfigLoading(false);
        })  .catch((e) => {  setAmplifyConfigLoading(false); setAmplifyConfigError(e.message); console.error("Amplify Config Error: Unrecoverable",e)})


    }, []);

    if (amplifyConfigLoading) return (<div className={'h-screen w-screen'}><LoadingSplash/></div>);
    if (amplifyConfigError) {
        return (
            <div className={'h-full w-full flex justify-center  flex-col'}>
                <div className={'w-40 mx-auto mb-5'}>
                    <Logo/>
                </div>
                <div className={'mx-auto'}>

                    <span
                        className={'text-red-500'}>{t("UNABLE_TO_LAUNCH_THIS_APPLICATION_PLEASE_CONTACT_LAUSD_FOR_MORE_INFORMATION")}</span>
                    <Link to={'/'}><div className={'mt-5'}><PrimaryButton>{t("RETURN_HOME")}</PrimaryButton></div></Link>
                </div>
            </div>);

    }
    if (oauthError) {
        return (
            <div className={'h-full w-full flex justify-center  flex-col'}>
                <div className={'w-40 mx-auto mb-5'}>
                    <Logo/>
                </div>
                <div className={'mx-auto'}>
                    <span className={'text-red-500'}>{oauthError}</span>
                    <Link to={'/'}><div className={'mt-5'}><PrimaryButton onClick={()=> {Auth.signOut(); setOauthError(undefined);}}>Sign Out</PrimaryButton></div></Link>
                </div>
            </div>);

    }
    return (
        <AuthProvider>
            <AmplifyApiProvider>
                <Outlet/>
            </AmplifyApiProvider>
        </AuthProvider>
    );
}

export default App;
