import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import TabNavigation from "../../../components/navigation/TabNavigation";
import NavTab from "../../../components/navigation/NavTab";
import SearchContainer from "../../../components/dashboard-search/SearchContainer";
import {FieldValues} from "react-hook-form";
import PractitionerFilterComponent from "../../../components/dashboard-search/PractitionerFilterComponent";
import {Clinics} from "../../../components/patient-dashboard";
import {usePatientSearch} from '../../../contexts/PatientSearchProvider';
import ProfessionalsTable from "../../../components/table/Professionals/ProfessionalsTable";
import InputGroup from "../../../components/group/InputGroup";
import LoadingSplash from "../../../components/spinner/LoadingSplash";
import ProfessionalsMobile from '../../../components/table/Professionals/ProfessionalsMobile';
import usePatient from '../../../hooks/usePatient';
import {ampli} from "../../../ampli";
import useAuth from "../../../hooks/useAuth";

function ProfessionalSearchContent() {
    const [t] = useTranslation();
    const { getPhoto } = usePatient()
    const {userGroup} = useAuth();
    const [activeTab, setActiveTab] = useState<string>('professional');
    const [showFilter, setShowFilter] = useState(false);
    const updateVisibility = useCallback((visibility: boolean) => setShowFilter(visibility), []);
    const {
        professionalSearchTerm,
        setProfessionalSearchTerm,
        clinicsSearchTerm,
        setClinicsSearchTerm,
        setFilterParams,
        fetchingPractitioners,
        practitioners
    } = usePatientSearch()

    function searchChanged(data: FieldValues) {
        ampli.track({event_type:"Search by keyword"}, {extra:{ keyword: data.searchTerm, userType: userGroup}});

        if (activeTab === 'professional') {
            setProfessionalSearchTerm && setProfessionalSearchTerm(data.searchTerm);
        }
        if (activeTab === 'clinics') {
            setClinicsSearchTerm && setClinicsSearchTerm(data.searchTerm);
        }
        setFilterParams && setFilterParams(undefined);
    }

    const updateSearchCriteria = useCallback((data: FieldValues) => searchChanged(data), [activeTab]);

    useEffect(() => {
        ampli.track({event_type:"Route to Home Screen's Get Care"});
    }, []);
    const handleClickTab = (tab: string) => {
        setActiveTab(tab)
        setProfessionalSearchTerm && setProfessionalSearchTerm('')
        setClinicsSearchTerm && setClinicsSearchTerm('')
    }
    useEffect(() => {
        if(showFilter) {
            ampli.track({event_type:"Filter button tapped"}, {extra:{ userType: userGroup}});
        }
    }, [showFilter]);

    return (
        <div>
            <div>
                <h1 className="font-semibold text-xl">{t('GET_CARE')}</h1>
            </div>
            <div className="flex flex-col mt-4">
                <SearchContainer
                    onCriteriaChanged={updateSearchCriteria}
                    // updateVisibility={updateVisibility}
                    searchValue={activeTab === 'professional' ? professionalSearchTerm : clinicsSearchTerm}
                />
                <PractitionerFilterComponent showFilter={showFilter} updateVisibility={updateVisibility} />
            </div>
            <div className={'mt-4'}>
                <TabNavigation>
                    <NavTab
                        title={t('PROFESSIONALS')}
                        clicked={() => handleClickTab('professional')}
                        active={activeTab === 'professional'}
                    />
                    <NavTab
                        title={t('CLINICS')}
                        clicked={() => handleClickTab('clinics')}
                        active={activeTab === 'clinics'}
                    />
                </TabNavigation>
                {activeTab === 'clinics' ? <Clinics /> : ''}
                {activeTab === 'professional' ?
                    <InputGroup>
                        {!fetchingPractitioners ?
                            <div>
                                <div className="lg:block hidden">
                                    <ProfessionalsTable
                                        professionals={practitioners || []}
                                        getPhoto={getPhoto}
                                    />
                                </div>

                                <div className="block lg:hidden">
                                    <ProfessionalsMobile
                                        professionals={practitioners || []}
                                        getPhoto={getPhoto}
                                    />
                                </div>
                            </div> : <LoadingSplash showLogo={false} />}
                    </InputGroup> : null}
            </div>
        </div>
    )
}


export default ProfessionalSearchContent;