import PrimaryButton from "./PrimaryButton";
import Spinner from "../spinner/Spinner";
import React, {ReactNode} from "react";

export default function SubmitPrimaryButton(props: { isLoading: boolean; loadingText?: string, children: ReactNode; }) {
    const {isLoading, children, loadingText} = props;
    return (
        <PrimaryButton type={'submit'} disabled={isLoading}>
            {isLoading ? <> <Spinner/> {loadingText}</> : children}
        </PrimaryButton>
    );
}