import React, {useState} from 'react';
import ImageUploading, {ImageListType} from 'react-images-uploading';

function UploadImageInput(props: {
    title: string;
    maxImageSize?: number;
    newImageError?: (error: string) => void;
    imageAdded: (image: ImageListType) => void;
    icon?: React.ReactElement,
}) {
    const {icon, title, maxImageSize = 3145728, imageAdded, newImageError} = props;
    const [images, setImages] = useState<ImageListType>([]);

    function onChange(imageList: ImageListType, addUpdateIndex?: number[]) {
        if (maxImageSize > (imageList?.[0]?.file?.size || 0)) {
            setImages(imageList);
            imageAdded(imageList);
        } else {
            if (newImageError) {
                newImageError("FILE_SIZE_OVER_LIMIT");
            }
        }
    }


    return (
        <div className={''}>
            <ImageUploading
                value={images}
                onChange={onChange}

                dataURLKey="data_url"
            >
                {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                        <div
                            className={`border-brand-color py-3 text-brand-color text-center border-2 border-dashed relative min-h-[5rem] w-full ${imageList.length === 0 ? 'flex justify-center' : 'bg-brand-color bg-opacity-10'}`}
                            style={isDragging ? {color: 'red'} : undefined}
                            onClick={onImageUpload}
                            {...dragProps}
                        >
                            {
                                imageList.map((image, index) => (
                                    <div key={index} className="image-item relative h-full max-w-sm mx-auto">
                                        <img className={'mx-auto'} src={image["data_url"]} alt="" width="100"/>
                                        <button
                                            className={'static text-white bg-brand-color  p-2 top-0 right-0 absolute'}
                                            onClick={onImageRemoveAll}>X
                                        </button>


                                    </div>
                                ))
                            }{imageList.length === 0 && icon} <span className={'pl-3 my-auto'}>{title}</span>
                        </div>
                        &nbsp;


                    </div>
                )}
            </ImageUploading>
        </div>
    );
}

export default UploadImageInput;