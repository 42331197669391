import React, {useEffect, useState} from 'react';

interface PaginationProps<T> {
  items?: T[];
  itemsPerPage: number;
  onPageChange: (page: number) => void;
  maxPagesToShow?: number;
}

function Pagination<T>({
  items = [],
  itemsPerPage,
  onPageChange,
  maxPagesToShow = 10,
}: PaginationProps<T>) {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const totalPages: number = Math.ceil(items.length / itemsPerPage);
  const [visiblePages, setVisiblePages] = useState<number[]>([]);

  const filterPages = (visiblePages: number[], totalPages: number) => {
    return visiblePages.filter(page => page <= totalPages);
  }

  const getVisiblePages = (page: number, total: number) => {
    if (total < 7) {
      return filterPages([1, 2, 3, 4, 5, 6], total);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, 5, total];
      }
    }
  }

  const changePage = (page: number) => {
    if (page === currentPage) {
      return;
    }
    setCurrentPage(page);
    const _visiblePages: number[] = getVisiblePages(page, totalPages);
    setVisiblePages(filterPages(_visiblePages, totalPages));
    onPageChange(page);
  }

  useEffect(() => {
    const _visiblePages = getVisiblePages(currentPage, totalPages);
    setVisiblePages(filterPages(_visiblePages, totalPages));
  }, [currentPage, totalPages])

  return (
    <div className="flex justify-center mt-4 gap-1">
      {visiblePages.map((page, index, array) => (
        <React.Fragment key={page}>
          {array[index - 1] + 2 <= page ? (
            <>
              <span
                key={index}
                className="px-2 py-1 mx-1 text-sm text-gray-500 rounded-md "
              >
                ...
              </span>

              <button
                className={`w-8 h-8 rounded-full flex items-center justify-center cursor-pointer focus:outline-none ${page === currentPage
                  ? 'bg-orange-500 text-white'
                  : 'bg-gray-200'
                  }`}
                onClick={() => changePage(page)}
              >
                {page}
              </button>
            </>
          ) : (
            <button
              className={`w-8 h-8 rounded-full flex items-center justify-center cursor-pointer focus:outline-none ${page === currentPage
                ? 'bg-orange-500 text-white'
                : 'bg-gray-200'
                }`}
              onClick={() => changePage(page)}
            >
              {page}
            </button>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

export default Pagination;
