import ProfileImage, {ProfileStatuses} from './ProfileImage';
import {parseDocumentPhoto} from '../../util/photo/parseDatabasePhotos';
import useProfessional from '../../hooks/useProfessional';
import useAuth from '../../hooks/useAuth';

import {useEffect, useState} from 'react';

function StudentProfileSummary(props: { patient?: any, isOnlyPhoto?: boolean }) {
  const { patient, isOnlyPhoto } = props

  const { getPhoto } = useProfessional();
  const { userProfile } = useAuth();
  const [profilePhotoSrc, setProfilePhotoSrc] = useState<any>()

  useEffect(() => {
    if (userProfile && patient?.photo?.[0]?.url) {
      patient?.photo?.sort((a: any, b: any) => Number(new Date(b?.creation)) - Number(new Date(a?.creation)))
      getPhoto && getPhoto(userProfile, patient.photo[0].url).then((value: any) => {
        setProfilePhotoSrc(parseDocumentPhoto(value.file))
      }).catch((reason: Error) => {
        console.error('Unable to get the photo: ', reason);
      })
    }
  }, [patient])

  return (
    <div className={`flex items-center py-5`}>
      <div className='w-[52px]'>
        <ProfileImage src={profilePhotoSrc} status={patient?.active ? ProfileStatuses.active : ProfileStatuses.inactive} />
      </div>
      {!isOnlyPhoto && (
        <div className="flex flex-col flex-1 ml-4">
          <p className="flex-1 text-base font-semibold text-heading">{patient?.name?.[0]?.text}</p>
        </div>
      )}
    </div>
  );
}

export default StudentProfileSummary;
