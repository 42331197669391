import {useEffect, useRef, useState} from 'react';
import CenterModal from "../dashboard-search/CenterModal";
import PrimaryButton from "../button/PrimaryButton";
import ImageUploading, {ImageListType} from 'react-images-uploading';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCamera, faClose} from '@fortawesome/free-solid-svg-icons'
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
import usePatient from '../../hooks/usePatient';
import useProfessional from '../../hooks/useProfessional';
import Spinner from '../spinner/Spinner';
import {useTranslation} from 'react-i18next';

function EditPhoto(props: {
  open: boolean,
  onClose: () => void,
  isPatient?: boolean,
  defaultPhoto?: string
}) {
  const { open, onClose, isPatient, defaultPhoto } = props;
  const [t] = useTranslation();
  const  { patient, updateStudentProfilePhoto, updatePatientPhoto, addStudentProfileMetadata } = usePatient()
  const { professional, updateProfilePhoto, updateProfessionalPhoto, addPractitionerProfileMetadata } = useProfessional()
  
  const maxImageSize = 2097152
  const [cropImage, setCropImage] = useState(defaultPhoto);
  const [images, setImages] = useState<ImageListType>([]);
  const [newImageError, setNewImageError] = useState<string>('');
  const cropperRef = useRef(null);
  const [cropper, setCropper] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function onChange(imageList: ImageListType, addUpdateIndex?: number[]) {
    if (maxImageSize > (imageList?.[0]?.file?.size || 0)) {
      setImages(imageList);
      if (cropper) {
        cropper.replace(imageList?.[0]?.["data_url"]);
      } else {
        setCropImage(imageList?.[0]?.["data_url"]);
      }
      setNewImageError('')
    } else {
      if (newImageError) {
        setNewImageError("FILE_SIZE_OVER_LIMIT");
      }
    }
  }

  function getRoundedCanvas(sourceCanvas: any) {
    const canvas = document.createElement('canvas');
    const context: any = canvas.getContext('2d');
    const width = sourceCanvas.width;
    const height = sourceCanvas.height;
    canvas.width = width;
    canvas.height = height;
    context.imageSmoothingEnabled = true;
    context.drawImage(sourceCanvas, 0, 0, width, height);
    context.globalCompositeOperation = 'destination-in';
    context.beginPath();
    context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
    context.fill();
    return canvas;
  }

  async function handleSavePhoto() {
    setIsLoading(true)
    let _photo = cropper.getCroppedCanvas({ imageSmoothingQuality: 'high' })
    _photo = getRoundedCanvas(_photo).toDataURL()
    if (isPatient) {
      if (updateStudentProfilePhoto) {
        let path = patient.photo?.[0]?.url
        if (!path && addStudentProfileMetadata) {
          const { body } = await addStudentProfileMetadata(patient?.subject)
          path = body.path
        }
        await updateStudentProfilePhoto((_photo.replace('data:', '').replace(/^.+,/, '') || ''), path)
        updatePatientPhoto && updatePatientPhoto(path);
        onClose();
      }
    } else {
      if (updateProfilePhoto) {
        let path = professional?.photo?.[0].url
        if (!path && addPractitionerProfileMetadata) {
          const { body } = await addPractitionerProfileMetadata()
          path = body.path 
        }
        await updateProfilePhoto((_photo.replace('data:', '').replace(/^.+,/, '') || ''), (path || ''))
        updateProfessionalPhoto && updateProfessionalPhoto();
        onClose();
      }
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (!cropImage || !cropperRef?.current) return
    const _cropper = new Cropper(cropperRef?.current, {
      aspectRatio: 1,
      viewMode: 1,
      dragMode: 'move',
      zoomable: false,
      toggleDragModeOnDblclick: false,
      autoCropArea: 1,
      movable: false
    });
    setCropper(_cropper);
  }, [cropImage])

  return (
    <CenterModal
      isOpen={open}
      onClose={onClose}
    >
      <div className='py-8 px-6 sm:px-12 max-h-[90vh] overflow-auto relative'>
        <div className={'flex absolute justify-center items-center top-4 right-4'}>
            <FontAwesomeIcon icon={faClose} className={'h-4 w-4 px-1 py-1 cursor-pointer'} onClick={onClose} />
        </div>
        <p className="text-2xl font-semibold">{t('EDIT_PHOTO')}</p>
        <div className="pt-8 pb-10">
          {cropImage && (
            <div className='h-[350px] w-[calc(100vw-48px)] sm:w-[500px] circle-shape-cropper-container'>
              <img
                ref={cropperRef}
                src={cropImage}
                className='w-full h-full object-contain'
                alt=''
              />
            </div>
          )}
          {!cropImage && (
            <ImageUploading
              value={images}
              onChange={onChange}
              dataURLKey="data_url"
            >
              {({
                onImageUpload,
                isDragging,
                dragProps,
              }) => (
                <div className="upload__image-wrapper">
                  <div
                    className='flex flex-col items-center justify-center rounded cursor-pointer border-brand-color py-3 border-2 border-dashed relative h-[350px] w-[calc(100vw-48px)] sm:w-[500px]'
                    style={isDragging ? { color: 'red' } : undefined}
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    <FontAwesomeIcon
                      icon={faCamera}
                      className='text-brand-color w-10 h-10'
                    />
                    <p className='mt-6 text-lg'>
                      <span className='text-brand-color'>{t('BROWSE')}</span> {t('OR_DRAG_DROP')}
                    </p>
                  </div>
                </div>
              )}
            </ImageUploading>
          )}
        </div>
        {newImageError && <span className='mb-2 text-red-500 text-base block'>{newImageError}</span>}
        <div className="flex flex-col sm:flex-row">
          {cropImage && (
            <ImageUploading
              value={images}
              onChange={onChange}
              dataURLKey="data_url"
            >
              {({
                onImageUpload,
                isDragging,
                dragProps,
              }) => (
                <div
                  className='w-full sm:w-[220px]'
                  style={isDragging ? { color: 'red' } : undefined}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  <PrimaryButton outLine>
                    {t('UPLOAD_DIFFERENT_PHOTO')}
                  </PrimaryButton>
                </div>
              )}
            </ImageUploading>
          )}
          <div className='w-full sm:w-[165px] ml-auto mt-3 sm:mt-0'>
            <PrimaryButton
              onClick={() => handleSavePhoto()}
              disabled={!cropImage}
            >
              <div className={`flex items-center justify-center ${!cropImage && 'cursor-not-allowed'}`}>
                <span>{t('SAVE_PHOTO')}</span>
                {isLoading && <Spinner customSize={'w-6 h-6 ml-3'} />}
              </div>
            </PrimaryButton>
          </div>
        </div>
      </div>
    </CenterModal>
  )
}

export default EditPhoto;
