import {useMemo, useState} from "react";
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faCheckCircle, faCircle, faMagnifyingGlass, faXmark} from "@fortawesome/free-solid-svg-icons";
import PrimaryButton from "../button/PrimaryButton";
import useProfessional from "../../hooks/useProfessional";
import Spinner from "../spinner/Spinner";

function SchoolFilter(props: { updateVisibility: (visibility: boolean) => void, schoolIds: string[], showNavigation?: boolean,setValue: (name: string, value: string) => void , maximumSelectableSchools?: number }) {
  const { updateVisibility, setValue, schoolIds, showNavigation=true , maximumSelectableSchools=3} = props;

  const [t] = useTranslation()
  const {viewableSchools, schoolList} = useProfessional();

  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedIds, setSelectedIds] = useState<string[]>(schoolIds)

  const filteredSchoolList = useMemo(() => {
    return (viewableSchools.length > 0 ?  viewableSchools : schoolList?.schools)?.filter((item: any) => item?.name?.toLowerCase().includes(searchValue.toLowerCase())).slice(0, 100) || []
  }, [viewableSchools, schoolList?.schools, searchValue])

  function handleSave () {
    selectedIds?.length > 0 && setValue('organizationid\:exact', selectedIds.join('|'))
    updateVisibility(false)
  }

  function handleClickSchool(id: string) {
    if ( selectedIds.length >= maximumSelectableSchools && !selectedIds.includes(id)) return
    let updateSelectedIds = []
    if (selectedIds.includes(id)) updateSelectedIds = selectedIds.filter((item: string) => item !== id)
    else updateSelectedIds = [...selectedIds, id]
    setSelectedIds(updateSelectedIds)
  }

  return (
    <div className="p-6 flex flex-col h-full">
        {showNavigation && <> <div className="flex items-center justify-between mb-[27px]">
        <div className="flex items-center text-brand-color cursor-pointer" onClick={() => updateVisibility(false)}>
          <FontAwesomeIcon icon={faArrowLeft} className='w-4 mr-[10px]' />
          <span className="font-medium">{t('BACK')}</span>
        </div>
        <button type={'button'}
          className="text-slate-400 hover:text-slate-500 transition duration-300 ease-in-out "
          onClick={() => updateVisibility(false)}
        >
          <FontAwesomeIcon icon={faXmark} />
        </button>
      </div>
      <h2 className="font-semibold mb-5">{t('SCHOOLS')}</h2>
        </>
}
      <div className={"w-full flex gap-3 items-center bg-slate-50 rounded-sm border-0 p-4 outline-0 mb-2"}>
        <FontAwesomeIcon icon={faMagnifyingGlass} color={"#8C8C8C"} className="w-[17px] h-[17px]" />
        <input
          className={"grow w-auto bg-transparent border-0 outline-0 text-sm"}
          placeholder={t('SEARCH_SCHOOLS')}
          type={'text'}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </div>
      <div className="flex-1 overflow-x-hidden min-h-[200px]">
        {!schoolList?.loading && !schoolList?.error && filteredSchoolList?.length > 0 && filteredSchoolList
          .map((school: any) => (
            <div
              key={school?.id}
              onClick={() => handleClickSchool(school?.id)}
              className="flex items-center justify-between py-4 pr-[7px] hover:bg-[#f8a03a0d] border-b-[1px] border-[#F4F4F4] cursor-pointer"
            >
              <div className="flex items-center">
                <div className="flex items-center justify-center bg-neutral-100 w-8 h-8 min-w-[32px] rounded-full mr-4">
                  <div className="bg-clinic-icon w-[14px] h-[14px] bg-contain" />
                </div>
                <span className="text-sm font-medium">{school?.name}</span>
              </div>
              <FontAwesomeIcon
                icon={selectedIds.includes(school?.id) ? faCheckCircle : faCircle}
                color={selectedIds.includes(school?.id) ? "#73C92D" : "#F4F4F4"}
                className="w-5 h-5"
              />
            </div>
          ))}
        {!schoolList?.loading && !schoolList?.error && filteredSchoolList?.length === 0 && (
          <span className="block mt-2 text-[#8C8C8C]">{t('NO_SEARCH_MATCHES')}</span>
        )}
        {schoolList?.loading && (
          <div className="flex justify-center mt-5">
            <Spinner />
          </div>
        )}
        {!schoolList?.loading && schoolList?.error && (
          <p className="mt-5">{schoolList.error}</p>
        )}
      </div>
      <div className="mt-5 w-full">
        <PrimaryButton onClick={handleSave}>{t('SAVE')}</PrimaryButton>
      </div>
    </div>
  )
};

export default SchoolFilter;