import {UserUpdateParams} from "@sendbird/chat";
import {sendbird} from "./index";

export async function updateSendBirdMetadata(userProfile: string, name: string, profileUrl: string) {
    try {
        const user = await sendbird.connect(userProfile);
        const params: UserUpdateParams = {
            nickname: name,
            profileUrl: profileUrl,
        };
         await sendbird.updateCurrentUserInfo(params);
        // The user is connected to the Sendbird server.
    } catch (err) {
        // Handle error.
    }
}
export async function updateBothUserProfiles(userProfile: string, name: string, profileUrl: string, otherUserProfile: string, otherName: string, otherProfileUrl: string) {
    try {
        await updateSendBirdMetadata(otherUserProfile, otherName, otherProfileUrl);
        await sendbird.disconnect();
        return await updateSendBirdMetadata(userProfile, name, profileUrl);
    } catch (err) {
        // Handle error.
    }
}