import {ISearchPlus} from "../Icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CenterModal from "../dashboard-search/CenterModal";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from 'react-i18next'

function SavedSearchNotification(props: {
  open: boolean,
  onClose: () => void,
}) {
  const [t] = useTranslation()
  const { open, onClose } = props;

  return (
    <CenterModal
      isOpen={open}
      onClose={() => onClose()}
    >
      <div className='p-5 flex flex-col items-center justify-center sm:w-[400px] sm:h-[278px] relative'>
        <button type={'button'}
          className="text-slate-400 w-5 h-5 absolute top-5 right-5 hover:text-slate-500 transition duration-300 ease-in-out"
          onClick={onClose}
        >
          <FontAwesomeIcon icon={faXmark} />
        </button>
        <div className="w-[120px] h-[120px] flex items-center justify-center rounded-full bg-opacity-5 bg-brand-color">
            <ISearchPlus />
        </div>
        <p className="mt-8 text-xl font-semibold text-center">{t('SEARCH_WAS_SAVED')}</p>
      </div>
    </CenterModal>
  )
}

export default SavedSearchNotification;
