import React, {useMemo} from "react";
import {PractitionerProvider, usePractitioner} from "../../../contexts/practitioner/PractitionerProvicer";
import {parseDocumentPhoto} from "../../../util/photo/parseDatabasePhotos";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-regular-svg-icons";
import {useTranslation} from "react-i18next";

export function StatusBy(props: { evaluation: any }) {
    const { evaluation } = props;
  return <>
    {evaluation?
       <PractitionerProvider practitionerId={evaluation?.evaluator}
        photoUrl={evaluation?.evaluator?.photo?.[0]?.url}>
        <PractitionerProfileSummary evaluation={evaluation} />
      </PractitionerProvider>
     : '---'
    }
  </>
}

function PractitionerProfileSummary(props: { evaluation: any }) {
  const { evaluation } = props;
  const { practitionerProfilePhoto, practitioner } = usePractitioner();
  const [t, i18n] = useTranslation();
  const profilePhotoSrc = useMemo(() => parseDocumentPhoto(practitionerProfilePhoto), [practitionerProfilePhoto]);

  return <>
    <div className='flex items-center mt-4'>
      <div className='mr-4 w-12 h-12 flex justify-center aspect-square bg-neutral-100 bg rounded-full overflow-hidden'>
        {profilePhotoSrc ? (
          <img src={profilePhotoSrc} className={'object-center  object-cover'} alt='practitioner' />
        ) : (
          <FontAwesomeIcon icon={faUser} className={'h-2/5 my-auto'} />
        )}
      </div>
      <div>
        <span className='block text-base font-semibold'>{practitioner?.name?.[0]?.text || 'no name'}</span>
        <span className='block text-xs mt-[6px] text-[#8C8C8C]'>
           {new Date(evaluation?.date + "Z").toLocaleString(i18n.resolvedLanguage, {
             month: 'numeric',
             day: 'numeric',
             year: 'numeric',
             hour: '2-digit',
             minute: '2-digit'

           })}
        </span>
      </div>
    </div>
  </>
}