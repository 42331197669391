import React from 'react';
import InputGroup from "../group/InputGroup";
import {usePatientSearch} from "../../contexts/PatientSearchProvider";
import ClinicsTable from "../table/clinics/ClinicsTable";
import ClinicsMobile from '../table/clinics/ClinicsMobile';
import LoadingSplash from "../spinner/LoadingSplash";

function Clinics() {
    const { clinicList, fetchingClinics } = usePatientSearch();

    return (
        <section>
            <div className={''}>
                <InputGroup>
                    {!fetchingClinics ? (
                        <>
                            <div className="lg:block hidden">
                                <ClinicsTable
                                    clinics={clinicList || []}
                                />
                            </div>
                            <div className="block lg:hidden">
                                <ClinicsMobile clinics={clinicList || []} />
                            </div>
                        </>
                    )
                        : <LoadingSplash showLogo={false} />}
                </InputGroup>
            </div>
        </section>
    );
}

export default Clinics;
