import {LandingPage} from './pages';
import App from './App';
import {Navigate, RouteObject} from 'react-router-dom';
import {Login} from './pages/login';
import Register from './pages/login/Register';
import compose from './util/compose';
import withBasicAuth from './hocs/withBasicAuth';
import UpdatePassword from './pages/login/UpdatePassword';
import RegisterPane from './components/login/RegisterPane';
import StudentRegister from './pages/login/StudentRegister';
import LoadingSplash from './components/spinner/LoadingSplash';
import Professional from './pages/professional/Professional';
import Onboarding from './pages/professional/onboarding/Onboarding';
import Patient from './pages/patient/Patient';

import {Notifications, Profile,} from './components/professional-dashboard';
import withProfessional from "./hocs/withProfessional";
import StudentDetail from "./pages/professional/dashboard/StudentDetail";
import StudentSearch from "./pages/professional/dashboard/StudentSearch";
import withPatient from "./hocs/withPatient";
import {default as ProfessionalDashboard} from './pages/professional/dashboard/Dashboard';
import {default as PatientDashboard} from './pages/patient/dashboard/Dashboard';
import Messaging from "./pages/patient/dashboard/Messaging";
// import ProfessionalDetail from "./pages/patient/dashboard/ProfessionalDetail";
import PractitionerSearchView from "./pages/patient/dashboard/PractitionerSearchView";
import AppointmentDetail from "./pages/professional/dashboard/AppointmentDetail";
import Immunization from "./pages/patient/dashboard/Immunization";
import AppointmentsList from './pages/patient/dashboard/AppointmentsList';
import OauthLogin from "./pages/login/OauthLogin";
import Message from "./pages/Message";
import ProfessionalRefer from './pages/professional/dashboard/ProfessionalRefer';
import {default as HelpCenterPatient} from "./pages/patient/dashboard/HelpCenter";
import {default as HelpCenterPractitioner} from "./pages/professional/dashboard/HelpCenter";
import ManageAvalibilityView from "./pages/professional/dashboard/ManageAvalibilityView";
import PerformanceDashboard from './pages/professional/dashboard/PerformanceDashboard';
import MySchoolList from './pages/professional/dashboard/MySchoolList';
import ChildList from './pages/patient/dashboard/ChildList';
import Logout from "./pages/login/Logout";
import isDev from "./util/config/envDetect";
import AdminDashboard from "./pages/professional/dashboard/AdminDashboard";

const SuperLanding = compose(withBasicAuth)(LandingPage);
const StudentInfoRegister = compose(withBasicAuth)(StudentRegister);

const PractitionerView = compose(withBasicAuth)(Professional);
const OnboardingView = compose(withBasicAuth, withProfessional)(Onboarding);
const DashboardView = compose(withBasicAuth, withProfessional)(ProfessionalDashboard);

const PatientView = compose(withBasicAuth)(Patient);
const PatientDashboardView = compose(withBasicAuth, withPatient)(PatientDashboard);

function routes(): RouteObject[] {

    return [
        {
            path: "/",
            element: <App/>,
            children: [
                {index: true, element: <SuperLanding/>},
                {path: 'login', element: <Login/>},
                {path: 'logout', element: <Logout/>},
                {path: 'login/oauth', element: <OauthLogin/>},
                {path: 'update-password', element: <UpdatePassword/>},
                {
                    path: 'register', element: <Register/>,
                    children: [
                        {index: true, element: <RegisterPane/>},
                        {path: 'patient', element: <StudentInfoRegister/>}
                    ]
                },
                {
                    path: "/professional",
                    element: <PractitionerView/>,
                    children: [
                        {index: true, element: <div className={'h-screen w-screen'}><LoadingSplash/></div>},
                        {
                            path: 'onboarding/:stage?',
                            element: <OnboardingView/>,
                        },
                        {
                            path: 'dashboard',
                            element: <DashboardView/>,
                            children: [
                                {index: true, element: <Navigate to='/professional/dashboard/students' />},
                                {path: 'students', element: <StudentSearch/>,},
                                {path: 'students/:studentId', element: <StudentDetail/> },
                                {path: 'appointments', element: <AppointmentDetail/>,},
                                {path: 'appointments/student/:studentId', element: <StudentDetail/> },
                                {path: 'appointments/:studentId/:appointId', element: <ProfessionalRefer/> },
                                {path: 'appointments/manage-availability', element: <ManageAvalibilityView/>,},
                                {path: 'messages', element: <Messaging/>},
                                {path: 'messages/:subject', element: <Message/>,},
                                {path: 'notifications', element: <Notifications/>},
                                ...(isDev() ? [{path: 'performance-dashboard', element: <PerformanceDashboard/>}] : []),
                                {path: 'admin-dashboard', element: <AdminDashboard/>},
                                {path: 'help-center', element: <HelpCenterPractitioner/>,},
                                {path: 'profile', element: <Profile/>},
                                {path: 'my-school', element: <MySchoolList/>},
                            ],
                        },
                    ]
                },
                {
                    path: "/patient",
                    element: <PatientView/>,
                    children: [
                        {index: true, element: <div className={'h-screen w-screen'}><LoadingSplash/></div>},
                        {
                            path: 'dashboard',
                            element: <PatientDashboardView/>,
                            children: [
                                {index: true, element: <ChildList />},
                                {path: 'get-care', element: <PractitionerSearchView/>,},
                                {path: 'appointments', element: <AppointmentsList/>,},
                                // {path: 'professional/:professionalId', element: <ProfessionalDetail/>},
                                {path: 'messages', element: <Messaging/>,},
                                {path: 'messages/:subject', element: <Message/>,},
                                {path: 'immunizations', element: <Immunization/>,},
                                {path: 'help-center', element: <HelpCenterPatient/>,},

                            ],
                        },
                    ]
                }
            ]
        },
    ];
}

export default routes;
