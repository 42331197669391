import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next'
import {AlternateInputRadio} from "../input/AlternateInputRadio";
import {UseFormReturn} from "react-hook-form";
import {ScheduleSlot, useSchedule} from "../../contexts/patient/ScheduleProvider";
import {getSlotDuration} from "../../util/date/convertDateString";

function StartTime(props: { form:UseFormReturn<any>}) {
    const {form} = props;
    const [t, i18n] = useTranslation();
    const {scheduleSlots} = useSchedule();
    interface TimeSlotPair {
         displayed: ScheduleSlot;
         hidden: ScheduleSlot;
    }
    function firstFreeSlots(scheduleSlots: ScheduleSlot[], increments= [30, 0]) : TimeSlotPair[] {
       return scheduleSlots.map((slot, index): (TimeSlotPair|undefined) => {

           const nextSlot = scheduleSlots?.[index+1];
              if (slot.status=== 'free' && nextSlot && nextSlot.status === 'free' && nextSlot.start === slot.end && getSlotDuration(slot) === getSlotDuration(nextSlot)) {
                 return { displayed: slot, hidden: nextSlot };
              }
       }).filter((slot) => slot !== undefined) as TimeSlotPair[];

    }

    const startingScheduleSlots = useMemo(() => firstFreeSlots(scheduleSlots||[]), [scheduleSlots]);


    return (
        <>{ startingScheduleSlots &&  startingScheduleSlots.length > 0 ?
        <div className="overflow-y-auto w-full mt-3 flex flex-col gap-3">
            <ul className={'grid grid-cols-2 gap-3 '}>
                {startingScheduleSlots.map((slot, index) => (
                    <li key={slot.displayed.id} >
                        <AlternateInputRadio
                            id={slot.displayed.id}
                            inputLabel={new Date(slot.displayed.start).toLocaleTimeString(i18n.resolvedLanguage, {
                                hour: '2-digit',
                                minute: '2-digit'
                            })}
                            optionValue={slot.displayed.id+ "|" + slot.hidden.id}
                            {...form.register('startTime', {required: true})}
                        />
                    </li>
                ))}
            </ul>
        </div> :
            <div className={'h-full'}>
                <span className={'text text-neutral-400'}>{t('No available slots')}</span>
            </div>}
            </>
    );
}

export default StartTime;