import ReactApexChart from "react-apexcharts";
import {IShareForwardLine} from "../Icons";
import {useTranslation} from "react-i18next";
import {ApexOptions} from "apexcharts";
import {memo, useEffect, useState} from "react";
import TimeView from "./TimeView";

function Appointments(props: { data: any }) {
  const [t] = useTranslation();

  const { data } = props;

  const [chartId, setChartId] = useState('myChart');
  const [series, setSeries] = useState<number[]>([])

  const options: ApexOptions = {
    chart: {
      // height: 200,
      id: chartId,
      type: 'donut',
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            show: true,
            color: '#454545',
            fontSize: '14px',
            offsetY: 20
          },
          value: {
            show: true,
            fontSize: '40px',
            fontWeight: 700,
            offsetY: -20,
            formatter: (val: number) => {
              return `${data.new_patients + data?.old_patients}`;
            },
          }
        },
        hollow: {
          margin: 0,
          size: '65px',
        },
        track: {
          background: 'rgba(115, 201, 45, 0.20)'
        },
      }
    },
    labels: ['Appointments'],
    fill: {
      colors: ['#73C92D']
    },
    stroke: {
      lineCap: 'round'
    },
  }

  useEffect(() => {
    setSeries([parseInt(`${data.new_patients / (data.old_patients + data.new_patients) * 100}`)])
    setChartId(`myChart-${new Date().getTime()}`);
  }, [data])

  return (
    <div className="bg-white rounded-[4px] w-full md:w-[340px] mt-6 mr-6 flex flex-col justify-between">
      <div className="py-6 px-10">
        <h2 className="text-lg font-semibold">{t('APPOINTMENTS')}</h2>
        <div className="h-[250px]">
          <ReactApexChart options={options} series={series} type="radialBar" height='100%' />
        </div>
        <div>
          <div className="flex items-center mb-2">
            <div className="w-4 h-4 mr-3 bg-[#73c92d33] rounded-full" />
            <p className="text-sm mb-0 font-normal"><span className="font-medium">{data?.old_patients}</span> - Old patients</p>
          </div>
          <div className="flex items-center">
            <div className="w-4 h-4 mr-3 bg-[#73C92D] rounded-full" />
            <p className="text-sm mb-0 font-normal"><span className="font-medium">{data?.new_patients}</span> - New patients</p>
          </div>
        </div>
        <div className="border border-[#73C92D] rounded-lg py-4 px-6 mt-4">
          <p className="text-base mb-6">Average appointment duration</p>
          <TimeView time={data?.duration} />
        </div>
        <div className="border border-[#9662D7] rounded-lg py-4 px-6 mt-4">
          <p className="text-base mb-6">Reffered appointments</p>
          <div className="flex items-center">
            <IShareForwardLine color="#9662D7" />
            <span className="ml-3 text-[40px] font-bold">{data?.reffered_appointments}</span>
          </div>
        </div>
      </div>
      <div className="bg-[#73c92d33] py-3 px-10 text-sm">
        You are in the top 1% of professionlas who have taken appointments.
      </div>
    </div>
  )
}

export default memo(Appointments);