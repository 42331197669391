import {Auth} from "aws-amplify";

const AWSConfig = (launch: any, hostname:string) => ({

    "Auth": {

        // REQUIRED - Amazon Cognito Region
        region: launch.region,

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: launch.auth.userPoolId,


        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: launch.auth.clientId,
        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType: launch.auth.authFlow,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,

        // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
        // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
        signUpVerificationMethod: "code", // 'code' | 'link'


        // OPTIONAL - Hosted UI configuration
        oauth: {
            domain: new URL(launch.auth.domain).hostname,
            scope: launch.auth.scopes,
            redirectSignIn: `${window.location.origin}/login/oauth`,
            redirectSignOut: `${window.location.origin}/logout`,
            responseType: "token", // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
    },
    API: {
        endpoints: [
            {
                name: "LAUSDEndpoint",
                endpoint: `${hostname}/api/patient`,
                custom_header: async () => {
                    return {Authorization: `Bearer ${ (await Auth.currentSession()).getIdToken().getJwtToken() }`}
                },
            },
            {
                name: "LAUSDPractitionerEndpoint",
                endpoint: `${hostname}/api/practitioner`,
                custom_header: async () => {
                    return {Authorization: `Bearer ${ (await Auth.currentSession()).getIdToken().getJwtToken() }`}
                },
            },
            {
                name: "LAUSDDocumentEndpoint",
                endpoint: `${hostname}/api/doc`,
                custom_header: async () => {
                    return {Authorization: `Bearer ${ (await Auth.currentSession()).getIdToken().getJwtToken() }`}
                    // return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                },
            },
            {
                name: "LAUSDAppEndpoint",
                endpoint: `${hostname}/api/app`,
                custom_header: async () => {
                    return {Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`}
                    // return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                },
            },
            {
                name: "LAUSDAdminEndpoint",
                endpoint: `${hostname}/api/admin`,
                custom_header: async () => {
                    return {Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`}
                    // return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                },
            },
            {
                name: "LAUSDRegistrationEndpoint",
                endpoint: `${hostname}/api/app`,
            }
        ]
    }
});
export default AWSConfig;
