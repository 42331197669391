import React, {ReactNode} from 'react';

function InputGroup(props: { children?: ReactNode, className?:string }) {
    const {children,className} = props;
    return (
        <div className={'shadow  py-6 px-5 lg:px-10 bg-white'+ ' ' + className}>
            {children}
        </div>
    );
}

export default InputGroup;