import React, {useEffect} from 'react';
import Messaging from "./patient/dashboard/Messaging";
import {useParams} from "react-router-dom";
import {createOrReuseGroupChannel} from "../util/sendBird";
import useAuth from "../hooks/useAuth";

function Message(props: {}) {
    const {subject} = useParams();
    const {userProfile} = useAuth();
    useEffect(() => {
        if(subject && userProfile){
            createOrReuseGroupChannel(userProfile, subject)
        }
    }, [subject, userProfile]);
    return (
        <Messaging subject={subject}/>
    );
}

export default Message;