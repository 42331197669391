import {useEffect, useRef, useState} from "react";
import {ITranslate} from "../Icons";
import {useTranslation} from "react-i18next";
import CenterModal from "../dashboard-search/CenterModal";

function LanguageSelector() {
  const [t, i18n] = useTranslation();
  const [active, setActive] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [top, setTop] = useState<string>('')

  const elementRef = useRef<any>();

  function handleChangeLanguage(langKey: string) {
    setActive(false);
    setShowModal(true);
    i18n.changeLanguage(langKey);
    window.localStorage.setItem('lang_key', langKey);
    setTimeout(() => {
      setShowModal(false);
    }, 1000);
  }

  function handleClickBtn() {
    const top = elementRef?.current?.getBoundingClientRect()?.top;
    setTop(`${top}px`);
    setActive(prev => !prev);
  }

  function handleClose(e: MouseEvent) {
    if (!active) return;
    if (e.target && !elementRef?.current?.contains(e.target)) {
      setActive(false);
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleClose);
    return () => window.removeEventListener('click', handleClose);
  }, [active]);

  return (
    <>
      <div>
        <button
          className={`flex flex-row rounded-sm w-full py-3 px-5 ${active && '!bg-[#f8a03a0d]'} hover:bg-[#f8a03a0d]`}
          ref={elementRef}
          onClick={handleClickBtn}
        >
          <div className={'rounded-full bg-opacity-5 px-2.5 py-2'}>
            <ITranslate />
          </div>
          <div className={'ml-3 text-base my-auto'}>
            <span>{t('LANGUAGE')}</span>
          </div>
        </button>
        {active && (
          <>
            <div className='fixed bottom-0 right-0 top-0 left-[320px] w-full bg-black opacity-50 hidden lg:block' />
            <div className='absolute top-0 bg-white left-[320px] w-[280px] hidden lg:block' style={{ top }}>
              <div
                  className={`cursor-pointer py-4 px-8 border-b font-semibold ${i18n.language === 'en-us' && 'bg-brand-color text-white'}`}
                  onClick={() => handleChangeLanguage('en-us')}
              >
                {t('ENGLISH')}
              </div>
              <div
                  className={`cursor-pointer py-4 px-8 font-semibold ${i18n.language === 'es-us' && 'bg-brand-color text-white'}`}
                  onClick={() => handleChangeLanguage('es-us')}
              >
                {t('SPANISH')}
              </div>
            </div>
          </>
        )}
        <div className="block lg:hidden flex items-center px-5">
          <div
              className={`border flex-1 flex py-3 items-center justify-center ${i18n.language === 'en-us' && 'bg-[#f8a03a0d] text-brand-color border-brand-color'}`}
              onClick={() => handleChangeLanguage('en-us')}
          >
            English
          </div>
          <div
              className={`border flex-1 flex py-3 items-center justify-center ${i18n.language === 'es-us' && 'bg-[#f8a03a0d] text-brand-color border-brand-color'}`}
              onClick={() => handleChangeLanguage('es-us')}
          >
            Spanish
          </div>
        </div>
      </div>
      <CenterModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      >
        <div className="flex flex-col items-center justify-center px-12 py-12 w-[90vw] md:w-fit">
          <div className={'rounded-full bg-opacity-5 p-8 text-brand-color bg-[#f8a03a0d]'}>
            <ITranslate width="47" height="44" />
          </div>
          <p className="max-w-[300px] text-center font-semibold text-xl mt-6">We are updating the interface language</p>
        </div>
      </CenterModal>
    </>
  )
};

export default LanguageSelector;