import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBookMedical, faPen} from "@fortawesome/free-solid-svg-icons";
import {faCalendar, faUser} from "@fortawesome/free-regular-svg-icons";
import PrimaryButton from "../button/PrimaryButton";
import DocumentOpenLink from "../link/DocumentOpenLink";
import {DocumentProvider} from "../../contexts/DocumentProvider";
import {usePatient} from '../../contexts/PatientProvider';
import useAuth from '../../hooks/useAuth';
import {parseDocumentPhoto} from "../../util/photo/parseDatabasePhotos";
import {StatusBy} from '../table/immunization/StatusBy';
import {getDateFromString} from '../../util/date/convertDateString';
import EvaluationStatus from "../immunization/EvaluationStatus";
import {getLastEvaluation} from "../../util/immunization/immunization";

function ImmunizationView(props: { immunizationTitle: string, isNotDone?: boolean, immunization: any, updateModal?: (modalState: boolean) => void, isProfessionalView?: boolean, setShowEdit?: (modalState: boolean) => void }) {
    const { immunizationTitle, immunization, updateModal, isNotDone= true, isProfessionalView, setShowEdit } = props;
    const [t, i18n] = useTranslation();

    const reducedOccurrences = useMemo(() => immunization.occurrences ? immunization.occurrences.map((occurrence: string) => getDateFromString(occurrence).toLocaleDateString(i18n.resolvedLanguage)) : ['---'], [immunization.occurrences]);
    return (
        <div className={'min-h-[40vh]'}>
            <div className={'mt-4 mb-8 px-12'}>
                <ul>
                    {reducedOccurrences.map((occurrence: string, index: number) =>
                        <li key={index}>
                            <FontAwesomeIcon icon={faCalendar} className={'text-brand-color' + ' ' + 'h-5   my-auto mr-3'} />
                            <span className={'text-sm'}>
                                {t('DATE_ADMINISTERED')}: 
                                <b>
                                    {occurrence}
                                </b>
                            </span>
                        </li>)}
                </ul>
            </div>
            <div className={'flex flex-row mb-6 px-12'}>
                <FontAwesomeIcon icon={faBookMedical} className={'text-violet-500' + ' ' + 'h-5   mr-3'} />
                <span className={'text-sm'}>
                    <b>
                        {immunization?.attachment?.[0]?.title || 'No Document Name'}
                    </b>
                </span>


            </div>
            {immunization?.attachment?.[0]?.url &&
                <div className='px-12'>
                    <div className={'w-[144px] ml-8'}>
                        <DocumentProvider photoUrl={immunization?.attachment?.[0]?.url}>
                            <DocumentOpenLink contentType={immunization?.attachment?.[0]?.contentType}>
                                <PrimaryButton outLine={true}>{t('OPEN_DOCUMENT')}</PrimaryButton>
                            </DocumentOpenLink>
                        </DocumentProvider>
                    </div>
                </div>
            }
            <div className='flex items-center bg-zinc-50 px-12 h-12 mt-8 mb-[20px]'>
                <span className='text-sm font-medium'>{t('STATUS')}</span>
            </div>
            {immunization?.evaluations?.length > 0 ? (
                <div className='px-12'>
                    <EvaluationStatus evaluation={getLastEvaluation(immunization)} />
                    {isProfessionalView ? (
                        <StatusBy evaluation={getLastEvaluation(immunization)} />
                    ) : (
                        <ProfileSummary evaluation={getLastEvaluation(immunization)} />
                    )}
                    <div className='mt-4 bg-[#F4F4F4] h-[1px]' />
                    {getLastEvaluation(immunization)?.description && (
                        <div className='mt-4 overflow-y-auto'>
                            <span className='block font-medium mb-[6px]'>{t('MESSAGE')}:</span>
                            <span className='text-base'>{getLastEvaluation(immunization)?.description}</span>
                        </div>
                    )}
                </div>
            ) : (
                immunization?.temp_evaluation ? (
                    <div className='px-12'>
                        <EvaluationStatus evaluation={{doseStatus: { coding: [{ code: immunization?.temp_evaluation?.code }]}}} />
                        {immunization?.temp_evaluation?.description && (
                            <div className='mt-4 overflow-y-auto'>
                                <span className='block font-medium mb-[6px]'>{t('MESSAGE')}:</span>
                                <span className='text-base'>{immunization?.temp_evaluation?.description}</span>
                            </div>
                        )}
                    </div>
                ) : (
                    <span className='text-sm text-[#8C8C8C] px-12'>{t('NO_STATUS')}</span>
                )
            )}

            {isNotDone && immunization?.evaluations?.length > 0 && getLastEvaluation(immunization)?.doseStatus.coding?.[0]?.code === 'notvalid' && !isProfessionalView && (
                <div className='px-12 mb-12'>
                    <div className='w-[170px] mt-10'>
                        <PrimaryButton
                            outLine
                            onClick={() => setShowEdit && setShowEdit(true)}
                        >
                            <FontAwesomeIcon icon={faPen} className={'4-4 my-auto mr-3'} />
                            <span>{t('EDIT_VACCINATION')}</span>
                        </PrimaryButton>
                    </div>
                </div>
            )}
        </div>
    );
}

function ProfileSummary(props: { evaluation: any }) {
    const { evaluation } = props
    const { getPractitionerInfo, getPhoto } = usePatient()
    const { userProfile } = useAuth()
    const [profilePhotoSrc, setProfilePhotoSrc] = useState<any>();
    const [practitionerName, setPractitionerName] = useState();
    const [t, i18n] = useTranslation();

    async function getPractitioner() {
        try {
            if (evaluation?.evaluator && getPractitionerInfo) {
                const practitioner = await getPractitionerInfo(evaluation.evaluator);
                if (practitioner?.body?.photo?.[0]?.url && getPhoto) {
                    const value = await getPhoto(userProfile as string, practitioner?.body?.photo?.[0]?.url)
                    setProfilePhotoSrc(parseDocumentPhoto(value.file))
                }
                setPractitionerName(practitioner?.body?.name?.[0]?.text)
            }
        } catch (error) {
            console.log('unable to get professional info: ', error)
        }
    }

    useEffect(() => {
        getPractitioner()
    }, [])

    return (
        <div className='flex items-center mt-4'>
            <div className='mr-4 w-12 h-12 flex justify-center aspect-square bg-neutral-100 bg rounded-full overflow-hidden'>
                {profilePhotoSrc ? (
                    <img src={profilePhotoSrc} className={'object-center  object-cover'} alt='practitioner' />
                ) : (
                    <FontAwesomeIcon icon={faUser} className={'h-2/5 my-auto'} />
                )}
            </div>
            <div>
                <span className='block text-base font-semibold'>{practitionerName || 'no name'}</span>
                <span className='block text-xs mt-[6px] text-[#8C8C8C]'>
                    {new Date(evaluation?.date + "Z").toLocaleString(i18n.resolvedLanguage, {
                        month: 'numeric',
                        day: 'numeric',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'

                    })}
                </span>
            </div>
        </div>
    )
}

export default ImmunizationView;