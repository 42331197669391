import {useState} from 'react';
import {useParams} from "react-router-dom";
import {useEvaluation} from '../../contexts/practitioner/EvaluationProvider';
import CenterModal from "../dashboard-search/CenterModal";
import {FieldValues, useForm} from "react-hook-form";
import TextAreaInput from "../input/TextAreaInput";
import SubmitPrimaryButton from "../button/SubmitPrimaryButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from 'react-i18next';
import {usePatient} from '../../contexts/practitioner/PatientProvider';

function ImmunizationEvaluation(props: { immunizationId: string, isEvaluationForApprove: boolean, updateModal?: (modalState: boolean) => void, showModalInitial?: boolean, step?: number, isReview?: boolean }) {
	const { updateModal, showModalInitial = false, immunizationId, isEvaluationForApprove, step=0, isReview } = props;
	const { studentId } = useParams();

	const [t] = useTranslation();
	const { handleGetImmunizationEvaluations, evaluatePatientImmunization } = useEvaluation();
	const [loading, setLoading] = useState(false);
	const { getPatient, setPatient, reviewImmunizations, setReviewImmunizations } = usePatient()
	const [submitError, setSubmitError] = useState<string>();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	async function onSubmit(data: FieldValues) {
		const status = isEvaluationForApprove
			? {
				code: "valid",
				display: "Valid",
				system: "http://terminology.hl7.org/CodeSystem/immunization-evaluation-dose-status"
			} : {
				code: "notvalid",
				display: "Not valid",
				system: "http://terminology.hl7.org/CodeSystem/immunization-evaluation-dose-status"
			}
		const description = data.message;
		const body = {
			immunizationEvent: immunizationId,
			doseStatus: status,
			description
		}
		if (isReview) {
			const updatedReviewImmunizations = reviewImmunizations.map((immunization: any, i: number) => {
				if (i === step) {
					const tempEvaluation = { code: isEvaluationForApprove ? 'valid' : 'notvalid', description}
					return {...immunization, temp_evaluation: tempEvaluation }
				}
				return immunization
			})
			setReviewImmunizations && setReviewImmunizations(updatedReviewImmunizations)
			updateModal && updateModal(false);
			return
		}
		setSubmitError(undefined);
		setLoading(true);
		if (studentId) {
			evaluatePatientImmunization && evaluatePatientImmunization(studentId, body).then(async (value) => {
				if (getPatient) {
					const value = await getPatient(studentId)
					setPatient && setPatient(value.body);
				}
				setLoading(false);
				updateModal && updateModal(false);
			}).catch((reason) => {
				setLoading(false);
				setSubmitError(reason?.message)
				console.error("Unable to update the status", reason);
			});
		}
	}

	return (
		<CenterModal isOpen={showModalInitial} onClose={() => updateModal && updateModal(false)}>
			<div className={'flex flex-row justify-end'}><FontAwesomeIcon icon={faClose} className={' h-4 p-4'} onClick={() => updateModal && updateModal(false)} /></div>
			<form onSubmit={handleSubmit(onSubmit)} className={'p-4'}>
				<article
					className={'flex flex-col h-full justify-between overflow-y-auto lg:px-8 min-w-max lg:min-w-[40vw]'}
				>
					<div className="flex flex-col mb-8">
						<div className="flex items-center justify-between py-3">
							<h1 className="text-xl font-semibold">
								{isEvaluationForApprove ? 'Approving' : 'Rejecting'}
							</h1>
						</div>
						<span>
							{isEvaluationForApprove ? 'Any question or reminder for student.' : 'Inform the student why their immunization record has been rejected. '}
						</span>
					</div>
					<div>
						<TextAreaInput
							inputLabel={'Message'}
							id={'message'}
							rows={"6"}
							placeholder={'Send the parent a message'}
							required={!isEvaluationForApprove}
							type={'text'} {...register("message", {
								required: !isEvaluationForApprove,
							maxLength:5000,
						})}
						/>
						<span className={'text-red-600'}>
							{errors['message']?.type === 'required' && <p role="alert">{t('MESSAGE_IS_REQUIRED')}</p>}
						</span>
						<span className={'text-red-600'}>
							{errors['message']?.type === 'maxLength' && <p role="alert">{t('MESSAGE_IS_TO_LONG_MAXIMUM_5000')}</p>}
						</span>
					</div>
					<span className={'text-red-600'}>{submitError}</span>
					<div className={'w-40 ml-auto border mt-14'}>
						<SubmitPrimaryButton isLoading={loading || !studentId}>{t('SUBMIT')}</SubmitPrimaryButton>
					</div>
				</article>
			</form>
		</CenterModal>
	);
}

export default ImmunizationEvaluation;