import {useMemo} from "react";
import {ITimerLine} from "../Icons";

function TimeView(props: { time: number }) {
  const { time } = props;

  const calculatedTime = useMemo(() => {
    const absMinute = Math.abs(time)
    let hours: string | number = Math.floor(absMinute / 60)
    let minutes: string | number = Math.floor(absMinute - (hours * 60))
    if (hours < 10) { hours = '0' + hours }
    if (minutes < 10) { minutes = '0' + minutes }
    return { hours: parseInt(`${hours}`), minutes: parseInt(`${minutes}`) }
  }, [time])

  return (
    <div className="flex items-center">
      <ITimerLine color={calculatedTime?.hours > 24 ? '#F8A03A' : '#73C92D'} />
      <span className="ml-3 text-[40px] font-bold">
        {calculatedTime?.hours > 0 && <>{calculatedTime?.hours} <span className="text-lg font-semibold mr-2">h</span></>}
         {calculatedTime?.minutes} <span className="text-lg font-semibold">min</span>
      </span>
    </div>     
  )
}

export default TimeView;