import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faShare, faXmark} from '@fortawesome/free-solid-svg-icons';
import {faClock} from '@fortawesome/free-regular-svg-icons';

const AppointmentStatus = ({
                              appointmentstatus,
                              appointmentdate,
                              appointmenttime,
                             appointmentReferer,
                             isProfessional
}: {
  appointmentstatus?: string;
  appointmentdate?: string;
  appointmenttime?: string;
  appointmentReferer?: string;
  isProfessional?: boolean
}) => {
  const [t] = useTranslation();
  const getStatusIcon = () => {
    switch (appointmentstatus) {
      case 'proposed':
        case 'pending':
        return faClock;
      case 'accepted':
        return faClock;
      case 'cancelled':
        return faXmark;
      case 'referred':
        return faShare;
      case 'done':
        return faCheck;
      default:
        return faClock;
    }
  };

  function getStatusColor(status?: string) {
    switch (status) {
      case 'proposed':
      case 'pending':
        return 'text-blue-600';
      case 'accepted':
        return 'text-yellow-400';
      case 'cancelled':
        return 'text-red-600';
      case 'done':
        return 'text-gray-400';
      default:
        return 'text-yellow-400';
    }
  };

  function getStatusText(status?: string) {
    switch (status) {
      case 'proposed':
        return isProfessional ? t('REQUESTED') : t('PENDING');
      case 'booked':
        return isProfessional ? t('SCHEDULED') : t('CONFIRMED');
      case 'cancelled':
        return t('CANCELED');
      case 'referred':
        return t('REFERRED');
      case 'done':
        return t('DONE');
      default:
        return status;
    }
  }

  return (
    <div className={'flex items-top gap-2'}>
      <FontAwesomeIcon
        icon={getStatusIcon()}
        className={`text-sm mt-1 ` + getStatusColor(appointmentstatus)}
      />
      <p
        className={`text-md ` + getStatusColor(appointmentstatus)}
        style={{ textTransform: 'capitalize' }}
      >
        {appointmentstatus === 'accepted' ? (
          appointmentdate &&
          appointmenttime && (
            <div className="grid text-sm text-gray-950">
              <span className="font-semibold">
                {appointmentdate}
              </span>
              <span>{appointmenttime}</span>
            </div>
          )
        ) : (
          <span
            className={appointmentstatus === 'referred' ? 'text-gray-950' : ''}
          >
            {getStatusText(appointmentstatus)}
          </span>
        )}
      </p>
    </div>
  );
};

export default AppointmentStatus;
