import {useContext} from "react";
import {AuthContext} from "../contexts/AuthContext";

export default function useAuth() {
    const {
        signOut,
        signIn,
        user,
        userLoading,
        userGroup,
        authError,
        userProfile,
        getUserGroup,
        isInAdminGroup,
        completeNewPassword,
    } = useContext(AuthContext);

    return {
        user,
        signOut,
        completeNewPassword,
        isInAdminGroup,
        userGroup,
        userLoading,
        authError,
        userProfile,
        getUserGroup,
        signIn
    };
}
