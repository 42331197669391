import React, {useEffect} from 'react';
import {LAUNCH_CONFIG} from "../../App";
import {useNavigate} from "react-router-dom";
import LoadingSplash from "../../components/spinner/LoadingSplash";

function Logout() {
    const navigate = useNavigate();
    useEffect(() => {

        const logoutUrl = LAUNCH_CONFIG?.auth.providerInfo?.PATIENT?.logout;
        if(logoutUrl) {
            window.location.href = logoutUrl;
        } else {
            navigate('/login');
        }

    }, []);
    return (
        <div>
            <LoadingSplash/>
        </div>
    );
}

export default Logout;