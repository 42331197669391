import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useImmunization} from "../../contexts/patient/ImmunizationProvider";
import {Immunization, Status} from "../../types/database/SharedTypes";
import Spinner from "../spinner/Spinner";

import InputGroup from "../group/InputGroup";
import PrimaryButton from "../button/PrimaryButton";
import {getImmunizationStatus, getLastUpdateDate} from "../../util/immunization/immunization";
import PatientImmunizationItem from "./PatientImmunizationItem";
import {usePatient} from "../../contexts/PatientProvider";


function ImmunizationView(props: {}) {
    const [t] = useTranslation();
    const {patient} = usePatient()
    const { immunizations: patientImmunizations, loadingImmunizations, updateImmunizations, markImmunizationAsCompleted } = useImmunization();
    const [submittingForReviewLoading, setSubmittingForReviewLoading] = useState<boolean>(false)
    const [submitForReviewError, setSubmitForReviewError] = useState<string>();

    const immunizationSubmissionCompletionStatus = useMemo(() => {
        if(loadingImmunizations){
            return 'not-done';
        }
        if (patient && patient.immunizationStatus === 'green:evaluation' || patient.immunizationStatus === 'green:recommendation'){
            return 'compliant';
        }

        const submissionsNotDone = patientImmunizations?.some((immunization) => immunization.status === 'not-done');
        if(submissionsNotDone) {
            return 'not-done';
        }

        const submissionsCompleted = patientImmunizations?.every((immunization) => immunization.status === 'completed');
        if(submissionsCompleted) {
            return 'waiting-for-approval';
        }

        return 'compliant';

    }, [patientImmunizations, loadingImmunizations, patient]);



    const lastUpdateDate = useMemo(() => {
       const compareDates = (a: Immunization, b: Immunization) => {
           const aDate = new Date(getLastUpdateDate(a.updated));
           const bDate = new Date(getLastUpdateDate(b.updated));
           if (!getLastUpdateDate(b.updated))
                return -1;
            return aDate < bDate ? 1 : aDate > bDate ? -1 : 0;
        };

        const information = patientImmunizations?.sort(compareDates);
        const lastUpdateDate1 = getLastUpdateDate(information?.[0]?.updated);
        if(!lastUpdateDate1){
            return t("NEVER");
        }

        return  new Date(lastUpdateDate1).toLocaleDateString();
    }, [patientImmunizations]);

    function getBaskGroundColorFromSubmittingStatus() {
        switch (immunizationSubmissionCompletionStatus) {
            case 'compliant':
                return 'bg-lime-500';
            case 'waiting-for-approval':
                return 'bg-sky-600';
            case 'not-done':
            default:
                return 'bg-brand-color';

        }
    }

    function getButtonTextForStatus() {
        switch (immunizationSubmissionCompletionStatus) {
            case 'compliant':
                return 'Completed';
            case 'waiting-for-approval':
                return 'Waiting For Review';
            case 'not-done':
            default:
                return 'Submit For Review';

        }
    }

    function getButtonColorForStatus() {
        switch (immunizationSubmissionCompletionStatus) {
            case 'compliant':
                return 'text-lime-500';
            case 'waiting-for-approval':
                return 'text-sky-600';
            case 'not-done':
            default:
                return 'text-brand-color border-brand-color border';

        }
    }


    function handleStatusUpdateRequest() {
        setSubmitForReviewError(undefined);
        if( loadingImmunizations || submittingForReviewLoading || immunizationSubmissionCompletionStatus === 'compliant' || immunizationSubmissionCompletionStatus === 'waiting-for-approval') {
            return;
        }
        if (!patientImmunizations?.some((item)=> [Status.Pending, Status.EditedAndPending].includes(getImmunizationStatus(item)))){
            setSubmitForReviewError("Please Upload Some Information First");
            return;
        }

       if(markImmunizationAsCompleted && patientImmunizations){
           setSubmittingForReviewLoading(true);
           const statusPromises = patientImmunizations.filter(immunization => immunization.status === "not-done").map((immunization) => {
              return  markImmunizationAsCompleted(immunization.id);
           })
           Promise.all(statusPromises).finally( () => {
               updateImmunizations &&  updateImmunizations()
               setSubmittingForReviewLoading(false);
           }).catch((e) => {
               console.log(e);
               setSubmittingForReviewLoading(false);
           });

       }



    }

    return (
        <div>
            <div className={"bg-opacity-10 flex flex-col justify-between lg:col-span-full px-10 py-6 sm:flex-row"+ " " + getBaskGroundColorFromSubmittingStatus()} >
                {loadingImmunizations || submittingForReviewLoading? <div className={'w-full flex flex-row justify-center'}><Spinner/></div> :<>
                <span className="text-base font-semibold my-auto">Vaccination Records</span>
                <div className="flex flex-row justify-between md:justify-normal">
                    {submitForReviewError ?? <span className={'text-red-500'}>{submitForReviewError}</span>}
                    <div className="mr-5 text-base my-auto text-neutral-400">
                        <span >Last update: {!loadingImmunizations? lastUpdateDate: t("LOADING...")}</span>
                    </div>
                    <div className={'w-40'}>
                        <PrimaryButton outLine className={getButtonColorForStatus()+ " " + " bg-white h-12 font-medium text-base rounded-sm w-full"} disabled={immunizationSubmissionCompletionStatus === 'compliant' || immunizationSubmissionCompletionStatus === 'waiting-for-approval' } onClick={()=> handleStatusUpdateRequest()}>{getButtonTextForStatus()}</PrimaryButton>
                    </div>
                </div>
                </>}
            </div>
            <InputGroup>

            {loadingImmunizations ?
                <div className={'flex flex-row justify-center'}><Spinner /></div>
                : <div>

                    {patientImmunizations && patientImmunizations?.length === 0 ? (
                        <div className={'flex flex-row justify-center'}>
                            <span className={'text-gray-400'}>{t('NO_RECOMMENDATIONS_FOUND')}</span>
                        </div>)
                        : (
                        <div className={'grid lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 grid-flow-row gap-5 mt-8'}>
                            {patientImmunizations?.map((immunization: Immunization, index: number) =>
                                <PatientImmunizationItem key={immunization.id} immunization={immunization} canAddDose={immunization.status==="not-done"}/>

                            )}
                        </div>)

                    }

                </div>}
            </InputGroup>
        </div>

    );
}

export default ImmunizationView;