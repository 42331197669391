import React, {useMemo} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUp} from "@fortawesome/free-solid-svg-icons";

function PercentChange(props: { percentChange: number, roundingFunction?: (v: number) => number }) {
    const {percentChange, roundingFunction} = props;
    const percentChangeIncrease = useMemo(() => percentChange > 0, [percentChange]);
    const percentChangeNone = useMemo(() => {
        return percentChange === 0;
    }, [percentChange]);

    return (
        <div
            className={' flex gap-2 ' + " " + (!percentChangeNone ? (percentChangeIncrease ? 'text-green-500' : 'text-red-500') : "")}>
            <FontAwesomeIcon icon={faArrowUp}
                             className={'my-auto transition transform   ease-in-out duration-500' + " " + (!percentChangeIncrease ? "rotate-180" : '') + " " + (percentChangeNone ? "hidden" : "")}/>
            <span>{Math.abs(roundingFunction ? roundingFunction(percentChange) : percentChange)}%</span>
        </div>
    );
}

export default PercentChange;