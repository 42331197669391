import React, {ReactNode, useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next'
import LoadingSplash from '../spinner/LoadingSplash';
import Pagination from '../pagination/SimplePagination';

function TableMobile(props: {
    children: ReactNode[];
    itemsPerPage?: number;
    onChangePage?: (page: number) => void;
    showPagination?: boolean;
    loading?: boolean;
}) {
    const { onChangePage, showPagination, itemsPerPage = (showPagination ? 10 : 400), loading, children } = props;
    const [t] = useTranslation();
    function updateDisplayedItems(values: ReactNode[] | ReactNode): ReactNode[] {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        if (Array.isArray(values)) {
            if (values && values.length > 0) {
                return values?.slice(startIndex, endIndex);
            } else {
                return [];
            }
        } else return [values];
    }

    const updatePageClicked = useCallback((page: number) => {
        setCurrentPage(page);
        onChangePage && onChangePage(page);
    }, []);

    const [currentPage, setCurrentPage] = useState(1);

    const displayedItems = useMemo(() => updateDisplayedItems(children), [children, currentPage]);

    return (
        <div>
            {loading ? (
                <LoadingSplash showLogo={false} />
            ) : (
                <>
                    <div className={'grid grid-cols-1 divide-y overflow-ellipsis border-b border-b-neutral-10'}>
                        {children && Array.isArray(children) && children.length === 0 ? (
                            <div className="border-b">
                                <div className="px-4 py-2 text-sm text-gray-500">
                                    {t('NO_ITEMS_FOUND')}
                                </div>
                            </div>
                        ) : (
                            children &&
                                displayedItems ? displayedItems.map((value, index) =>
                                    <div key={index}>
                                        {value}
                                    </div>
                                ) : null
                        )}
                    </div>
                    {showPagination && (
                        <Pagination
                            items={children}
                            itemsPerPage={itemsPerPage}
                            onPageChange={updatePageClicked}
                        />
                    )}
                </>
            )}
        </div>
    );
}

export default TableMobile;