import {useTranslation} from 'react-i18next';
import TableView from "../TableView";
import StudentRow from "./StudentRow";

function StudentsTable(props: { students: any[] }) {
    const { students } = props;

    const [t] = useTranslation()

    const studentHeader: string[] = [
        t('NAME'), t('STATUS'), t('SCHOOL'), t('MESSAGE')
    ]

    return (
        <>
            <TableView tableHeaders={studentHeader} showPagination={true}>
                {students?.map(student =>
                    <StudentRow key={student.id} student={student}/>
                )}
            </TableView>
        </>
    );
}


export default StudentsTable;