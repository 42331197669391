import {useEffect, useState} from "react";
import ProfileImage, {ProfileStatuses} from "./ProfileImage";
import AppointmentStatus from "./AppointmentStatus";
import {parseDocumentPhoto} from "../../util/photo/parseDatabasePhotos";
import usePatient from "../../hooks/usePatient";
import useAuth from "../../hooks/useAuth";

function AppointmentProfileSummary(props: { participant?: any, appointmentstatus?: string, isDetail?: boolean }) {
  const { participant, appointmentstatus, isDetail } = props
  const { getPhoto } = usePatient();
  const { userProfile } = useAuth();

  const [profilePhotoSrc, setProfilePhotoSrc] = useState<any>()

  useEffect(() => {
    if (userProfile && participant?.photo?.[0]?.url) {
      getPhoto && getPhoto(userProfile, participant.photo[0].url).then((value: any) => {
        setProfilePhotoSrc(parseDocumentPhoto(value.file))
      }).catch((reason: Error) => {
        console.error('Unable to get the photo: ', reason);
      })
    }
  }, [participant])

  return (
    <div className={`flex items-center py-5 pr-5 ${isDetail && 'pl-5'}`}>
      <div className='w-[52px] mr-5'>
        <ProfileImage src={profilePhotoSrc} status={participant.active ? ProfileStatuses.active : ProfileStatuses.inactive} />
      </div>
      <div className="flex flex-col flex-1">
        <p className="flex-1 text-base font-semibold text-heading">{participant?.name?.[0]?.text}</p>
        {appointmentstatus && (
          <div className="mt-2">
            <AppointmentStatus
              appointmentstatus={appointmentstatus}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default AppointmentProfileSummary;