import {ReactNode} from 'react';
import {NavLink, To} from 'react-router-dom';

interface SelectableSideNavButtonProps {
    icon?: ReactNode;
    url: To;
    title?: string;
    active?: boolean;
    clicked?: () => any;
    unreadMessageCount?: number
}

function SelectableSideNavButton(props: SelectableSideNavButtonProps) {
    const {icon, title, url, active, unreadMessageCount} = props;
    return (
        <>
            <NavLink
                to={url}
                role={'button'}
                className={`flex flex-row rounded-sm py-2 px-5 ${active && '!bg-brand-color text-white'} hover:bg-[#f8a03a0d]`}
                onClick={props.clicked}
            >
                <div className={'rounded-full bg-opacity-5 px-2.5 py-2'}>{icon}</div>
                <div className={'ml-3 flex items-center justify-between flex-1 text-base my-auto'}>
                    <span>{title}</span>
                    {url === 'messages' && !!unreadMessageCount && (
                        <span className='bg-[#FF3700] text-white w-6 h-6 flex items-center justify-center rounded-full text-xs'>{unreadMessageCount}</span>
                    )}
                </div>
            </NavLink>
        </>
    );
}

export default SelectableSideNavButton;
