import {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {SavedSearch, ServerReply} from '../../types/database/SharedTypes';
import {API} from "aws-amplify";

type SavedSearchContextType = {
  savedSearchList?: SavedSearchListType;
  updateSavedSearch?: (items: SavedSearch[]) => Promise<any>;
  handleUpdateSavedSearch?: (items: SavedSearch[]) => void;
  addSavedSearch?: (item: SavedSearch) => Promise<any>;
};

interface SavedSearchListType {
  loading: boolean,
  searches: SavedSearch[],
  error: any
}

const SavedSearchContext = createContext<SavedSearchContextType>({});

export const useSavedSearch = () => useContext(SavedSearchContext);

export function SavedSearchProvider(props: { children: ReactNode }) {
  const [savedSearchList, setSavedSearchList] = useState<SavedSearchListType>({ loading: false, searches: [], error: null })

  async function updateSavedSearch(items: SavedSearch[]) {
    const apiName = 'LAUSDAppEndpoint';
    const path = `/profile`;
    const myInit = {
      body: [
        { op: "replace", path: "/preferences/searches", value: items },
      ],
      headers: {}
    };
    return API.patch(apiName, path, myInit);
  }

  async function addSavedSearch(item: SavedSearch) {
    const apiName = 'LAUSDAppEndpoint';
    const path = `/profile`;
    const myInit = {
      body: [
        { op: "add", path: "/preferences/searches", value: [item] },
      ],
      headers: {}
    };
    return API.patch(apiName, path, myInit);
  }

  async function getProfile(): Promise<ServerReply<any>> {
    const apiName = 'LAUSDAppEndpoint';
    const path = `/profile`;
    const myInit = {
      body: {},
      headers: {}
    };
    return API.get(apiName, path, myInit);
  }

  function handleUpdateSavedSearch(searches: SavedSearch[]) {
    const clonedSearches = JSON.parse(JSON.stringify(searches))
    setSavedSearchList(prevState => ({
      ...prevState,
      searches: clonedSearches
    }));
  }

  useEffect(() => {
    setSavedSearchList(prevState => ({
      ...prevState,
      loading: true
    }));
    getProfile().then((response: any) => {
      setSavedSearchList({
        loading: false,
        error: null,
        searches: response?.body?.preferences?.searches ?? []
      });
    }).catch((error: any) => {
      setSavedSearchList(prevState => ({
        ...prevState,
        loading: false,
        error: error?.message || 'Unable to get the school list'
      }));
    });
  }, [])

  return (
    <SavedSearchContext.Provider
      value={{
        savedSearchList,
        updateSavedSearch,
        addSavedSearch,
        handleUpdateSavedSearch
      }}
    >
      {props.children}
    </SavedSearchContext.Provider>
  )
}