import React, {useCallback, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next';
import {faCalendar} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PrimaryButton from "../../button/PrimaryButton";
import ProfileSummary from "../../status/ProfileSummary";
import BookAppointment from '../../patient-dashboard/BookAppointment';
import {parseDocumentPhoto} from "../../../util/photo/parseDatabasePhotos";
import useAuth from '../../../hooks/useAuth';
import {useRefer} from '../../../contexts/practitioner/ReferProvider';
import Spinner from '../../spinner/Spinner';
import {sendAutomatedSendbirdMessage, updateBothUserProfiles} from "../../../util/sendBird";
import usePatient from "../../../hooks/usePatient";
import TableMobile from '../TableMobile';
import MessageLoadingButton from '../../button/MessageLoadingButton';

function ProfessionalsMobile(props: { professionals: any[], studentId?: string, getPhoto?: any, appointId?: string }) {
    const { professionals, getPhoto, appointId } = props;

    const [t] = useTranslation();

    return (
        <TableMobile showPagination={true}>
            {professionals?.map((user) => (
                <ProfessionalMobileItem
                    key={user?.id}
                    professional={user}
                    getPhoto={getPhoto}
                    studentId={props.studentId}
                    appointId={appointId}
                />
            ))}
        </TableMobile>
    )
}

function ProfessionalMobileItem(props: { professional: any, studentId?: string, getPhoto: any, appointId?: string }) {
    const { professional, getPhoto, appointId } = props
    const { userProfile } = useAuth();
    const { patient } = usePatient();
    const { createRefer } = useRefer();
    const navigate = useNavigate();
    const [t] = useTranslation();

    const [isReferLoading, setIsReferLoading] = useState<boolean>(false)
    const [profilePhotoSrc, setProfilePhotoSrc] = useState<any>()
    const [showModal, setShowModal] = useState<boolean>(false);
    const [messageNavigateLoading, setMessageNavigateLoading] = useState<string>();

    const updateModal = useCallback(
        (visibility: boolean) => setShowModal(visibility),
        [showModal]);

    async function handleCreateRefer() {
        try {
            setIsReferLoading(true)
            if (createRefer && appointId && userProfile && props.studentId) {
                await sendAutomatedSendbirdMessage(userProfile, props.studentId, t('message_recommend_meet_professional').replace('_professional_name_', professional.name).replace('_professional_organizationname_', professional.organizationname));
                await createRefer(professional?.id, appointId)
                navigate(`/professional/dashboard/messages/${props.studentId}`)
            }
        } catch (error) {
            console.log("Unable to create a refer!", error)
        } finally {
            setIsReferLoading(false)
        }
    }

    useEffect(() => {
        if (userProfile && professional?.photo) {
            getPhoto && getPhoto(userProfile, professional?.photo).then((value: any) => {
                setProfilePhotoSrc(parseDocumentPhoto(value.file))
            }).catch((reason: Error) => {
                console.error('Unable to get the photo: ', reason);
            })
        }
    }, [])

    function initiateMessagesView() {
        setMessageNavigateLoading(`${t('LOADING_MESSAGES')}...`);
        updateBothUserProfiles(userProfile||'', patient.name?.[0].text, patient.photo?.[0].url,professional?.id || '',professional?.name|| "", professional?.photo|| '' )
            .then(() => {
              navigate(`/patient/dashboard/messages/${professional?.id}`)
            }).finally(() =>  setMessageNavigateLoading(undefined));
      }

    return (
        <div>
            <div className="flex flex-row align-top pt-2 pb-4">
                <div className="flex">
                    <ProfileSummary src={profilePhotoSrc} />
                </div>
                <div className='flex flex-col pt-3 ml-1 w-full'>
                    <span className='font-semibold'>{professional?.name}</span>
 
                    {appointId ? (
                        <div className='flex mt-4'>
                            <PrimaryButton
                                outLine
                                onClick={() => handleCreateRefer()}
                            >
                                <div className='flex items-center justify-center w-full'>
                                    {!isReferLoading && <div className='bg-share-icon w-4 min-w-4 h-4 bg-contain mr-2' />}
                                    {t('REFER')}
                                    {isReferLoading && (
                                        <div className='ml-2'>
                                            <Spinner customSize='h-6 w-6' />
                                        </div>
                                    )}
                                </div>
                            </PrimaryButton>
                        </div>
                    ) : (
                        <div className='flex mt-4'>
                            <div className='w-[110px]'>
                                <PrimaryButton onClick={() => { updateModal(true); }}>
                                    <FontAwesomeIcon
                                        icon={faCalendar}
                                        className='text-white'
                                    />
                                    <span className='ml-3'>{t('BOOK')}</span>
                                </PrimaryButton>
                            </div>
                            <div className='ml-4'>
                                <MessageLoadingButton
                                    handleNavigateMessage={initiateMessagesView}
                                    loadingMessage={messageNavigateLoading}
                                    isMobile
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {showModal && (
                <BookAppointment
                    displayModal={showModal}
                    modalVisibilityChanged={updateModal}
                    practitioner={{
                        subject: professional.id,
                        name: [{ text: professional.name }],
                        organization: { name: professional.organizationname }
                    }}
                    srcImg={profilePhotoSrc}
                />
            )}
        </div>
    )
}

export default ProfessionalsMobile;