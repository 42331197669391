import React from 'react';
import {useTranslation} from "react-i18next";
import TableView from "../table/TableView";
import {StudentProfile} from "../table/student/StudentRow";
import VaccinationStatus from "../status/VaccinationStatus";
import ExpandableTableRow from "../table/ExpandableTableRow";

function StudentsListTable(props: { students: any[], studentClicked?: (studentId: string) => void }) {

    const {students, studentClicked} = props;

    const [t] = useTranslation();


    const studentHeader: string[] = [
        t('NAME'), t('STATUS')
    ]

    return (
        <>
            <TableView tableHeaders={studentHeader} itemsPerPage={7} showPagination={true}>
                {students?.map(student =>
                    <ExpandableTableRow key={student.id}
                                        onClick={() => studentClicked && student.id && studentClicked(student.id)}>
                        <td>

                            <StudentProfile patient={student}/>

                        </td>
                        <td>
                            <div className={'mr-2'}>
                                <VaccinationStatus status={student.immunizationstatus ? {
                                    color: student.immunizationstatus?.split(':')[0],
                                    display: student.immunizationstatus?.split(':')[1]
                                } : undefined}/>
                            </div>
                        </td>
                    </ExpandableTableRow>
                )}
            </TableView>
        </>
    );
}

export default StudentsListTable;