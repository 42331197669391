import React, {ReactNode, useMemo, useState} from 'react';
import CenterModal from "../dashboard-search/CenterModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faSyringe} from "@fortawesome/free-solid-svg-icons";
import {DocumentProvider as ProfessionalDocumentProvider} from "../../contexts/practitioner/DocumentProvider";
import ImmunizationView from "../modal/ImmunizationView";
import {Immunization} from "../../types/database/SharedTypes";
import {getImmunizationStatus} from "../../util/immunization/immunization";

function PractitionerImmunizationModal(props:{showModal: boolean,showStatus?:boolean,children?: ReactNode, immunization: Immunization, onModalVisibilityChange: (modalViewState: boolean)=> void}){
    const { showModal, immunization, showStatus=true, onModalVisibilityChange } = props;

    const [showEvaluationModal, setShowEvaluationModal] = useState<boolean>(false);


    const immunizationTitle = immunization?.vaccineCode?.coding?.[0]?.display;

    const immunizationStatus = useMemo(()=> getImmunizationStatus(immunization), [immunization]);

    function handleUpdateModal(modalViewState: boolean) {
        onModalVisibilityChange(modalViewState);

    }

    function handleOpenEvaluationModal(modalViewState: boolean) {
        setShowEvaluationModal(modalViewState);
    }

    return (
        <CenterModal isOpen={showModal} onClose={() => handleUpdateModal(false)}>
            <div
                className={'flex flex-col max-h-[90vh] justify-between overflow-y-auto  lg:min-w-[40vw] min-w-[90vw]  w-full pb-8'}
            >
                <div className='relative bg-zinc-50 py-8'>
                    <div className={'absolute top-3 right-5'}><FontAwesomeIcon icon={faClose} className={' h-4 p-4'} onClick={() => handleUpdateModal(false)} /></div>
                    <div className={'flex flex-row px-8 lg:px-12'}>
                        <FontAwesomeIcon icon={faSyringe} className={'text-violet-500' + ' ' + 'h-5   my-auto mr-3'} />
                        <span className={'my-auto text-xl'}>
                            <b>
                                {immunizationTitle}
                            </b>
                        </span>
                    </div>
                </div>
                <ProfessionalDocumentProvider>
                    <ImmunizationView
                        isProfessionalView={true}
                        immunization={immunization}
                        immunizationTitle={immunizationTitle}
                    />
                </ProfessionalDocumentProvider>
                <div>
                    {props.children}
                </div>
            </div>

        </CenterModal>
    );
}

export default PractitionerImmunizationModal;