import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next'
import Logo from '../components/logo/Logo';
import SelectableSideNavButton from '../components/navigation/SelectableSideNavButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleUser} from '@fortawesome/free-regular-svg-icons'
import {faArrowRight} from '@fortawesome/free-solid-svg-icons'
import useAuth from "../hooks/useAuth";
import UserProfileSummary from "../components/profile/UserProfileSummary";
import useProfessional from "../hooks/useProfessional";
import {UserGroup} from "../contexts/AmplifyApiProvider";
import React, {ReactNode, useEffect, useMemo, useState} from "react";
import usePatient from "../hooks/usePatient";
import {parseDocumentPhoto} from "../util/photo/parseDatabasePhotos";
import LanguageSelector from '../components/languageSelector';
import {sendbird} from '../util/sendBird';
import {GroupChannelHandler} from '@sendbird/chat/groupChannel';

const SideNav = (props: {
    professional: boolean; items: any[];
    navItemClicked?: (navSectionClicked: string) => any; renderProfileView?: () => ReactNode
}) => {
    const [t] = useTranslation();
    const { userGroup, signOut, userProfile } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const [userInformation, setUserInformation] = useState<{ name?: string, description?: string, profilePhoto?: string, immunizationStatus?: string | undefined }>({ name: 'Test Patient', description: 'he/her' });
    const { professional, profilePhoto: professionalProfilePhoto } = useProfessional();
    const { patient, profilePhoto: patientProfilePhoto, parentStudents, getPhoto } = usePatient();
    const [unreadMessageCount, setUnreadMessageCount] = useState<number>(0);
    const [lastActivity, setLastActivity] = useState(Date.now());

    const onActivity = () => {
        setLastActivity(Date.now());
      };
    
      useEffect(() => {
        window.addEventListener('mousemove', onActivity);
        window.addEventListener('keydown', onActivity);
        return () => {
          window.removeEventListener('mousemove', onActivity);
          window.removeEventListener('keydown', onActivity);
        };
      }, []);
    
      useEffect(() => {
        const interval = setInterval(() => {
          if (Date.now() - lastActivity > 60 * 60 * 1000) {
            signOut && signOut();
          }
        }, 10000); // check every 10 seconds
        return () => clearInterval(interval);
      }, [lastActivity]);

    useEffect(() => {
        if (userGroup) {
            if (userGroup === UserGroup.PATIENT) {
                setUserInformation({
                    name: patient?.name?.[0].text,
                    description: patient?.pronouns?.display,
                    profilePhoto: parseDocumentPhoto(patientProfilePhoto),
                    immunizationStatus: patient?.immunizationStatus
                });
            } else if (userGroup === UserGroup.PRACTITIONER) {
                setUserInformation({ name: professional?.name?.[0].text, description: professional?.pronouns?.[0]?.display, profilePhoto: parseDocumentPhoto(professionalProfilePhoto) });
            }
        }

    }, [userGroup, patient, professional, patientProfilePhoto, professionalProfilePhoto]);

    async function getTotalUnreadMessageCount() {
        try {
            const count: number = await sendbird.groupChannel.getTotalUnreadMessageCount();
            setUnreadMessageCount(count);
        } catch (error) {
            console.log('Unable to get the unread message count: ', error);
        }
    }

    const visitTutorial = useMemo(() => {
        const completedItems =  window.localStorage?.getItem(`${userProfile}-completed-tutorials`)
        return completedItems ? JSON.parse(completedItems) : []
    }, [userProfile])

    const tutorialCount = userGroup === UserGroup.PRACTITIONER ? 5 : 7

    useEffect(() => {
        if (sendbird.connectionState !== 'OPEN') return;
        getTotalUnreadMessageCount();
        const groupChannelHandler = new GroupChannelHandler();
        groupChannelHandler.onChannelChanged = () => {
            getTotalUnreadMessageCount();
        }
        sendbird.groupChannel.addGroupChannelHandler('UNREAD_MESSAGE_HANDLER', groupChannelHandler);
        return () => sendbird.groupChannel.removeGroupChannelHandler('UNREAD_MESSAGE_HANDLER');
    }, [sendbird.connectionState])

    return (
        <aside>
            <div className={'flex flex-col py-2 min-h-[calc(100vh-80px)] lg:min-h-screen'}>
                <div className={'mx-auto mb-5'}>
                    <div className={'px-5 lg:block hidden'}>
                        <Logo />
                    </div>
                </div>
{/*                {userGroup === UserGroup.PRACTITIONER &&
                    <div className={'mb-5 px-5'}>
                        <PrimaryButton outLine={true}>
                            <div className={'flex-row flex justify-between px-5 '}>  <div><FontAwesomeIcon icon={faSchool} className={'text-brand-color h-5'} /> School</div> <div><FontAwesomeIcon icon={faChevronDown} className={'text-brand-color h-4'} /></div></div>
                        </PrimaryButton>
                    </div>
                }*/}
                <div className='flex-1 overflow-auto max-h-[calc(100vh-120px)] lg:max-h-[calc(100vh-190px)] flex flex-col justify-between'>
                    <div className='flex-1 flex flex-col justify-between'>
                        <div className='mb-8'>
                            {props?.items?.map((item) => (
                                <div className={'mb-3'} key={item?.title}>
                                    <SelectableSideNavButton
                                        title={item.title}
                                        icon={item.icon}
                                        url={item.url}
                                        clicked={() =>
                                            props.navItemClicked && props.navItemClicked(item.title)
                                        }
                                        active={item.url && location.pathname.includes(item.url)}
                                        unreadMessageCount={unreadMessageCount}
                                    />
                                </div>
                            ))}
                            <LanguageSelector />
                        </div>
                        {visitTutorial?.length !== tutorialCount && (
                            <div
                                className='bg-lime-green bg-opacity-10 flex items-center px-[30px] py-4 cursor-pointer'
                                onClick={() => navigate(`/${userGroup === UserGroup.PRACTITIONER ? 'professional' : 'patient'}/dashboard/help-center`)}
                            >
                                <span className='text-sm font-medium mr-3'>{t('HELP_CENTER_BANNER_TEXT')}</span>
                                <div className='w-8 h-8 rounded-full bg-white min-w-[32px] flex items-center justify-center'>
                                    <FontAwesomeIcon icon={faArrowRight} className='text-brand-color' />
                                </div>
                            </div>
                        )}
                    </div>

                    <div className={'border-t border-t-neutral-10 bg-white'}>
                        <div className={"py-3 px-5"}>
                            <UserProfileSummary
                                isEditAvailable
                                src={userInformation.profilePhoto}
                                name={userInformation.name || 'Loading...'}
                                description={userInformation.description}
                                immunizationStatus={userInformation.immunizationStatus}
                                isPatient={userGroup === UserGroup.PATIENT}
                            />
                        </div>
                        {!professional && (parentStudents || []).length > 1 && (
                            <>
                                <div className='py-3 px-5 mt-0.5'>
                                    <button
                                        className={`w-full border-brand-color border flex items-center justify-center ${location.pathname === '/patient/dashboard' && 'bg-[#f8a03a1a]'}  py-2`}
                                        onClick={() => navigate('/patient/dashboard')}
                                    >
                                        <FontAwesomeIcon
                                            icon={faCircleUser}
                                            className='text-brand-color w-4 h-4'
                                        />
                                        <span className='text-brand-color text-sm ml-2'>{t('SWITCH_STUDENT')}</span>
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </aside>
    );
};

export default SideNav;
