import React, {Children} from 'react';
import {InputRadioParams} from "./InputRadio";

// TODO make more general for selecting properly
function DualSelect(props: { children: React.ReactElement<InputRadioParams>[], cols?: number }) {
    const {children, cols = 2} = props;

    return (
        <>
            <ul className={`grid w-full gap-0 grid-cols-${cols}`}>
                {Children.map(children, (child: React.ReactElement<InputRadioParams>, index) => {
                    return (
                        <li>
                            {child}
                        </li>
                    )
                })}

            </ul>
        </>
    );
}


export default DualSelect;