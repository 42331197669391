import {useTranslation} from 'react-i18next';
import usePatient from '../../../hooks/usePatient';
import AppointmentStatus from '../../status/AppointmentStatus';
import TableView from "../TableView";
import AppointmentProfileSummary from '../../status/AppointmentProfileSummary';
import {ISchool} from '../../Icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStethoscope} from "@fortawesome/free-solid-svg-icons";

function AppointmentsTable(props: { appointments: any[], showSelectAppointment: any, setShowSelectAppointment: (appointment: any) => void }) {
  const {
    appointments,
    showSelectAppointment,
    setShowSelectAppointment
  } = props;

  const { patient } = usePatient()
    const [t, i18n] = useTranslation();

  const DocumentHeaders: string[] = [
    t('NAME'), t('SPECIALTY'), t('SCHOOL'), t('DATE'), t('STATUS')
  ]

  return (
    <>
      <TableView
        tableHeaders={DocumentHeaders}
        showPagination
      >
        {appointments.map((appointment, index) =>
          <tr
            key={appointment.id}
            className={`${showSelectAppointment?.id === appointment?.id && 'bg-[#f8a03a0d]'} cursor-pointer border-b border-b-neutral-100`}
            onClick={() => setShowSelectAppointment(appointment)}
          >
            <td className='pr-4'>
              <AppointmentProfileSummary participant={appointment?.participant} />
            </td>
            <td>
              <div className='flex items-center'>
                <FontAwesomeIcon
                  icon={faStethoscope}
                  className='text-brand-color mr-3'
                />
                <span className='text-base text-heading'>{appointment?.participant?.profession?.display || '---'} {appointment?.participant?.profession?.code ? `(${appointment?.participant?.profession?.code})` : ''}</span>
              </div>
            </td>
            <td>
              <div className='flex items-center'>
                <span className="text-brand-color mr-3">
                  <ISchool />
                </span>
                <span className='text-base text-heading'>{patient?.managingOrganization?.name || '---'}</span>
              </div>
            </td>
            <td>
              <div className='flex'>
                <div className="bg-calendar-icon w-4 h-4 bg-contain mr-3 mt-1" />
                <div className='flex flex-col'>
                  <span className='text-heading text-base font-semibold'>
                    {new Date(appointment.start).toLocaleDateString(i18n.resolvedLanguage, {
                        weekday: 'short',
                        month: 'short',
                        day: 'numeric'
                    })},
                    <span className='text-heading text-base font-normal'>
                      {` ${new Date(appointment.start).toLocaleTimeString(i18n.resolvedLanguage, {
                          hour: '2-digit',
                          minute: '2-digit'
                      })}`}
                    </span>
                  </span>
                </div>
              </div>
            </td>
            <td>
              <div>
                <AppointmentStatus
                  appointmentstatus={appointment?.status}
                />
              </div>
            </td>
          </tr>
        )}
      </TableView>
    </>
  );
}

export default AppointmentsTable;
