import Spinner from "../spinner/Spinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faComment} from "@fortawesome/free-regular-svg-icons";
import {useTranslation} from 'react-i18next';

function MessageLoadingButton(props: { isMobile?: boolean, loadingMessage?: string, handleNavigateMessage: () => void }) {
  const { isMobile, loadingMessage, handleNavigateMessage } = props;

  const [t] = useTranslation();

  return (
    <button className="cursor-pointer text-brand-color" onClick={handleNavigateMessage}>
      {isMobile ? (
        <div
          className='flex items-center px-5 border-brand-color border bg-white text-brand-color h-12 font-medium text-base rounded-sm w-full'
        >
          {
            loadingMessage ? (
              <Spinner customSize='w-5 h-5' />
            ) : (
              <FontAwesomeIcon
                icon={faComment}
                className="text-orange-300"
              />
            )
          }
          <span className='ml-3'>{t('MESSAGE')}</span>
        </div>
      ) : (
        <div
          className='flex items-center px-5 border-brand-color border bg-white text-brand-color h-12 font-medium text-base rounded-sm w-full'
        >
          {loadingMessage ? (
            <div className='flex items-center'>
              <Spinner customSize='w-6 h-6' />
              {loadingMessage}
            </div>
          ) : (
            <>
              <FontAwesomeIcon
                icon={faComment}
                className="text-orange-300"
              />
              <span className='ml-3'>{t('MESSAGE')}</span>
            </>
          )}
        </div>
      )}
    </button>
  )
}

export default MessageLoadingButton;