import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next';
import {useNavigate} from "react-router-dom";
import PrimaryButton from '../button/PrimaryButton';
import InputGroup from '../group/InputGroup';
import LoadingSplash from '../spinner/LoadingSplash';
import AppointmentStatus from '../status/AppointmentStatus';
import AppointmentsTable from '../table/Professionals/AppointmentsTable';
import PrimaryLink from '../link/PrimaryLink';
import {useAppointment} from "../../contexts/practitioner/AppointmentProvider";
import StudentProfileSummary from '../status/StudentProfileSummary';
import ProfessionalAppointmentFilterComponent from '../dashboard-search/ProfessionalAppointmentFilterComponent';
import {ICalendar, ICalendarLine, IFilter, ISchool} from '../Icons';
import {AppointmentDetailProvider, useAppointmentDetail} from "../../contexts/practitioner/AppointmentDetailProvider";
import Spinner from "../spinner/Spinner";
import TableMobile from '../table/TableMobile';

const Appointments = () => {
  const { appointmentList } = useAppointment();

  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<Record<string,string>>({});
  const [filteredAppointments, setFilteredAppointments] = useState([])

  useEffect(() => {
    if (appointmentList?.loading) return
  
    let updatedAppointments = JSON.parse(JSON.stringify(appointmentList?.appointments))
  
    if (Object.keys(filterParams).length > 0) {
      updatedAppointments = updatedAppointments
        .filter((appointment: any) => filterParams?.['organizationid\:exact'] ? appointment?.subject?.managingOrganization?.id === filterParams?.['organizationid\:exact'] : true)
        .filter((appointment: any) => filterParams?.status
          ? (filterParams?.status === 'referred'
            ? appointment?.referrals?.length
            : !appointment?.referrals?.length && filterParams?.status === appointment?.status)
          : true)
          .filter((appointment: any) => filterParams?.date ? new Date(appointment?.start).toLocaleDateString(i18n.resolvedLanguage, {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
          }) === new Date(filterParams?.date).toLocaleDateString(i18n.resolvedLanguage, {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
          }) : true)
      setFilteredAppointments(updatedAppointments || [])
      filterParams?.sort === 'oldest'
        ? updatedAppointments.sort((a: any, b: any) => Number(new Date(a?.start)) - Number(new Date(b?.start)))
        : updatedAppointments.sort((a: any, b: any) => Number(new Date(b?.start)) - Number(new Date(a?.start)))
    } else {
      updatedAppointments.sort((a: any, b: any) => Number(new Date(b?.start)) - Number(new Date(a?.start)));
    }
  
    setFilteredAppointments(updatedAppointments || [])
  }, [appointmentList, filterParams])

  return (
    <section>
      <div className="flex md:flex-row flex-col items-center justify-between max-[1022px]:py-2">
        <h1 className="font-semibold text-2xl md:text-left text-center">
          {t('APPOINTMENTS')}
        </h1>

        <div className={'w-full sm:w-fit lg:mt-0 mt-4 flex items-center flex-col sm:flex-row'}>
          <div className='w-full sm:w-[240px]'>
            <PrimaryLink to={'manage-availability'}>
              <PrimaryButton outLine={true}>
                <div className='flex items-center justify-center'>
                  <ICalendarLine />
                  <span className="ml-2 font-medium text-base">{t('MANAGE_AVAILABILITY')}</span>
                </div>
              </PrimaryButton>
            </PrimaryLink>
          </div>
          <div className='w-full sm:w-[130px] sm:ml-4 mt-3 sm:mt-0'>
            <PrimaryButton
              outLine
              onClick={() => setShowFilter(true)}
              disabled={appointmentList?.loading}
            >
              <div className='relative flex items-center justify-center h-full'>
                {Object.keys(filterParams).length > 0 && <div className='absolute top-[-8px] right-[-8px] rounded-full w-4 h-4 bg-lime-green' />}
                <IFilter />
                <span className="font-medium text-base ml-2">{t('FILTERS')}</span>
              </div>
            </PrimaryButton>
          </div>
        </div>
      </div>

      <ProfessionalAppointmentFilterComponent
        showFilter={showFilter}
        updateVisibility={() => setShowFilter(false)}
        setFilterParams={setFilterParams}
      />

      <div className={'mt-4'}>
        <InputGroup>
          {!appointmentList?.loading ? (
            <div>
              {appointmentList?.error ? (
                <div className="flex items-center justify-center text-base w-auto">
                  <p className="text-gray-500 text-center">{appointmentList?.error}</p>
                </div>
              ) : (
                <>
                  <div className="lg:block hidden">
                    <AppointmentsTable appointments={filteredAppointments || []} />
                  </div>
                  <div className="block lg:hidden">
                    <TableMobile showPagination={true}>
                      {filteredAppointments?.map((appointment: any) => {
                        return (
                          <div
                            key={appointment?.id}
                            className='border-b border-b-neutral-100'
                            onClick={() => navigate(`/professional/dashboard/appointments/student/${appointment?.subject}`, {
                              state: { appointment }
                            })}
                          >
                            <AppointmentDetailProvider appointmentId={appointment?.id}>
                              <AppointmentRowPractitioner />
                            </AppointmentDetailProvider>
                          </div>
                        );
                      })}
                    </TableMobile>
                  </div>
                </>
              )}
            </div>
          ) : (
            <LoadingSplash showLogo={false} />
          )}
        </InputGroup>
      </div>
    </section>
  );
};
function AppointmentRowPractitioner() {
  const [t, i18n] = useTranslation();
  const {appointment: appointmentDetailState} = useAppointmentDetail();

  return (
      <>
      {!!appointmentDetailState?.loading ?
          <div className={'flex justify-center py-20'}>
            <Spinner />
          </div>

          :
      <div className="flex items-start">
        <div className="flex mr-4">
          <StudentProfileSummary
              isOnlyPhoto
              patient={appointmentDetailState?.appointment?.subject}
          />
        </div>
        <div className='py-5'>
          <p className="flex-1 text-base font-semibold text-heading mb-2">{appointmentDetailState?.appointment?.subject?.name?.[0]?.text}</p>
          <div className='flex items-center mb-2'>
                                  <span className='text-brand-color mr-3'>
                                    <ISchool />
                                  </span>
            <span className='text-base text-heading'>{appointmentDetailState?.appointment?.subject?.managingOrganization?.name || '---'}</span>
          </div>
          <div className='flex mb-2'>
            <span className='text-brand-color mr-3'><ICalendar /></span>
            <div className='flex flex-col'>
                                    <span className='text-heading text-base font-semibold'>
                                      {new Date(appointmentDetailState?.appointment?.start).toLocaleDateString(i18n.resolvedLanguage, {
                                        weekday: 'short',
                                        month: 'short',
                                        day: 'numeric'
                                      })},
                                      <span
                                          className='text-heading text-base font-normal'>{` ${new Date(appointmentDetailState?.appointment?.start).toLocaleTimeString(i18n.resolvedLanguage, {
                                        hour: '2-digit',
                                        minute: '2-digit'
                                      })}`}</span>
                                    </span>
            </div>
          </div>
          <div className="flex items-center gap-2">
            {appointmentDetailState?.appointment?.referrals?.length > 0 ? (
                <div className='flex items-center justify-center'>
                  <div className='bg-share-icon w-4 min-w-4 h-4 bg-contain mr-2' />{t('REFERRED')}
                </div>
            ) : (
                <AppointmentStatus
                    appointmentstatus={appointmentDetailState?.appointment?.status}
                    isProfessional
                />
            )}
          </div>
        </div>
      </div>
            }
      </>
  );
}

export default Appointments;
