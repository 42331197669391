import React, {createContext, useCallback, useEffect, useState} from "react";
import {Auth, Hub} from "aws-amplify";
import {CognitoUser, CognitoUserSession} from "amazon-cognito-identity-js";
import {UserGroup} from "./AmplifyApiProvider";
import {ampli} from "../ampli";
import {useTranslation} from "react-i18next";


export const AuthContext = createContext<{
    user?: CognitoUser,
    userLoading: boolean,
    userGroup?: UserGroup,
    signOut?: ()=> Promise<void>,
    authError?: string,
    userProfile?: string,
    refreshAuth?: () => Promise<any>
    getUserGroup?: (user: CognitoUser) => UserGroup | undefined,
    setDefaultUserProfile?: (userProfile: string) => void,
    isInAdminGroup?: () => boolean,
    signIn?: (username: string, password: string,) => Promise<CognitoUser>
    completeNewPassword?: (newPassword: string) => Promise<any>
}>({userLoading: true});

export const AuthProvider = (props: { children: React.ReactElement<any, any> }) => {
    const {children} = props
    const [user, setUser] = useState<CognitoUser>();
    const [userGroup, setUserGroup] = useState<UserGroup>();
    const [userProfile, setUserProfile] = useState<string>();
    const [userLoading, setUserLoading] = useState<boolean>(true);
    const [authError, setAuthError] = useState<string>();
    const [t] = useTranslation();

    useEffect(() => {
        Hub.listen('auth', ({payload: {event, data}}) => {

            switch (event) {
                case 'autoSignIn':
                    setUser(data);
                    break;
                case 'signIn':
                case 'cognitoHostedUI':
                    ampli.track({event_type:"Login Success"});
                    getUser().then(userData => setUser(userData));
                    break;
                case 'signOut':
                    setUser(undefined);
                    ampli.track({event_type:"Log out"});
                    break;
                case 'signIn_failure':
                case 'customState_failure':
                case 'cognitoHostedUI_failure':
                    ampli.track({event_type:"Login Fail"});
                    setAuthError(t("THERE_WAS_AN_ISSUE_ACCESSING_YOUR_ACCOUNT_PLEASE_CONTACT_LAUSD_FOR_MORE_INFORMATION"))
                    console.log('Sign in failure', data);
                    break;
            }
        });

        getUser().then(userData => {
            setUser(userData);
            setUserLoading(false);
        });
    }, []);
    useEffect(() => {
        if (user) {

            ampli.identify(user.getUsername(), {
                extra: {
                    Role: getUserGroup(user)?.toLowerCase(),
                    Email: user.getUsername()
                }
            });

            const decodePayload = user?.getSignInUserSession()?.getIdToken().decodePayload();
            const profile = decodePayload?.profile;
            if(!profile){
                setAuthError(t("THERE_WAS_AN_ISSUE_ACCESSING_YOUR_ACCOUNT_PLEASE_CONTACT_LAUSD_FOR_MORE_INFORMATION"));
            }
            setUserProfile(profile);
            setUserGroup(getUserGroup(user));
        }
    }, [user]);


    function refreshAuth(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            let refreshToken = user?.getSignInUserSession()?.getRefreshToken();
            if (refreshToken) {
                user?.refreshSession(refreshToken, (err, result: CognitoUserSession) => {
                    if (!err) {
                        setUserProfile(result?.getIdToken().decodePayload().profile);
                        resolve()
                    } else {
                        reject(err);
                    }
                });
            } else {
                reject(new Error("No refresh token present"));
            }
        })
    }

    function completeNewPassword(newPassword: string) {
        return Auth.completeNewPassword(
            user,
            newPassword,
        )
    }

    function getUserGroup(user_m: CognitoUser): UserGroup | undefined {
        const userSession = user_m.getSignInUserSession();
        const cognitoGroups = userSession?.getIdToken()?.payload?.['cognito:groups'];
        if (cognitoGroups?.includes(UserGroup.PRACTITIONER))
            return UserGroup.PRACTITIONER;
        if (cognitoGroups?.includes(UserGroup.PATIENT))
            return UserGroup.PATIENT;

        return undefined;
    }

    function isInAdminGroup() {
        const userSession = user?.getSignInUserSession();
        if (!userSession) {
            return false;
        }
        const userGroups = userSession.getIdToken()?.payload?.['cognito:groups'];

        if (userGroups) {
            return userGroups.includes(UserGroup.ADMINISTRATOR);
        } else {
            return false;
        }
    }

    function getUser() {
        return Auth.currentAuthenticatedUser()
            .then(userData => userData)
            .catch(() => console.log('Not signed in'));
    }

    const setDefaultUserProfile = useCallback((userP: string) => {
        setUserProfile(userP);
    }, []);

    async function signIn(username: string, password: string) {
        setUserLoading(true);
        const signedUser = await Auth.signIn(username, password);
        setUser(signedUser);
        setUserLoading(false);
        return signedUser;

    }


    async function signOut() {
        ampli.track({event_type:"Log out"});
        await Auth.signOut();
    }


    return (
        <AuthContext.Provider
            value={{
                signOut,
                signIn,
                getUserGroup,
                user,
                authError,
                userGroup,
                userProfile,
                userLoading,
                setDefaultUserProfile,
                isInAdminGroup,
                refreshAuth,
                completeNewPassword,
            }}>
            {children}
        </AuthContext.Provider>
    );
};
