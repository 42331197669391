import React from 'react';
import {useTranslation} from 'react-i18next';
import InputGroup from "../group/InputGroup";
import {To} from "react-router-dom";
import ResourceGridGroup from "./ResourceGridGroup";

export interface Resource {
    type: "document"| "link";
    title: string;
    link:To;
}
function Resources(props: {}) {
    const [t] = useTranslation();
    const immunizationRequirementsResources:Resource[] = [
        {title: t('PARENTS_GUIDE_TO_IMMUNIZATIONS_REQUIRED_FOR_SCHOOL_ENTRY'), type: "document", link: "https://drive.google.com/file/d/1yvRU413FI-v3Fp3MH0KsWq4A3Pm6xkZ_/view?usp=share_link"},
        {title: t('PARENTS_GUIDE_TO_IMMUNIZATIONS_REQUIRED_FOR_SCHOOL_ENTRY_SPANISH'), type: "document", link: "https://drive.google.com/file/d/1nU1G8ekiTkFEOwbAAE9tOF16Wg62d0jf/view?usp=share_link"},
        {title: t('PARENTS_GUIDE_TO_IMMUNIZATIONS_REQUIRED_FOR_PRE_KINDERGARTEN_CHILD_CARE'), type: "document", link: "https://drive.google.com/file/d/1KFeeHTm7P5_bn8vNI_eNsuWfeQ-l42jR/view?usp=share_link"},
        {title: t('PARENTS_GUIDE_TO_IMMUNIZATIONS_REQUIRED_FOR_PRE_KINDERGARTEN_CHILD_CARE_SPANISH'), type: "document", link: "https://drive.google.com/file/d/17K3_wfDblAAR5ZmxamrsoMkA-lCA9Hkt/view?usp=share_link"},
        {title: t('IMMUNIZATION_REQUIREMENTS_FOR_PRE_KINDERGARTEN'), type: "document", link: "https://drive.google.com/file/d/1DEq0RlPA2GHK8P3lSXE7R-OPmQ5fV4bb/view?usp=share_link"},
        {title: t('IMMUNIZATION_REQUIREMENTS_FOR_K_12TH_GRADE_INCLUDING_TK'), type: "document", link: "https://drive.google.com/file/d/1DgJjJ7bXsR4RLB26xBXKbLR87sqRu5hW/view?usp=share_link"},
    ];
    const immunizationFlyersResources:Resource[] = [
        {title: t('DONT_WAIT_VACCINATE_IN_ENGLISH'), type: "document", link: "https://drive.google.com/file/d/1enXDy6-PQVsGvthWx789f7qxBGU7sH67/view?usp=share_link"},
        {title: t('DONT_WAIT_VACCINATE_IN_SPANISH'), type: "document", link: "https://drive.google.com/file/d/1qi9BtIH0JsLOtUkRYkks_FXfoV_CbP-b/view?usp=share_link"},
        {title: t('SHOTS_FOR_SCHOOLS_FLYER'), type: "document", link: "https://drive.google.com/file/d/1MvKZxFWI2AJW5Z3o9TKizs05dqK07SgJ/view?usp=share_link"},
        ];
    const immunizationAccessFamilies:Resource[] = [
        {title: t('LAUSD_WELLNESS_CENTERS_ON_SCHOOL_CAMPUSES'), type: "document", link: "https://drive.google.com/file/d/1egUWoRpYjK1PvCk5cNIm-gP5yWxEOlFk/view?usp=share_link"},
        {title: t('LAUSD_STUDENT_AND_FAMILY_WELLNESS_CENTERS_OFF_CAMPUS_CLINICS'), type: "document", link: "https://drive.google.com/file/d/1HTaVW7atacMr-CnRYGyBHtN-dy0zcXaI/view?usp=share_link"},
        {title: t('NO_COST_OR_LOW_COST_IMMUNIZATIONS_TO_CHILDREN_18_YEARS_OR_YOUNGER'), type: "document", link: "https://drive.google.com/file/d/1x_IsiTg_4LWxGREXTpz4c4yxrPyALQao/view?usp=share_link"},
        {title: t('PUBLIC_HEALTH_IMMUNIZATION_CLINICS'), type: "document", link: "https://drive.google.com/file/d/1Yx_teVTH1r27IcXrIiHYSB_6VcOPkRX9/view?usp=share_link"},
        ];
    const parentPortalResources:Resource[] = [
        {title: t('PARENT_PORTAL_RESOURCES_FOR_PARENTS'), type: "link", link: "https://achieve.lausd.net/Page/10470"},
        {title: t('STEPS_PARENTS_CAN_TAKE_TO_ACCESS_PARENT_PORTAL'), type: "document", link: "https://achieve.lausd.net/cms/lib/CA01000043/Centricity/Domain/577/ParentPortal/Beginning%20Steps%20Parents%20Can%20Take%20to%20Access%20Parent%20Portal_ENG.pdf"},
        {title: t('STEPS_PARENTS_CAN_TAKE_TO_ACCESS_PARENT_PORTAL_SPANISH'), type: "document", link: "https://achieve.lausd.net/cms/lib/CA01000043/Centricity/Domain/577/ParentPortal/Beginning%20Steps%20Parents%20Can%20Take%20to%20Access%20Parent%20Portal_SPAN.pdf"},
        {title: t('HOW_TO_CREATE_A_PARENT_PORTAL_ACCOUNT_VIDEO'), type: "link", link: "https://lausd.wistia.com/medias/j2niodqyjw"},
        {title: t('WHATS_AVAILABLE_IN_PARENT_PORTAL_ENGLISH_AND_SPANISH'), type: "document", link: "https://achieve.lausd.net/cms/lib/CA01000043/Centricity/domain/577/passport/PASSport%20Module%20Information%20ShortEnglishSpanish.pdf"},
        ];
    const onePage:Resource[] = [
        {title: t('SCHOOL_BASED_HEALTH_CENTERS_POST'), type: "document", link: "https://drive.google.com/file/d/1s3QxhrNWHLsKJxK0dH02sG_cHZ_dn-Nv/view?usp=share_link"},
        {title: t('SCHOOL_BASED_HEALTH_CENTERS_POST_SPANISH'), type: "document", link: "https://drive.google.com/file/d/1asCMXrT3fdXGMQKg7TYCNc03GGbsYHEE/view?usp=share_link"},
        ];
    return (
        <div>
            <InputGroup>
                <div className={'mb-5'}>
                    <h2 className="font-semibold text-lg">{t('RESOURCES')}</h2>
                </div>

                <div className={'mb-5'}>
                    <h3 className="font-semibold text-base">Immunization Requirements</h3>
                </div>
                <ResourceGridGroup resources={immunizationRequirementsResources}/>

                <div className={'mb-5'}>
                    <h3 className="font-semibold text-base">Immunization Flyers</h3>
                </div>
                <ResourceGridGroup resources={immunizationFlyersResources}/>

                <div className={'mb-5'}>
                    <h3 className="font-semibold text-base">Immunization Access for Families</h3>
                </div>
                <ResourceGridGroup resources={immunizationAccessFamilies}/>

                <div className={'mb-5'}>
                    <h3 className="font-semibold text-base">Parent Portal Resources</h3>
                </div>
                <ResourceGridGroup resources={parentPortalResources}/>

                <div className={'mb-5'}>
                    <h3 className="font-semibold text-base">One Page Post Links for School-Based Health Center</h3>
                </div>
                <ResourceGridGroup resources={onePage}/>

            </InputGroup>
        </div>
    );
}

export default Resources;