import React from 'react';
import {PatientProvider} from "../../contexts/practitioner/PatientProvider";
import RightModal from "../dashboard-search/RightModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import compose from "../../util/compose";
import withPatientDetail from "../../hocs/withPatientDetail";
import StudentDetailPane from "../detail/StudentDetailPane";

const PatientDetailView = compose(withPatientDetail)(StudentDetailPane);


function StudentDetailRightPane(props: {
    studentId: string,
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
}) {
    const {showModal, setShowModal, studentId} = props;
    const [t] = useTranslation();
    return (
        <RightModal isOpen={showModal} onClose={() => setShowModal(false)}>
            <div className={"w-[70rem] max-w-full "}>
                <div className="flex flex-col px-3  ">
                    <div className="flex items-center justify-between py-4">

                        <h2 className="text-xl font-semibold">{t('Student Detail')}</h2>


                        <button type={'button'}
                                className="text-slate-400 hover:text-slate-500 transition duration-300 ease-in-out "
                                onClick={() => setShowModal(false)}
                        >
                            <FontAwesomeIcon icon={faXmark}/>
                        </button>
                    </div>
                    <div onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                    }}> {/*Dont allow click on anything here*/}
                        <PatientProvider patientId={studentId}>
                            <PatientDetailView studentId={studentId} enableClicking={false}></PatientDetailView>
                        </PatientProvider>
                    </div>
                </div>
            </div>
        </RightModal>
    );
}

export default StudentDetailRightPane;