import React, {useEffect, useState} from "react";
import {ServerReply} from "../../types/database/SharedTypes";
import {API} from "aws-amplify";
import usePatient from "../../hooks/usePatient";

type DocumentContextType = {
    documents?: any[],
    updateDocuments?: () => void,
    getPatientDocuments?: (patientId: string) => Promise<ServerReply<{items: any[]}>>
    loadingDocuments: boolean,
};

const DocumentContext = React.createContext<DocumentContextType>({loadingDocuments: true});
export const useDocument = () => React.useContext(DocumentContext);

export function DocumentProvider (props: { children: React.ReactNode }) {
    const [documents, setDocuments] = useState<any[]>();
    const [loadingDocuments, setLoadingDocuments] = useState<boolean>(true);
    const {patient} = usePatient();

    async function getPatientDocuments(patientId: string): Promise<ServerReply<{items: any[]}>> {
        const apiName = 'LAUSDDocumentEndpoint';
        const path = `/patient/document/reference/${patientId}`;
        const myInit = {
            body: {},
            headers: {}
        };
        return API.get(apiName, path, myInit);
    }
    function updateDocuments(){
        if( patient.subject) {
            setLoadingDocuments(true);
            return getPatientDocuments(patient.subject).then((documents) => {
                let items = documents.body.items;
                setDocuments(items);
            }).finally(() => setLoadingDocuments(false));
        }
    }

    useEffect(() => {
        if( patient.subject) {
            setLoadingDocuments(true);
            getPatientDocuments(patient.subject).then((documents) => {
                setDocuments(documents.body.items);
            }).finally(() => setLoadingDocuments(false))
         /*       .catch((err) => {
                const documentHC = {
                    category : [
                        {
                            coding :
                                {
                                    code : "11369-6",
                                    display : "History of Immunization",
                                    system : "http://loinc.org",
                                },

                            text : "Document type",
                        }
                    ],
                    content: [
                        {
                            attachment : {
                                contentType : "application/pdf",
                                creation : "2023-05-12T20:27:21.608159",
                                title : "Test.pdf",
                                url : "patient/PA-f48aa75d-fdeb-4e92-ba34-5f7423974a2f/11369-6/6683bc99-a98b-424a-a45c-3999d48399b4",
                            },
                        }
                    ],
                    created : {
                        date : "2023-05-12T20:27:22.134575",
                        source : "rtsparent20@gmail.com",
                        sourceType : 'user',
                    },
                    date : "2023-05-12T20:27:22.134590",
                    id : "DOC-bbb75fa3-e3eb-4ae2-bd80-36f19e0e5648",
                    resourceType : 'DocumentReference',
                    subject : "PA-f48aa75d-fdeb-4e92-ba34-5f7423974a2f",
                    tenant : 'lausd',
                };
                setDocuments([documentHC]);
            })*/
            ;
        }
    }, [patient]);

    return (
        <DocumentContext.Provider value={{
            documents,
            updateDocuments,
            loadingDocuments,
            getPatientDocuments
        }}>
            {props.children}
        </DocumentContext.Provider>
    );
};