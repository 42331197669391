import React from "react";
import LoadingSplash from "../components/spinner/LoadingSplash";
import useAuth from "../hooks/useAuth";
import {UserGroup} from "../contexts/AmplifyApiProvider";
import {Link, Navigate, useLocation} from "react-router-dom";
import usePatient from "../hooks/usePatient";
import Logo from "../components/logo/Logo";
import PrimaryButton from "../components/button/PrimaryButton";
import {useTranslation} from "react-i18next";


export default function withPatient(Component: typeof React.Component) {
    return (props: any) => {
        const {patient, errorMessage} = usePatient();
        const {userGroup, authError} = useAuth();
        const {pathname, search} = useLocation();
        const [t] = useTranslation();

        if (userGroup === UserGroup.PATIENT) {
            if (!patient) {
                if(authError || errorMessage) {
                    return (
                        <div className={'h-full w-full flex justify-center  flex-col'}>
                            <div className={'w-40 mx-auto mb-5'}>
                                <Logo/>
                            </div>
                            <div className={'mx-auto'}>
                                <span className={'text-red-500'}>{authError} {errorMessage}</span>
                                <Link to={'/'}><div className={'mt-5'}><PrimaryButton>{t("RETURN_HOME")}</PrimaryButton></div></Link>
                            </div>
                        </div>);

            } else {
                    return (<div className={'h-screen w-screen'}><LoadingSplash/></div>);
            }
            } else {
                return <Component {...props}></Component>;
            }
        } else if (!userGroup) {
            return (<div className={'h-screen w-screen'}><LoadingSplash/></div>);
        } else {
            return ( // Maybe just show a error instead?
                <Navigate
                    to="/login"
                    state={{from: pathname, search}}
                    replace
                />
            );
        }
    }
}
