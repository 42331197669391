import React from 'react';
import compose from "../../../util/compose";
import withAdministrator from "../../../hocs/withAdministrator";
import AdminPane from "../../../components/admin-dashboard/AdminPane";

const AdminReportsView = compose(withAdministrator)(AdminPane);

function AdminDashboard(props: {}) {
    return (
        <AdminReportsView/>
    );
}

export default AdminDashboard;