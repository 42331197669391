import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import InputGroup from "../group/InputGroup";
import TutorialItem from "./tutorial-item";
import {Accordion, AccordionItem} from "../dashboard-search/Accordion";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp, faLocationDot} from "@fortawesome/free-solid-svg-icons";
import Resources from "./Resources";
import {faEnvelope} from "@fortawesome/free-regular-svg-icons";
import {FAQ, Tutorial} from "../../types/database/SharedTypes";
import useAuth from '../../hooks/useAuth';

function HelpCenterView(props: { tutorials: Tutorial[], faqs: FAQ[], showResources?: boolean }) {
    const { tutorials, faqs, showResources = true } = props;
    const [t] = useTranslation();
    const { userProfile } = useAuth();
    const defaultCompleatedItems = window.localStorage?.getItem(`${userProfile}-completed-tutorials`) && JSON.parse(window.localStorage?.getItem(`${userProfile}-completed-tutorials`) || '') || []
    const [visitedItems, setVisitedItems] = useState<number[]>(defaultCompleatedItems)

    const updateVisitedItems = (item: number) => {
        if (!visitedItems.includes(item)) {
            const updatedItems = [...visitedItems, item]
            setVisitedItems(updatedItems)
            window.localStorage.setItem(`${userProfile}-completed-tutorials`, JSON.stringify(updatedItems))
        }
    }

    return (
        <div>
            <div className={'mb-5'}>
                <h1 className="font-semibold text-xl">{t('HELP_CENTER')}</h1>
            </div>
            <div className={'mb-5'}>
                <InputGroup>
                    <div className={'mb-4'}>
                        <h2 className="font-semibold text-lg">{t('HOW_TOS')}</h2>
                    </div>
                    <span className='block text-base mb-5'>{t('Completed_tutorials_steps').replace('_total_steps_', `${visitedItems.length}/${tutorials.length}`)}</span>

                    <div className={'mb-5 grid lg:grid-cols-3 gap-4 grid-flow-row'}>
                        {tutorials.map((tutorial, index) =>
                            <TutorialItem
                                key={index}
                                tutorial={tutorial}
                                updateVisitedItems={updateVisitedItems}
                                active={visitedItems.includes(tutorial?.index)}
                            />
                        )}
                    </div>
                </InputGroup>
            </div>
            <div className={'mb-5'}>
                <InputGroup>
                    <div className={'mb-5'}>
                        <h2 className="font-semibold text-lg">{t('FAQ')}</h2>
                    </div>
                    <div className={'grid divide-y'}>
                        {faqs.map(({ title, description }, index) =>
                            <div className={'py-5'} key={index}>
                                <Accordion>
                                    <AccordionItem
                                        title={title}
                                        isDefaultOpen={false}
                                        openIcon={<div className={'text-color-inherit'}><FontAwesomeIcon icon={faChevronDown} /></div>}
                                        closeIcon={<div className={'text-color-inherit'}><FontAwesomeIcon icon={faChevronUp} /></div>}
                                    >
                                        <div className="">
                                            <p>
                                                {description}
                                            </p>
                                        </div>
                                    </AccordionItem>
                                </Accordion>
                            </div>

                        )}
                    </div>
                </InputGroup>
            </div>
            <div className={'mb-5'}>
                {showResources && <Resources />}
            </div>
            <div className={'mb-5'}>
                <InputGroup>
                    <div className={'mb-5'}>
                        <h2 className="font-semibold text-lg">{t('CONTACTS')}</h2>
                    </div>
                    <div className={'grid lg:grid-flow-col auto-row-auto gap-4 '}>
                        <div className={'flex flex-row'}>
                            <FontAwesomeIcon icon={faEnvelope} className={'text-brand-color h-5 p-4 rounded-full bg-brand-color bg-opacity-5  my-auto mr-3'} />
                            <span className={'font-semibold my-auto'}>lausd-support@viveconcierge.net</span>
                        </div>
                        <div className={'flex flex-row'}>
                            <FontAwesomeIcon icon={faLocationDot} className={'text-brand-color h-5 p-4 rounded-full bg-brand-color bg-opacity-5  my-auto mr-3'} />
                            <span className={'font-semibold my-auto'}>Headquarters - 333 South Beaudry Avenue, Los Angeles, CA 90017</span>
                        </div>
                    </div>
                </InputGroup>
            </div>
        </div>
    );
}

export default HelpCenterView;