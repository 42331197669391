import React from 'react';

function BoldHeader(props: { title: string }) {
    const {title} = props;
    return (
        <div className={'bold-header'}>
            <h2 className={'pb-4 font-semibold text-3xl uppercase border-b text-center   border-brand-color'}>{title}</h2>


        </div>

    );
}

export default BoldHeader;
