import {useState} from 'react';
import ProfileImage, {ProfileStatuses} from "../status/ProfileImage";
import VaccinationStatus from '../status/VaccinationStatus';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRightFromBracket, faPen} from '@fortawesome/free-solid-svg-icons';
import useAuth from "../../hooks/useAuth";
import EditPhoto from "./EditPhoto";

function UserProfileSummary(props: { src: string | undefined, name?: string, description?: string | null, status?: ProfileStatuses, isWhiteText?: boolean, immunizationStatus?: string, isPatient?: boolean, isEditAvailable?: boolean }) {
    const { src, description, name, status, isWhiteText, immunizationStatus, isPatient, isEditAvailable } = props;
    const { signOut } = useAuth();
    
    const [openEditPhoto, setOpenEditPhoto] = useState<boolean>(false);

    return (
        <div className={'py-3'}>
            <div className={'flex justify-between items-center mb-3'}>
                <div className="w-[52px] h-[52px] relative">
                    <ProfileImage src={src} status={status} />
                    {isEditAvailable && (
                        <button
                            className='absolute bottom-[-8px] right-[-16px] flex items-center justify-center rounded-full border border-brand-color w-8 h-8 bg-white'
                            onClick={() => setOpenEditPhoto(true)}
                        >
                            <FontAwesomeIcon icon={faPen} className='text-brand-color h-3' />
                        </button>
                    )}
                </div>
                <FontAwesomeIcon
                    icon={faArrowRightFromBracket}
                    className="text-brand-color h-5 w-5 cursor-pointer"
                    onClick={() => signOut && signOut()}
                />
            </div>

            <div className={`flex flex-col items-start justify-center`}>
                <div className={`flex flex-col items-start mb-2 ${isWhiteText && '!text-white'}`}>
                    {name && <span className={'my-auto font-semibold text-base'}>{name}</span>}
                </div>
                {isPatient && (
                    <VaccinationStatus isPatient={isPatient} status={immunizationStatus ? {
                        color: immunizationStatus?.split(':')[0],
                        display: immunizationStatus?.split(':')[1]
                    } : undefined} />
                )}
            </div>

            {openEditPhoto && (
                <EditPhoto
                    defaultPhoto={src}
                    isPatient={isPatient}
                    open={openEditPhoto}
                    onClose={() => setOpenEditPhoto(false)}
                />
            )}
        </div>
    );
}

export default UserProfileSummary;
