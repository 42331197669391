import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PrimaryButton from "../button/PrimaryButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import DocumentUpload from "./DocumentUpload";
import DocumentsTable from "../table/document/DocumentsTable";
import DocumentMobile from "../table/document/DocumentMobile";
import {useDocument} from "../../contexts/patient/DocumentProvider";
import usePatient from "../../hooks/usePatient";
import Spinner from "../spinner/Spinner";

function DocumentView(props: {}) {
    const [t] = useTranslation();
    const {documents, loadingDocuments} = useDocument();
    const {patient} = usePatient();
    const [showAddDocumentModal, setShowAddDocumentModal] = useState<boolean>(false);

    const sortedDocuments = useMemo(() => {
        const updatedDocuments = documents || patient?.documents || [];
        return updatedDocuments.sort((a: any, b: any) => Number(new Date(b?.created?.date)) - Number(new Date(a?.created?.date)));
    }, [documents, patient?.documents]);

    return (
        <div>
            <div className={'flex lg:flex-row flex-col justify-between'}>
                <h4 className={'font-semibold '}>{t('DOCUMENTS')}</h4>
                <div className={'lg:w-60 lg:mt-0 mt-4 w-full mb-4'}>
                    <PrimaryButton
                        outLine={true}
                        onClick={()=>setShowAddDocumentModal(true)}
                    >
                        <FontAwesomeIcon
                            icon={faPlus}
                            className={'h-5 mr-3'}
                        />
                        <span>{t('ADD_NEW_DOCUMENT')}</span>
                    </PrimaryButton>
                    <DocumentUpload showModalInitial={showAddDocumentModal} updateModal={setShowAddDocumentModal}/>
                </div>
            </div>
            { loadingDocuments?
                <div className={'flex flex-row justify-center'}><Spinner/></div>
                :
                <div>
                    <div className={'hidden lg:block'}>
                        <DocumentsTable documents={sortedDocuments} />
                    </div>
                    <div className={'lg:hidden'}>
                        <DocumentMobile documents={sortedDocuments}/>
                    </div>
            </div>}
        </div>
    );
}

export default DocumentView;