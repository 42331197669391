import AppointmentProvider from '../../../contexts/patient/AppointmentProvider';
import {Appointments} from '../../../components/patient-dashboard';
import {useEffect} from "react";
import {ampli} from "../../../ampli";

const AppointmentsList = () => {

    useEffect(() => {
        ampli.track({event_type:"Route to Home Screen's My appointments"});
    }, []);
  return (
    <AppointmentProvider>
      <Appointments />
    </AppointmentProvider>
  );
};

export default AppointmentsList;
