import React from 'react';
import {useLocation, useParams} from "react-router-dom";
import {PatientProvider} from "../../../contexts/practitioner/PatientProvider";
import StudentDetailPane from "../../../components/detail/StudentDetailPane";
import compose from "../../../util/compose";
import withPatientDetail from "../../../hocs/withPatientDetail";

const PatientDetailView = compose(withPatientDetail)(StudentDetailPane);
function StudentDetail() {

    const {studentId} = useParams()
    const { state } = useLocation()

    return (
        <PatientProvider patientId={studentId}>
            <PatientDetailView studentId={studentId} appointment={state?.appointment}></PatientDetailView>
        </PatientProvider>
    );
}

export default StudentDetail;