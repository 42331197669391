import React, {useState} from 'react';
import InputGroup from "../group/InputGroup";
import {Controller, useForm} from "react-hook-form";
import UploadImageInput from "../input/UploadImageInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleUser} from "@fortawesome/free-regular-svg-icons";
import {ImageListType} from "react-images-uploading";
import SubmitPrimaryButton from "../button/SubmitPrimaryButton";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import useProfessional from "../../hooks/useProfessional";
import {OnboardingSection} from "../../pages/professional/onboarding/SideNav";

function ProfilePhoto(props: { onSectionCompleted: (section: OnboardingSection) => any }) {
    const {onSectionCompleted} = props;
    const {register, handleSubmit, control, formState: {errors}} = useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const [fidImg, setFidImg] = useState<File>();
    const {addPractitionerProfileMetadata, uploadProfilePhoto} = useProfessional();
    const [submitError, setSubmitError] = useState<string>();

    function studentIdAdded(image: ImageListType) {
        setFidImg(image?.[0].file);
    }

    function submitForm(data: any) {


        if (fidImg) {
            setSubmitError(undefined);
            setLoading(true);
            return addPractitionerProfileMetadata && addPractitionerProfileMetadata().then(({body}) => {
                return uploadProfilePhoto && uploadProfilePhoto(fidImg, body.path).then(() => {
                    setLoading(false);
                    onSectionCompleted(OnboardingSection.ProfilePhoto);
                }).catch((reason) => {
                    setLoading(false);
                    setSubmitError(reason.message);
                })

            }).catch((reason) => {
                setLoading(false);
                setSubmitError(reason.message);
            });
        }
    }

    return (
        <>
            <InputGroup>
                <form onSubmit={handleSubmit(submitForm)}>
                <div className={'max-w-md mx-auto'}>
                <h2 className={'mx-auto mb-3 mt-5 font-semibold text-xl text-center'}>Provide a current photo for your professional profile</h2>
                <h3 className={'mx-auto font-medium text-sm text-center mb-10'}>Please select photo to upload (png, jpg)</h3>
                <div className={'mx-auto w-50'}>
                    <Controller
                        name="profilePhoto"
                        control={control}
                        rules={{required: true}}
                        render={({field}) =>
                            <UploadImageInput
                                icon={<FontAwesomeIcon icon={faCircleUser} className={'h-20 text-brand-color'}/>}
                                imageAdded={(image) => {
                                    studentIdAdded(image);
                                    field.onChange(image)
                                }}
                                title={'Edit profile photo'}/>}/>
                    <span className={'text-red-600'}>{errors['profilePhoto']?.type === 'required' &&
                        <p role="alert">Profile photo is required</p>}</span>

                </div>
                </div>
                    <span className={'text-red-600'}>{submitError}</span>
                <div className={'w-40 ml-auto'}>
                    <SubmitPrimaryButton isLoading={loading}>Continue <FontAwesomeIcon
                        icon={faArrowRight}/></SubmitPrimaryButton>
                </div>
                </form>
            </InputGroup>
        </>
    );
}

export default ProfilePhoto;