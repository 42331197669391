import {useTranslation} from 'react-i18next'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useCallback, useState} from 'react';
import {Outlet, useLocation} from 'react-router-dom'
import {useWindowSize} from '../../../hooks/useWindowSize';
import {faBars, faStethoscope, faXmark} from '@fortawesome/free-solid-svg-icons';
import SideNav from '../../SideNav';
import {faQuestionCircle} from '@fortawesome/free-regular-svg-icons';
import VideoCallModal from "../../../components/modal/VideoCallModal";
import {ICalendar, IDossierLine, IMessages} from '../../../components/Icons';


const Dashboard = () => {
  const { pathname } = useLocation()
  const [t] = useTranslation();
  const {width} = useWindowSize();
  const [showNavbar, setShowNavbar] = useState<boolean>(false);
  const lg_breakpoint = 1024;
  const menuItems = [
    {
      title: t('GET_CARE'),
      icon: <FontAwesomeIcon icon={faStethoscope} />,
      url: 'get-care'
    },
    {
      title: t('APPOINTMENTS'),
      icon: <ICalendar />,
      url: 'appointments',
    },
    {
      title: t('MESSAGES'),
      icon: <IMessages />,
      url: 'messages',
    },
    {
      title: t('IMMUNIZATION'),
      icon: <IDossierLine />,
      url: 'immunizations',
    },
    {
      title: t('HELP_CENTER'),
      icon: <FontAwesomeIcon icon={faQuestionCircle} />,
      url: 'help-center',
    },
  ];

  const shouldDisplayNav = useCallback(() => {
    return showNavbar || (width || 0) > lg_breakpoint;
  }, [width, showNavbar]);
  const shouldDisplayContent = useCallback(() => {
    return !showNavbar || (width || 0) > lg_breakpoint;
  }, [width, showNavbar]);

  return (
      <div className={'flex flex-row w-full relative'}>
        {shouldDisplayNav() && ( // TODO componentize sidenavs
            <div className={'z-10'}>
              <div className={'w-screen h-screen fixed bg-black bg-opacity-20 lg:w-fit'}
                   onClick={() => setShowNavbar(!showNavbar)}/>
              <div
                  className={'lg:max-w-md bg-white shadow-lg min-h-screen  fixed  w-80'}
              >
                <FontAwesomeIcon
                    onClick={() => setShowNavbar(!showNavbar)}
                    className={'ml-auto p-5 h-10 lg:hidden'}
                    icon={faXmark}
                />
                <SideNav items={menuItems} professional={false} navItemClicked={() => setShowNavbar(false)}/>
              </div>


            </div>
        )}


        <main className={`${pathname !== '/patient/dashboard' && 'lg:px-10 lg:pt-10'} lg:ml-80 w-full bg-neutral-100 min-h-screen`}>
          <div className={'bg-white shadow-lg mb-5 lg:mb-0'}>
            <FontAwesomeIcon
                onClick={() => setShowNavbar(!showNavbar)}
                className={'h-10 p-5 lg:hidden'}
                icon={faBars}
            />
          </div>
          <div className={`${pathname !== '/patient/dashboard' && 'px-2'}`}>
            <Outlet/>
            <VideoCallModal/>
          </div>
        </main>
      </div>
  );
};
export default Dashboard;
