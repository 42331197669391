import {useTranslation} from 'react-i18next';
import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import TableView from "../TableView";
import ExpandableTableRow from "../ExpandableTableRow";
import GetTelecom from './GetTelecom';
import PrimaryButton from "../../button/PrimaryButton";
import {useRefer} from "../../../contexts/practitioner/ReferProvider";
import Spinner from '../../spinner/Spinner';
import {sendAutomatedSendbirdMessage} from "../../../util/sendBird";
import useAuth from "../../../hooks/useAuth";
import {ampli} from "../../../ampli";

function ClinicsTable(props: { clinics: any[], appointId?: string, studentId?: string }) {
    const { clinics, appointId } = props;
    const [t] = useTranslation();
    const { createRefer } = useRefer();
    const navigate = useNavigate();
    const { userProfile } = useAuth();
    const [isReferLoading, setIsReferLoading] = useState<string>('');

    const DocumentHeaders: string[] = [
        t('NAME'), t('ADDRESS'), t('HOURS_OF_OPERATIONS'), t('PHONE'), t('FAX')
    ];

    const ReferDocumentHeaders: string[] = [
        t('NAME'), t('ADDRESS'), t('HOURS_OF_OPERATIONS'), t('REFER')
    ];

    async function handleCreateRefer(subjectId: string, clinicName: string, clinicAddress: string) {
        try {
            setIsReferLoading(subjectId)
            if (createRefer && appointId && userProfile && props.studentId) {
                await sendAutomatedSendbirdMessage(userProfile, props.studentId, `Hi, I recommend you visit ${clinicName} at ${clinicAddress}`);
                await createRefer(subjectId, appointId);
                ampli.track({
                    event_type: 'Referred Appointment'
                });
                navigate(`/professional/dashboard/messages/${props.studentId}`);
            }
        } catch (error) {
            console.log("Unable to create a refer!", error)
        } finally {
            setIsReferLoading('')
        }
    }

    return (
        <>
            <TableView tableHeaders={appointId ? ReferDocumentHeaders : DocumentHeaders} showPagination={true}>
                {clinics?.map(clinic => (
                    <ExpandableTableRow key={clinic.id}>
                        <td>
                            <div className="flex items-center py-6 pr-4">
                                <div className="flex items-center justify-center bg-neutral-100 w-[52px] h-[52px] rounded-full mr-4">
                                    <div className="bg-clinic-icon w-6 h-6" />
                                </div>
                                <p className="flex-1 text-base font-semibold text-heading">{clinic?.name}</p>
                            </div>
                        </td>
                        <td>
                            <div className="flex py-6 pr-4">
                                <div className='mr-4'>
                                    <div className="bg-location-icon w-4 h-4 bg-contain mt-1" />
                                </div>
                                <div className="flex flex-col">
                                    <span className="text-base text-heading">{clinic?.address?.[0]?.line[0]}</span>
                                    <span className="text-base text-heading">{`${clinic?.address?.[0]?.city}, ${clinic?.address?.[0]?.postalCode}`}</span>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="flex py-6 pr-4">
                                <div className='mr-4'>
                                    <div className="bg-calendar-icon w-4 h-4 bg-contain mt-1" />
                                </div>
                                <div className="flex flex-col">
                                    <span className="text-base font-semibold text-heading">{clinic?.text?.split(/[0-9]/)[0]}</span>
                                    <span className="text-base text-heading">{clinic?.text?.replace(clinic?.text.split(/[0-9]/)[0], '')}</span>
                                </div>
                            </div>
                        </td>
                        {appointId ? (
                            <td>
                                <PrimaryButton
                                    outLine
                                    onClick={() => handleCreateRefer(clinic?.id, clinic?.name, clinic?.address?.[0]?.line?.[0])}
                                >
                                    <div className='flex items-center justify-center w-[120px] px-4'>
                                        {isReferLoading !== clinic?.id && <div className='bg-share-icon w-4 min-w-4 h-4 bg-contain mr-2' />}
                                        {t('REFER')}
                                        {isReferLoading === clinic?.id && (
                                            <div className='ml-2'>
                                                <Spinner customSize='h-6 w-6' />
                                            </div>
                                        )}
                                    </div>
                                </PrimaryButton>
                            </td>
                        ) : (
                            <>
                                <td>
                                    <div className="flex pr-4">
                                        <GetTelecom system='PHONE' telecomArray={clinic?.telecom || []} />
                                    </div>
                                </td>
                                <td>
                                    <div className="flex">
                                        <GetTelecom system='FAX' telecomArray={clinic?.telecom || []} />
                                    </div>
                                </td>
                            </>
                        )}
                    </ExpandableTableRow>
                ))}
            </TableView>
        </>
    );
}

export default ClinicsTable;