import {ReactNode} from "react";

export interface Professional {
    active: boolean;
    created: CreationObject;
    filter_string: string;
    organization?: any;
    aboutMe?: string;
    profession?: any;
    interests?: string;
    identifier: Identifier[];
    name: HumanName[];
    photo?: { url: string }[];
    owner: string;
    communication?: { code: CodeableConcept }[];
    resourceType: string;
    status: { onboarding: OnboardingStatus };
    subject: string;
    telecom: ContactPoint[];
    tenant: string;
    type: string;
}

export interface OnboardingStatus {
    addressComplete: StatusInfo;
    identificationComplete: StatusInfo;
    photoComplete: StatusInfo;
    profileComplete: StatusInfo;
    signatureComplete: StatusInfo;
}

export interface ContactPoint {
    // from Element: extension
    system?: "phone" | "fax" | "email" | "pager" | "url" | "sms" | "other"; // I phone | fax | email | pager | url | sms | other
    value: string; // I The actual contact point details
    use: "home" | "work" | "temp" | "old" | "mobile"; // home | work | temp | old | mobile - purpose of this contact point
    rank?: number; // Specify preferred order of use (1 = highest)
    period?: Period;  // Time period when the contact point was/is in use
}

export interface StatusInfo {
    status: boolean;
}

export interface HumanName {
    use: "usual" | "official" | "temp" | "nickname" | "anonymous" | "old" | "maiden"; // usual | official | temp | nickname | anonymous | old | maiden
    text: string; // Text representation of the full name
    family: string; // Family name (often called 'Surname')
    given: string[]; // Given names (not always 'first'). Includes middle names
    prefix: string[]; // Parts that come before the name
    suffix: string[]; // Parts that come after the name
    period: Period; // Time period when name was/is in use
}

export interface CreationObject {
    date: Date;
    sourceType: string;
    source: string;
}

export interface Communication {
    coding: Coding[];
    text: string;
}

export interface Identifier {
    use?: "usual" | "official" | "temp" | "secondary" | "old";
    type: CodeableConcept[]; // Description of identifier
    system: string; // The namespace for the identifier value
    value: string; // I The value that is unique
    period: Period; // Time period when id is/was valid for use
    assigner: string; // Organization that issued id (may be just text)
}

export interface CodeableConcept {
    coding: Coding[];
    text: string;
}

export interface Coding {
    system?: string;
    version?: string;
    code: string;
    display: string;
    userSelected?: boolean;
}
export interface DocumentAttachment {
    contentType?: string;
    creation?: Date;
    title?: string;
    url: string;
}

export interface Period {
    start: Date;
    end: Date;
}
export interface DocumentPathReference {
    path: string;
}
export interface ServerReply<T> {
    statusCode: number;
    body: T;
}

export interface FileServerReply<T> {
    statusCode: number;
    file: string;
}

export interface Address {
    state: string;
    city: string;
    line: string;
    postalCode: string;
}

export interface Step {
    title: string;
    image: string;
}
export interface Tutorial {
    index: number;
    title: string;
    icon: ReactNode;
    steps?: Step[];
}
export interface FAQ { title: string; description: ReactNode };

export interface SchoolSearch {
    id: string;
    name: string;
}
export interface SavedSearch {
    id: string;
    title: string;
    schools: SchoolSearch[];
    status: string;
    isDefault: boolean;
}
export interface FHIREvent {
    sourceType: string
}
type OccurrenceSeries = `occurrence${number}`;

export interface Immunization {
    id: string;
    resourceType: "Immunization";
    updated?: CreationObject[];
    attachment?: DocumentAttachment[];
    temp_evaluation?: any;
    tenant: string;
    basedOn?: Reference<ImmunizationRecommendation>[];
    occurrences?: string[];
    description?: string;
    text?: string;
    date: Date;
    patient?: string;
    [name:OccurrenceSeries]: {occurrenceDateTime: string};
    created: CreationObject;
    evaluations?: ImmunizationEvaluation[];
    status: "completed" | "entered-in-error" | "not-done"
    vaccineCode: CodeableConcept;
}
export interface ImmunizationRecommendation {
    date: string;
    id: string;
    recommendation: VaccineRecommendation[];
}
export interface VaccineRecommendation {
    id: string;
    vaccineCode: CodeableConcept[];
    forecastStatus: CodeableConcept;
    targetDisease?: CodeableConcept;
    contraindicatedVaccineCode?: CodeableConcept[];
    forecastReason?: CodeableConcept[];
    dateCriterion?: DateCriterion[];
    description?: string // MD String
    series?: string;
    doseNumber?: string;
    seriesDoses?: string;
    supportingImmunization?: Reference<Immunization|ImmunizationEvaluation>;
    supportingPatientInformation?: Reference<any>
}
export interface ImmunizationEvaluation {
    temp_evaluation?: any;
    status: "completed" | "entered-in-error";
    id: string;
    patient: any;
    date?: Date;
    targetDisease: CodeableConcept;
    immunizationEvent: Reference<Immunization>;
    doseStatus: CodeableConcept;
    doseStatusReason?: CodeableConcept[];
    description?: string // MD String
    series?: string;
    doseNumber?: string;
    seriesDoses?: string;
}
export interface Reference<T> {
    reference?: string;
    type?: string
    identifier?: any;
    display?: string;
}
export interface DateCriterion {
    code: CodeableConcept;
    value: Date // DateTime
}
export enum Status {
    MissingShot = 'missingShot',
    Pending = 'pending',
    EditedAndPending = 'editedAndPending',
    Approved = 'approved',
    Rejected = 'rejected'
}