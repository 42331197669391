import React, {useState} from 'react';
import ImmunizationOverview from "./ImmunizationOverview";
import DateRangeSelector, {DateRanges} from "./DateRangeSelector";
import {ReportProvider} from "../../contexts/admin/ReportProvider";
import {subtractPeriodToDate} from "../../util/date/convertDateString";
import NonCompliantStudents from "./NonCompliantStudents";
import PractitionerSearchProvider from "../../contexts/PractitionerSearchProvider";

function AdminPane(props: {}) {
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [period, setPeriod] = useState<DateRanges>('month');
    const dateChanged = (date: Date, period: DateRanges) => {
        setStartDate(date);
        setPeriod(period);
        console.log("Date Changed", date, period);
    };
    return (
        <div>
            <div className={'py-8'}>
                <DateRangeSelector newDatePeriodUpdated={dateChanged}/>
            </div>
            <ReportProvider startDate={subtractPeriodToDate(startDate, period)} endDate={startDate}>
                <ImmunizationOverview/>
            </ReportProvider>
            <div className={'mt-4 pb-8'}>
                <PractitionerSearchProvider>
                    <NonCompliantStudents/>
                </PractitionerSearchProvider>
            </div>
        </div>
    );
}

export default AdminPane;