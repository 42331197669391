import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {AlternateInputRadio} from "../input/AlternateInputRadio";
import {UseFormReturn} from "react-hook-form";
import {ScheduleSlot, useSchedule} from "../../contexts/patient/ScheduleProvider";

function EndTime(props: {form:UseFormReturn<any>, startTime: string}) {
    const {form, startTime} = props;
    const [t, i18n] = useTranslation();
    const {scheduleSlots} = useSchedule();


    function maxAvailableSlots(scheduleSlots: ScheduleSlot[], increments= [30, 0]) {
        const startingSlotIndex = scheduleSlots.findIndex((slot) => slot.id === startTime);
        const startingSlots = scheduleSlots.slice(startingSlotIndex, scheduleSlots.length);
        let finalAvailableSlots = [];
        for (const scheduleSlot of startingSlots) {
            const minutes = new Date(scheduleSlot.end).getMinutes();
            if(scheduleSlot.status === 'free'){
                if(increments.includes(minutes)) {
                    finalAvailableSlots.push(scheduleSlot);
                }
            } else {
                break;
            }
        }
        console.log(startingSlots, startingSlotIndex);
        return finalAvailableSlots;
    }

    const endingScheduleSlots = useMemo(() => maxAvailableSlots(scheduleSlots||[]), [scheduleSlots]);


    return (<> { endingScheduleSlots &&  endingScheduleSlots.length > 0 ?
        <div className="h-full w-full mt-3 flex flex-col gap-3">
            <div className={'grid grid-cols-2 gap-3 '}>
                {endingScheduleSlots
                    .map((time, index) => (
                        <div key={time.id} className={'something'}>
                            <AlternateInputRadio
                                id={time.id}
                                inputLabel={new Date(time.end).toLocaleTimeString(i18n.resolvedLanguage, {
                                    hour: '2-digit',
                                    minute: '2-digit'
                                })}
                                optionValue={time.id}
                                {...form.register('endTime', {required: true})}
                            />
                        </div>
                    ))}
            </div>
        </div> :
        <div className={'h-full'}>
            <span className={'text text-neutral-400'}>{t('No available slots')}</span>
        </div>}
        </>
    );
}

export default EndTime;