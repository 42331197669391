import React from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {DocumentProvider} from "../../../contexts/DocumentProvider";
import TableView from "../TableView";
import ExpandableTableRow from "../ExpandableTableRow";
import PractitionerDocumentDownloadLink from "../../link/PractitionerDocumentDownloadLink";
import {IDossierLine} from '../../Icons';

function DocumentsTable(props: { documents: any[] }) {
    const {documents} = props;

    const [t, i18n] = useTranslation();
    const DocumentHeaders: string[] = [
        t('NAME'), t('DATE_UPLOADED'), t('VIEW')
    ];

    return (
        <>
            <TableView tableHeaders={DocumentHeaders} showPagination>
                {documents?.map((document, index) =>
                    <ExpandableTableRow key={document.id + index}>
                        <td>
                            <div className={'my-auto font-semibold text-base flex text-center py-4'}>
                                <div className='mr-3 flex items-center justify-center'>
                                    <IDossierLine color='#9662D7' />
                                </div>
                                <span
                                className={'my-auto '}><b> {document.content?.[0]?.attachment?.title}</b></span>
                            </div>
                        </td>
                        <td>
                            {new Date(document?.created?.date).toLocaleDateString(i18n.resolvedLanguage)}
                        </td>
                        <td>
                            <DocumentProvider photoUrl={document.content?.[0].attachment.url}>
                                <PractitionerDocumentDownloadLink contentType={document?.content?.[0].attachment.contentType}>
                                    <div
                                        className={'bg-brand-color text-brand-color  rounded-full bg-opacity-10 w-fit px-4 py-3'}>
                                        <FontAwesomeIcon icon={faEye}/>
                                    </div>
                                </PractitionerDocumentDownloadLink>
                            </DocumentProvider>
                        </td>
                    </ExpandableTableRow>
                )}

            </TableView>
        </>
    );
}

export default DocumentsTable;