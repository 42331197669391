import React, {memo, ReactNode, useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import RightModal from "./RightModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import PrimaryButton from "../button/PrimaryButton";
import {UseFormReturn} from "react-hook-form";

function FilterComponent(props: { children: ReactNode, form: UseFormReturn, onUpdateFilterParams?: (params?: Record<string, any>) => void, displayFilter?: boolean; filterVisibilityChanged?: (visible: boolean) => void }) {
    const {displayFilter = false, form, children, onUpdateFilterParams, filterVisibilityChanged} = props;

    const [t] = useTranslation()
    const {handleSubmit, reset} = form;


    const [showFilter, setShowFilter] = useState(displayFilter);

    function changeFilterDisplayed(visibility: boolean) {
        setShowFilter(visibility);
        filterVisibilityChanged && filterVisibilityChanged(visibility);
    }

    const updateFilter = useCallback((visibility: boolean) => changeFilterDisplayed(visibility), []);

    useEffect(() => {
        setShowFilter(displayFilter);
    }, [displayFilter]);


    function onSubmit(data: any) {
        if (onUpdateFilterParams) {
            onUpdateFilterParams(data);
        }

        updateFilter(false);
    }

    return (

            <RightModal isOpen={showFilter} onClose={() => updateFilter(false)}>
                <form className={'h-full'} onSubmit={handleSubmit(onSubmit)}>
                <div className={'flex flex-col h-full justify-between overflow-y-auto'}>

                    <div className="flex flex-col px-3  ">
                        <div className="flex items-center justify-between py-4">
                            <h1 className="text-xl font-semibold">{t('FILTERS')}</h1>

                            <button type={'button'}
                                className="text-slate-400 hover:text-slate-500 transition duration-300 ease-in-out "
                                onClick={() => updateFilter(false)}
                            >
                                <FontAwesomeIcon icon={faXmark} />
                            </button>
                        </div>

                        {children}


                    </div>

                    {/* Modal Footer */}

                    <div className="flex gap-2 items-center px-3 py-4  w-full">
                        <PrimaryButton outLine={true} type={'button'}
                                       onClick={() => {
                                           reset();
                                           onUpdateFilterParams && onUpdateFilterParams(undefined);
                                       }}
                        >
                            {t('RESET')}
                        </PrimaryButton>

                        <PrimaryButton type={'submit'}
                        >
                            {t('APPLY')}
                        </PrimaryButton>
                    </div>

                </div>
                </form>
            </RightModal>

    );
}

export default memo(FilterComponent);