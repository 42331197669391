import React from "react";
import LoadingSplash from "../components/spinner/LoadingSplash";
import useCodeSets from "../hooks/useCodeSets";

export default function withStudentCodeSets(Component: typeof React.Component) {
    return (props: any) => {
        const {genderCodes, relationshipCodes, raceCodes, pronounCodes} = useCodeSets();

        if (!(genderCodes && relationshipCodes && raceCodes && pronounCodes)) {
            return (<LoadingSplash showLogo={false}
                                   loadingText={'Retrieving School Information' + (genderCodes ? '.' : '') + (relationshipCodes ? '.' : '') + (raceCodes ? '.' : '') + (pronounCodes ? '.' : '')}/>);
        } else {
            return <Component {...props}></Component>;
        }
    }
}
