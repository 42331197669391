import React from "react";
import useAuth from "../hooks/useAuth";
import {Navigate, useLocation} from "react-router-dom";


export default function withAdministrator(Component: typeof React.Component) {
    return (props: any) => {
        const {isInAdminGroup} = useAuth();
        const {pathname, search} = useLocation();

        if (isInAdminGroup && isInAdminGroup()) {
            return <Component {...props}></Component>;
        } else {
            return ( // Maybe just show an error instead?
                <Navigate
                    to="../"
                    state={{from: pathname, search}}
                    replace
                />
            );
        }
    }
}
