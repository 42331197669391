import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import TableMobile from "../TableMobile";
import GetTelecom from './GetTelecom';
import {AccordionItem} from '../../dashboard-search/Accordion';
import {faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PrimaryButton from '../../button/PrimaryButton';
import {useRefer} from "../../../contexts/practitioner/ReferProvider";
import Spinner from '../../spinner/Spinner';
import {sendAutomatedSendbirdMessage} from "../../../util/sendBird";
import useAuth from "../../../hooks/useAuth";
import {ampli} from "../../../ampli";

function ClinicsMobile(props: { clinics: any[], appointId?: string, studentId?: string  }) {
  const { clinics, appointId } = props;
  const [t] = useTranslation();
  const { createRefer } = useRefer();
  const { userProfile } = useAuth();
  const navigate = useNavigate();
  const [isReferLoading, setIsReferLoading] = useState<string>('');

  async function handleCreateRefer(subjectId: string, clinicName: string, clinicAddress: string) {
    try {
      setIsReferLoading(subjectId)
      if (createRefer && appointId && userProfile && props.studentId) {
        await sendAutomatedSendbirdMessage(userProfile, props.studentId, `Hi, I recommend you visit ${clinicName} at ${clinicAddress}`);
        await createRefer(subjectId, appointId)
        ampli.track({
          event_type: 'Referred Appointment'
        });
        navigate(`/professional/dashboard/messages/${props.studentId}`)
      }
    } catch (error) {
      console.log("Unable to create a refer!", error)
    } finally {
      setIsReferLoading('')
    }
  }

  return (
    <TableMobile showPagination>
      {clinics.map(({
        name,
        address,
        text,
        telecom,
        id
      }) =>
        <div
          className="flex py-3"
          key={id}
        >
          <div className="flex items-center justify-center bg-neutral-100 w-[48px] h-[48px] rounded-full mr-4 my-2">
            <div className="bg-clinic-icon w-5 h-5" />
          </div>
          <div className="flex-1">
            <AccordionItem
              title={<>
                <div className="flex items-center pb-[5px]">
                  <p className="flex-1 text-base font-semibold text-heading">{name}</p>
                </div>
                <div className="flex py-[5px]">
                  <div className='mr-4'>
                    <div className="bg-location-icon w-4 h-4 bg-contain mt-1" />
                  </div>
                  <div className="flex flex-col">
                    <span className="text-base text-heading">{address?.[0]?.line[0]}</span>
                    <span className="text-base text-heading">{`${address?.[0]?.city}, ${address?.[0]?.postalCode}`}</span>
                  </div>
                </div>
                <div className="flex pt-[5px]">
                  <div className='mr-4'>
                    <div className="bg-calendar-icon w-4 h-4 bg-contain mt-1" />
                  </div>
                  <div className="flex flex-col">
                    <span className="text-base font-semibold text-heading">{text?.split(/[0-9]/)[0]}</span>
                    <span className="text-base text-heading">{text?.replace(text.split(/[0-9]/)[0], '')}</span>
                  </div>
                </div>
              </>}
              isIconStart
              isDefaultOpen={false}
              openIcon={<FontAwesomeIcon
                icon={faChevronDown}
                className="text-heading"
              />}
              closeIcon={<FontAwesomeIcon
                icon={faChevronUp}
                className="text-heading"
              />}
            >
              <div>
                {appointId ? (
                  <PrimaryButton
                    outLine
                    onClick={() => handleCreateRefer(id, name, address?.[0]?.line?.[0])}
                  >
                    <div className='flex items-center justify-center px-4'>
                      {isReferLoading !== id && <div className='bg-share-icon w-4 min-w-4 h-4 bg-contain mr-2' />}
                      {t('REFER')}
                      {isReferLoading === id && (
                        <div className='ml-2'>
                          <Spinner customSize='h-6 w-6' />
                        </div>
                      )}
                    </div>
                  </PrimaryButton>
                ) : (
                  <>
                    <div className="flex pb-[5px]">
                      <GetTelecom system='PHONE' telecomArray={telecom || []} />
                    </div>
                    <div className="flex py-[5px]">
                      <GetTelecom system='FAX' telecomArray={telecom || []} />
                    </div>
                  </>
                )}
              </div>
            </AccordionItem>
          </div >
        </div>
      )
      }
    </TableMobile >
  );
}

export default ClinicsMobile;