import {useTranslation} from 'react-i18next'
import {faStethoscope, faSyringe} from "@fortawesome/free-solid-svg-icons";
import PrimaryLink from "../../../components/link/PrimaryLink";
import {FAQ, Tutorial} from "../../../types/database/SharedTypes";
import HelpCenterView from "../../../components/help-center/HelpCenterView";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ICalendarLine, IMessages, IShareForwardLine} from '../../../components/Icons';

function HelpCenter(props: {}) {
  const { } = props;
  const [t] = useTranslation();
  // "shadow-3xl shadow-lime-500 border border-lime-500"
  const tutorials: Tutorial[] = [
    {
      index: 1,
      title: t('HOWTOMANAGESTUDENTS'),
      icon: <FontAwesomeIcon icon={faStethoscope} />,
      steps: [
        { title: t('OPEN_STUDENTS_FROM_THE_HOMEPAGE'), image: require('../../../assets/images/help-center/professional-steps/manage-students/manage-students-1.png') },
        { title: t('CLICK_ON_THIS_ICON_FOR_FILTERING_THE_STUDENTS_LIST'), image: require('../../../assets/images/help-center/professional-steps/manage-students/manage-students-2.png') },
        { title: t('FILTER_STUDENTS_BY_SCHOOL_OR_VACCINATION_STATUS'), image: require('../../../assets/images/help-center/professional-steps/manage-students/manage-students-3.png') },
        { title: t('SAVE_YOUR_FILTERS_SETTINGS_TO_QUICKLY_APPLY_THEM_IN_NEXT_TIME'), image: require('../../../assets/images/help-center/professional-steps/manage-students/manage-students-4.png') },
        { title: t('APPLY_AND_MANAGE_YOUR_SAVED_SEARCHES'), image: require('../../../assets/images/help-center/professional-steps/manage-students/manage-students-5.png') },
        { title: t('REVIEW_THE_LIST_OF_STUDENTS_AND_CLICK_ON_THE_NAME_FOR_GETTING_DETAILED_INFO'), image: require('../../../assets/images/help-center/professional-steps/manage-students/manage-students-6.png') },
        { title: t('REVIEW_THE_PERSONAL_INFO_OF_STUDENT_AND_HIM_PARENT_DATE_OF_BIRTH_SCHOOL_STUDENT_ID'), image: require('../../../assets/images/help-center/professional-steps/manage-students/manage-students-7.png') },
        { title: `...${t('VACCINES')}...`, image: require('../../../assets/images/help-center/professional-steps/manage-students/manage-students-8.png') },
        { title: `...${t('IMMUNIZATION_DOCUMENTS')}...`, image: require('../../../assets/images/help-center/professional-steps/manage-students/manage-students-9.png') },
        { title: t('LEAVE_REVIEW_OR_RECOMMENDATION_MESSAGE_ABOUT_PROFILE'), image: require('../../../assets/images/help-center/professional-steps/manage-students/manage-students-10.png') },
        { title: t('SELECT_STATUS_AND_LEAVE_A_RECOMMENDATION_MESSAGE'), image: require('../../../assets/images/help-center/professional-steps/manage-students/manage-students-11.png') },
      ],
    },
    {
      index: 2,
      title: t('CHECKSTUDENTIMMUNIZATION'),
      icon: <FontAwesomeIcon icon={faSyringe} />,
      steps: [
        { title: t('OPEN_STUDENTS_FROM_THE_HOMEPAGE'), image: require('../../../assets/images/help-center/professional-steps/student-immunization/student-immunization-1.png')},
        { title: t('CLICK_ON_THE_STUDENTS_NAME_FOR_OPENING_THEIR_PROFILE'), image: require('../../../assets/images/help-center/professional-steps/student-immunization/student-immunization-2.png')},
        { title: t('GO_TO_VACCINES_SECTION_AND_SELECT_THE_RECORD'), image: require('../../../assets/images/help-center/professional-steps/student-immunization/student-immunization-3.png')},
        { title: t('REVIEW_THE_IMMUNIZATION_INFO_AND'), image: require('../../../assets/images/help-center/professional-steps/student-immunization/student-immunization-4.png')},
        { title: t('LEAVE_MESSAGE_OR_REASON_OF_YOUR_DECISION_TO_STUDENT_AND'), image: require('../../../assets/images/help-center/professional-steps/student-immunization/student-immunization-5.png')},
        { title: t('STATUS_WAS_SAVED'), image: require('../../../assets/images/help-center/professional-steps/student-immunization/student-immunization-6.png')},
      ]
    },
    {
      index: 3,
      title: t('REFERAPPOINTMENT'),
      icon: <IShareForwardLine />,
      steps: [
        { title: t('OPEN_APPOINTMENTS_FROM_THE_HOMEPAGE'), image: require('../../../assets/images/help-center/professional-steps/appointment/appointment-1.png')},
        { title: t('SELECT_AN_APPOINTMENT_WITH_THE_EQUESTED_STATUS'), image: require('../../../assets/images/help-center/professional-steps/appointment/appointment-2.png')},
        { title: t('REVIEW_THE_APPOINTMENTS_DETAILS_FROM_STUDENT_AND_CLICK_ON_REFER_BUTTON'), image: require('../../../assets/images/help-center/professional-steps/appointment/appointment-3.png')},
        { title: t('FIND_THE_PROFESSIONAL_OR_CLINIC_YOU_WANT_TO_REFER_THE_STUDENT_AND_CLICK_ON_THE_REFER_BUTTON_WITH_THEM'), image: require('../../../assets/images/help-center/professional-steps/appointment/appointment-4.png')},
        { title: t('THE_PROFILE_OF_THIS_PROFESSIONAL_WILL_BE_SENT_TO_THE_STUDENT_AND_YOU_CAN_WRITE_ADDITIONAL_MESSAGES_TO_HIM'), image: require('../../../assets/images/help-center/professional-steps/appointment/appointment-5.png')},
      ]
    },
    {
      index: 4,
      title: t('HOWTOMANAGEAVAILABILITY'),
      icon: <ICalendarLine />,
      steps: [
        { title: t('OPEN_APPOINTMENTS_FROM_THE_HOMEPAGE'), image: require('../../../assets/images/help-center/professional-steps/manage-availability/manage-availability-1.png')},
        { title: t('CLICK_ON_THE_ICON_AT_THE_TOP_OF_PAGE_TO_MANAGE_YOUR_WORK_SCHEDULE'), image: require('../../../assets/images/help-center/professional-steps/manage-availability/manage-availability-2.png')},
        { title: t('SET_YOUR_OPERATIONS_TIME_FOR_EACH_DAY_OF_WEEK_OR_MARK_THE_DAY_LIKE_OUT'), image: require('../../../assets/images/help-center/professional-steps/manage-availability/manage-availability-3.png')},
        { title: t('SET_VACATION_DATES_IF_YOURE_PLANNING_IT'), image: require('../../../assets/images/help-center/professional-steps/manage-availability/manage-availability-4.png')},
        { title: t('FILL_DATE_FROM_DATE_TO_AND_CLICK_ON_SAVE_BUTTON'), image: require('../../../assets/images/help-center/professional-steps/manage-availability/manage-availability-5.png')},
      ]
    },
    {
      index: 5,
      title: t('MESSAGESTUDENT'),
      icon: <IMessages />,
      steps: [
        { title: t('OPEN_MESSAGES_FROM_THE_HOMEPAGE'), image: require('../../../assets/images/help-center/professional-steps/messaging-student/messaging-student-1.png')},
        { title: t('SELECT_A_CHAT_FROM_YOUR_LIST'), image: require('../../../assets/images/help-center/professional-steps/messaging-student/messaging-student-2.png')},
        { title: t('WRITE_A_MESSAGE_OR_UPLOAD_MEDIA_HERE'), image: require('../../../assets/images/help-center/professional-steps/messaging-student/messaging-student-3.png')},
        { title: t('CLICK_ON_THIS_ICON_FOR_STARTING_A_VIDEO_CALL_WITH_STUDENTS'), image: require('../../../assets/images/help-center/professional-steps/messaging-student/messaging-student-4.png')},
        { title: t('CLICK_ON_THIS_ICON_TO_MANAGE_CHAT'), image: require('../../../assets/images/help-center/professional-steps/messaging-student/messaging-student-5.png')},
      ]
    },
    // {
    //   index: 6,
    //   title: 'How to create an account',
    //   icon: faUserNurse,
    //   steps: [
    //     { title: 'Go to Login page and click on Sign Up.', image: require('../../../assets/images/help-center/professional-steps/creating-account/creating-account-1.png')},
    //     { title: 'Select the Health Professional button and fill Personal Info for registering.', image: require('../../../assets/images/help-center/professional-steps/creating-account/creating-account-2.png')},
    //     { title: 'Go to each text field and fill in the required information.', image: require('../../../assets/images/help-center/professional-steps/creating-account/creating-account-3.png')}
    //   ]
    // },
    // {
    //   index: 7,
    //   title: 'How to manage notifications',
    //   icon: faBell,
    //   steps: [
    //     { title: 'Open Notifications from the Homepage.', image: require('../../../assets/images/help-center/professional-steps/manage-notification/manage-notification-1.png')},
    //     { title: 'Review new notifications and click on to go to Appointment or Messages.', image: require('../../../assets/images/help-center/professional-steps/manage-notification/manage-notification-2.png')},
    //   ]
    // },
    // {
    //   index: 8,
    //   title: 'How to manage my schools',
    //   icon: faSchool,
    //   steps: [
    //     { title: 'Open My School from the Homepage.', image: require('../../../assets/images/help-center/professional-steps/manage-schools/manage-schools-1.png')},
    //     { title: 'Click on the button at the top of page to manage your list of schools.', image: require('../../../assets/images/help-center/professional-steps/manage-schools/manage-schools-2.png')},
    //     { title: 'Find the schools, mark the checkboxes required and click the Save button.', image: require('../../../assets/images/help-center/professional-steps/manage-schools/manage-schools-3.png')}
    //   ]
    // },
  ];

  const faq: FAQ[] = [
    { title: t('HPFAQONETITLE'), description: t('HPFAQONETEXT') },
    { title: t('HPFAQTWOTITLE'), description: t('HPFAQTWOTEXT') },
    {
      title: t('HPFAQTHREETITLE'), description: (<>
        <p>{t('HPFAQTHREETEXT')}</p>
        <p><PrimaryLink to={'https://www.cdph.ca.gov/Programs/CID/DCDC/Pages/Immunization/School/shotsforschool.aspx'}>{t('HPFAQTHREELINKSTRING')}</PrimaryLink></p></>)
    },
    {
      title: t('HPFAQFOURTITLE'), description: <p>{t('HPFAQFOURTEXT')}</p>
    },
    { title: t('HPFAQFIVETITLE'), description: <p>{t('HPFAQFIVETEXT')}</p> },
    {
      title: t('HPFAQSIXTITLE'),
      description: <p>{t('HPFAQSIXTEXT')}</p>
    },
    {
      title: t('HPFAQSEVENTITLE'),
      description: <p>{t('HPFAQSEVENTEXT')}</p>
    },{
      title: t('HPFAQEIGHTTITLE'),
      description: <p>{t('HPFAQEIGHTTEXT')}</p>
    },
  ];
  return (
    <div className={'help-center'}>
      <HelpCenterView tutorials={tutorials} faqs={faq} showResources={false} />
    </div>
  );
}

export default HelpCenter;