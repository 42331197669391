import React from 'react';
import InputGroup from "../group/InputGroup";
import ClinicsTable from "../table/clinics/ClinicsTable";
import ClinicsMobile from '../table/clinics/ClinicsMobile';
import LoadingSplash from "../spinner/LoadingSplash";
import {useRefer} from '../../contexts/practitioner/ReferProvider';

function Clinics(props: { appointId?: string , studentId?: string }) {
    const { clinicList, fetchingClinics } = useRefer();
    const { appointId } = props
    return (
        <section>
            <div className={''}>
                <InputGroup>
                    {!fetchingClinics ? (
                        <>
                            <div className="lg:block hidden">
                                <ClinicsTable
                                    studentId={props.studentId}
                                    clinics={clinicList || []}
                                    appointId={appointId}
                                />
                            </div>
                            <div className="block lg:hidden">
                                <ClinicsMobile
                                    studentId={props.studentId}
                                    clinics={clinicList || []}
                                    appointId={appointId}
                                />
                            </div>
                        </>
                    )
                        : <LoadingSplash showLogo={false} />}
                </InputGroup>
            </div>
        </section>
    );
}

export default Clinics;
