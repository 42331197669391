import React from 'react';
import heroImg from '../../assets/images/hero-image.png'
import Logo from "../../components/logo/Logo";
import BoldHeader from "../../components/header/BoldHeader";
import SubHeader from "../../components/header/SubHeader";
import {Link} from "react-router-dom";
import ResetPasswordPane from "../../components/login/ResetPasswordPane";

function Login() {
    return (
        <div className="login-page ">
            <div className="flex flex-row">
                <div className="min-h-screen  basis-full lg:basis-6/12">
                    <div className={'container  flex flex-col max-w-sm pt-24 mx-auto h-full px-4'}>
                        <Link to={'/'}>
                            <BoldHeader title={'Immunization Assistant'}/>
                            <SubHeader title={'Keeping Kids In School'}/>
                        </Link>
                        <div className={'mt-4'}>
                            <ResetPasswordPane/>
                        </div>

                        <div className={'mt-auto mb-5 text-center text-gray-400'}>
                            <div>
                                <span>Democratizing Concierge Medicine™</span>
                            </div>
                            <div>
                                <span>Patent Pending</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="min-h-screen max-h-screen lg:block hidden basis-8/12 relative">
                    <div
                        className={'absolute object-cover object-left-top bg-gradient-to-b from-white via-transparent h-full w-full top-0 left-0'}></div>
                    <div className={'absolute mt-11 ml-14'}>
                        <Logo/>
                    </div>
                    <img src={heroImg} className="hero-image w-full h-full  object-cover object-left-top" alt={'hero'}/>
                </div>
            </div>

        </div>
    );
}

export default Login;
