import React, {ReactNode, useContext, useEffect, useState} from "react";
import {API} from "aws-amplify";
import {Coding, FileServerReply, Immunization, ServerReply, Status} from "../../types/database/SharedTypes";
import {ProfessionalContext} from "../ProfessionalProvider";
import {getImmunizationStatus, isInValidDoseStatus} from "../../util/immunization/immunization";

type PatientContextType = {
    patient?: any;
    patientProfilePhoto?: string;
    patientStudentId?: string;
    acceptAppointment?: (subjectId: string, appointId: string) => Promise<any>;
    declineAppointment?: (subjectId: string, appointId: string) => Promise<any>;
    getPatient?: (subjectID: string) => Promise<ServerReply<any>>;
    setPatient?: (patient: any) => void;
    setReviewImmunizations?: (immunizations: any) => void;
    getImmunizations?: (subjectID: string) => Promise<ServerReply<any>>;
    markImmunizationAsNotDone?: (studentSubject: string, immunizationId: string) => Promise<ServerReply<any>>;
    reviewImmunizations?: any;
    immunizationSubmissionCompletionStatus?: "completed" | "entered-in-error" | "not-done";
    immunizationsRecommendations?: any[];
    evaluatePatientImmunization?: (studentSubject: string, body: object) => Promise<any>;
    recommendationsLoading?: boolean
};

const PatientContext = React.createContext<PatientContextType>({});
export const usePatient = () => React.useContext(PatientContext);

export function PatientProvider(props: { children: ReactNode, patientId?: string }) {

    const [patient, setPatient] = useState<any>();
    const [patientProfilePhoto, setPatientProfilePhoto] = useState<string>();
    const [patientStudentId, setPatientStudentId] = useState<string>();
    const [immunizationsRecommendations, setImmunizationsRecommendations] = useState<any[]>();
    const [reviewImmunizations, setReviewImmunizations] = useState<Immunization[]>([]);
    const [recommendationsLoading, setRecommendationLoading] = useState<boolean>(false);
    const [immunizationSubmissionCompletionStatus, setImmunizationSubmissionCompletionStatus] = useState<"completed" | "entered-in-error" | "not-done">();
    const { professional } = useContext(ProfessionalContext);

    async function getPatient(subjectID: string): Promise<ServerReply<any>> {
        const apiName = 'LAUSDPractitionerEndpoint';
        const path = `/practitioner/patient/${subjectID}`;
        const myInit = {
            body: {},
            headers: {}
        };
        return API.get(apiName, path, myInit);
    }
    async function getPhoto(subjectID: string, photoPath: string): Promise<FileServerReply<any>> {
        const apiName = 'LAUSDDocumentEndpoint';
        const path = `/practitioner/document/${subjectID}?path=${encodeURIComponent(photoPath)}`;
        const myInit = {
            body: {},
            headers: {}
        };
        return API.get(apiName, path, myInit).catch((err) => {
            //Unable to get photo
        });
    }

    async function acceptAppointment(subjectId: string, appointId: string): Promise<any> {
        const apiName = 'LAUSDPractitionerEndpoint';
        const path = `/practitioner/appointment/response/${subjectId}/${appointId}`;
        const myInit = {
            body: { participantStatus: 'accepted' },
            headers: {}
        };
        return API.post(apiName, path, myInit);
    }

    async function getImmunizationRecommendations(subjectId: string): Promise<any> {
        const apiName = 'LAUSDPractitionerEndpoint';
        const path = `/practitioner/immunization/recommendations/${subjectId}`;
        const myInit = {
            body: {},
            headers: {}
        };
        return API.get(apiName, path, myInit);
    }

    async function declineAppointment(subjectId: string, appointId: string): Promise<any> {
        const apiName = 'LAUSDPractitionerEndpoint';
        const path = `/practitioner/appointment/response/${subjectId}/${appointId}`;
        const myInit = {
            body: { participantStatus: 'declined' },
            headers: {}
        };
        return API.post(apiName, path, myInit);
    }

    async function markImmunizationAsNotDone(studentSubject: string, immunizationId: string): Promise<ServerReply<any>> {
        const apiName = 'LAUSDPractitionerEndpoint';
        const path = `/practitioner/immunization/${studentSubject}`;
        const myInit = {
            body: {
                id: immunizationId,
                patch: [
                    { op: "replace", path: "/status", value: "not-done" },
                ]
            },
            headers: {}
        };
        return API.patch(apiName, path, myInit);
    }

    async function evaluatePatientImmunization(studentSubject: string, body: object): Promise<ServerReply<any>> {
        const apiName = 'LAUSDPractitionerEndpoint';
        const path = `/practitioner/immunization/evaluation/${studentSubject}`;
        const myInit = {
            body: body,
            headers: {}
        };
        return API.post(apiName, path, myInit);
    }

    useEffect(() => {
        if (props.patientId) {
            let subjectID = props.patientId;
            getPatient(subjectID).then(async (value) => {
                setPatient(value.body);
                // if(professional)
                //setImmunizations(await Promise.all(value.body.immunizations.map(async (immunization: any) => (await getPatientImmunizations(professional.subject, immunization.id)).body)))
            })
        }
    }, [props.patientId]);
    useEffect(() => {
        if (patient && professional && professional.subject) {
            let photo = patient.photo;
            let studentIdUrl = getStudentIdAttachment(patient.identifier)?.url;
            if (photo && photo.length > 0) {
                let firstPhotoElement = photo[0];
                if (firstPhotoElement.contentType === 'image') {
                    let urlPath = firstPhotoElement.url;
                    getPhoto(professional.subject, urlPath).then((value) => {
                        setPatientProfilePhoto(value.file);
                    });
                }
            }
            if (studentIdUrl) {
                getPhoto(professional.subject, studentIdUrl).then((value) => {
                    setPatientStudentId(value.file);
                });
            }
        }
    }, [patient]);

    useEffect(() => {
        if (props.patientId && patient) {
            setRecommendationLoading(true)
            getImmunizationRecommendations(props.patientId).then((documents) => {
                setImmunizationsRecommendations(documents.body.items);


            }).catch((err) => {
                console.error("Unable to get update Schedule", err);
            }).finally(() => setRecommendationLoading(false));

            const immunizations = patient?.immunizations.filter((item: Immunization) => (!(item.evaluations && item.evaluations.length>0)  || isInValidDoseStatus(item)) && item.status==="completed" && [Status.Pending, Status.EditedAndPending].includes(getImmunizationStatus(item)) );
            setImmunizationSubmissionCompletionStatus(immunizations.some((imm: Immunization)=> imm.status === 'not-done' ) ? "not-done" : "completed")
            setReviewImmunizations(immunizations);
        }
    }, [props.patientId, patient]);

    function getStudentIdAttachment(identifier?: any[]) {
        if (identifier) {
            return identifier.find((value) => value.type?.coding?.find((value2: Coding) => value2.code === "STU"))?.attachment;
        }
        return undefined;
    }

    return (
        <PatientContext.Provider value={{
            patient,
            patientProfilePhoto,
            patientStudentId,
            immunizationSubmissionCompletionStatus,
            acceptAppointment,
            declineAppointment,
            markImmunizationAsNotDone,
            getPatient,
            setPatient,
            setReviewImmunizations,
            evaluatePatientImmunization,
            immunizationsRecommendations,
            reviewImmunizations,
            recommendationsLoading
        }}>
            {props.children}
        </PatientContext.Provider>
    );
};