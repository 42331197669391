import React from 'react';
import ProfileImage, {ProfileStatuses} from "./ProfileImage";
import VaccinationStatus from './VaccinationStatus';

function ProfileSummary(props: {src: string | undefined, name?: string, description?:string | null, status?: ProfileStatuses, isWhiteText?: boolean, immunizationStatus?: string, isPatient?: boolean }) {

 const {src, description, name, status, isWhiteText, immunizationStatus, isPatient} = props;
    return (
        <div className={'flex flex-row py-3'}>

            <div className={'w-10 min-w-10 mr-3'}>
                <ProfileImage src={src} status={status}/>
            </div>

            <div className={`flex flex-col items-start justify-center`}>
                <div className={`flex flex-col items-start ${isWhiteText && '!text-white'}`}>
                    {name && <span className={'my-auto font-semibold'}>{name}</span>}
                    {description && <span className={'text-sm mb-1'}>{description}</span>}
                </div>
                {isPatient && (
                    <VaccinationStatus isPatient={isPatient} status={immunizationStatus ? {
                        color: immunizationStatus?.split(':')[0],
                        display: immunizationStatus?.split(':')[1]
                    } : undefined}/>
                )}
            </div>
        </div>
    );
}

export default ProfileSummary;