import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import heroImg from '../../assets/images/hero-image.png'
import Logo from "../../components/logo/Logo";
import BoldHeader from "../../components/header/BoldHeader";
import SubHeader from "../../components/header/SubHeader";
import LoginPane from "../../components/login/LoginPane";
import {Link, useSearchParams} from "react-router-dom";
import {ampli} from "../../ampli";
import {Auth} from "aws-amplify";

function Login() {
    const [t] = useTranslation();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if(searchParams && searchParams.get('ref') === 'portal') {
            ampli.track({event_type:"Login from Portal"});
            Auth.federatedSignIn({customProvider: 'LAUSDParentIdp'});
        }
    }, [searchParams]);

    return (
        <div className="login-page ">
            <div className="flex flex-row">
                <div className="min-h-screen  basis-full lg:basis-6/12">
                    <div className={'container  flex flex-col max-w-sm pt-24 mx-auto h-full px-4'}>
                        <Link to={'/'}>
                            <BoldHeader title={t('IMMUNIZATION_ASSISTANT')}/>
                            <SubHeader title={t('KEEPING_OUR_KIDS_IN_SCHOOL')}/>
                        </Link>
                        <div className={'mt-4'}>
                            <LoginPane/>
                        </div>
                        <div className={'mt-4 text-center  font-medium'} onClick={()=> ampli.track({event_type:"Sign up clicked"})}>
                            <a className={'text-brand-color'} href={'https://parentws.lausd.net/parentauthen/register.jsp?app=passport'}>
                                {t('DONT_HAVE_AN_ACCOUNT_SIGN_UP')}
                            </a>
                        </div>
                        <div className={'mt-auto mb-5 text-center text-gray-400'}>
                            <div>
                                <span>{t('DEMOCRATIZING_CONCIERGE_MEDICINE')}</span>
                            </div>
                            <div>
                                <span>{t('PATENT_PENDING')}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="min-h-screen max-h-screen lg:block hidden basis-8/12 relative">
                    <div
                        className={'absolute object-cover object-left-top bg-gradient-to-b from-white via-transparent h-full w-full top-0 left-0'}></div>
                    <div className={'absolute mt-11 ml-14'}>
                        <Logo/>
                    </div>
                    <img src={heroImg} className="hero-image w-full h-full  object-cover object-left-top" alt={'hero'}/>
                </div>
            </div>

        </div>
    );
}

export default Login;
