import {useContext} from "react";
import {PatientContext} from "../contexts/PatientProvider";

export default function usePatient() {
    const {
        getOrganizations,
        getPatientInfo,
        submitStudent,
        getPhoto,
        uploadDocument,
        uploadImmunizationDocumentMetadata,
        scheduleAppointment,
        profilePhoto,
        errorMessage,
        schoolList,
        patient,
        parentStudents,
        uploadStudentProfilePhoto,
        updateStudentProfilePhoto,
        updatePatientPhoto,
        addStudentProfileMetadata
    } = useContext(PatientContext);

    return {
        getOrganizations,
        getPhoto,
        submitStudent,
        uploadDocument,
        uploadImmunizationDocumentMetadata,
        scheduleAppointment,
        getPatientInfo,
        schoolList,
        profilePhoto,
        errorMessage,
        patient,
        parentStudents,
        uploadStudentProfilePhoto,
        updateStudentProfilePhoto,
        updatePatientPhoto,
        addStudentProfileMetadata
    };
}
