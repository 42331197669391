import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import Dropzone from "react-dropzone";
import {faFileLines} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Noop} from "react-hook-form";
import {formatBytes} from "../../util/international/numbers";
import {ReactComponent as DRed} from "../../assets/icons/d_red.svg"
import PrimaryButton from "../button/PrimaryButton";

function DocumentUploadInput(props: { children?: React.ReactNode;onChange: (...event: any[]) => void; onBlur: Noop ;extraProps?: {[x: string]: any}}, ref: React.ForwardedRef<any>) {
    const {extraProps, onChange, onBlur} = props;

    const [t, i18n] = useTranslation();
    const onDrop = useCallback((acceptedFiles: File[]) => {
        onChange(acceptedFiles);
    }, []);

    return (
        <div>
            <Dropzone
                onDrop={onDrop}
                noClick={true}
                maxFiles={1}
                maxSize={6000000}
                accept={
                {
                    'application/pdf': [],
                    'image/jpeg': [],
                    'image/png': [],

                }
            }

            >
                {({getRootProps, getInputProps, open, fileRejections, acceptedFiles}) => (
                    <section>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} onBlur={onBlur} {...extraProps}/>
                            {!(acceptedFiles.length > 0) &&
                            <button onClick={open} type={'button'} className={'border-brand-color  py-3 text-brand-color text-center border-2 border-dashed relative min-h-[5rem] w-full h-64 flex justify-center'}>
                                <FontAwesomeIcon icon={faFileLines} className={'h-20 text-brand-color my-auto mr-4'}/> <span className={'my-auto'}>{t('UPLOAD_DOCUMENT')}</span>
                            </button>
                            }
                        </div>
                        {acceptedFiles.length > 0 &&  <div className={'bg-brand-color bg-opacity-10 p-6'}>
                            <div className={'flex flex-row gap-4 mb-4'}>

                                <DRed className={''}/>

                                <div className={'flex flex-col'}>
                                        <span className={'font-semibold'}>
                                           {new Date().toLocaleDateString()} - {acceptedFiles[0].name}
                                        </span>
                                    <span className={'text-xs'}> {formatBytes(acceptedFiles[0].size)}</span>
                                </div>
                            </div>
                            <div className={'mb-4'} >
                                <a onClick={(e)=> {
                                    if(e.target == e.currentTarget){
                                        e.stopPropagation();
                                    }
                                }} href={URL.createObjectURL(acceptedFiles[0])} target={'_blank'} >
                                    <PrimaryButton type={'button'} outLine={true}>
                                        Open Document
                                    </PrimaryButton>
                                </a>
                            </div>
                            <div className={'text-xs mb-4'}>
                                <span>{t("DATE_UPLOADED")} : {new Date().toLocaleDateString(i18n.resolvedLanguage)}</span>
                            </div>
                            <div className={'text-xs mb-4'}>
                                <span>{t("LAST_VIEWED")} : {new Date().toLocaleDateString(i18n.resolvedLanguage)}</span>
                            </div>
                        </div>}
                        <ul>
                            {fileRejections.map((fileRejection,index) => (
                                <li key={index}>
                                {fileRejection.errors.map(e => (
                                <span className={'text-red-400'} key={e.code}>{e.message}</span>
                                    ))}
                                </li>
                            ))}
                        </ul>
                    </section>

                )}
            </Dropzone>
        </div>
    );
}

export default React.forwardRef(DocumentUploadInput);