import {CreationObject, Immunization, Status} from "../../types/database/SharedTypes";
import {parseISOLocal} from "../date/convertDateString";

export function getLastUpdateDate(updates: any) {
    const sorted = sortUpdatesByDate(updates);
    return sorted?.[0]?.date;
}
export function hasAnAttachment(immunization: Immunization) {
    return immunization.attachment !== undefined && immunization.attachment.length > 0;
}

export function getLastUpdateDateByUser(updates?: CreationObject[]) {
    const sorted = sortUpdatesByDate(updates);
    return sorted?.find((update: any) => update.sourceType === 'user')?.date;
}

function sortUpdatesByDate(updates?: any[]) {
    return updates?.sort((a: any, b: any) => new Date(b.date || 0).getTime() - new Date(a.date || 0).getTime());
}
export function getLastEvaluation(immunization: Immunization) {
    const sorted = sortUpdatesByDate(immunization.evaluations);
    return sorted?.[0];
}

function wasLastUpdatedByUser(immunization: Immunization) {
    if (!immunization?.updated) return isUserSource(immunization?.created?.sourceType);
    const sorted = sortUpdatesByDate(immunization?.updated);
    return isUserSource(sorted?.[0]?.sourceType);
}

function wasUpdatedAfterRejection(immunization: Immunization) {
    const updateDate = getLastUpdateDateByUser(immunization.updated);
    const evaluationDate = getLastUpdateDate(immunization.evaluations);
    return updateDate && evaluationDate && new Date(updateDate) > new Date(evaluationDate);
}



function isUserSource(sourceType: string) {
    return sourceType === 'user';
}

function isValidDoseStatus(immunization: Immunization) {
    return getSortedLastEvaluationDoseStatus(immunization) === 'valid';
}
export function isInValidDoseStatus(immunization: Immunization) {
    return getSortedLastEvaluationDoseStatus(immunization) === 'notvalid';
}
function getSortedLastEvaluationDoseStatus(immunization: Immunization) {
    const sortedEvaluations = sortUpdatesByDate(immunization?.evaluations);
    return sortedEvaluations?.[0]?.doseStatus?.coding?.[0]?.code?.toLowerCase();
}
export function getOccurrenceHistoryDates(immunization: Immunization): Date[] {
    if (immunization.occurrences === undefined || immunization.occurrences.length === 0) return [];
    const immunizationOccurrencesDeepCopy = JSON.parse(JSON.stringify(immunization.occurrences));
    if( isLastOccurrenceAddedByApplication(immunization)) {
        immunizationOccurrencesDeepCopy.pop(); // remove last occurrence because it was added by the application
    }
    return immunizationOccurrencesDeepCopy.map((occurrence: any) => parseISOLocal(occurrence));

}
export function isLastOccurrenceAddedByApplication(immunization: Immunization) {
    return getImmunizationStatus(immunization) !== Status.MissingShot;
}
export function getImmunizationStatus(immunization: Immunization) {
    if (isInValidDoseStatus(immunization) && !wasUpdatedAfterRejection(immunization)) return Status.Rejected;
    if (!hasAnAttachment(immunization)) return Status.MissingShot;
    if (!immunization?.evaluations) return Status.Pending;
    if (isValidDoseStatus(immunization)) return Status.Approved;
    if (wasUpdatedAfterRejection(immunization)) return Status.EditedAndPending;

    return Status.Rejected;
}