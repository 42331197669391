import React, {useCallback, useState} from 'react';
import ImmunizationReview from "./ImmunizationReview";
import PractitionerImmunizationModal from "./PractitionerImmunizationModal";
import {Immunization} from "../../types/database/SharedTypes";

function ImmunizationStepReview(props: { immunizations:  Immunization[], reviewImmunizations: Immunization[], showModal: boolean, setShowModal: (showModal: boolean)=> void}) {
    const {immunizations, reviewImmunizations, showModal, setShowModal} = props;

    const [reviewStep, setReviewStep] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const loadingNoCloseModal = useCallback((modalRequestedVisibility: boolean) => { // Stop modal from closing if loading
        if(isLoading){
            return;
        } else {
            setShowModal(modalRequestedVisibility);
        }
    }, [isLoading]);

    const immunization = reviewImmunizations[reviewStep];
    return (
        immunization &&
        <PractitionerImmunizationModal showModal={showModal} showStatus={true} immunization={immunization} onModalVisibilityChange={loadingNoCloseModal}>
            <ImmunizationReview immunization={immunization} onReviewLoading={setIsLoading} setReviewStep={setReviewStep} reviewStep={reviewStep} onReview={()=> setShowModal(false)} immunizations={immunizations}/>
        </PractitionerImmunizationModal>
    );
}

export default ImmunizationStepReview;