import React from 'react';
import PractitionerSearchProvider from "../../../contexts/PractitionerSearchProvider";
import {Students} from "../../../components/professional-dashboard";
import {SavedSearchProvider} from '../../../contexts/practitioner/SaveSearchProvider';

function StudentSearch() {
    return (
        <PractitionerSearchProvider>
            <SavedSearchProvider>
                <Students/>
            </SavedSearchProvider>
        </PractitionerSearchProvider>
    );
}

export default StudentSearch;