import React, {useCallback, useRef, useState} from 'react';
import InputGroup from "../group/InputGroup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faXmark} from "@fortawesome/free-solid-svg-icons";

import SignatureCanvas from 'react-signature-canvas'
import SubmitPrimaryButton from "../button/SubmitPrimaryButton";
import useProfessional from "../../hooks/useProfessional";
import {useForm} from "react-hook-form";
import {OnboardingSection} from "../../pages/professional/onboarding/SideNav";

function ServiceAgreement(props: { onSectionCompleted: (section: OnboardingSection) => any }) {
    const {onSectionCompleted} = props;
    const padRef = useRef<SignatureCanvas>(null);
    const {register, handleSubmit, control, formState: {errors}} = useForm();
    const [canvas, setCanvas] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const clearSignatureCanvas = useCallback(() => {
        padRef?.current?.clear();
        setCanvas(undefined);

    }, []);
    const [fidImg, setFidImg] = useState<File>();
    const {addSignatureMetadata, uploadSignature} = useProfessional();
    const [submitError, setSubmitError] = useState<string>();

    function dataURItoBlob(dataURI:  string) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        //Old Code
        //write the ArrayBuffer to a blob, and you're done
        //var bb = new BlobBuilder();
        //bb.append(ab);
        //return bb.getBlob(mimeString);

        //New Code
        return new Blob([ab], {type: mimeString});


    }

    function submitForm(data: any) {
        setSubmitError(undefined);
        setLoading(true);
        let file = new File([dataURItoBlob(padRef?.current?.toDataURL()||'')], 'signiture.png');

        if(file)
            return addSignatureMetadata&& addSignatureMetadata().then(({body})=>{
                return uploadSignature && uploadSignature(file, body.path).then(()=>{
                    onSectionCompleted(OnboardingSection.ServiceAgreement);
                    setLoading(false);
                }).catch((reason)=>{
                    setLoading(false);
                    setSubmitError(reason.message);
                })

            }).catch((reason)=>{
                setLoading(false);
                setSubmitError(reason.message);
            });
    }

    return (
        <div>
        <InputGroup>
            <form onSubmit={handleSubmit(submitForm)}>
                <div className={'px-6'}>
                <h2 className={'mx-auto mb-8 mt-5 font-semibold text-xl text-center'}>Service agreement</h2>
                <p className={'mb-9'}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Quis enim lobortis scelerisque fermentum dui faucibus in ornare. Ipsum consequat nisl vel pretium lectus quam. Felis eget velit aliquet sagittis id consectetur. Vulputate sapien nec sagittis aliquam malesuada bibendum arcu. Pellentesque dignissim enim sit amet venenatis. Maecenas pharetra convallis posuere morbi leo urna. Amet volutpat consequat mauris nunc congue. Purus non enim praesent elementum facilisis leo vel. Tristique senectus et netus et malesuada. Nunc mi ipsum faucibus vitae aliquet nec. Nisl rhoncus mattis rhoncus urna neque viverra justo nec. Penatibus et magnis dis parturient montes. Lorem sed risus ultricies tristique. Sem viverra aliquet eget sit amet tellus cras adipiscing. Vestibulum morbi blandit cursus risus.

                    Sagittis nisl rhoncus mattis rhoncus urna neque viverra justo. Quis risus sed vulputate odio ut. Maecenas ultricies mi eget mauris pharetra. Pulvinar neque laoreet suspendisse interdum consectetur libero id faucibus. Mauris nunc congue nisi vitae suscipit. Lectus mauris ultrices eros in cursus turpis massa tincidunt. Leo integer malesuada nunc vel risus commodo. Risus quis varius quam quisque id. Viverra tellus in hac habitasse. Tempus iaculis urna id volutpat lacus. Magna ac placerat vestibulum lectus mauris ultrices. Gravida arcu ac tortor dignissim convallis aenean et tortor. Malesuada fames ac turpis egestas maecenas pharetra convallis. Augue eget arcu dictum varius duis at. Eros donec ac odio tempor orci dapibus. Consequat nisl vel pretium lectus quam id. Eros in cursus turpis massa tincidunt. Velit aliquet sagittis id consectetur purus. Augue ut lectus arcu bibendum at varius vel pharetra vel. Lectus nulla at volutpat diam ut venenatis.</p>
                <span className={'font-medium text-sm  '}>Your Signature</span>
                <div className={'border-gray-200 border-2 max-w-7xl mb-3 overflow-x-scroll'}>
                <SignatureCanvas
                    ref={padRef}
                    canvasProps={{
                        height: 200,
                        width: 1500,
                    }}
                />
                </div>
                <div className={'flex flex-row text-brand-color font-medium mt-5'} onClick={clearSignatureCanvas}>
                    <FontAwesomeIcon icon={faXmark} className={'h-5 my-auto'}/> Reset Signature
                </div>
                <div className={'w-40 ml-auto border mt-14 '}>
                    <SubmitPrimaryButton isLoading={loading}>Continue <FontAwesomeIcon
                        icon={faArrowRight}/></SubmitPrimaryButton>
                </div>
                </div>
            </form>
            </InputGroup>
        </div>
    );
}

export default ServiceAgreement;