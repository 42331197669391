import {useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from 'react-i18next';
import FilterComponent from "./FilterComponent";
import {Controller, useForm} from "react-hook-form";
import RightModal from './RightModal';
import SchoolFilter from './SchoolFilter';
import useProfessional from "../../hooks/useProfessional";
import Calendar from "../calendar";

function ProfessionalAppointmentFilterComponent(props: { showFilter: boolean, updateVisibility: (visibility: boolean) => void, setFilterParams?: (params: Record<string,string>) => void; }) {
  const { showFilter, updateVisibility, setFilterParams } = props;

  const [t] = useTranslation()
  const { schoolList } = useProfessional();

  const form = useForm();
  const { register, setValue, getValues, control } = form;

  const [showSchoolFilter, setShowSchoolFilter] = useState<boolean>(false);
  const [showDropdown, setShowDropdown] = useState<boolean>(false)
  const [_, setFilterDate] = useState<any>() // Dummy state variable for re-render after setValue in React Hook Form. Do not remove.

  const elementRef = useRef<any>()

  const handleUpdateFilterParams = (formData: any) => {
    const data = JSON.parse(JSON.stringify(formData || {}))
    Object.keys(data).forEach(key => {
      if (typeof data[`${key}`] === 'undefined' || data[`${key}`] === null) {
        delete data[`${key}`]
      }
    })
    setFilterParams && setFilterParams(data);
  }

  const schoolName = useMemo(() => {
    const schoolId = getValues('organizationid\:exact')
    return schoolList?.schools?.find((school: any) => school?.id === schoolId)?.name || ''
  }, [getValues('organizationid\:exact')])

  const sortOptions: { code: string, display: string }[] = [
    { code: 'newest', display: t('NEWEST') },
    { code: 'oldest', display: t('OLDEST') },
  ]

  const handleSelect = (e: MouseEvent) => {
    if (e.target && !elementRef?.current?.contains(e.target)) {
      setShowDropdown(false)
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleSelect)
    return () => window.removeEventListener('click', handleSelect)
  }, [showDropdown])

  return (
    <FilterComponent
      form={form}
      displayFilter={showFilter}
      onUpdateFilterParams={handleUpdateFilterParams}
      filterVisibilityChanged={updateVisibility}
    >
      <div className="relative">
        <div
          className="flex justify-between items-center border-b border-b-neutral-10 mt-3 py-5 cursor-pointer"
          ref={elementRef}
          onClick={() => setShowDropdown(true)}
        >
          <span className="font-semibold">{t('SORTED_BY')}</span>
          <span className="text-[#8C8C8C] capitalize">{getValues('sort') ?? t('NEWEST')}</span>
        </div>
        {showDropdown && (
          <div className="absolute right-0 z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none border-[1px] border-[#8C8C8C]">
            <Controller
              name="sort"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <div>
                  {sortOptions.map(({ code, display }) => (
                    <div
                      key={code}
                      onClick={() => field.onChange(code)}
                      className={`${getValues('sort') === code && 'text-brand-color'} px-5 cursor-pointer hover:bg-[#f8a03a0d]`}
                    >
                        <span
                            className="py-[14px] block border-b border-b-neutral-10 h-full last:border-b-0"
                        >
                            {display}
                        </span>
                    </div>
                  ))}
                </div>
              )}
            />
          </div>
        )}
      </div>
      <div
        className="flex justify-between items-center border-b border-b-neutral-10 py-1"
      >
        <span className="font-semibold">{t('DATE')}</span>
        <div className=" cursor-pointer">
          <Calendar
            placeholder=""
            title='Created Date'
            onChange={(date) => {
              setValue('date', date)
              setFilterDate(date)
            }}
            defaultDate={getValues('date')}
          />
        </div>
      </div>
      {/* <div
        className="flex justify-between items-center cursor-pointer border-b border-b-neutral-10 py-5"
        onClick={() => setShowSchoolFilter(true)}
      >
        <span className="font-semibold">{t('SCHOOLS')}</span>
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
      {schoolName && (
        <div>
          <div className='flex items-center py-[13px]'>
            <div className='w-2 h-2 min-w-[8px] bg-brand-color mr-3' />
            <span className='text-[#454545]'>{schoolName}</span>
          </div>
        </div>
      )}
      <div className="border-b border-b-neutral-10 py-5">
        <p className="font-semibold">{t('APPOINTMENT_STATUS')}</p>
        <div className="mt-5 grid gap-3 grid-cols-2">
          <div>
            <InputRadio
              inputLabel={<AppointmentStatus appointmentstatus="proposed" />}
              optionValue='proposed'
              id={`proposed`}
              {...register("status", {
                required: false,
              })}
            />
          </div>
          <div>
            <InputRadio
              inputLabel={<AppointmentStatus appointmentstatus="booked" />}
              optionValue='booked'
              id={`booked`}
              {...register("status", {
                required: false,
              })}
            />
          </div>
          <div>
            <InputRadio
              inputLabel={<AppointmentStatus appointmentstatus="done" />}
              optionValue='done'
              id={`done`}
              {...register("status", {
                required: false,
              })}
            />
          </div>
          <div>
            <InputRadio
              inputLabel={<AppointmentStatus appointmentstatus="cancelled" />}
              optionValue='cancelled'
              id={`cancelled`}
              {...register("status", {
                required: false,
              })}
            />
          </div>
          <div>
            <InputRadio
              inputLabel={<AppointmentStatus appointmentstatus="referred" />}
              optionValue='referred'
              id={`referred`}
              {...register("status", {
                required: false,
              })}
            />
          </div>
        </div>
      </div> */}

      {showSchoolFilter && (
        <RightModal isOpen={showSchoolFilter} onClose={() => setShowSchoolFilter(false)}>
          <SchoolFilter
            updateVisibility={setShowSchoolFilter}
            setValue={setValue}
            schoolIds={getValues('organizationid\:exact')?.split('|') || []}
          />
        </RightModal>
      )}
    </FilterComponent>
  );
}

export default ProfessionalAppointmentFilterComponent;