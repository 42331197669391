import ProfileImage from '../status/ProfileImage';
import {useDocument} from "../../contexts/DocumentProvider";
import {parseDocumentPhoto} from "../../util/photo/parseDatabasePhotos";
import {Summary} from './MessageComponent';
import {useMemo} from 'react'

function SearchItemUI(props: { message: any, onResultClick?: any }) {
    const { message, onResultClick } = props

    const { document } = useDocument();
    const profilePhotoSrc = useMemo(() => parseDocumentPhoto(atob(document || '')), [document]);

    function getDate(timestamp: number) {
        const date = new Date(timestamp);
        return date.toLocaleDateString('en-US');
    }

    return (
        <div
            className={`flex items-center cursor-pointer border-b border-b-neutral-10 py-5 px-12`}
            onClick={() => onResultClick && onResultClick()}
        >
            <div className='w-10 h-10'>
                <ProfileImage src={profilePhotoSrc} status={message?.sender?.isActive} />
            </div>
            <div className='flex flex-col ml-3 items-start flex-1'>
                <div className='flex justify-between items-center w-full'>
                    <span className='font-semibold text-base truncate max-w-[100px]'>{message?.sender?.nickname || 'No name'}</span>
                    <span className='text-xs text-color-[#8C8C8C]'>{getDate(message?.createdAt)}</span>
                </div>
                <div className='flex  justify-between items-center w-full mt-2'>
                    <span className='text-sm text-color-[#8C8C8C] truncate max-w-[150px]'>{message?.message}</span>
                </div>
            </div>
        </div>
    )
}

function SearchItem(props: { message: any, onResultClick?: any }) {
    const { message } = props

    return (
      <Summary otherMember={message?.sender}>
        <SearchItemUI
          {...props}
        />
      </Summary>
    )
  }

export default SearchItem;