import React, {useEffect} from 'react';
import {usePractitionerSearch} from "../../contexts/PractitionerSearchProvider";
import RightModal from "../dashboard-search/RightModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import useProfessional from "../../hooks/useProfessional";
import StudentsListTable from "./StudentsListTable";

import LoadingSplash from "../spinner/LoadingSplash";
import StudentDetailRightPane from "./StudentDetailRightPane";

function SchoolNonCompliantDetailRightModal(props: {
    schoolId: string,
    showModal: boolean,
    setShowModal: (showModal: boolean) => void
}) {
    const {showModal, setShowModal, schoolId} = props;
    const [t] = useTranslation();
    const {fetchingPatients, setFilterParams, patients} = usePractitionerSearch();
    const {schoolList} = useProfessional();
    const [showStudentDetail, setShowStudentDetail] = React.useState<boolean>(false);
    const [selectedStudentId, setSelectedStudentId] = React.useState<string>();
    useEffect(() => {
        setFilterParams && setFilterParams({"organizationid:exact": schoolId});
    }, [schoolId]);

    function handleStudentClicked(studentId: string) {
        console.log("Student Clicked");
        setSelectedStudentId(studentId);
        setShowStudentDetail(true);

    }

    return (
        <div>

            <RightModal isOpen={showModal} onClose={() => setShowModal(false)}>
                <div className={"w-[70rem] max-w-full "}>
                    <div className="flex flex-col px-3  ">
                        <div className="flex items-center justify-between py-4">

                            <div className="flex items-center py-2 pr-4">
                                <div
                                    className="flex items-center justify-center bg-neutral-100 w-[52px] h-[52px] rounded-full mr-4">
                                    <div className="bg-clinic-icon w-6 h-6"/>
                                </div>
                                <h2 className="flex-1 text-lg font-semibold text-heading whitespace-nowrap">{schoolList?.schools.find(school => school.id === schoolId)?.name || '---'}</h2>
                            </div>

                            <button type={'button'}
                                    className="text-slate-400 hover:text-slate-500 transition duration-300 ease-in-out "
                                    onClick={() => setShowModal(false)}
                            >
                                <FontAwesomeIcon icon={faXmark}/>
                            </button>
                        </div>
                        {!fetchingPatients && patients ?
                            <div>
                                <StudentsListTable students={patients || []} studentClicked={handleStudentClicked}/>
                            </div> : <LoadingSplash showLogo={false}/>}

                        {selectedStudentId &&
                            <StudentDetailRightPane studentId={selectedStudentId} showModal={showStudentDetail}
                                                    setShowModal={setShowStudentDetail}/>}
                    </div>
                </div>
            </RightModal>


        </div>
    );
}

export default SchoolNonCompliantDetailRightModal;