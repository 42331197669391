import React from 'react';
import ProfessionalProvider from "../../contexts/ProfessionalProvider";
import {Outlet} from "react-router-dom";
import CodeProvider from "../../contexts/CodeProvider";
import {VideoContextProvider} from "../../contexts/VideoContextProvider";

function Professional() {
    return (
        <ProfessionalProvider>
            <CodeProvider>
                <VideoContextProvider>
                    <Outlet/>
                </VideoContextProvider>
            </CodeProvider>
        </ProfessionalProvider>
    );
}

export default Professional;