import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import FilterComponent from "./FilterComponent";
import {useForm} from "react-hook-form";
import useProfessional from "../../hooks/useProfessional";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faClose} from "@fortawesome/free-solid-svg-icons";
import {InputRadio} from "../input/InputRadio";
import {usePractitionerSearch} from "../../contexts/PractitionerSearchProvider";
import RightModal from './RightModal';
import SchoolFilter from './SchoolFilter';
import VaccinationStatus from '../status/VaccinationStatus';
import {ampli} from "../../ampli";
import useAuth from "../../hooks/useAuth";
import {useSavedSearch} from '../../contexts/practitioner/SaveSearchProvider';
import {SavedSearch} from '../../types/database/SharedTypes';

function PatientFilterComponent(props: { showFilter: boolean, updateVisibility: (visibility: boolean) => void }) {
    const { showFilter, updateVisibility } = props;
    const [t] = useTranslation()

    const { schoolList, selectedSchools, setSelectedSchools } = useProfessional();
    const { savedSearchList } = useSavedSearch()
    const form = useForm();
    const { userGroup } = useAuth();
    const { register, setValue, getValues, watch } = form;
    const { setFilterParams } = usePractitionerSearch();
    const [showSchoolFilter, setShowSchoolFilter] = useState<boolean>(false);

    const handleUpdateFilterParams = (formData: any) => {
        const data = JSON.parse(JSON.stringify(formData || {}))
        Object.keys(data).forEach(key => {
            if (typeof data[`${key}`] === 'undefined' || data[`${key}`] === null || data[`${key}`]  === '') {
                delete data[`${key}`]
            }
        })
        if (data?.['immunizationstatus:exact'] && data['immunizationstatus:exact'] === 'nonCompliant') {
            data['immunizationstatus:exact'] = ['red:recommendation', 'red:evaluation'];
        }


        if (!formData && setSelectedSchools) { // If the filters are blank, then we need to clear the selected schools
            setSelectedSchools(undefined)
        } else if (data?.['organizationid:exact']) { // if the filter params need to update the school list
            data['organizationid:exact'] = data?.['organizationid:exact']?.split('|');

            if (setSelectedSchools) {
                setSelectedSchools(data['organizationid:exact']);
            }
        }  if( selectedSchoolList?.length > 0){ // If there are any selected schools, then we need to update the filter params
            data['organizationid:exact'] = selectedSchoolList?.map((school: any) => school?.id)

        } else { // If there are no selected schools, then we need to clear the filter params
            setSelectedSchools && setSelectedSchools(undefined);
        }
       ampli.track({event_type:"Search filters applied"}, {extra:{ userType: userGroup, filters: data}});
        setFilterParams && setFilterParams(data);
    }

    const selectedSchoolList = useMemo(() => {
        const schoolIds =  getValues('organizationid\:exact')?.split('|')|| selectedSchools;
        return schoolList?.schools?.filter((school: any) => schoolIds?.includes(school?.id)) || []
    }, [selectedSchools, schoolList?.schools, watch('organizationid\:exact')])

    function handleRemoveSchool(schoolId: string) {
        const schoolIds =  getValues('organizationid\:exact')?.split('|')|| selectedSchools;
        const updatedIds = schoolIds.filter((id:string) => id !== schoolId)
        setValue('organizationid\:exact', updatedIds.join('|'))
    }
    // console.log(watch('organizationid\:exact'))

    useEffect(() => {
        if (savedSearchList && !savedSearchList?.loading) {
            const defaultSearch = savedSearchList?.searches?.find((item: SavedSearch) => item?.isDefault)
            const schoolIds = defaultSearch?.schools.map((school)=> school.id);
            setSelectedSchools && setSelectedSchools( schoolIds)
            setValue('organizationid\:exact', schoolIds?.join("|"))
            if (defaultSearch?.status === 'pending')  setValue('immunizationstatus:exact', 'yellow:pending')
            else if (defaultSearch?.status === 'nonCompliant')  setValue('immunizationstatus:exact', 'nonCompliant')
            else setValue('immunizationstatus:exact', '')
        }
    }, [savedSearchList?.searches])

    return (
        <FilterComponent
            form={form}
            displayFilter={showFilter}
            onUpdateFilterParams={handleUpdateFilterParams}
            filterVisibilityChanged={updateVisibility}
        >

            <div
                className="flex justify-between items-center mt-[23px] mb-1 cursor-pointer"
                onClick={() => setShowSchoolFilter(true)}
            >
                <span className='text-base font-semibold'>{t('SCHOOLS')}</span>
                <FontAwesomeIcon icon={faChevronRight} className='h-3 text-brand-color' />
            </div>
            {selectedSchoolList?.length > 0 && (
                selectedSchoolList.map((item) => (
                    <div key={item?.id}>
                        <div className='flex items-center justify-between py-[13px]'>
                            <div className='flex items-center mr-2'>
                                <div className='w-2 h-2 min-w-[8px] bg-brand-color mr-3' />
                                <span className='text-[#454545]'>{item?.name}</span>
                            </div>
                            <FontAwesomeIcon
                                icon={faClose}
                                className='h-3.5 text-brand-color cursor-pointer'
                                onClick={() => handleRemoveSchool(item.id)}
                            />
                        </div>
                    </div>
                ))
            )}
            <div className='bg-[#F4F4F4] h-[1px] w-full mt-4' />

            <div className="py-4">
                <h2 className="text-base font-semibold">{t('STATUS')}</h2>
                <div className="w-full mt-5">
                    <div className='grid gap-3 grid-cols-2'>
                        <div>
                            <InputRadio inputLabel={<VaccinationStatus status={{ color: 'yellow', display: 'pending' }} />}
                                optionValue={'yellow:pending'}
                                id={`no`}
                                {...register("immunizationstatus:exact", {
                                    required: false,
                                })} />
                        </div>
                        <div>
                            <InputRadio inputLabel={<VaccinationStatus status={{ color: 'red', display: 'recommendation' }} />}
                                optionValue={'nonCompliant'}
                                id={`none`}
                                {...register("immunizationstatus:exact", {
                                    required: false,
                                })} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-[#F4F4F4] h-[1px] w-full mt-5' />

            {showSchoolFilter && (
                <RightModal isOpen={showSchoolFilter} onClose={() => setShowSchoolFilter(false)}>
                    <SchoolFilter
                        updateVisibility={setShowSchoolFilter}
                        setValue={setValue}
                        maximumSelectableSchools={20}
                        schoolIds={getValues('organizationid\:exact')?.split('|') || selectedSchools ||[]}
                    />
                </RightModal>
            )}
        </FilterComponent>
    );
}

export default PatientFilterComponent;