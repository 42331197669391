import React, {ReactNode} from 'react';

function TabNavigation(props: { children: ReactNode }) {
    const {children} = props;

    return (
        <div className={'bg-brand-color bg-opacity-5 px-6 flex flex-row'}>
            {children}
        </div>
    );
}

export default TabNavigation;