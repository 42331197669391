import React, {ReactNode} from 'react';

function NavTab(props: { title: ReactNode, clicked: () => any, active?: boolean, visited?: boolean }) {
    const {title, clicked, active = false, visited = false} = props;

    return (
        <div>
            <div onClick={clicked}
                 className={(active && 'border-b-2') + '  ' + 'border-b-brand-color py-3 mx-5 cursor-pointer '}>{title}</div>
        </div>
    );
}

export default NavTab;