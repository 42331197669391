import {useEffect, useState} from 'react'
import {FieldValues} from "react-hook-form";
import ReviewHistorySearch from "../dashboard-search/ReviewHistorySearch";
import ReviewingHistoryTable from '../table/reviewingHistory/ReviewingHistoryTable';
import ReviewingHistoryMobile from '../table/reviewingHistory/ReviewingHistoryMobile';
import {usePerformance} from '../../contexts/practitioner/PerformanceProvider';
import ReviewingHistoryFilterComponent from '../dashboard-search/ReviewingHistoryFilterComponent';
import {useTranslation} from "react-i18next";

function ReviewingHistory() {
  const { reviews } = usePerformance();

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<Record<string,string>>({});
  const [searchTerm, setSearchTerm] = useState('')
    const [studentList, setStudentList] = useState(reviews);
    const [t, i18n] = useTranslation();

  function searchChanged(data: FieldValues) {
    // setFilterParams && setFilterParams(undefined);
    setSearchTerm && setSearchTerm(data.searchTerm);
  }

  useEffect(() => {
    let updatedStudnets = reviews?.filter(student => student.name.toLowerCase().includes(searchTerm.toLowerCase()))
    if (Object.keys(filterParams).length > 0 && updatedStudnets && updatedStudnets?.length > 0) {
      updatedStudnets = updatedStudnets
        .filter((student: any) => filterParams?.['organizationid\:exact'] ? student?.managingOrganization?.id === filterParams?.['organizationid\:exact'] : true)
        .filter((student: any) => filterParams?.status
          ? (filterParams?.status === 'rejected'
            ? student?.evaluation?.doseStatus?.coding?.[0]?.code === 'notvalid'
            : student?.evaluation?.doseStatus?.coding?.[0]?.code === 'valid')
          : true)
          .filter((appointment: any) => filterParams?.date ? new Date(appointment?.date).toLocaleDateString(i18n.resolvedLanguage, {
              month: 'short',
              day: 'numeric',
              year: 'numeric'
          }) === new Date(filterParams?.date).toLocaleDateString(i18n.resolvedLanguage, {
              month: 'short',
              day: 'numeric',
              year: 'numeric'
          }) : true)
        filterParams?.sort === 'oldest'
        ? updatedStudnets.sort((a: any, b: any) => Number(new Date(a?.date)) - Number(new Date(b?.date)))
        : updatedStudnets.sort((a: any, b: any) => Number(new Date(b?.date)) - Number(new Date(a?.date)))
      setStudentList(updatedStudnets || [])
    } else {
      updatedStudnets?.sort((a: any, b: any) => Number(new Date(b?.date)) - Number(new Date(a?.date)));
      setStudentList(updatedStudnets)
    }
  }, [reviews, searchTerm, filterParams])

  return (
    <div>
      <ReviewingHistoryFilterComponent
        showFilter={showFilter}
        updateVisibility={() => setShowFilter(false)}
        setFilterParams={setFilterParams}
      />
      <ReviewHistorySearch
        updateVisibility={setShowFilter}
        onCriteriaChanged={searchChanged}
        filterParams={filterParams}
      />
      <div className='bg-white rounded-[4px] py-6 px-9 mt-6 mb-6'>
        <h2 className='text-lg font-semibold'>Immunization reviewing history</h2>
        <div className='lg:block hidden'>
          <ReviewingHistoryTable students={studentList || []} />
        </div>
        <div className='block lg:hidden'>
          <ReviewingHistoryMobile students={studentList || []} />
        </div>
      </div>
    </div>
  )
}

export default ReviewingHistory;
