import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import PrimaryButton from '../button/PrimaryButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClose, faPen} from '@fortawesome/free-solid-svg-icons';
import useProfessional from '../../hooks/useProfessional';
import {ISchool} from '../Icons';
import SchoolEdit from '../modal/SchoolEdit';
import useAuth from '../../hooks/useAuth';

export function MySchoolList() {
  const [t] = useTranslation();
  const { userProfile } = useAuth();
  const { viewableSchools, setViewableSchools } = useProfessional();


  const [showModal, setShowModal] = useState(false);

  function removeSchool(schoolId: string) {
    const updatedSchoolList = viewableSchools.filter((school)=> school.id !== schoolId);
    setViewableSchools && setViewableSchools(updatedSchoolList);
  }

  return (
    <div>
      <div className='flex items-center justify-between mb-8'>
        <h2 className='text-2xl font-bold'>{t('MY_SCHOOL')}</h2>
        <div className={'flex flex-row'}>
          <div className='w-[130px] mr-4'>
            <PrimaryButton outLine onClick={() => setViewableSchools && setViewableSchools([])}>
              <FontAwesomeIcon icon={faClose} className='text-brand-color h-4 w-4' />
              <span className='ml-2 text-base'>{t('RESET')}</span>
            </PrimaryButton>
          </div>
          <div className='w-[130px]'>
            <PrimaryButton outLine onClick={() => setShowModal(true)}>
              <FontAwesomeIcon icon={faPen} className='text-brand-color h-4 w-4' />
              <span className='ml-2 text-base'>{t('EDIT_LIST')}</span>
            </PrimaryButton>
          </div>
        </div>
      </div>
      <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4'>
        {viewableSchools && viewableSchools.length > 0 && viewableSchools?.map(school => (
          <div key={school.id} className='bg-white rounded-lg px-5 pt-5 pb-8'>
            <div className={'flex-row flex remove-school '} onClick={()=> removeSchool(school.id)}>
              <FontAwesomeIcon icon={faClose} className=' ml-auto  h-4 w-4' />
            </div>
            <div className='flex justify-center mb-[30px]'>
              <div className='flex items-center justify-center rounded-full w-20 h-20 bg-white-smoke text-grey [&>svg]:w-10 [&>svg]:h-10'>
                <ISchool />
              </div>
            </div>
            <p className='text-base font-semibold text-center text-heading'>{  school?.name || '---'}</p>
          </div>
        ))}
        {viewableSchools && viewableSchools?.length === 0 && (
          <>{t('NO_ITEMS_FOUND')}</>
        )}
      </div>
      {showModal && <SchoolEdit showModalInitial={showModal} updateVisibility={setShowModal} selectedSchools={viewableSchools} />}
    </div>    
  )
}
