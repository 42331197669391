import React from 'react';
import ManageAvailability from "../../../components/professional-dashboard/ManageAvailability";
import {ScheduleProvider} from "../../../contexts/practitioner/ScheduleProvider";

function ManageAvalibilityView(props: {}) {
    return (
        <ScheduleProvider>
            <ManageAvailability/>
        </ScheduleProvider>
    );
}

export default ManageAvalibilityView;