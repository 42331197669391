import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PrimaryButton from '../button/PrimaryButton';
import {faArrowLeftLong, faPen, faPlus, faTrash} from '@fortawesome/free-solid-svg-icons';
import PrimaryLink from '../link/PrimaryLink';
import InputGroup from '../group/InputGroup';
import ManageAvailabilityTable from '../table/ManageAvailability';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Vacation from './Vacation';
import {useSchedule} from "../../contexts/practitioner/ScheduleProvider";
import useProfessional from "../../hooks/useProfessional";
import {useNavigate} from "react-router-dom";
import SelectInput from "../input/SelectInput";
import {getDateFromHours, getDateFromString} from "../../util/date/convertDateString";
import DualSelect from "../input/DualSelect";
import {InputRadio} from "../input/InputRadio";
import {useFieldArray, useForm} from "react-hook-form";
import SubmitPrimaryButton from "../button/SubmitPrimaryButton";
import Confirm from '../modal/Confirm';
import Spinner from '../spinner/Spinner';
import {useWindowSize} from '../../hooks/useWindowSize';

export const daysOfWeek = [
  'mon',
  'tue',
  'wed',
  'thu',
  'fri',
  'sat',
  'sun',
];

interface ConfirmType {
  open: boolean;
  content: string;
  handleOnAccept: () => void
}

const ManageAvailability = () => {
  const [t, i18n] = useTranslation();
  const { width } = useWindowSize();
  const DayOfWeek = {
    mon: t('MONDAY'),
    tue: t('TUESDAY'),
    wed: t('WEDNESDAY'),
    thu: t('THURSDAY'),
    fri: t('FRIDAY'),
    sat: t('SATURDAY'),
    sun: t('SUNDAY'),
    unk: t('UNKNOWN')
  }
  const [showModal, setShowModal] = useState(false);
  const { updateServiceAvailability, services, cancelVacation, refreshServices } = useProfessional();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<{ loading: boolean, index: number | null }>({ loading: false, index: null })
  const [editVactionIndex, setEditVacationIndex] = useState(null)
  const [editVaction, setEditVacation] = useState(null)
  const [confirm, setConfirm] = useState<ConfirmType>({ open: false, content: '', handleOnAccept: () => { } });

  const [submitError, setSubmitError] = useState<string>();
  const navigate = useNavigate();
  const updateModal = useCallback(
    (visibility: boolean) => setShowModal(visibility),
    []
  );
  const { schedule: inj, refreshSchedule } = useSchedule();
  const scheduleData = useMemo(() => {
    return inj?.availability?.[0]?.availableTime?.map((item: any, index: number) => {
      return {
        id: Object.keys(DayOfWeek).findIndex(key => key === item?.daysOfWeek?.[0]) || index,
        name: DayOfWeek[(item?.daysOfWeek?.[0] || 'unk') as keyof typeof DayOfWeek],
        time_from: item?.availableStartTime,
        time_to: item?.availableEndTime,
        status: 'in',
      };
    });
  }, [inj]);

  const scheduleDefault = [
    {
      id: 0,
      name: t('MONDAY'),
      time_from: '',
      time_to: '',
      status: 'out',
    },
    {
      id: 1,
      name: t('TUESDAY'),
      time_from: '',
      time_to: '',
      status: 'out',
    },
    {
      id: 2,
      name: t('WEDNESDAY'),
      time_from: '',
      time_to: '',
      status: 'out',
    },
    {
      id: 3,
      name: t('THURSDAY'),
      time_from: '',
      time_to: '',
      status: 'out',
    },
    {
      id: 4,
      name: t('FRIDAY'),
      time_from: '',
      time_to: '',
      status: 'out',
    },
    {
      id: 5,
      name:  t('SATURDAY'),
      time_from: '',
      time_to: '',
      status: 'out',
    },
    {
      id: 6,
      name: t('SUNDAY'),
      time_from: '',
      time_to: '',
      status: 'out',
    },
  ];

  const schedule = useMemo(() => scheduleDefault.map(item => scheduleData?.find((scheduleItem: any) => scheduleItem?.name === item.name) || item ), [scheduleData]);

  useEffect(() => {
    if (scheduleData) {
      console.log('resetting', schedule);
      reset({ availability: schedule });
    }
  }, [scheduleData]);

  const form = useForm(
    {
      defaultValues: { availability: schedule },
    }
  );
  const { register, handleSubmit, control, reset, watch, formState: { errors } } = form;
  const { fields, append, remove } = useFieldArray({ control, name: "availability" });

  const timeOptions = [
    '00:00',
    '00:30',
    '01:00',
    '01:30',
    '02:00',
    '02:30',
    '03:00',
    '03:30',
    '04:00',
    '04:30',
    '05:00',
    '05:30',
    '06:00',
    '06:30',
    '07:00',
    '07:30',
    '08:00',
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
    '20:00',
    '20:30',
    '21:00',
    '21:30',
    '22:00',
    '22:30',
    '23:00',
    '23:30',
  ];

  const onSubmit = (data: any) => {
    console.log(data);
    var arr = Object.values(data.availability);
    console.log("Data", arr);
    const availableTime = arr.map((avalibility: any, index) => {
      const { time_from, time_to, status } = avalibility;
      if (time_from && time_to && status === 'in') {
        return { daysOfWeek: [daysOfWeek[index]], availableStartTime: time_from, availableEndTime: time_to };
      }
    }).filter((e: any) => e);
    const notAvailable = arr.map((avalibility: any, index) => {
      const { time_from, time_to, status } = avalibility;
      if (!(time_from && time_to && status === 'in')) {
        if (time_from && time_to && status === 'out') {
          return { daysOfWeek: [daysOfWeek[index]], availableStartTime: time_from, availableEndTime: time_to };
        }
      }

    }).filter((e: any) => e);

    console.log(availableTime, notAvailable);
    const serviceId: string | undefined = services?.[0].id;
    setSubmitError(undefined);
    setLoading(true);
    updateServiceAvailability && updateServiceAvailability(availableTime, notAvailable, serviceId).then(async (res) => {
      refreshSchedule && (await refreshSchedule(res.body.id));

    }).catch((err) => {
      console.error("Unable to get update Schedule", err);
      setSubmitError("Unable to update Schedule: " + err.message);
    }).finally(() => setLoading(false));
  };

  function handleCancelVacation(vacationIndex: number) {
    setLoadingState({ loading: true, index: vacationIndex })
    const notAvailableTime = services?.[0]?.availability?.[0]?.notAvailableTime.filter((_: any, index: number) => vacationIndex !== index)
    cancelVacation && cancelVacation(services?.[0].id, notAvailableTime)
      .then(() => {
        refreshServices && refreshServices()
      })
      .catch((reason) => {
        console.error("Unable to cancel vacation", reason);
        setSubmitError("Unable to cancel vacation: " + reason.message);
      }).finally(() => setLoadingState({ loading: false, index: null }));
  }

  function onCancelVacation(index: number) {
    setConfirm({
      open: true,
      content: 'Are you sure you want to cancel the vacation?',
      handleOnAccept: () => {
        setConfirm({ ...confirm, open: false })
        handleCancelVacation(index)
      }
    })
  }

  function generateDatesInRange(startDate:Date, endDate:Date) {
    let dates = [];
    let currentDate = startDate;

    while (currentDate < endDate) {
      dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }

  const disabledDates = useMemo(() => {
    if (services?.[0]?.availability?.[0]?.notAvailableTime?.length > 0) {
      let dates: Date[] = []
      services?.[0]?.availability?.[0]?.notAvailableTime.forEach((vacation: any, index: number) => {
        if (editVactionIndex !== index) {
          const dateRange = generateDatesInRange(getDateFromString(vacation?.during?.start), getDateFromString(vacation?.during?.end))
          dates = [...dates, ...dateRange]
        }
      })
      return dates
    }
    return []
  }, [services?.[0]?.availability?.[0]?.notAvailableTime, editVactionIndex])

  return (
    <section>
      <div>
        <PrimaryLink to="/professional/dashboard/appointments">
          <FontAwesomeIcon icon={faArrowLeftLong} />
          <span className='ml-1'>{t('BACK', 'Back')}</span>
        </PrimaryLink>
      </div>

      <div className="mt-4 flex items-start sm:items-center justify-between flex-col sm:flex-row">
        <h1 className="font-bold text-2xl max-[1022px]:text-center">
          {t('MANAGE_AVAILABILITY')}
        </h1>
        {!services?.[0]?.availability?.[0]?.notAvailableTime?.length && (
          <div className={'w-40 mt-3 sm:mt-0'}>
            <PrimaryButton

              outLine={true}
              onClick={() => setShowModal(!showModal)}
            >
              <FontAwesomeIcon icon={faPlus} className={'mr-4'} />
              <span>{t('ADD_VACATION')}</span>
            </PrimaryButton>
          </div>
        )}
      </div>

      <div className={'flex flex-col lg:flex-row mt-8'}>
        <div className='flex-1'>
          <InputGroup>
            <div>
              {(width || 0) > 1024 ? (
                <ManageAvailabilityTable schedule={schedule || []} submit={onSubmit} loading={loading} submitError={submitError} timeOptions={timeOptions} />
              ) : (
                <div>
                  <div className={'grid grid-cols-1 divide-y overflow-ellipsis'}>
                    <>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={'divide-y'}>
                          {fields?.map((field, index: number) =>
                            <div key={field.id}>

                              <div className="p-2" >
                                <div>
                                  <span className="font-semibold">{DayOfWeek[(Object.keys(DayOfWeek)[index] || 'unk') as keyof typeof DayOfWeek]}</span>
                                </div>
                              </div>


                              <div className="p-2">
                                <SelectInput
                                  id={`availability.${index}.time_from`}
                                  placeholder={t('SELECT')}
                                  disabled={watch(`availability.${index}.status`) === 'out'}
                                  inputLabel={t('TIME_FROM')}
                                  inputName={`availability.${index}.time_from`}
                                  {...register(`availability.${index}.time_from`)}
                                >
                                  <option value="">{t('SELECT')}</option>
                                  {timeOptions.map((time) => (
                                    <option value={time} key={time}>
                                      {getDateFromHours(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                    </option>
                                  ))}
                                </SelectInput>

                              </div>


                              <div className="p-2">
                                <SelectInput
                                  id={`availability.${index}.time_to`}
                                  placeholder={t('SELECT')}
                                  disabled={watch(`availability.${index}.status`) === 'out'}
                                  inputLabel={t('TIME_TO')}
                                  inputName={`availability.${index}.time_to`}
                                  {...register(`availability.${index}.time_to`)}
                                >
                                  <option value="">{t('SELECT')}</option>
                                  {timeOptions.map((time) => (
                                    <option value={time} key={time}>
                                      {getDateFromHours(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                    </option>
                                  ))}
                                </SelectInput>
                              </div>

                              <div className="p-2 [&>ul>li>label]:py-[11px]">
                                <DualSelect>
                                  <InputRadio
                                    inputLabel={t('IN')}
                                    id={`availability.${index}.status.in`}
                                    optionValue={'in'}
                                    {...register(`availability.${index}.status`)}
                                  />

                                  <InputRadio
                                    inputLabel={t('OUT')}
                                    id={`availability.${index}.status.out`}
                                    optionValue={'out'}
                                    {...register(`availability.${index}.status`)}
                                    style={{ width: '100px' }}
                                  />
                                </DualSelect>
                              </div>

                            </div>)}
                        </div>
                        <span className={'text-red-600'}>{submitError}</span>
                        <div className={'grid grid-cols-1 mt-4 p-2 gap-4'}>
                          <PrimaryButton
                          type='button'
                          outLine
                          onClick={() => reset({ availability: schedule })}
                          >
                            {t('CANCEL')}
                          </PrimaryButton>
                          <SubmitPrimaryButton isLoading={loading}>{t('SAVE')}</SubmitPrimaryButton>
                        </div>
                      </form>
                    </>
                  </div>
                </div>
              )}
            </div>
          </InputGroup>
        </div>
        {services?.[0]?.availability?.[0]?.notAvailableTime?.length > 0 && (
          <div className='lg:w-[385px] my-8 lg:ml-5 lg:my-0'>
            <InputGroup>
              <p className='font-semibold mt-0 mb-3'>{t('VACATION')}</p>
              {services?.[0]?.availability?.[0]?.notAvailableTime?.map((vacation: any, index: any) => (
                <div key={index} className='flex items-center justify-between py-4 border-b border-b-neutral-10'>
                  <span className='font-medium'>
                    {getDateFromString(vacation?.during?.start).toLocaleDateString(i18n.resolvedLanguage, {
                      month: 'short',
                      day: 'numeric',
                      year: 'numeric'
                    })} - {new Date(vacation?.during?.end).toLocaleDateString(i18n.resolvedLanguage, {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric'
                  })}
                  </span>
                  <div className='flex items-center ml-4'>
                    <button
                      className="w-8 h-8 bg-[#f8a03a0d] rounded-full flex items-center justify-center"
                      onClick={() => {
                        setEditVacationIndex(index)
                        setEditVacation(vacation)
                        setShowModal(true)
                      }}
                    >
                      <FontAwesomeIcon icon={faPen} className='text-brand-color text-sm' />
                    </button>
                    {loadingState?.index === index
                      ? <Spinner customSize='w-6 h-6' />
                      : (<button
                        className="w-8 h-8 bg-[#f8a03a0d] rounded-full flex items-center justify-center"
                        onClick={() => onCancelVacation(index)}
                      >
                        <FontAwesomeIcon icon={faTrash} className='text-red-500 text-sm' />
                      </button>
                      )}
                  </div>
                </div>
              ))}
              <div className='mt-10'>
                <PrimaryButton
                  outLine={true}
                  onClick={() => {
                    setEditVacationIndex(null)
                    setEditVacation(null)
                    setShowModal(!showModal)
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} className={'mr-4'} />
                  <span>{t('ADD_VACATION')}</span>
                </PrimaryButton>
              </div>
            </InputGroup>
          </div>
        )}
      </div>

      {showModal && (
        <Vacation
          editVactionIndex={editVactionIndex}
          editVaction={editVaction}
          displayModal={showModal}
          modalVisibilityChanged={updateModal}
          disabledDates={disabledDates}
        />
      )}

      <Confirm
        content={confirm.content}
        open={confirm.open}
        onClose={() => setConfirm({ ...confirm, open: false })}
        onCancel={() => setConfirm({ ...confirm, open: false })}
        onAccept={confirm.handleOnAccept}
      />
    </section>
  );
};
export default ManageAvailability;
