import React from 'react';
import {Outlet} from "react-router-dom";
import CodeProvider from "../../contexts/CodeProvider";
import PatientProvider from "../../contexts/PatientProvider";
import {VideoContextProvider} from "../../contexts/VideoContextProvider";

function Patient() {
    return (
        <PatientProvider>
            <CodeProvider>
                <VideoContextProvider>
                    <Outlet/>
                </VideoContextProvider>
            </CodeProvider>
        </PatientProvider>
    );
}

export default Patient;