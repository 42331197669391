import React, {useContext, useEffect, useState} from "react";
import {API} from "aws-amplify";
import {ServerReply} from "./AppointmentProvider";
import {AuthContext} from "../AuthContext";

type AppointmentDetailContextType = {
    appointment?: AppointmentDetailState;
};

const AppointmentDetailContext = React.createContext<AppointmentDetailContextType>({});
export const useAppointmentDetail = () => React.useContext(AppointmentDetailContext);

interface AppointmentDetailState {
    loading?: boolean;
    appointment?: any;
    error?: any;
}

export function AppointmentDetailProvider(props: {children: React.ReactNode, appointmentId?: string})  {
    const { userProfile } = useContext(AuthContext);

    const [appointment, setAppointment] = useState<AppointmentDetailState>();

    async function getAppointment(appointmentId=props.appointmentId): Promise<ServerReply<{body: any}>> {
        const apiName = 'LAUSDPractitionerEndpoint';
        const path = `/practitioner/appointment/${userProfile}/${appointmentId}`;
        const myInit = {
            body: {},
            headers: {},
        };
        return API.get(apiName, path, myInit);
    }

    function fetchAppointments() {
        setAppointment(prevState => ({
            ...prevState,
            loading: true
        }));
        getAppointment().then((value) => {
            setAppointment({
                loading: false,
                appointment: value.body,
                error: null
            });
        }).catch((error: any) => {
            setAppointment({
                loading: false,
                appointment: undefined,
                error: error?.message || 'Unable to get appointment details'
            });
        })
    }

    useEffect(() => {
        fetchAppointments();
    }, []);

    return (
        <AppointmentDetailContext.Provider value={{
            appointment
        }}>
            {props.children}
        </AppointmentDetailContext.Provider>
    );
};