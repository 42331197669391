import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useCallback, useState} from 'react';
import {useWindowSize} from '../../../hooks/useWindowSize';
import {faBars, faStethoscope, faXmark} from '@fortawesome/free-solid-svg-icons';
import SideNav from '../../SideNav';
import {Outlet} from 'react-router-dom';
import {faQuestionCircle,} from '@fortawesome/free-regular-svg-icons';
import VideoCallModal from "../../../components/modal/VideoCallModal";
import {IAdminDashboard, ICalendar, IDashboardLine, IMessages, ISchool} from '../../../components/Icons';
import isDev from "../../../util/config/envDetect";
import useAuth from "../../../hooks/useAuth";

const Dashboard = () => {
  const [t] = useTranslation();
  const {width} = useWindowSize();
  const [showNavbar, setShowNavbar] = useState<boolean>(false);
  const {isInAdminGroup} = useAuth();
  const lg_breakpoint = 1024;

  const menuItems = [
    {
      title: t('STUDENTS'),
      icon: <FontAwesomeIcon icon={faStethoscope} />,
      url: 'students'
    },
    {
      title: t('APPOINTMENTS'),
      icon: <ICalendar />,
      url: 'appointments',
    },
    {
      title: t('MESSAGES'),
      icon: <IMessages />,
      url: 'messages',
    },
    ...(isDev() ? [{
      title: t('DASHBOARD'),
      icon: <IDashboardLine />,
      url: 'performance-dashboard',
    }] : []),
    {
      title: t('MY_SCHOOL'),
      icon: <ISchool />,
      url: 'my-school',
    },
    ...(isInAdminGroup && isInAdminGroup() ? [{
      title: t('ADMIN_DASHBOARD'),
      icon: <IAdminDashboard/>,
      url: 'admin-dashboard',
    }] : []),
    {
      title: t('HELP_CENTER'),
      icon: <FontAwesomeIcon icon={faQuestionCircle} />,
      url: 'help-center',
    },
  ];

  const shouldDisplayNav = useCallback(() => {
    return showNavbar || (width || 0) > lg_breakpoint;
  }, [width, showNavbar]);


  return (
      <div className={'flex flex-row w-full relative'}>
        {shouldDisplayNav() && ( // TODO components sidenav
            <div className={'z-10'}>
              <div className={'w-screen h-screen fixed bg-black bg-opacity-20 lg:w-fit'}
                   onClick={() => setShowNavbar(!showNavbar)}/>
              <div
                  className={'lg:max-w-md bg-white shadow-lg min-h-screen  fixed  w-80'}
              >
                <FontAwesomeIcon
                    onClick={() => setShowNavbar(!showNavbar)}
                    className={'ml-auto p-5 h-10 lg:hidden'}
                    icon={faXmark}
                />
                <SideNav items={menuItems} professional={true} navItemClicked={() => setShowNavbar(false)}/>
              </div>


            </div>
        )}


        <main className={'lg:px-10 lg:pt-10 lg:ml-80 w-full bg-neutral-100 min-h-screen'}>
          <div className={'bg-white shadow-lg mb-5 lg:mb-0'}>
          <FontAwesomeIcon
              onClick={() => setShowNavbar(!showNavbar)}
              className={'h-10 p-5 lg:hidden'}
              icon={faBars}
          />
          </div>
          <div className={' px-2 '}>
          <Outlet/>
            <VideoCallModal/>
          </div>

        </main>
      </div>
  );
};
export default Dashboard;
