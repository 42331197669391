import {useContext, useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom';
import starBg from '../../../assets/images/star-bg.png';
import usePatient from '../../../hooks/usePatient';
import {AuthContext} from "../../../contexts/AuthContext";
import {parseDocumentPhoto} from "../../../util/photo/parseDatabasePhotos";
import ProfileImage from "../../../components/status/ProfileImage";
import VaccinationStatus from "../../../components/status/VaccinationStatus";
import {IPhStudent} from "../../../components/Icons";

function ChildList() {
  const { patient, parentStudents, getPhoto } = usePatient();

  return (
    <div className="relative bg-light-primary lg:h-screen lg:max-h-screen overflow-auto flex justify-center p-4">
      <img src={starBg} className='absolute bottom-0 left-0 w-full h-full' />
      <div className='relative mt-20'>
        <h1 className='text-4xl font-bold mb-6 text-center'>Hello, {patient?.name?.[0].text || '---'}</h1>
        <p className='text-lg text-center'>We have downloaded information from your LAUSDs system profile.</p>
        <p className='text-lg text-center'>Switch between student profiles to manage their stats.</p>
        <div className='flex mt-16 flex-wrap justify-center'>
          {parentStudents?.map(student => (
            <StudentItem
              key={student.subject}
              student={student}
              getPhoto={getPhoto}
              parseDocumentPhoto={parseDocumentPhoto}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const StudentItem = (props: any) => {
  const {
    student,
    getPhoto,
    parseDocumentPhoto
  } = props

  const navigate = useNavigate();
  const { userProfile, setDefaultUserProfile } = useContext(AuthContext);
  const [userInformation, setUserInformation] = useState<{ name?: string, description?: string, profilePhoto?: string, immunizationStatus?: string }>({ name: 'Test Patient', description: 'he/her' });

  useEffect(() => {
    setUserInformation({
      ...userInformation,
      name: student?.name?.[0].text,
      description: student?.pronouns?.display,
      immunizationStatus: student?.immunizationStatus
    });
    getPhoto(student.subject, student.photo?.[0].url).then((value: any) => {
      setUserInformation({
        name: student?.name?.[0].text,
        description: student?.pronouns?.display,
        profilePhoto: parseDocumentPhoto(value.file),
        immunizationStatus: student?.immunizationStatus
      });
    }).catch((reason: Error) => {
      console.error('Unable to get student photo: ', reason);
    })
  }, [student])

  const handleGotoStudentImmunization = (student: any) => {
    setDefaultUserProfile && setDefaultUserProfile(student.subject);
    navigate('/patient/dashboard/immunizations');
  }

  return (
    <div
      className={`lg:w-[220px] flex flex-col items-center bg-white cursor-pointer hover:bg-primary-hover ${userProfile == student.subject && 'border-2 border-brand-color'} block p-8 rounded-lg m-3`}
      onClick={() => handleGotoStudentImmunization(student)}
    >
      <div className="w-[100px] h-[100px] mb-7 bg-[#F6F6F6] flex items-center justify-center rounded-full">
        {userInformation.profilePhoto
          ? <ProfileImage src={userInformation.profilePhoto} />
          : <IPhStudent />}
      </div>
      <span className={`${userProfile == student.subject && 'text-brand-color'} text-lg text-center font-semibold`}>{userInformation.name}</span>
      <div className="mt-3 relative w-full flex justify-center">
        <VaccinationStatus isPatient status={userInformation.immunizationStatus ? {
          color: userInformation.immunizationStatus?.split(':')[0],
          display: userInformation.immunizationStatus?.split(':')[1]
        } : undefined} />
      </div>
    </div>
  )
}

export default ChildList;