export function parseDocumentPhoto(documentStringBase64?: string, contentType?: string): string | undefined {
    if (documentStringBase64)
    {
        if(!contentType){
            contentType = detectImageMimeType(documentStringBase64);
        }
        try {
            const updatedStringBase64 = /^[A-Za-z0-9+/=]+$/.test(documentStringBase64) ? documentStringBase64 : btoa(documentStringBase64)
            const blob = b64toBlob(updatedStringBase64, contentType)
            return URL.createObjectURL(blob);
        } catch (e) {
            return undefined;
        }

    } else {
        return undefined;
    }
}
function b64toBlob(b64Data:string, contentType?:string) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    const sliceSize=512

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: contentType});
}
const signatures = {
    JVBERi0: "application/pdf",
    R0lGODdh: "image/gif",
    R0lGODlh: "image/gif",
    iVBORw0KGgo: "image/png",
    "/9j/": "image/jpg"
};

export function detectImageMimeType(b64: string) {
    for (let s  in signatures) {
        if (b64.indexOf(s) === 0) {
            return signatures[s as keyof typeof signatures];
        }
    }
    return "image/*";
}

