import {useMemo, useState} from "react";
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faCircle, faMagnifyingGlass, faXmark} from "@fortawesome/free-solid-svg-icons";
import PrimaryButton from "../button/PrimaryButton";
import useProfessional from "../../hooks/useProfessional";
import Spinner from "../spinner/Spinner";
import CenterModal from "../dashboard-search/CenterModal";
import useAuth from "../../hooks/useAuth";

function SchoolEdit(props: { updateVisibility: (visibility: boolean) => void, showModalInitial?: boolean, selectedSchools?: string[]}) {
  const { updateVisibility, showModalInitial = false, selectedSchools } = props;

  const [t] = useTranslation();
  const { schoolList, setViewableSchools } = useProfessional();
  const { userProfile } = useAuth();

  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedItems, setSelectedItems] = useState<any[]>(selectedSchools || []);

  const filteredSchoolList = useMemo(() => {
    return schoolList?.schools?.filter((item: any) => item?.name?.toLowerCase().includes(searchValue.toLowerCase())).slice(0, 100) || []
  }, [schoolList, searchValue]);

  function handleSave() {
    setViewableSchools && setViewableSchools(selectedItems);
    updateVisibility(false)
  }

  function handleClickSchool(school: any) {
    let updateSelectedItems = []
    if (selectedItems?.find(item => item.id === school?.id)) updateSelectedItems = selectedItems.filter(item => item.id !== school?.id)
    else updateSelectedItems = [...selectedItems, school]
    setSelectedItems(updateSelectedItems)
  }

  const selectedIds = useMemo(() => {
    return selectedItems?.map(item => item?.id)
  }, [selectedItems])

  return (
    <CenterModal isOpen={showModalInitial} onClose={() => updateVisibility(false)}>
      <div className="p-10 flex flex-col h-screen w-screen md:max-h-[760px] md:w-[600px] relative">
        <button type={'button'}
          className="text-slate-400 absolute top-4 right-4 hover:text-slate-500 transition duration-300 ease-in-out"
          onClick={() => updateVisibility(false)}
        >
          <FontAwesomeIcon icon={faXmark} />
        </button>
        <h2 className="text-2xl font-semibold mb-[27px]">{t('EDIT_SCHOOL_LIST')}</h2>
        <div className={"w-full flex gap-3 items-center bg-slate-50 rounded-sm border-0 p-4 outline-0"}>
          <FontAwesomeIcon icon={faMagnifyingGlass} color={"#8C8C8C"} className="w-[17px] h-[17px]" />
          <input
            className={"grow w-auto bg-transparent border-0 outline-0 text-sm"}
            placeholder={t('SEARCH_SCHOOLS')}
            type={'text'}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        <p className="text-base my-6">{t('CHECK_THE_SCHOOLS_YOU_WORK_WITH')}:</p>
        <div className="flex-1 overflow-x-hidden min-h-[200px]">
          {!schoolList?.loading && !schoolList?.error && filteredSchoolList?.length > 0 && filteredSchoolList
          .map((school: any) => (
            <div
              key={school?.id}
              onClick={() => handleClickSchool(school)}
              className="flex items-center justify-between py-4 pr-[7px] hover:bg-[#f8a03a0d] border-b-[1px] border-[#F4F4F4] cursor-pointer"
            >
              <div className="flex items-center">
                <div className="flex items-center justify-center bg-neutral-100 w-8 h-8 min-w-[32px] rounded-full mr-4">
                  <div className="bg-clinic-icon w-[14px] h-[14px] bg-contain" />
                </div>
                <span className="text-sm font-medium">{school?.name}</span>
              </div>
              <FontAwesomeIcon
                icon={selectedIds.includes(school?.id) ? faCheckCircle : faCircle}
                color={selectedIds.includes(school?.id) ? "#73C92D" : "#F4F4F4"}
                className="w-5 h-5"
              />
            </div>
          ))}
          {!schoolList?.loading && !schoolList?.error && filteredSchoolList?.length === 0 && (
            <span className="block mt-2 text-[#8C8C8C]">{t('NO_SEARCH_MATCHES')}</span>
          )}
          {schoolList?.loading && (
            <div className="flex justify-center mt-5">
              <Spinner />
            </div>
          )}
          {!schoolList?.loading && schoolList?.error && (
            <p className="mt-5">{schoolList.error}</p>
          )}
        </div>
        <div className="mt-5 w-[115px] ml-auto">
          <PrimaryButton onClick={handleSave}>{t('SAVE')}</PrimaryButton>
        </div>
      </div>
    </CenterModal>
  )
};

export default SchoolEdit;
