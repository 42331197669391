import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from "react-router-dom";
import {usePatient} from "../../contexts/practitioner/PatientProvider";
import InputGroup from "../group/InputGroup";
import PrimaryButton from "../button/PrimaryButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock, faComment, faEnvelope, faUser} from "@fortawesome/free-regular-svg-icons";
import ProfileImage, {ProfileStatuses} from "../status/ProfileImage";
import {parseDocumentPhoto} from "../../util/photo/parseDatabasePhotos";
import {faArrowLeft, faCakeCandles} from "@fortawesome/free-solid-svg-icons";
import {ISchool} from '../Icons';
import VaccinationStatus from "../status/VaccinationStatus";
import useCapitalizePrototype from '../../hooks/useCapitalizePrototype'
import DocumentsTable from "../table/document/DocumentsTable";
import DocumentMobile from "../table/document/DocumentMobile";
import AppointmentStatus from '../status/AppointmentStatus';
import useAuth from '../../hooks/useAuth';
import Spinner from '../spinner/Spinner';
import {updateBothUserProfiles} from "../../util/sendBird";
import useProfessional from "../../hooks/useProfessional";
import Confirm from '../modal/Confirm';
import {ampli} from "../../ampli";
import {Immunization} from '../../types/database/SharedTypes';
import PractitionerImmunizationItem from "../immunization/PractitionerImmunizationItem";
import ImmunizationStepReview from "../immunization/ImmunizationStepReview";
import StatusChangeModal from "../immunization/StatusChangeModal";

interface ConfirmType {
    open: boolean;
    content: string;
    handleOnAccept: () => void
}

function StudentDetailPane(props: { studentId?: string, appointment?: any, enableClicking?: boolean }) {
    const {appointment, enableClicking = true} = props
    const [t, i18n] = useTranslation()
    const { patient, patientProfilePhoto, immunizationSubmissionCompletionStatus,recommendationsLoading, acceptAppointment, declineAppointment, reviewImmunizations, setReviewImmunizations, immunizationsRecommendations } = usePatient();
    const { userProfile } = useAuth();
    const {professional} = useProfessional();
    const navigate = useNavigate()
    const [appointmentResponseError, setAppointmentResponseError] = useState<string>('');
    const [confirm, setConfirm] = useState<ConfirmType>({ open: false, content: '', handleOnAccept: () => {} });
    const [showReivewImmunizations, setShowReviewImmunizations] = useState<boolean>(false)
    const [step, setStep] = useState(0)

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [messageNavigateLoading, setMessageNavigateLoading] = useState<string>();
    const [showStatusChangeModal, setShowStatusChangeModal] = useState(false);

    let { state } = useLocation();

    useCapitalizePrototype();

    function getAge(dateString: string) {
        const today = new Date();
        const birthDate = new Date(dateString);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    function getFormattedDateOfBirth(dateOfBirth: string) {

        return new Date(dateOfBirth).toLocaleDateString();
    }

    const dob = useMemo(() => getFormattedDateOfBirth(patient?.birthDate), [patient]);
    const primaryContact = useMemo(() => patient?.contact?.[0], [patient]);
    const profilePhotoSrc = useMemo(() => parseDocumentPhoto(patientProfilePhoto), [patientProfilePhoto]);

    useEffect(() => {
        if(state?.immunizationstatus && patient?.immunizationStatus){
            if (state.immunizationstatus !== patient.immunizationStatus) { // if the status is changed from what was in the list

                setShowStatusChangeModal(true) // Show status change modal
                setTimeout(() => {
                    setShowStatusChangeModal(false)
                }, 5000);

            }
        }
    }, [state?.immunizationstatus, patient?.immunizationStatus]);

    async function handleAcceptAppointment() {
        setIsLoading(true)
        setAppointmentResponseError('')
        try {
            if (acceptAppointment && userProfile) {
                await acceptAppointment(userProfile, appointment?.id);
                setIsLoading(false);
                ampli.track({
                    event_type: 'Accepted Appointment'
                });
                navigate('/professional/dashboard/appointments')
            }
        } catch (error: any) {
            console.log('unable to accept the appointment: ', error)
            setAppointmentResponseError(error?.message)
        } finally {
            setIsLoading(false)
        }
    }

    function onCancelAppointment() {
        setConfirm({
            open: true,
            content: t('APPOINTMENT_CANCEL_CONFIRM_MSG'),
            handleOnAccept: () => {
                setConfirm({ ...confirm, open: false })
                handleDeclineAppointment()
            }
        })
    }

    async function handleDeclineAppointment() {
        setIsLoading(true)
        setAppointmentResponseError('')
        try {
            if (declineAppointment && userProfile) {
                await declineAppointment(userProfile, appointment?.id);
                setIsLoading(false);
                ampli.track({
                    event_type: 'Canceled Appointment'
                });
                navigate('/professional/dashboard/appointments')
            }
        } catch (error: any) {
            console.log('unable to decline the appointment: ', error)
            setAppointmentResponseError(error?.message)
        } finally {
            setIsLoading(false)
        }
    }

    function initiateMessagesView() {
        setMessageNavigateLoading(`${t('LOADING_MESSAGES')}...`);
        updateBothUserProfiles(userProfile||'', professional?.name?.[0].text || '', professional?.photo?.[0].url || '',patient?.subject || '',patient?.name?.[0]?.text|| "", patient?.photo?.[0]?.url|| '' )
            .then(() => {
                navigate(`../messages/${patient?.subject}`);
            }).finally(() =>  setMessageNavigateLoading(undefined));
    }

    function handleUpdateModal(modalState: boolean) {
        setShowReviewImmunizations(modalState)
        if (reviewImmunizations?.length !== step + 1) {
            const updatedReviewImmunizations = reviewImmunizations.map((immunization: any) => {
                delete immunization?.temp_evaluation
                return immunization
            })
            setStep(0)
            setReviewImmunizations && setReviewImmunizations(updatedReviewImmunizations)
        }
    }

    const status = useMemo(() => (patient?.immunizationStatus ? ({ color: patient?.immunizationStatus.split(':')?.[0], display: patient?.immunizationStatus.split(':')?.[1] }) : { color: 'purple', display: 'Unsatisfactory' }), [patient]);

    return (
        <div>
            {appointment && (
                <>
                    <button
                        className='text-brand-color text-base cursor-pointer'
                        onClick={() => navigate(-1)}
                    >
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            className='mr-3'
                        />
                        <span className='font-medium'>{t('BACK')}</span>
                    </button>
                    <div className='mt-6 px-10 py-5 mb-5 bg-[#f8a03a0d]'>
                        <div className='flex lg:flex-row flex-col justify-between'>
                            <div className='flex sm:flex-row flex-col sm:items-center items-start lg:mb-0 mb-3'>
                                <div className='flex items-center sm:mr-14 mr-0'>
                                    <div className='bg-calendar-icon w-4 h-4 bg-contain mr-3' />
                                    <span className='text-base'>
                                        {new Date(appointment.start).toLocaleDateString(i18n.resolvedLanguage, {
                                            month: 'short',
                                            day: 'numeric',
                                            year: 'numeric'
                                        })}
                                    </span>
                                </div>
                                <div className='flex items-center sm:mr-14 mr-0'>
                                    <FontAwesomeIcon icon={faClock} className={'h-4 text-brand-color mr-3'} />
                                    <span className='text-base'>
                                        {new Date(appointment.start).toLocaleTimeString(i18n.resolvedLanguage, {
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        })}
                                        -
                                        {new Date(appointment.end).toLocaleTimeString(i18n.resolvedLanguage, {
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        })}
                                    </span>
                                </div>
                                <div className='flex items-center sm:mr-14 mr-0'>
                                    <AppointmentStatus
                                        appointmentstatus={appointment?.referrals?.length > 0 ? 'referred' : appointment?.status}
                                        appointmentdate={new Date(appointment.start).toLocaleDateString(i18n.resolvedLanguage, {
                                            weekday: 'short',
                                            month: 'short',
                                            day: 'numeric'
                                        })}
                                        appointmenttime={new Date(appointment.start).toLocaleTimeString(i18n.resolvedLanguage, {
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        })}
                                        isProfessional
                                    />
                                </div>
                            </div>
                            {(appointment.status !== 'cancelled' && !appointment?.referrals?.length) && (
                                <div className='flex'>
                                    {appointment.status !== 'booked' && (
                                        <>
                                            <div className='w-36'>
                                                <PrimaryButton
                                                    outLine={true}
                                                    onClick={() => navigate(`/professional/dashboard/appointments/${appointment?.subject?.subject}/${appointment?.id}`)}
                                                >
                                                    <div className='flex items-center justify-center w-full'>
                                                        <div className='bg-share-icon w-4 min-w-4 h-4 bg-contain mr-2' /> {t('REFER')}
                                                    </div>
                                                </PrimaryButton>
                                            </div>
                                            <div className='w-36 ml-3'>
                                                <PrimaryButton onClick={handleAcceptAppointment}>
                                                    <div className='flex items-center justify-center'>
                                                        <span>{t('CONFIRM')}</span>
                                                        {isLoading && (
                                                            <div className='ml-2'>
                                                                <Spinner />
                                                            </div>
                                                        )}
                                                    </div>
                                                </PrimaryButton>
                                            </div>
                                        </>
                                    )}
                                    {appointment.status === 'booked' && (
                                        <div className='ml-3'>
                                            <PrimaryButton outLine onClick={onCancelAppointment}>
                                                <div className='flex items-center justify-center px-6'>
                                                    <span>{t('CANCEL_APPOINTMENT')}</span>
                                                    {isLoading && (
                                                        <div className='ml-2'>
                                                            <Spinner />
                                                        </div>
                                                    )}
                                                </div>
                                            </PrimaryButton>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        {appointmentResponseError && <div className='text-red-600 mt-2'>{appointmentResponseError}</div>}
                    </div>
                    <Confirm
                        content={confirm.content}
                        open={confirm.open}
                        onClose={() => setConfirm({ ...confirm, open: false })}
                        onCancel={() => setConfirm({ ...confirm, open: false })}
                        onAccept={confirm.handleOnAccept}
                    />
                </>
            )}
            <div className={'grid lg:grid-cols-4 gap-8'}>
                <div className={'text-center'}>
                    <InputGroup className={'h-full'}>
                        <div className={'h-full'}>
                            <div className={'w-28 mx-auto mb-4'}>
                                <ProfileImage src={profilePhotoSrc} status={patient?.active ? ProfileStatuses.active : ProfileStatuses.inactive}></ProfileImage>
                            </div>
                            <div className='mb-2'><h2 className={'font-semibold text-xl'}>{patient?.name?.[0]?.text}</h2></div>
                            <div className='flex justify-center'>
                                <VaccinationStatus status={status} />
                            </div>
                            <div className='mt-4'>
                                <div>
                                    <PrimaryButton disabled={!enableClicking} outLine={true}
                                                   onClick={() => initiateMessagesView()}>
                                        <FontAwesomeIcon icon={faComment} className={'h-4 mr-1 mt-1'} />{t('MESSAGE')}
                                    </PrimaryButton>
                                </div>
                            </div>
                            {messageNavigateLoading && <div ><Spinner/>{messageNavigateLoading}</div>}
                        </div>
                    </InputGroup>
                </div>
                <div className={'lg:col-span-3 [&>div]:h-full'}>
                    <InputGroup>
                        <div className='grid grid-cols-1 sm:grid-cols-2 gap-6'>
                            <div>
                                <span className='text-[#8C8C8C] text-sm capitalize'>
                                    {primaryContact?.relationship?.[0].coding?.[0]?.display?.toLowerCase() || 'Parent'}
                                </span>
                                <div className='flex items-center mt-[7px]'>
                                    <FontAwesomeIcon icon={faUser} className={'h-4 mr-3 text-brand-color'} />
                                    <span className='text-base'>
                                        {primaryContact?.name?.text || '---'}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <span className='text-[#8C8C8C] text-sm'>{t('DATE_OF_BIRTH')}</span>
                                <div className='flex items-center mt-[7px]'>
                                    <FontAwesomeIcon icon={faCakeCandles} className={'h-4 mr-3 text-brand-color'} />
                                    <span className='text-base'>{dob}</span>
                                </div>
                            </div>
                            <div>
                                <span className='text-[#8C8C8C] text-sm capitalize'>
                                    {`${primaryContact?.relationship?.[0].coding?.[0]?.display?.toLowerCase() || 'Parent'}’s ${t('EMAIL')}`}
                                </span>
                                <div className='flex items-center mt-[7px]'>
                                    <FontAwesomeIcon icon={faEnvelope} className={'h-4 mr-3 text-brand-color'} />
                                    <span className='text-base'>
                                    {(Array.isArray(primaryContact?.telecom)? primaryContact?.telecom?.find((telecom: any) => telecom.system === 'EMAIL')?.value : primaryContact?.telecom?.value)|| '---'}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <span className='text-[#8C8C8C] text-sm'>{t('SCHOOL')}</span>
                                <div className='flex items-center mt-[7px]'>
                                    <span className='mr-3 text-brand-color'>
                                        <ISchool />
                                    </span>
                                    <span className='text-base'>
                                    {patient?.managingOrganization?.name || '---'}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </InputGroup>
                </div>
                {reviewImmunizations?.length > 0 && immunizationSubmissionCompletionStatus === "completed" && (
                    <div className='flex items-center justify-between px-10 py-6 lg:col-span-full bg-brand-color bg-opacity-10 flex-col sm:flex-row'>
                        <span className='text-base font-semibold'>{t('VACCINE')}</span>
                        <div className='flex items-center'>
                            <div className='mr-10 text-base'>
                                {/* <span className='font-medium'>{reviewImmunizations?.length} {t('VACCINES')} </span> */}
                                <span>{t('REVIEW_VACCINATION_RECORDS')}</span>
                            </div>
                            <div>
                                <PrimaryButton onClick={() => setShowReviewImmunizations(true)}
                                               disabled={!enableClicking || reviewImmunizations.every((immunization: Immunization) => immunization.status !== "completed")}>
                                    <div className='px-5'>
                                        {t('REVIEW')}
                                    </div>
                                </PrimaryButton>
                            </div>
                        </div>                                                         
                    </div>
                )}
                <div className={'lg:col-span-full'}>
                    <InputGroup>
                        <h4 className={'font-semibold '}>{t('VACCINES')}</h4>
                        {recommendationsLoading ? <div className='p-5 flex justify-center'><Spinner /></div> : (
                            <div>
                                <div className={'grid lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 grid-flow-row gap-5 mt-8'}>
                                    {patient?.immunizations?.map((immunization: any, index: number) =>
                                        <PractitionerImmunizationItem key={index} immunization={immunization} />

                                    )}
                                </div>
                                {patient?.immunizations?.length === 0 && <div className={'flex flex-row justify-center'}><span className={'text-gray-400'}>{t('NO_ITEMS_FOUND')}</span></div>}
                            </div>
                        )}
                    </InputGroup>
                </div>
                <div className={'lg:col-span-full pb-8'}>
                    <InputGroup>
                        <h4 className={'font-semibold '}>{t('DOCUMENTS')}</h4>
                        <div className={'hidden lg:block'}>
                            <DocumentsTable documents={patient?.documents} />
                        </div>
                        <div className={'lg:hidden'}>
                            <DocumentMobile documents={patient?.documents} />
                        </div>
                    </InputGroup>
                </div>
                {showReivewImmunizations && immunizationsRecommendations && (
                    <ImmunizationStepReview showModal={showReivewImmunizations} setShowModal={setShowReviewImmunizations}  reviewImmunizations={reviewImmunizations} immunizations={patient?.immunizations}/>

                )}
                <StatusChangeModal showModal={showStatusChangeModal} setShowModal={setShowStatusChangeModal} newStatus={patient?.immunizationStatus} originalStatus={state?.immunizationstatus} />
            </div>
        </div>
    );
}

export default StudentDetailPane;