import SearchContainer from '../dashboard-search/SearchContainer';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import InputGroup from "../group/InputGroup";
import {usePractitionerSearch} from "../../contexts/PractitionerSearchProvider";
import StudentsTable from "../table/student/StudentsTable";
import LoadingSplash from "../spinner/LoadingSplash";
import {FieldValues} from "react-hook-form";
import PatientFilterComponent from "../dashboard-search/PatientFilterComponent";
import StudentsMobile from "../table/student/StudentsMobile";
import {ampli} from "../../ampli";
import useAuth from "../../hooks/useAuth";
import SchoolFilter from "../dashboard-search/SchoolFilter";
import useProfessional from "../../hooks/useProfessional";
import SavedSearches from '../dashboard-search/SavedSearches';
import {useSavedSearch} from '../../contexts/practitioner/SaveSearchProvider';
import {SavedSearch} from '../../types/database/SharedTypes';
import Spinner from '../spinner/Spinner';

function Students() {
    const [t] = useTranslation();

    const { userGroup, userProfile } = useAuth();
    const [currentPage, setCurrentPage] = useState(1);
    const { patients, fetchingPatients } = usePractitionerSearch();
    const { setSearchTerm, setFilterParams, searchError, filterParams } = usePractitionerSearch();
    const [showFilter, setShowFilter] = useState(false);
    const { selectedSchools, setSelectedSchools, schoolList } = useProfessional();
    const { savedSearchList } = useSavedSearch()
    const updateVisibility = useCallback((visibility: boolean) => setShowFilter(visibility), []);
    const itemsPerPage = 15;
    const maximumSelectableSchools = 20;

    function handlePageChange(page: number) {
        setCurrentPage(page);
    }

    useEffect(() => {
    }, [fetchingPatients]);


    function searchChanged(data: FieldValues) {
        let searchTerm = data.searchTerm;
        ampli.track({ event_type: "Search by keyword" }, { extra: { keyword: data.searchTerm, userType: userGroup } });
        setSearchTerm && setSearchTerm(searchTerm);
    }

    useEffect(() => {
        if (showFilter) {
            ampli.track({ event_type: "Filter button tapped" }, { extra: { userType: userGroup } });
        }
    }, [showFilter]);

    useEffect(() => {
        if (selectedSchools) {
            setFilterParams && setFilterParams({ "organizationid:exact": selectedSchools });
        }
    }, []);

    const updateSearchCriteria = useCallback((data: FieldValues) => searchChanged(data), []);

    function submitSchools() {


    }

    function schoolSelectionChanged(name: string, value: string) {
        const selectionItems = value.split('|');
        if (setSelectedSchools) {
            setSelectedSchools(selectionItems);
            setFilterParams && setFilterParams({ "organizationid:exact": selectionItems });
        }
    }

    const selectedSchoolNames = useMemo(() => schoolList?.schools?.filter((school: any) => selectedSchools?.includes(school?.id)).map(school => school.name) || [], [schoolList, selectedSchools]
    );

    useEffect(() => {
        if (!savedSearchList?.loading && savedSearchList) {
            const defaultSearch = savedSearchList?.searches?.find((item: SavedSearch) => item?.isDefault)
            const updateFilterParams: any = {}
            if (defaultSearch && defaultSearch?.schools?.length > 0) {
                const schoolIds = defaultSearch.schools.map(school => school.id)
                updateFilterParams['organizationid:exact'] = schoolIds
                setSelectedSchools && setSelectedSchools(schoolIds)
            }
            if (defaultSearch?.status === 'pending') updateFilterParams['immunizationstatus:exact'] = 'yellow:pending'
            if (defaultSearch?.status === 'nonCompliant') updateFilterParams['immunizationstatus:exact'] = ['red:recommendation', 'red:evaluation']
            if (!defaultSearch && setSelectedSchools) {
                const schoolIds =  window.localStorage?.getItem(`${userProfile}-my-schools`)
                const updatedIds = schoolIds ? JSON.parse(schoolIds) : []
                setSelectedSchools(updatedIds)
            }
            
            setFilterParams && setFilterParams({ ...updateFilterParams })
        }
    }, [savedSearchList?.searches])

    return (
        <>
            <section>
                <div>
                    <h1 className="font-semibold text-xl">{t('STUDENTS')}</h1>
                </div>
                {savedSearchList?.loading ? (
                    <div className='mt-10 flex justify-center'>
                        <Spinner />
                    </div>
                ) : (
                    <>
                        <div className="flex flex-col mt-4">

                            <SearchContainer
                                onCriteriaChanged={updateSearchCriteria}
                                updateVisibility={updateVisibility}
                                searchError={searchError}
                                filterParams={filterParams}
                                isProfessional
                            >
                                <div className={'flex flex-row gap-2.5 mt-4'}>
                                    <div className={'flex-shrink-0'}>
                                        <SavedSearches/>
                                    </div>
                                    <div className=' w-[1px] bg-[#F4F4F4] h-auto'/>

                                    <div
                                        className="grid lg:auto-cols-auto grid-cols-1 lg:grid-cols-none gap-2.5 justify-start  grid-flow-row  lg:grid-flow-col   overflow-auto  ">

                                        {selectedSchoolNames.map((school: string) => {
                                            return <div key={school}
                                                        className="px-3.5 w-fit py-2 bg-orange-400 bg-opacity-10 rounded-sm  text-zinc-700 text-base font-normal leading-tight">{school}</div>
                                        })}
                                    </div>
                                </div>
                            </SearchContainer>
                            <PatientFilterComponent showFilter={showFilter} updateVisibility={updateVisibility}  />
                        </div>
                        {
                            (!selectedSchools || !(selectedSchools?.length > 0)) ?
                                <div className={'pb-8 h-screen sm:h-[calc(100vh_-_252px)] mt-4'}>
                                    <div className={'bg-lime-green px-10 py-4 bg-opacity-10'}>
                                        <span className={"text-zinc-700 text-base font-semibold leading-normal"}>{t("SELECT_SCHOOL")} </span>
                                        <span className={"text-zinc-700 text-base font-normal leading-normal"}>{t("no_more_than_number", {maximum_number:maximumSelectableSchools} )}</span>
                                    </div>
                                    <div className={'w-full bg-white px-4 h-[calc(100%_-_56px)]'}>
                                        <div className={'h-full'}>
                                            <SchoolFilter
                                                updateVisibility={submitSchools}
                                                setValue={schoolSelectionChanged}
                                                maximumSelectableSchools={maximumSelectableSchools}
                                                showNavigation={false}
                                                schoolIds={selectedSchools || []}
                                            />
                                        </div>
                                    </div>
                                </div> : <>
                                    <div className={'mt-4'}>
                                        <InputGroup>
                                            {!fetchingPatients ?
                                                <div>
                                                    <div className="lg:block hidden">
                                                        <StudentsTable
                                                            students={patients || []}
                                                        />
                                                    </div>

                                                    <div className="block lg:hidden">

                                                        <StudentsMobile students={patients || []} />
                                                    </div>
                                                </div> : <LoadingSplash showLogo={false} />}
                                        </InputGroup>
                                    </div>
                                </>
                        }
                    </>

                )}

            </section>

        </>
    );
}

export default Students;
