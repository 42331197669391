import React from 'react';

import Logo from "../../components/logo/Logo";
import heroImg from "../../assets/images/kids-sitting.png";
import BoldHeader from "../../components/header/BoldHeader";
import SubHeader from "../../components/header/SubHeader";
import {Outlet} from "react-router-dom";

function Register() {
    return (
        <div className="register-page ">
            <div className="register-header flex flex-row">
                <div className="h-48 basis-full lg:basis-6/12">
                    <div className={'container  flex flex-row py-10 mx-auto h-full justify-center'}>
                        <div className={'flex flex-row'}>
                            <Logo/>
                            <div className={'flex-col ml-12 justify-center hidden lg:flex'}>
                                <BoldHeader title={'Immunization Assistant'}/>
                                <SubHeader title={'Keeping Kids In School'}/>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="h-48 lg:block hidden basis-8/12 relative">
                    <div
                        className={'absolute object-cover object-left-top bg-gradient-to-r' +
                            ' from-white via-transparent h-full w-full top-0 left-0'}/>

                    <img src={heroImg} className="hero-image w-full h-full  object-cover object-left-top" alt={'hero'}/>
                </div>
            </div>
            <div className={'register-body max-w-3xl mx-auto py-11 px-4'}>
                <Outlet/>
            </div>

        </div>
    );
}

export default Register;
