import TableView from "../TableView";
import StudentMobileItem from "./StudentMobileItem";

function StudentsMobile(props: { students: any[] }) {
    const { students } = props;
    return (
        <TableView showPagination={true}>
            {students.map(({
                name,
                pronounsdisplay,
                grade,
                id,
                organizationname,
                immunizationstatus,
                photo,
            }) =>
                <StudentMobileItem key={id} student={{ name, pronounsdisplay, grade, id, organizationname, immunizationstatus, photo }} />
            )}
        </TableView>
    );
}

export default StudentsMobile;