import {ReactNode, useCallback, useState} from 'react';

interface AccordionProps {
  children: React.ReactNode;
}

const Accordion: React.FC<AccordionProps> = ({ children }) => {
  return <div>{children}</div>;
};

interface AccordionItemProps {
  title: ReactNode;
  children: ReactNode;
  openIcon?: ReactNode;
  closeIcon?: ReactNode;
  isIconStart?: boolean,
  isDefaultOpen?: boolean
}

const AccordionItem = ({
  title,
  openIcon,
  closeIcon,
  children,
  isIconStart,
  isDefaultOpen
}: AccordionItemProps) => {
  const [isOpen, setIsOpen] = useState(isDefaultOpen ?? true);

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <div>
      <button
        type={'button'}
        className={`py-2 flex ${isIconStart ? "items-start" : "items-center"} justify-between w-full text-left font-semibold focus:outline-none`}
        onClick={handleToggle}
      >
        <span className="text-base font-semibold">{title}</span>
        <span className="text-orange-400">{isOpen ? closeIcon : openIcon}</span>
      </button>
      {isOpen && <div className="py-2">{children}</div>}
    </div>
  );
};

export { Accordion, AccordionItem };
