import React from "react";
import LoadingSplash from "../components/spinner/LoadingSplash";
import {usePatient} from "../contexts/practitioner/PatientProvider";


export default function withPatientDetail(Component: typeof React.Component) {
    return (props: any) => {
        const {patient, patientProfilePhoto, patientStudentId} = usePatient();


            if (!patient) {
                return (<LoadingSplash showLogo={false}/>);
            } else {
                return <Component {...props}></Component>;
            }

    }
}
