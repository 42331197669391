import React, {createContext, useContext, useEffect, useState} from 'react';

import {API} from "aws-amplify";
import {UserGroup} from "./AmplifyApiProvider";
import axios from "axios";
import {AuthContext} from "./AuthContext";
import {FileServerReply} from "../types/database/SharedTypes";
import {updateSendBirdMetadata} from "../util/sendBird";
import {detectImageMimeType} from '../util/photo/parseDatabasePhotos'
import {AWSConfigS} from "../App";
import {useTranslation} from "react-i18next";

export interface Professional {
    photo: FileDetail[];
    active: boolean;
    created: CreationObject;
    filter_string: string;
    identifier: Identifier[];
    pronouns: Coding[];
    name: HumanName[];
    owner: string;
    communication: Communication;
    resourceType: string;
    status: {onboarding: OnboardingStatus};
    subject: string;
    telecom: ContactPoint[];
    tenant: string;
    type: string;
}
export interface FileDetail {
    contentType: string;
    creation: Date;
    url: string;
}
export interface OnboardingStatus {
    addressComplete: StatusInfo;
    identificationComplete: StatusInfo;
    photoComplete: StatusInfo;
    profileComplete: StatusInfo;
    signatureComplete: StatusInfo;
}
export interface ContactPoint {
    // from Element: extension
    system? : "phone" | "fax" | "email" | "pager" | "url" | "sms" | "other"; // I phone | fax | email | pager | url | sms | other
    value : string; // I The actual contact point details
    use : "home" | "work" | "temp" | "old" | "mobile"; // home | work | temp | old | mobile - purpose of this contact point
    rank? : number; // Specify preferred order of use (1 = highest)
    period? :  Period;  // Time period when the contact point was/is in use
}
export interface StatusInfo {
    status: boolean;
}
export interface HumanName {
    use : "usual" | "official" | "temp" | "nickname" | "anonymous" | "old" | "maiden"; // usual | official | temp | nickname | anonymous | old | maiden
    text : string; // Text representation of the full name
    family : string; // Family name (often called 'Surname')
    given : string[]; // Given names (not always 'first'). Includes middle names
    prefix : string[]; // Parts that come before the name
    suffix : string[]; // Parts that come after the name
    period :  Period; // Time period when name was/is in use
}
export interface CreationObject {
    date: Date;
    sourceType: string;
    source:string;
}
export interface Communication {
    coding: Coding[];
    text: string;
}
export interface Identifier {
    use? : "usual" | "official" | "temp" | "secondary" | "old";
    type :  CodeableConcept[]; // Description of identifier
    system : string; // The namespace for the identifier value
    value : string; // I The value that is unique
    period : Period; // Time period when id is/was valid for use
    assigner : string; // Organization that issued id (may be just text)
}
export interface CodeableConcept {
    coding: Coding;
    text: string;
}
export interface Coding {
    system?: string;
    version?: string;
    code: string;
    display: string;
    userSelected?: boolean;
}

export interface Period {
    start: Date;
    end: Date;
}

export interface ServerReply<T> {
    statusCode: number;
    body: T;
}

// Changes to onboarding status items does not need to be changed here too with these dynamic types
type OnboardingKeys = keyof OnboardingStatus;
export type OnboardingFlatStatuses = { [key in OnboardingKeys]: boolean };
interface schoolListType {
    loading: boolean,
    schools: any[],
    error: any
}
interface SavedSchoolsListType {
    loading: boolean,
    schools: any[],
    error: any
}

export const ProfessionalContext = createContext<{
    professional?: Professional,
    profilePhoto?: string,
    errorMessage?: string,
    services?: any[],
    setSchoolInformation?: (schoolId: string, roomID: string, profile?: UserGroup) => Promise<any>,
    uploadFId?: (facId: File, facIdPath: string, profile?: UserGroup) => Promise<any>,
    uploadSignature?: (signatureFile: File, signaturePath: string, profile?: UserGroup) => Promise<any>,
    uploadProfilePhoto?: (profilePhoto: File, profilePhotoPath: string, profile?: UserGroup) => Promise<any>,
    updateProfilePhoto?: (profilePhoto: string, profilePhotoPath: string, profile?: UserGroup) => Promise<any>,
    addFIdMetadata?: (fid: string, profile?: UserGroup) => Promise<any>,
    addSignatureMetadata?: (profile ?: UserGroup) => Promise<any>,
    addPractitionerProfileMetadata?: (profile?: UserGroup) => Promise<any>,
    getPatients?: () => Promise<ServerReply<{ items: any[] }>>,
    refreshServices?: (profile?: UserGroup) => Promise<any>,
    updateServiceAvailability?: (availableTime: any[], notAvailable: any[], serviceId?: string,profile?: UserGroup) => Promise<any>,
    addNewVacation?: (vacationStart: string, vacationEnd: string, serviceId: string, profile?: UserGroup) => Promise<any>,
    updateVacation?: (vacationStart: string, vacationEnd: string, serviceId: string, vacationIndex: any, profile?: UserGroup) => Promise<any>,
    cancelVacation?: (serviceId: string, notAvailableTime: any[], profile?: UserGroup) => Promise<any>,
    getPatient?: (patientId: string) => Promise<ServerReply<any>>,
    searchPatients?: (searchQuery?: string) => Promise<ServerReply<{ items: any[] }>>,
    getPhoto?: (subjectID: string, photoPath: string) => Promise<any>,
    filterPatients?: (filterQuery?: Record<string, string| string[]>) => Promise<ServerReply<{ items: any[] }>>,
    onboardingStatuses?: OnboardingFlatStatuses,
    setPractitionerProfile?: (profession: Coding, services: Coding[], aboutMe: string, interest: string, genderCode: Coding, pronouns: Coding, race: Coding, communication: Communication, profile?: UserGroup) => Promise<any>
    getSchools?: () => Promise<{ body: { items: { id: string, name: string }[] }, statusCode: number }>,
    schoolList?: schoolListType,
    selectedSchools?: string[],
    viewableSchools: any[];
    setViewableSchools?: (schools: any[] ) => void,
    setSelectedSchools?: (schools: string[] | undefined) => void,
    updateProfessionalPhoto?: () => void
}>({viewableSchools: []});

function ProfessionalProvider(props: { children: React.ReactElement<any, any> }) {
    const {children} = props;
    const {userProfile} = useContext(AuthContext);
    const [t] = useTranslation();
    const [professional, setProfessional] = useState<Professional>();
    const [services, setServices] = useState<any[]>();
    const [schoolList, setSchoolList] = useState<schoolListType>({ loading: false, schools: [], error: null });
    const [onboardingStatus, setOnboardingStatus] = useState<OnboardingFlatStatuses>();
    const [profilePhoto, setProfilePhoto] = useState<string>();
    const [selectedSchools, setSelectedSchools] = useState<string[] | undefined>();
    const [viewableSchools, setViewableSchoolsProx] = useState<any[]>([]);
    const [savedSchools, setSavedSchools] = useState<SavedSchoolsListType>({ loading: false, schools: [], error: null })
    const [errorMessage, setErrorMessage] = useState<string>();


    async function getProfessionalInfo(profile = userProfile): Promise<ServerReply<Professional>> {
        const apiName = 'LAUSDPractitionerEndpoint';
        const path = `/practitioner/onboarding/practitioner/${profile}`;
        const myInit = {
            body: {},
            headers: {}
        };
        return API.get(apiName, path, myInit);
    }

    async function getPatients(): Promise<ServerReply<any>> {
        const apiName = 'LAUSDPractitionerEndpoint';
        const path = `/practitioner/filter/patients?`;
        const myInit = {
            body: {},
            headers: {}
        };
        return API.get(apiName, path, myInit);
    }
    async function updateSavedSchools(schoolObjects: any[]) {
        const apiName = 'LAUSDAppEndpoint';
        const path = `/profile`;
        const myInit = {
            body: [
                { op: "replace", path: "/preferences/schools", value: schoolObjects },
            ],
            headers: {}
        };
        return API.patch(apiName, path, myInit);
    }
    async function addSavedSchools(schoolObjects: any[]) {
        const apiName = 'LAUSDAppEndpoint';
        const path = `/profile`;
        const myInit = {
            body: [
                { op: "add", path: "/preferences/schools", value: schoolObjects },
            ],
            headers: {}
        };
        return API.patch(apiName, path, myInit);
    }
    async function getProfile(): Promise<ServerReply<any>> {
        const apiName = 'LAUSDAppEndpoint';
        const path = `/profile`;
        const myInit = {
            body: {},
            headers: {}
        };
        return API.get(apiName, path, myInit);
    }


    async function setViewableSchools(schoolObjects: any[]){
        setViewableSchoolsProx(schoolObjects)
        if (!savedSchools.loading && ( !savedSchools.schools )){
            savedSchools.loading = true; // Make sure school adding cant be spammed
            await addSavedSchools(schoolObjects);
            savedSchools.schools = []; // Make sure schools is now defined that we added to the path
            savedSchools.loading = false;  // Release Lock on schools
        } else {
            await updateSavedSchools(schoolObjects);
        }

    }

    useEffect(() => {
        setSavedSchools(prevState => ({
            ...prevState,
            loading: true
        }));
        getProfile().then((response: any) => {
            setSavedSchools({
                loading: false,
                error: null,
                schools: response?.body?.preferences?.schools
            });
            setViewableSchoolsProx(response?.body?.preferences?.schools || [])
        }).catch((error: any) => {
            setSavedSchools(prevState => ({
                ...prevState,
                loading: false,
                error: error?.message || 'Unable to get the school list'
            }));
        });
    }, [])
    async function filterPatients(filterParams?: Record<string,string | string[]>): Promise<ServerReply<{ items: any[] }>> {

        const apiName = 'LAUSDPractitionerEndpoint';
        const path = `/practitioner/filter/patients`;
        const myInit = {
            body: {},
            headers: {},
            queryStringParameters: filterParams,
        };
        return API.get(apiName, path, myInit);
    }

    async function getPatient(patientId: string): Promise<ServerReply<any>> {
        const apiName = 'LAUSDPractitionerEndpoint';
        const path = `/practitioner/patient/${patientId}`;
        const myInit = {
            body: {},
            headers: {}
        };
        return API.get(apiName, path, myInit);
    }
    async function searchPatients(searchQuery?: string): Promise<ServerReply<{ items: any[] }>> {
        const apiName = 'LAUSDPractitionerEndpoint';
        const path = `/practitioner/patients?name:contains=${searchQuery}`;
        const myInit = {
            body: {},
            headers: {}
        };
        return API.get(apiName, path, myInit);
    }
    function getOnboardingStatuses(){



        let onboardingStatus: OnboardingFlatStatuses | undefined;

        if(professional){
            onboardingStatus = {} as OnboardingFlatStatuses;
            for (const professionalStatus in professional.status.onboarding) {
                onboardingStatus[professionalStatus as keyof OnboardingStatus] = professional.status.onboarding[professionalStatus as keyof OnboardingStatus].status;
            }
        }
        return onboardingStatus;
    }

    async function getSchools(): Promise<{ body: { items: [{ id: string, name: string }] }, statusCode: number }> {
        const apiName = 'LAUSDPractitionerEndpoint';
        const path = `/practitioner/organizations?type=edu`;
        const myInit = {
            body: {},
            headers: {}
        };
        return API.get(apiName, path, myInit);
    }

    async function getPhoto(subjectID: string, photoPath: string): Promise<FileServerReply<any>> {
        const apiName = 'LAUSDDocumentEndpoint';
        const path = `/practitioner/document/${subjectID}?path=${photoPath}`;
        const myInit = {
            body: {},
            headers: {
                Accept: "image/*"
            }
        };
        return API.get(apiName, path, myInit).catch((err) => {
            //Unable to get photo
        });;
    }

    function isOnboardingComplete() {
        let completionStatus = true;
        if (professional) {
            let onboardingFlatStatuses = getOnboardingStatuses()!;
            for (const onboardingStatus in onboardingFlatStatuses) {
                completionStatus = completionStatus && onboardingFlatStatuses[onboardingStatus as keyof OnboardingStatus];
            }
            return completionStatus;
        } else {
            return undefined;
        }
    }
    function setPractitionerProfile(profession: Coding, services: Coding[], aboutMe: string, interest: string, genderCode: Coding, pronouns: Coding, race: Coding, communication: Communication, profile = userProfile) {
        const apiName = 'LAUSDPractitionerEndpoint';
        const path = `/practitioner/onboarding/practitioner/${profile}`;
        const myInit = {
            body:[


                    // [{"op":"add","path":"\/gender","value":{"display":"Other","system":"http:\/\/terminology.hl7.org\/CodeSystem\/v2-0001","code":"O"}},{"op":"add","path":"\/interests","value":"Fghfghfgh"},{"op":"add","path":"\/serviceProvisionCode","value":[{"text":"true","coding":{"display":"Telemedicine","system":"http:\/\/www.viveconcierge.com\/CodeSystem\/service-provision","code":"TEL"}}]},{"op":"add","path":"\/pronouns","value":{"display":"They, Them, Their, Theirs, Themself","system":"http:\/\/loinc.org","code":"LA29520-6"}},{"op":"add","path":"\/race","value":{"display":"Alaska Native","system":"http:\/\/terminology.hl7.org\/CodeSystem\/v3-Race","code":"1735-0"}},{"op":"add","path":"\/profession","value":{"display":"Doctor of Osteopathy","system":"http:\/\/terminology.hl7.org\/CodeSystem\/v2-0360","code":"DO"}},{"op":"add","path":"\/aboutMe","value":"Gfhfghfgh"},{"op":"add","path":"\/communication","value":[{"code":{"text":"communication","coding":[{"display":"Afrikaans","system":"https:\/\/www.iso.org\/iso-639-language-codes.html","code":"af"}]}}]}]

                      {"op": "add", "path": "/gender", "value": genderCode},
         {"op": "add", "path": "/aboutMe", "value": aboutMe},
        {"op": "add", "path": "/interests", "value": interest},
        {"op": "add", "path": "/profession", "value": profession},
        {"op": "add", "path": "/serviceProvisionCode", "value": services},
        {"op": "add", "path": "/pronouns", "value": pronouns},
        {"op": "add", "path": "/communication", "value": [{code:communication}]},
        // {"op": "replace", "path": "/profile/expertise", "value": expertise},
        {"op": "add", "path": "/race", "value": race}
]

            ,
            headers: {}
        };
        return API.patch(apiName, path, myInit);
    }
    function setSchoolInformation(schoolID: string, roomId:string, profile=userProfile) {
        const apiName = 'LAUSDPractitionerEndpoint';
        const path = `/practitioner/onboarding/practitioner/${profile}`;
        const myInit = {
            body:
                [
                    {"op": "add", "path": "/providedBy", "value": {"reference": schoolID, "type": "Organization"}},
                    {"op": "add", "path": "/extraDetails", "value": roomId}

                ]
            ,
            headers: {}
        };
        return API.patch(apiName, path, myInit);

    }

    async function uploadFId(facId: File, facIdPath: string, profile = userProfile): Promise<any> {
        const apiName = 'LAUSDDocumentEndpoint';
        const path = `/practitioner/document/${profile}?path=${facIdPath}`;

        const amplifyEndpoint = AWSConfigS.API.endpoints.find((endpoint: { name: string }) => endpoint.name === apiName);
        const url = (amplifyEndpoint?.endpoint || '') + path;
        const headers: any = amplifyEndpoint?.custom_header && await amplifyEndpoint?.custom_header();
        if (headers) {
            headers['Content-Type'] = facId.type;
        }
        return axios.put(url, facId, {headers});
    }


    async function uploadSignature(signatureFile: File, signaturePath: string, profile = userProfile): Promise<any> {
        const apiName = 'LAUSDDocumentEndpoint';
        const path = `/practitioner/document/${profile}?path=${signaturePath}`;

        const amplifyEndpoint = AWSConfigS.API.endpoints.find((endpoint: { name: string }) => endpoint.name === apiName);
        const url = (amplifyEndpoint?.endpoint || '') + path;
        const headers: any = amplifyEndpoint?.custom_header && await amplifyEndpoint?.custom_header();
        if (headers) {
            headers['Content-Type'] = signatureFile.type;
        }
        return axios.put(url, signatureFile, {headers});
    }

    async function uploadProfilePhoto(profilePhoto: File, profilePhotoPath: string, profile = userProfile): Promise<any> {
        const apiName = 'LAUSDDocumentEndpoint';
        const path = `/practitioner/document/${profile}?path=${profilePhotoPath}`;

        const amplifyEndpoint = AWSConfigS.API.endpoints.find((endpoint: { name: string }) => endpoint.name === apiName);
        const url = (amplifyEndpoint?.endpoint || '') + path;
        const headers: any = amplifyEndpoint?.custom_header && await amplifyEndpoint?.custom_header();
        if (headers) {
            headers['Content-Type'] = profilePhoto.type;
        }
        return axios.put(url, profilePhoto, {headers});
    }


    async function updateProfilePhoto(profilePhoto: string, profilePhotoPath: string, profile = userProfile): Promise<any> {
        const apiName = 'LAUSDDocumentEndpoint';
        const path = `/practitioner/document/${profile}?path=${profilePhotoPath}`;
        const amplifyEndpoint = AWSConfigS.API.endpoints.find((endpoint: { name: string }) => endpoint.name === apiName);
        const url = (amplifyEndpoint?.endpoint || '') + path;
        const headers: any = amplifyEndpoint?.custom_header && await amplifyEndpoint?.custom_header();
        if (headers) {
            const type = detectImageMimeType(profilePhoto);
            headers['Content-Type'] = type;
        }
        return axios.put(url, profilePhoto, {headers});
    }

    async function addFIdMetadata(fid: string, profile = userProfile): Promise<any> {
        const apiName = 'LAUSDPractitionerEndpoint';
        const path = `/practitioner/onboarding/identification/${profile}`;
        const myInit = {
            body: {number: fid, type: "FI", "issuer": "LAUSD",},
            headers: {}
        };
        return API.post(apiName, path, myInit);
    }


    async function patchExistingAvailability(availableTime: any[], notAvailable: any[], serviceId: string,profile = userProfile): Promise<any> {
        const apiName = 'LAUSDPractitionerEndpoint';
        const path = `/practitioner/service/${profile}/${serviceId}`;
        const myInit = {
            body: [{"op":"replace","path":"/availability/0/availableTime","value": availableTime}],
            headers: {}
        };
        return API.patch(apiName, path, myInit);
    }
    async function newAvailability(availableTime: any[], notAvailable: any[], profile = userProfile): Promise<any> {
        const apiName = 'LAUSDPractitionerEndpoint';
        const path = `/practitioner/service/${profile}`;
        const myInit = {
            body: {"availability": [{availableTime}]},
            headers: {}
        };
        return API.post(apiName, path, myInit);
    }

    async function updateServiceAvailability(availableTime: any[], notAvailable: any[], serviceId?: string,profile = userProfile): Promise<any> {
       if(serviceId){
           return patchExistingAvailability(availableTime, notAvailable, serviceId,profile);
       } else {
           return newAvailability(availableTime, notAvailable,profile).then(async (res: any) => {
               await refreshServices();
               return res;
           });
       }
    }


    async function addNewVacation(vacationStartDate: string, vacationEndDate: string, serviceId: string,profile = userProfile): Promise<any> {
        const apiName = 'LAUSDPractitionerEndpoint';
        const path = `/practitioner/service/${profile}/${serviceId}`;
        const myInit = {
            body: [{"op": "add", "path": "/availability/0/notAvailableTime", value:[{"description": "Vacation", "during": {"start": vacationStartDate, "end": vacationEndDate}}]}],
            headers: {}
        };
        return API.patch(apiName, path, myInit);
    }
    async function updateVacation(vacationStartDate: string, vacationEndDate: string, serviceId: string, vactionIndex: any, profile = userProfile): Promise<any> {
        const apiName = 'LAUSDPractitionerEndpoint';
        const path = `/practitioner/service/${profile}/${serviceId}`;
        const myInit = {
            body: [{
                op: "replace",
                path: `/availability/0/notAvailableTime/${vactionIndex}`,
                value:{
                    description: "Vacation",
                    during: {
                        start: vacationStartDate,
                        end: vacationEndDate
                    }
                }
            }],
            headers: {}
        };
        return API.patch(apiName, path, myInit);
    }
    async function cancelVacation(serviceId: string, notAvailableTime: any[], profile = userProfile): Promise<any> {
        const apiName = 'LAUSDPractitionerEndpoint';
        const path = `/practitioner/service/${profile}/${serviceId}`;
        const myInit = {
            body: [{
                op: "replace",
                path: `/availability/0/notAvailableTime`,
                value: notAvailableTime
            }],
            headers: {}
        };
        return API.patch(apiName, path, myInit);
    }
    async function getServices(profile = userProfile): Promise<ServerReply<{items: {id:string}[]}>> {
        const apiName = 'LAUSDPractitionerEndpoint';
        const path = `/practitioner/services/${profile}`;
        const myInit = {
            body: {},
            headers: {}
        };
        return API.get(apiName, path, myInit);
    }
    async function refreshServices(profile = userProfile): Promise<void> {
       return getServices(profile)
           .then(({body: {items}}) => {
               setServices(items);
           })
    }

    async function addSignatureMetadata( profile = userProfile): Promise<any> {
        const apiName = 'LAUSDPractitionerEndpoint';
        const path = `/practitioner/onboarding/subscription/signature/${profile}`;
        const myInit = {
            body: {"documentId": "ViveProviderSubscriptionAgreement", "version": "0.01", "productName": "Basic Subscription"},
            headers: {}
        };
        return API.post(apiName, path, myInit);
    }

    async function addPractitionerProfileMetadata(profile = userProfile): Promise<any> {
        const apiName = 'LAUSDPractitionerEndpoint';
        const path = `/practitioner/onboarding/photo/${profile}`;
        const myInit = {
            body: {},
            headers: {}
        };
        return API.post(apiName, path, myInit);
    }

    function updateProfessionalPhoto() {
        if (professional) {
            professional?.photo?.sort((a: any, b: any) => Number(new Date(b?.creation)) - Number(new Date(a?.creation)))
            getPhoto(professional.subject, professional.photo?.[0].url).then(value => {
                setProfilePhoto(value?.file)
            })
        }
    }

    useEffect(() => {
        setSchoolList(prevState => ({
            ...prevState,
            loading: true
        }));
        getSchools().then(({body: {items}}) => {
            const sortedItems = items?.sort((a, b) => (a?.name < b?.name) ? -1 : (a?.name > b?.name) ? 1 : 0);
            setSchoolList({
                loading: false,
                schools: sortedItems,
                error: null
            });
        }).catch((error: any) => {
            setSchoolList({
                loading: false,
                schools: [],
                error: error?.message || 'Unable to get the school list'
            });
        })
    }, []);

    useEffect(() => {
        if(!services && userProfile) {
            getServices().then(({body: {items}}) => {
                setServices(items);
            })
        }

    }, [services, userProfile]);

    useEffect(() => {
        if(selectedSchools && selectedSchools.length > 0 && userProfile) {
            sessionStorage.setItem("selectedSchools", JSON.stringify(selectedSchools));
        }
    }, [selectedSchools]);

    useEffect(() => {
        if (userProfile && !professional) {

            getProfessionalInfo().then((value) => {
                setProfessional(value.body);
                updateSendBirdMetadata(userProfile, value.body.name?.[0].text, value.body.photo?.[0].url);
            }).catch((error) => {
                console.log(error);
                setErrorMessage(t('THERE_WAS_AN_ISSUE_ACCESSING_YOUR_ACCOUNT_PLEASE_CONTACT_LAUSD_FOR_MORE_INFORMATION'));
            });
        }
    }, [userProfile, professional]);
    useEffect(() => {
        if(professional && !profilePhoto){
            updateProfessionalPhoto();
        }
    }, [professional, profilePhoto]);

    return (
        <ProfessionalContext.Provider
            value={{
                professional,
                schoolList,
                profilePhoto,
                services,
                selectedSchools,
                viewableSchools,
                onboardingStatuses: onboardingStatus,
                setViewableSchools,
                setSchoolInformation,
                setSelectedSchools,
                setPractitionerProfile,
                addFIdMetadata,
                addPractitionerProfileMetadata,
                getPatient,
                getPhoto,
                refreshServices,
                updateServiceAvailability,
                addNewVacation,
                updateVacation,
                cancelVacation,
                searchPatients,
                filterPatients,
                getPatients,
                addSignatureMetadata,
                uploadFId,
                uploadProfilePhoto,
                uploadSignature,
                updateProfilePhoto,
                updateProfessionalPhoto
            }}>
            {children}
        </ProfessionalContext.Provider>
    );
}

export default ProfessionalProvider;
