import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next';
import {usePatientAppointment} from '../../contexts/patient/AppointmentProvider';
import InputGroup from '../group/InputGroup';
import LoadingSplash from '../spinner/LoadingSplash';
import AppointmentStatus from '../status/AppointmentStatus';
import AppointmentsTable from '../table/patientAppointment/AppointmentsTable';
import AppointmentDetail from './AppointmentDetail';
import AppointmentProfileSummary from '../status/AppointmentProfileSummary';
import CenterModal from '../dashboard-search/CenterModal';
import {useWindowSize} from '../../hooks/useWindowSize';
import PrimaryButton from '../button/PrimaryButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faStethoscope} from '@fortawesome/free-solid-svg-icons';
import PatientAppointmentFilterComponent from '../dashboard-search/PatientAppointmentFilterComponent';
import {IFilter, ISchool} from '../Icons';
import usePatient from '../../hooks/usePatient';
import TableMobile from '../table/TableMobile';
import Success from '../modal/Success';

const Appointments = () => {
    const [t, i18n] = useTranslation();
  const { appointmentList } = usePatientAppointment();
  const { patient } = usePatient()
  const [showSelectAppointment, setShowSelectAppointment] = useState(null)
  const { width } = useWindowSize();

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<Record<string, string>>({});
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [isSuccessCancel, setIsSuccessCancel] = useState<boolean>(false)

  useEffect(() => {
    if (appointmentList?.loading) return

    let updatedAppointments = JSON.parse(JSON.stringify(appointmentList?.appointments))

    if (Object.keys(filterParams).length > 0) {
      updatedAppointments = updatedAppointments
          .filter((appointment: any) => filterParams?.date ? new Date(appointment?.start).toLocaleDateString(i18n.resolvedLanguage, {
              month: 'short',
              day: 'numeric',
              year: 'numeric'
          }) === new Date(filterParams?.date).toLocaleDateString(i18n.resolvedLanguage, {
              month: 'short',
              day: 'numeric',
              year: 'numeric'
          }) : true)
      setFilteredAppointments(updatedAppointments || [])
      filterParams?.sort === 'oldest'
        ? updatedAppointments.sort((a: any, b: any) => Number(new Date(a?.start)) - Number(new Date(b?.start)))
        : updatedAppointments.sort((a: any, b: any) => Number(new Date(b?.start)) - Number(new Date(a?.start)))
    } else {
      updatedAppointments.sort((a: any, b: any) => Number(new Date(b?.start)) - Number(new Date(a?.start)));
    }

    setFilteredAppointments(updatedAppointments || [])
  }, [appointmentList, filterParams])

  return (
    <section>
      <div className="flex items-center justify-between max-[1022px]:p-2">
        <h1 className="font-semibold text-2xl text-heading max-[1022px]:flex-grow">
          {t('APPOINTMENTS')}
        </h1>
        <div className='w-[130px]'>
          <PrimaryButton
            outLine
            onClick={() => setShowFilter(true)}
            disabled={appointmentList?.loading}
          >
            <div className='relative flex items-center justify-center h-full'>
              {Object.keys(filterParams).length > 0 && <div className='absolute top-[-8px] right-[-8px] rounded-full w-4 h-4 bg-lime-green' />}
              <IFilter />
              <span className="ml-2">{t('FILTERS')}</span>
            </div>
          </PrimaryButton>
        </div>
      </div>

      <div className={'flex mt-8'}>
        <div className='flex-1'>
          <InputGroup>
            {!appointmentList?.loading ? (
              <div>
                <div className="lg:block hidden">
                  <AppointmentsTable
                    appointments={filteredAppointments || []}
                    showSelectAppointment={showSelectAppointment}
                    setShowSelectAppointment={setShowSelectAppointment}
                  />
                </div>
                <div className="block lg:hidden">
                  <TableMobile showPagination={true}>
                    {filteredAppointments?.map((appointment: any) => {
                      const {
                        id,
                        status
                      } = appointment;

                      return (
                        <div
                          key={id}
                          onClick={() => setShowSelectAppointment(appointment)}
                          className='border-b border-b-neutral-100'
                        >
                          <div className="p-4">
                            <div className="flex flex-grow flex-col break-words items-top">
                              <AppointmentProfileSummary participant={appointment?.participant} />
                            </div>
                            <div className='flex items-center mb-5'>
                              <FontAwesomeIcon
                                icon={faStethoscope}
                                className='text-brand-color mr-3'
                              />
                              <span className='text-base text-heading'>{appointment?.participant?.profession?.display || '---'} {appointment?.participant?.profession?.code ? `(${appointment?.participant?.profession?.code})` : ''}</span>
                            </div>
                            <div className='flex items-center mb-5'>
                              <span className="text-brand-color mr-3">
                                <ISchool />
                              </span>
                              <span className='text-base text-heading'>{patient?.managingOrganization?.name || '---'}</span>
                            </div>
                            <div className='flex mb-5'>
                              <div className="bg-calendar-icon w-4 h-4 bg-contain mr-3 mt-1" />
                              <div className='flex flex-col'>
                                <span className='text-heading text-base font-semibold'>
                                  {new Date(appointment.start).toLocaleDateString(i18n.resolvedLanguage, {
                                      weekday: 'short',
                                      month: 'short',
                                      day: 'numeric'
                                  })},
                                  <span
                                      className='text-heading text-base font-normal'>{` ${new Date(appointment.start).toLocaleTimeString(i18n.resolvedLanguage, {
                                      hour: '2-digit',
                                      minute: '2-digit'
                                  })}`}</span>
                                </span>
                              </div>
                            </div>
                            <div className="flex items-center gap-2">
                              <AppointmentStatus
                                appointmentstatus={status}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </TableMobile>
                </div>
              </div>
            ) : (
              <LoadingSplash showLogo={false} />
            )}
          </InputGroup>
        </div>
        {showSelectAppointment && (
          <>
            {(width || 0) > 1024 ? (
              <div className='w-[385px] ml-5'>
                <InputGroup className='h-[calc(100vh-200px)]'>
                  <AppointmentDetail
                    appointment={showSelectAppointment}
                    onClose={() => setShowSelectAppointment(null)}
                    setIsSuccessCancel={setIsSuccessCancel}
                  />
                </InputGroup>
              </div>
            ) : (
              <CenterModal
                isOpen={showSelectAppointment}
                onClose={() => setShowSelectAppointment(null)}
              >
                <div className='w-[90vw] p-6'>
                  <AppointmentDetail
                    appointment={showSelectAppointment}
                    onClose={() => setShowSelectAppointment(null)}
                    setIsSuccessCancel={setIsSuccessCancel}
                  />
                </div>
              </CenterModal>
            )}
          </>
        )}
      </div>
      <PatientAppointmentFilterComponent
        showFilter={showFilter}
        updateVisibility={() => setShowFilter(false)}
        setFilterParams={setFilterParams}
      />
      {isSuccessCancel && (
        <Success
          open={isSuccessCancel}
          onAccept={() => setIsSuccessCancel(false)}
          content={t('APPOINTMENT_CANCELED', 'Appointment canceled')}
          acceptBtnText={t('OK')}
          onClose={() => setIsSuccessCancel(false)}
        />
      )}
    </section>
  );
};

export default Appointments;
