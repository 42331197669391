import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWarning} from '@fortawesome/free-solid-svg-icons';
import CenterModal from "../dashboard-search/CenterModal";

function Confirm(props: {
  open: boolean,
  content: string,
  onClose: () => void,
  onCancel: () => void,
  onAccept: () => void,
  cancelBtnText?: string,
  acceptBtnText?: string
}) {
  const { open, content, onClose, onCancel, onAccept, cancelBtnText, acceptBtnText } = props;
  return (
    <CenterModal
      isOpen={open}
      onClose={() => onClose()}
    >
      <div className='p-5'>
        <div className='flex justify-center mb-4'>
          <FontAwesomeIcon
            icon={faWarning}
            className='text-[#FF3700] w-8 h-8'
          />
        </div>
        <p className='text-base font-semibold text-center mb-5'>{content}</p>
        <div className='flex justify-center'>
          <button
            className='border border-brand-color text-brand-color p-2 w-[150px] px-5 text-sm'
            onClick={() => onCancel()}
          >
            {cancelBtnText ?? 'Cancel'}
          </button>
          <button
            className='bg-brand-color text-white w-[150px] p-2 mx-3 text-sm'
            onClick={() => onAccept()}
          >
            {acceptBtnText ?? 'Accept'}
          </button>
        </div>
      </div>
    </CenterModal>
  )
}

export default Confirm
