import {memo, useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import CenterModal from '../dashboard-search/CenterModal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/free-solid-svg-icons';
import {Controller, FieldValues, useForm} from 'react-hook-form';
import Calendar from '../calendar';
import useProfessional from "../../hooks/useProfessional";
import SubmitPrimaryButton from "../button/SubmitPrimaryButton";
import {getDateFromString} from '../../util/date/convertDateString';

function Vacation(props: {
  displayModal: boolean;
  modalVisibilityChanged?: (visible: boolean) => void;
  editVactionIndex?: any,
  editVaction?: any,
  disabledDates?: Date[]
}) {
  const { displayModal = false, modalVisibilityChanged, editVactionIndex, editVaction, disabledDates } = props;

  const [t] = useTranslation();
  const isEditMode = editVactionIndex !== null
  const [showModal, setShowModal] = useState(displayModal);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    getValues,
    watch
  } = useForm();
  const { addNewVacation, updateVacation, services, refreshServices } = useProfessional();
  const [submitError, setSubmitError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [dateFrom, setDateFrom] = useState<Date>();
  const [dateTo, setDateTo] = useState<Date>();

  function changeModalDisplayed(visibility: boolean) {
    setShowModal(visibility);
    modalVisibilityChanged && modalVisibilityChanged(visibility);
  }

  const updateModal = useCallback(
    (visibility: boolean) => changeModalDisplayed(visibility),
    []
  );

  const onSubmit = (data: FieldValues) => {
    const dateFrom = data.dateFrom.toISOString().split('T')[0];
    data.dateTo.setDate(data.dateTo.getDate() + 1)
    const dateTo = data.dateTo.toISOString().split('T')[0];
    const serviceId = services?.[0].id;
    setSubmitError(undefined);
    if (serviceId) {
      setLoading(true);
      if (isEditMode) {
        updateVacation && updateVacation(dateFrom, dateTo, serviceId, editVactionIndex)
        .then(() => {
          refreshServices && refreshServices()
          updateModal(false);
        })
        .catch((reason) => {
          console.error("Unable to update vacation", reason);
          setSubmitError("Unable to update vacation: " + reason.message);
        }).finally(() => {
          setLoading(false);
        });
      } else {
        addNewVacation && addNewVacation(dateFrom, dateTo, serviceId)
          .then(() => {
            refreshServices && refreshServices()
            updateModal(false);
          })
          .catch((reason) => {
            console.error("Unable to add vacation", reason);
            setSubmitError("Unable to add vacation: " + reason.message);
          }).finally(() => {
            setLoading(false);
          });
      }
    } else {
      console.error("No service to update");
      setSubmitError("Unable to update Schedule: No service offered by this professional");
    }
  };

  useEffect(() => {
    if (editVaction) {
      setValue('dateFrom', getDateFromString(editVaction?.during?.start))
      setValue('dateTo', new Date(editVaction?.during?.end))
    }
  }, [editVaction])

  useEffect(() => {
    setShowModal(displayModal);
  }, [displayModal]);

  return (
    <CenterModal isOpen={showModal} onClose={() => updateModal(false)}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <article
          className={'flex flex-col h-full overflow-y-auto w-screen px-4 py-6 sm:w-[500px] sm:h-auto sm:px-12 sm:py-9'}
        >
          <div className="flex flex-col">
            <div className="flex items-center justify-between py-4">
              <h1 className="text-xl font-semibold">{t('VACATION')}</h1>

              <button type="button" onClick={() => updateModal(false)}>
                <FontAwesomeIcon icon={faXmark} />
              </button>
            </div>
          </div>

          <div className="pt-5">
            <div className={'flex flex-col'}>
              <div className={'my-auto flex flex-col'}>
                <label
                  htmlFor="datepicker"
                  className="font-semibold text-sm pb-3"
                >
                  {t('DATE_FROM')} <span className="required text-red-600">*</span>
                </label>
                <Controller
                  name="dateFrom"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Calendar
                      isModal
                      title={t('DATE_FROM')}
                      onChange={(date) => {
                        setDateFrom(date);
                        field.onChange(date);
                      }}
                      defaultDate={getValues('dateFrom') || (isEditMode ? getDateFromString(editVaction?.during?.start) : new Date())}
                      minDate={new Date()}
                      disabledDates={disabledDates}
                    />
                  )}
                />

                <span className={'text-red-600'}>
                  {errors['dateFrom']?.type === 'required' && (
                    <p role="alert">{t('validation_required').replace('_validation_field_', t('DATE_FROM'))}</p>
                  )}
                </span>
              </div>
            </div>

            <div className={'flex flex-col mt-8'}>
              <div className={'my-auto flex flex-col'}>
                <label
                  htmlFor="datepicker"
                  className="font-semibold text-sm pb-3"
                >
                  {t('DATE_TO')} <span className="required text-red-600">*</span>
                </label>
                <Controller
                  name="dateTo"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Calendar
                      isModal
                      title={t('DATE_TO')}
                      onChange={(date) => {
                        setDateTo(date);
                        field.onChange(date);
                      }}
                      minDate={getValues('dateFrom')}
                      defaultDate={getValues('dateTo') || (isEditMode ? new Date(editVaction?.during?.end) : new Date())}
                      disabledDates={disabledDates}
                    />
                  )}
                />

                <span className={'text-red-600'}>
                  {errors['dateTo']?.type === 'required' && (
                    <p role="alert">{t('validation_required').replace('_validation_field_', t('DATE_TO'))}</p>
                  )}
                </span>
                <span className={'text-red-600'}>
                  {errors['dateTo']?.type === 'minDate' && (
                    <p role="alert">{t('DATE_FROM_EARLIER_THAN_DATE_TO')}</p>
                  )}
                </span>
              </div>
            </div>

            <div className='flex justify-end mt-20'>
              <div className="w-3/12">
                <SubmitPrimaryButton isLoading={loading}>
                  {isEditMode ? t('UPDATE') : t('SAVE')}
                </SubmitPrimaryButton>
              </div>
            </div>
          </div>
        </article>
      </form>
    </CenterModal>
  );
};
export default memo(Vacation);
