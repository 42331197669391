import React from 'react';
import Logo from "../logo/Logo";
import Spinner from "./Spinner";

function LoadingSplash(props: { loadingText?: string; showLogo?: boolean }) {
    const {loadingText, showLogo = true} = props;
    return (
        <div className={'h-full w-full flex justify-center  flex-col'}>
            {showLogo && <div className={'w-40 mx-auto mb-5'}>
                <Logo/>
            </div>}
            <div className={'mx-auto'}>
                <Spinner/>
            </div>
            <div className={'mx-auto'}>
                <span>{loadingText}</span>
            </div>
        </div>
    );
}

export default LoadingSplash;