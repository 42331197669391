import {useEffect, useRef, useState} from 'react';
import {IBookMarkLine, IOkCircle} from '../Icons';
import {useTranslation} from 'react-i18next';
import {v4 as uuidv4} from 'uuid';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClose} from '@fortawesome/free-solid-svg-icons';
import TextInput from '../input/TextInput';
import {useSavedSearch} from '../../contexts/practitioner/SaveSearchProvider';
import SubmitPrimaryButton from '../button/SubmitPrimaryButton';
import {FieldValues, useForm} from "react-hook-form";
import {usePractitionerSearch} from '../../contexts/PractitionerSearchProvider';
import useProfessional from "../../hooks/useProfessional";
import {SavedSearch} from '../../types/database/SharedTypes';
import SavedSearchNotification from '../modal/SavedSearchNotification';

function SaveSearch() {
  const [t] = useTranslation();
  const { updateSavedSearch, handleUpdateSavedSearch, savedSearchList } = useSavedSearch();
  const { schoolList } = useProfessional();
  const { filterParams } = usePractitionerSearch()
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [isNotification, setIsNotification] = useState<boolean>(false)

  const [showSaveSearch, setShowSaveSearch] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const elementRef = useRef<any>();

  async function onSubmit(data: FieldValues) {
    const newId = uuidv4();
    const searchItem: any = { id: newId, title: data?.title, isDefault: true };
    if (filterParams?.['organizationid:exact']) {
      let schools = [];
      if (Array.isArray(filterParams?.['organizationid:exact'])) {
        schools = filterParams?.['organizationid:exact'].map((schoolId: string) => {
          const school = schoolList?.schools.find((item: any) => item.id === schoolId)
          return {
            id: school?.id,
            name: school?.name
          }
        })
      } else {
        const school = schoolList?.schools.find((item: any) => item.id === filterParams?.['organizationid:exact']);
        schools = [{ id: school?.id, name: school?.name }]
      }
      searchItem.schools = schools
    }
    if (filterParams?.['immunizationstatus:exact']) {
      searchItem.status = Array.isArray(filterParams?.['immunizationstatus:exact']) ? 'nonCompliant' : 'pending'
    }
    if (!!savedSearchList && updateSavedSearch) {
      try {
        setIsLoading(true)
        const updateSearches = savedSearchList?.searches?.map((item: SavedSearch) => {
          return {
            ...item,
            isDefault: false
          }
        })
        await updateSavedSearch([searchItem, ...updateSearches])
        setShowSaveSearch(false);
        reset()
        if (savedSearchList && handleUpdateSavedSearch) handleUpdateSavedSearch([searchItem, ...updateSearches])
        setIsNotification(true)
      } catch (err: any) {
        console.log(err?.message)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleSelect = (e: MouseEvent) => {
    if (e.target && !elementRef?.current?.contains(e.target)) {
      setShowSaveSearch(false)
      reset()
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleSelect)
    return () => window.removeEventListener('click', handleSelect)
  }, [showSaveSearch])

  return (
    <>
      <form className='relative' ref={elementRef} onSubmit={handleSubmit(onSubmit)}>
        <div className='flex items-center cursor-pointer h-full' onClick={() => setShowSaveSearch(prev => !prev)}>
          <IBookMarkLine />
          <span className='ml-2 text-base font-medimum text-brand-color'>{t('SAVE_SEARCH')}</span>
        </div>
        {showSaveSearch && (
          <div className='absolute md:w-[368px] right-0 top-[100%] mt-3 bg-white boxShadow-lg z-10'>
            <div className='relative py-7 px-9'>
              <FontAwesomeIcon icon={faClose} className='absolute right-5 top-5 cursor-pointer' onClick={() => setShowSaveSearch(false)} />
              <h3 className='font-semibold text-xl mb-8'>{t('SAVE_SEARCH')}</h3>
              <TextInput
                inputLabel={t('TITLE')}
                required
                {...register("title", { required: true })}
              />
              <div className='flex items-center mt-5 mb-10'>
                <IOkCircle />
                <span className='ml-3'>{t('SET_AS_DEFAULT')}</span>
              </div>
              <SubmitPrimaryButton isLoading={isLoading}>{t('SAVE')}</SubmitPrimaryButton>
            </div>
          </div>
        )}
      </form>
      {isNotification && <SavedSearchNotification open={isNotification} onClose={() => setIsNotification(false)} />}
    </>
  )
}

export default SaveSearch;
