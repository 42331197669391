import {UserGroup} from "../../../contexts/AmplifyApiProvider";

export default function UserGroupToPath(userGroup?: UserGroup) {
    switch (userGroup) {
        case UserGroup.PATIENT:
            return 'patient';
        case UserGroup.PRACTITIONER:
            return 'practitioner';
        default:
            return undefined;

    }
}