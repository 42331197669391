import {useContext} from "react";
import {ProfessionalContext} from "../contexts/ProfessionalProvider";

export default function useProfessional() {
    const {
        professional,
        schoolList,
        profilePhoto,
        onboardingStatuses,
        errorMessage,
        services,
        selectedSchools,
        viewableSchools,
        setSelectedSchools,
        setPractitionerProfile,
        setViewableSchools,
        setSchoolInformation,
        getPhoto,
        addSignatureMetadata,
        updateServiceAvailability,
        addPractitionerProfileMetadata,
        addFIdMetadata,
        uploadSignature,
        addNewVacation,
        updateVacation,
        cancelVacation,
        uploadProfilePhoto,
        filterPatients,
        searchPatients,
        getPatient,
        getPatients,
        uploadFId,
        refreshServices,
        updateProfilePhoto,
        updateProfessionalPhoto
    } = useContext(ProfessionalContext);

    return {
        setSchoolInformation,
        setPractitionerProfile,
        professional,
        schoolList,
        services,
        onboardingStatuses,
        errorMessage,
        selectedSchools,
        profilePhoto,
        viewableSchools,
        setViewableSchools,
        uploadSignature,
        setSelectedSchools,
        getPhoto,
        filterPatients,
        updateServiceAvailability,
        addNewVacation,
        updateVacation,
        cancelVacation,
        searchPatients,
        getPatient,
        getPatients,
        uploadFId,
        uploadProfilePhoto,
        addFIdMetadata,
        addSignatureMetadata,
        addPractitionerProfileMetadata,
        refreshServices,
        updateProfilePhoto,
        updateProfessionalPhoto
    };
}
