import React, {ReactNode, useContext, useEffect, useState} from "react";
import {FileServerReply, ServerReply} from "../../types/database/SharedTypes";
import {API} from "aws-amplify";
import {ProfessionalContext} from "../ProfessionalProvider";

type PractitionerContextType = {
    practitioner?:any;
    practitionerProfilePhoto?: string;
};

const PractitionerContext = React.createContext<PractitionerContextType>({});
export const usePractitioner = () => React.useContext(PractitionerContext);

export function PractitionerProvider(props: {children:ReactNode, practitionerId: string, photoUrl?: string})  {
    const [practitioner, setPractitioner] = useState<any>();
    const [practitionerProfilePhoto, setPractitionerProfilePhoto] = useState<string>();
    const {professional} = useContext(ProfessionalContext);

    async function getPractitioner(): Promise<ServerReply<any>> {
        const apiName = 'LAUSDPractitionerEndpoint';
        const path = `/practitioner/practitioner/${props.practitionerId}`;
        const myInit = {
            body: {},
            headers: {}
        };
        return API.get(apiName, path, myInit);
    }
    async function getPhoto(photoPath: string): Promise<FileServerReply<any>> {
        const apiName = 'LAUSDDocumentEndpoint';
        const path = `/practitioner/document/${professional?.subject}?path=${encodeURIComponent(photoPath)}`;
        const myInit = {
            body: {},
            headers: {}
        };
        return API.get(apiName, path, myInit).catch((err) => {
            //Unable to get photo
        });
    }

    useEffect(() => {
        if( !practitioner && props.practitionerId){
            getPractitioner().then((value)=>{
                setPractitioner(value.body);
                return value.body;
            });


        }
    }, [props.practitionerId, practitioner]);

    useEffect(() => {
        if(props.photoUrl)
        getPhoto(props.photoUrl).then((value)=>{
            setPractitionerProfilePhoto(value.file);
        });
    }, [props.photoUrl]);
    return (
        <PractitionerContext.Provider value={{
            practitioner,
            practitionerProfilePhoto
        }}>
            {props.children}
        </PractitionerContext.Provider>
    );
};