import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import ImmunizationOverviewChart from "./ImmunizationOverviewChart";
import InputGroup from "../group/InputGroup";
import PrimaryButton from "../button/PrimaryButton";
import SchoolImmunization from "../table/schoolImmunization/SchoolImmunization";
import {useReports} from "../../contexts/admin/ReportProvider";
import Spinner from "../spinner/Spinner";
import SchoolListRightModal from "./SchoolListRightModal";
import SchoolNonCompliantDetailRightModal from "./SchoolNonCompliantDetailRightModal";
import PractitionerSearchProvider from "../../contexts/PractitionerSearchProvider";
import {useTranslation} from "react-i18next";

export interface SchoolReport {
    "school_name": "string",
    "school_id": string,
    "compliant_students": number,
    "non_compliant_students": number,
    "non_compliant_rate": number,
    "compliance_rate_change_percent"?: number;
}

function ImmunizationOverview(props: {}) {
    // const [compliantPercentage, setCompliantPercentage] = useState(Math.floor(Math.random() * 200) - 100);
    const {getOverallCompliance, getSchoolCompliance} = useReports();
    const [loadingReport, setLoadingReport] = useState(true);
    const [complianceOverall, setComplianceOverall] = useState<{
        student_totals: { overall_total: number; historic_total: number; },
        non_compliant_student_totals?: { overall_total: number; historic_total: number; }
    }>();
    const [schoolCompliance, setSchoolCompliance] = useState<{ items: SchoolReport[] }>();

    const [t] = useTranslation();
    const [showSchoolModal, setShowSchoolModal] = useState(false);
    const [showSchoolDetail, setShowSchoolDetail] = useState<boolean>(false);
    const [selectedSchoolId, setSelectedSchoolId] = useState<string>();

    const [reportError, setReportError] = useState<string>();

    useEffect(() => {
        async function fetchData() {


            if (getSchoolCompliance && getOverallCompliance) {
                try {
                    setReportError(undefined);
                    setLoadingReport(true);
                    const complianceOverall = await getOverallCompliance();
                    setComplianceOverall(complianceOverall.body);

                    const serverReply = await getSchoolCompliance();
                    setSchoolCompliance(serverReply.body);
                } catch (e) {
                    setReportError("Unable to get compliance for this date range");
                }
            }
            setLoadingReport(false);


        }

        fetchData();
    }, [getSchoolCompliance, getOverallCompliance]);

    const nonCompliantStudents = complianceOverall?.non_compliant_student_totals?.overall_total || 0;
    const studentNumber = complianceOverall?.student_totals.overall_total || 0;
    const compliantStudents = studentNumber - nonCompliantStudents;

    const historicNonCompliantStudents = complianceOverall?.non_compliant_student_totals?.historic_total || 0;

    function handleSchoolClicked(schoolId: string) {
        console.log("School Clicked", schoolId);
        setSelectedSchoolId(schoolId);
        setShowSchoolDetail(true);
    }

    function handleSeeMoreClicked() {
        setShowSchoolModal(true);
    }


    let compliantPercentage = 100 - Math.floor((compliantStudents / (compliantStudents + nonCompliantStudents)) * 100) || 0;
    const nonCompliantPercentChange = (((nonCompliantStudents - historicNonCompliantStudents) / (studentNumber)) * 100) || 0;

    if (loadingReport) {
        return (<InputGroup>
            <div className={'h-[19rem] flex items-center'}>
                <div className={'mx-auto'}>
                    <Spinner/>
                </div>
            </div>
        </InputGroup>);
    }
    if (reportError) {
        return (
            <InputGroup>
                <div className={'h-[19rem] flex items-center'}>
                    <div className={'mx-auto'}>
                        <span className={'text-red-500'}>{reportError}</span>
                    </div>
                </div>
            </InputGroup>);
    }

    return (
        <InputGroup>

            <div className={'grid  lg:grid-cols-4  grid-cols-1 '}>
                <div className={'flex flex-col  '}>
                    <h2 className={'text-2xl font-bold'}>{t("IMMUNIZATION_STATUS")}</h2>
                    <ImmunizationOverviewChart percentChange={nonCompliantPercentChange}
                                               percentCompliant={compliantPercentage}/>
                </div>
                <div className={'col-span-3'}>
                    <SchoolImmunization schoolData={schoolCompliance?.items.slice(0, 3)}
                                        schoolClicked={handleSchoolClicked}/>
                    <div className={'flex flex-col lg:flex-row lg:justify-between py-4'}>
                        <div className={'flex flex-col lg:flex-row gap-4'}>
                            <div className={'flex gap-2 my-auto'}>
                                <span className={'rounded-full w-6 bg-[#9662d71a]'}/>
                                <span><b>{compliantStudents}</b> - {t("COMPLIANT_STUDENTS")}</span>
                            </div>
                            <div className={'flex gap-2 my-auto'}>
                                <span className={'rounded-full w-6 bg-[#894DEB]'}/>
                                <span><b>{nonCompliantStudents}</b> - {t("NON_-_COMPLIANT_STUDENTS")}</span>
                            </div>

                        </div>
                        {schoolCompliance &&
                            <div className={'w-40 mt-4 lg:mt-0'}>
                                <PrimaryButton outLine
                                               onClick={() => handleSeeMoreClicked()}><span>{t("SEE_MORE")}</span><FontAwesomeIcon
                                    icon={faChevronDown} color={'text-brand-color'}
                                    className={'ml-3'}></FontAwesomeIcon> </PrimaryButton>

                            </div>}
                    </div>
                </div>
            </div>
            {schoolCompliance && <>
                <SchoolListRightModal schoolComplianceReport={schoolCompliance.items} showModal={showSchoolModal}
                                      setShowModal={() => setShowSchoolModal(false)}/>
                {selectedSchoolId &&
                    <PractitionerSearchProvider><SchoolNonCompliantDetailRightModal schoolId={selectedSchoolId}
                                                                                    showModal={showSchoolDetail}
                                                                                    setShowModal={() => setShowSchoolDetail(false)}/></PractitionerSearchProvider>}
            </>
            }


        </InputGroup>
    );
}

export default ImmunizationOverview;