import {ApexOptions} from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons';
import CenterModal from '../dashboard-search/CenterModal';
import StudentImmunizationDetail from './StudentImmunizationDetail';

function StudentImmunization(props: { data: any }) {
    const { data } = props;

    const [t] = useTranslation();
    const [chartId, setChartId] = useState('myChart');
    const [series, setSeries] = useState<any[]>([]);
    const [showAll, setShowAll] = useState<boolean>(false)

    const colors = ['#F8A03A', '#73C92D', '#9662D7']

    const options: ApexOptions = {
        chart: {
            type: 'bar',
            id: chartId,
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            }
        },
        plotOptions: {
            bar: {
                barHeight: '14px',
                distributed: true,
                horizontal: true,
                dataLabels: {
                    position: 'right'
                },
            }
        },
        colors: colors,
        dataLabels: {
            enabled: true,
            textAnchor: 'start',
            style: {
                colors: ['#454545']
            },
            formatter(val, opts) {
                return `${val}%`
            },
            offsetX: 0
        },
        xaxis: {
            categories: data.slice(0, 3).map((item: { name: string, count: number }) => item?.name),
        },
        yaxis: {
            labels: {
                show: false
            }
        },
        legend: {
            show: false
        }
    };

    useEffect(() => {
        const values = data.slice(0, 3).map((item: { name: string, count: number }) => item?.count)
        setSeries([{ data: values }])
        setChartId(`myChart-${new Date().getTime()}`);
    }, [data])

    return (
        <>
            <div className="bg-white rounded-[4px] py-6 px-10 mt-6">
                <h2 className="text-lg font-semibold mb-[6px] text-base">Students immunization by school</h2>
                <p className=''>Top 3 school</p>
                <div>
                    <ReactApexChart options={options} series={series} type="bar" height={350} />
                </div>
                {data.slice(0, 3).map((item: { name: string, count: number }, index: number) => (
                    <div className="flex items-center mb-2" key={index}>
                        <div className={`w-4 h-4 mr-3 bg-[${colors[index]}] rounded-full`} />
                        <p className="text-sm mb-0 font-normal"> - {item?.name}</p>
                    </div>
                ))}
                <div className='flex justify-end'>
                    <div className='flex items-center text-brand-color cursor-pointer' onClick={() => setShowAll(true)}>
                        <span className='text-sm font-medium mr-1'>{t('SEE_ALL_STATISTIC', 'See all statistic')}</span>
                        <FontAwesomeIcon icon={faChevronDown} />
                    </div>
                </div>
            </div>
            <CenterModal isOpen={showAll} onClose={() => setShowAll(false)}>
                <StudentImmunizationDetail data={data} />
            </CenterModal>
        </>
    )
};

export default StudentImmunization;