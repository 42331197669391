import React, {useEffect} from 'react';
import MessageComponent from "../../../components/messaging/MessageComponent";
import useProfessional from "../../../hooks/useProfessional";
import usePatient from "../../../hooks/usePatient";
import {ampli} from "../../../ampli";

function Messaging(props: {subject?: string}) {
    const {subject} = props;
    const {patient} = usePatient();
    const {professional} = useProfessional()

    useEffect(() => {
        ampli.track({event_type:"Route to Home Screen's Messages"});
    }, []);

    return (
        <div className={'lg:h-[calc(100vh_-_80px)]'}> { (patient || professional) &&
            <MessageComponent subject={subject} userId={patient?.subject || professional?.subject} name={patient?.name?.[0].text || professional?.name?.[0].text} profileImageURL={'#'}/>
        }
        </div>
    );
}

export default Messaging;
