import React, {useState} from 'react';
import TextInput from "../input/TextInput";
import PrimaryLink from "../link/PrimaryLink";
import {Controller, useForm} from "react-hook-form";
import SelectInput from '../input/SelectInput';
import PrimaryButton from "../button/PrimaryButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import useDatePicker from "../../hooks/useDatePicker";
import UploadImageInput from "../input/UploadImageInput";
import {faAddressCard, faCircleUser} from "@fortawesome/free-regular-svg-icons";
import usePatient from "../../hooks/usePatient";
import {ExtraUserInformation} from "./RegisterPane";
import {ImageListType} from "react-images-uploading";
import {useNavigate} from "react-router-dom";
import useCodeSets from "../../hooks/useCodeSets";
import SubmitPrimaryButton from "../button/SubmitPrimaryButton";
import Calendar from '../calendar';


function StudentRegisterForm(props: { extraUserInformation?: ExtraUserInformation }) {
    const {extraUserInformation} = props;

    const {register, handleSubmit, control, formState: {errors}, getValues} = useForm();
    const [submitError, setSubmitError] = useState<string>();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const {genderCodes, relationshipCodes, raceCodes, pronounCodes} = useCodeSets();
    const [dateOfBirth, setDateOfBirth] = useState<Date>();
    const [studentIDFile, setStudentIDFile] = useState<File>();
    const [studentProfilePhotoFile, setStudentProfilePhotoFile] = useState<File>();

    const {submitStudent, schoolList} = usePatient();

    useDatePicker();

    function onSubmit(data: { [x: string]: string; }) {
        if (dateOfBirth && studentIDFile && studentProfilePhotoFile && extraUserInformation && pronounCodes && raceCodes && genderCodes && relationshipCodes) {
            setLoading(true);
            setSubmitError(undefined);
            let gender: "male" | "female" | "other" = 'other';
            switch (data.gender) {
                case "male":
                    gender = "male";
                    break;
                case "female":
                    gender = "female"
                    break;
            }
            submitStudent && submitStudent({
                firstName: data.fname,
                lastName: data.lname,
                middleName: data.mname,
                pronouns: pronounCodes.codes.find((value) => value.code === data.pronouns) || pronounCodes.codes[2],
                studentId: data['student-id'],
                dateOfBirth: dateOfBirth,
                phoneNumber: extraUserInformation.phoneNumber,
                address_ln1: extraUserInformation.addressLn1,
                address_ln2: extraUserInformation.addressLn2,
                schoolId: data.school,
                grade: +data.grade,
                race: raceCodes?.codes.find((value) => value.code === data.race) || raceCodes?.codes[0],
                gender: genderCodes.codes.find((value) => value.code === data.gender) || genderCodes.codes[3],
                studentIdFile: studentIDFile,
                profilePhoto: studentProfilePhotoFile,
                relationshipToUser: relationshipCodes.codes.find((value) => value.code === data.gender) || relationshipCodes.codes[0],

            }).then(() => {
                setLoading(false)
                navigate('/patient');
            }).catch((reason) => {
                setLoading(false);
                setSubmitError("Unable to create patient. Network Error");
                console.error("Unable to create patient", reason);
            });
        }
    }

    function studentIdAdded(images: ImageListType) {
        const image = images?.[0];
        setStudentIDFile(image?.file);
    }

    function studentProfilePhotoAdded(images: ImageListType) {
        const image = images?.[0];
        setStudentProfilePhotoFile(image?.file);
    }

    return (
        <>
            <h2 className={'font-bold text-4xl mb-12 text-center lg:text-left'}>Student Information</h2>
            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="grid lg:grid-cols-2 gap-4 mt-6">
                    <div>
                        <TextInput inputLabel={'Student First Name'} id={'fname'}
                                   aria-invalid={errors.fname ? "true" : "false"}
                                   placeholder={'ex. Alex'} required={true}
                                   type={'text'} {...register("fname", {
                            required: true,
                        })} />
                        <span className={'text-red-600'}>{errors.fname?.type === 'required' &&
                            <p role="alert">First name is required</p>}</span>
                    </div>
                    <div>
                        <TextInput inputLabel={'Student Middle Name'} id={'mname'}
                                   placeholder={'ex. Tom'}
                                   type={'text'} {...register("mname", {
                            required: false,
                        })} />
                    </div>
                    <div>
                        <TextInput inputLabel={'Student Last Name'} id={'lname'}
                                   aria-invalid={errors.lname ? "true" : "false"}
                                   placeholder={'ex. Smith'} required={true}
                                   type={'text'} {...register("lname", {
                            required: true,
                        })} />
                        <span className={'text-red-600'}>{errors.lname?.type === 'required' &&
                            <p role="alert">Last name is required</p>}</span>

                    </div>
                    <div className={' '}>
                        <SelectInput inputLabel={'Preferred Pronouns'} id={'pronouns'}
                                     aria-invalid={errors.pronounCodes ? "true" : "false"}
                                     placeholder={'Select'} required={true} defaultValue={''}
                                     arial-description={pronounCodes?.description}
                                     {...register("pronouns", {
                                         required: true,
                                     })} >
                            <option disabled value={''}>Select</option>
                            {pronounCodes?.codes?.map(({code, display}) => {
                                return (<option key={code} value={code}> {display} </option>);
                            })}

                        </SelectInput>
                        <span className={'text-red-600'}>{errors.pronouns?.type === 'required' &&
                            <p role="alert">Pronouns are required</p>}</span>

                    </div>
                    <div>
                        <SelectInput inputLabel={'Gender'} id={'gender'} placeholder={'Select'}
                                     arial-description={genderCodes?.description}
                                     required={true} defaultValue={''}
                                     aria-invalid={errors.genderCodes ? "true" : "false"}
                                     {...register("gender", {
                                         required: true,
                                     })} >
                            <option disabled value={''}>Select</option>
                            {genderCodes?.codes?.map(({code, display}) => {
                                return (<option key={code} value={code}> {display} </option>);
                            })}

                        </SelectInput>
                        <span className={'text-red-600'}>{errors.gender?.type === 'required' &&
                            <p role="alert">Gender is required</p>}</span>

                    </div>
                    <div>
                        <SelectInput inputLabel={'Race'} id={'race'} placeholder={'Select'}
                                     arial-description={raceCodes?.description}
                                     required={true} defaultValue={''}
                                     aria-invalid={errors.raceCodes ? "true" : "false"}
                                     {...register("race", {
                                         required: true,
                                     })} >
                            <option disabled value={''}>Select</option>
                            {raceCodes?.codes?.map(({code, display}) => {
                                return (<option key={code} value={code}> {display} </option>);
                            })}

                        </SelectInput>
                        <span className={'text-red-600'}>{errors.race?.type === 'required' &&
                            <p role="alert">Race is required</p>}</span>

                    </div>
                    <div>
                        <TextInput inputLabel={'Student ID'} id={'patient-id'}
                                   placeholder={'ID Number'} required={true}
                                   aria-invalid={errors['student-id'] ? "true" : "false"}
                                   type={'text'} {...register("patient-id", {
                            required: true,
                        })} />
                        <span className={'text-red-600'}>{errors['student-id']?.type === 'required' &&
                            <p role="alert">Student ID is required</p>}</span>

                    </div>
                    <div className={'flex flex-row'}>
                        <div className={'w-full pr-3'}>
                            <SelectInput inputLabel={'School'} id={'school'} placeholder={'Select'} defaultValue={''}
                                         aria-invalid={errors['school'] ? "true" : "false"}
                                         required={true}
                                         {...register("school", {
                                             required: true,
                                         })} >
                                <option disabled value={''}>Los Angelas Private School</option>
                                {schoolList?.map(({id, name}) => {
                                    return (<option key={id} value={id}> {name} </option>);
                                })}

                            </SelectInput>
                            <span className={'text-red-600'}>{errors['school']?.type === 'required' &&
                                <p role="alert">School is required</p>}</span>

                        </div>
                        <div className={'w-full'}>
                            <SelectInput inputLabel={'Grade'} id={'grade'} defaultValue={'6'}
                                         aria-invalid={errors['grade'] ? "true" : "false"}
                                         required={true}
                                         {...register("grade", {
                                             required: true,
                                         })} >
                                <option value={'0'}>0</option>
                                <option value={'1'}>1</option>
                                <option value={'2'}>2</option>
                                <option value={'3'}>3</option>
                                <option value={'4'}>4</option>
                                <option value={'5'}>5</option>
                                <option value={'6'}>6</option>
                                <option value={'7'}>7</option>
                                <option value={'8'}>8</option>
                                <option value={'9'}>9</option>
                                <option value={'10'}>10</option>
                                <option value={'11'}>11</option>
                                <option value={'12'}>12</option>

                            </SelectInput>
                            <span className={'text-red-600'}>{errors['grade']?.type === 'required' &&
                                <p role="alert">Grade is required</p>}</span>
                        </div>
                    </div>

                    <div className={'flex flex-col'}>
                        <div className={'my-auto'}>
                            <label htmlFor="datepicker" className="font-medium text-xs pb-2">Date of Birth <span
                                className="required text-red-600">*</span></label>
                            <Controller
                                name="dateOfBirth"
                                control={control}
                                rules={{required: true}}
                                render={({field}) => <Calendar
                                    isModal
                                    onChange={date => {
                                        setDateOfBirth(date);
                                        field.onChange(date);
                                    }}
                                    maxDate={new Date()}
                                    minDate={new Date("1950-01-01")}
                                    defaultDate={getValues('dateOfBirth')}
                                />}
                            />

                            <span className={'text-red-600'}>{errors['dateOfBirth']?.type === 'required' &&
                                <p role="alert">Date Of Birth is required</p>}</span>

                        </div>


                    </div>

                    <div>
                        <SelectInput inputLabel={'Relationship'} id={'relationship'}
                                     arial-description={relationshipCodes?.description}
                                     placeholder={'Select'} required={true} defaultValue={''}
                                     aria-invalid={errors['relationship'] ? "true" : "false"}
                                     {...register("relationship", {
                                         required: true,
                                     })} >
                            <option disabled value={''}>Select</option>
                            {relationshipCodes?.codes?.map(({code, display}) => {
                                return (<option key={code} value={code}> {display} </option>);
                            })}
                        </SelectInput>
                        <span className={'text-red-600'}>{errors['relationship']?.type === 'required' &&
                            <p role="alert">Relationship is required</p>}</span>

                    </div>


                    <div>
                        <Controller
                            name="studentProfilePhoto"
                            control={control}
                            rules={{required: true}}
                            render={({field}) =>
                                <UploadImageInput
                                    icon={<FontAwesomeIcon icon={faCircleUser} className={'h-20 text-brand-color'}/>}
                                    imageAdded={(image) => {
                                        studentProfilePhotoAdded(image);
                                        field.onChange(image)
                                    }}
                                    title={'Edit Student profile photo'}/>}/>
                        <span className={'text-red-600'}>{errors['studentProfilePhoto']?.type === 'required' &&
                            <p role="alert">Student Profile Photo is required</p>}</span>

                    </div>
                    <div>
                        <Controller
                            name="studentId"
                            control={control}
                            rules={{required: true}}
                            render={({field}) =>
                                <UploadImageInput
                                    icon={<FontAwesomeIcon icon={faAddressCard} className={'h-20 text-brand-color'}/>}
                                    imageAdded={(image) => {
                                        studentIdAdded(image);
                                        field.onChange(image)
                                    }}
                                    title={'Edit school ID photo'}/>}/>
                        <span className={'text-red-600'}>{errors['studentId']?.type === 'required' &&
                            <p role="alert">Student ID is required</p>}</span>

                    </div>

                </div>
                <span className={'text-red-600'}>{submitError}</span>
                <div className={'flex justify-between mt-6'}>
                    <div className={'mt-4 text-center  font-medium hidden lg:block'}>
                        Have already an account?
                        <PrimaryLink to={'/login'}>
                            &nbsp;Login
                        </PrimaryLink>
                    </div>
                    <div className={'block lg:hidden w-52'}>
                        <PrimaryLink to={'/login'}>
                            <PrimaryButton>Back</PrimaryButton>
                        </PrimaryLink>

                    </div>
                    <div className={'w-52'}>
                        <SubmitPrimaryButton isLoading={loading}> Continue <FontAwesomeIcon
                            icon={faArrowRight}/></SubmitPrimaryButton>
                    </div>
                </div>
            </form>
        </>
    );
}

export default StudentRegisterForm;