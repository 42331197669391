import React, {memo, ReactNode, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import "@sendbird/uikit-react/dist/index.css";
import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
import ProfileSummary from "../status/ProfileSummary";
import useAuth from "../../hooks/useAuth";
import {
    GroupChannelHideParams,
    GroupChannelListOrder,
    HiddenChannelFilter,
    HiddenState,
    Member
} from "@sendbird/chat/groupChannel";
import {UserGroup} from "../../contexts/AmplifyApiProvider";
import {MessageSearch} from '@sendbird/uikit-react';
import ChannelUI from "@sendbird/uikit-react/Channel/components/ChannelUI";
import {ChannelProvider, useChannelContext} from "@sendbird/uikit-react/Channel/context";
import {ProfileStatuses} from "../status/ProfileImage";
import {parseDocumentPhoto} from "../../util/photo/parseDatabasePhotos";
import {DocumentProvider, useDocument} from "../../contexts/DocumentProvider";
import MessageHeader from "./MessageHeader";
import {ChannelListQueries} from "SendbirdUIKitGlobal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsis, faVideo} from "@fortawesome/free-solid-svg-icons";
import {ChannelListProvider} from "@sendbird/uikit-react/ChannelList/context";
import ChannelListUI from "@sendbird/uikit-react/ChannelList/components/ChannelListUI";
import {createOrReuseGroupChannel, sendbird} from "../../util/sendBird";
import {useVideoContext} from "../../contexts/VideoContextProvider";
import {useWindowSize} from "../../hooks/useWindowSize";
import PrimaryButton from "../button/PrimaryButton";
import ChannelListItem from './ChannelListItem';
import SearchItem from './SearchItem';
import {ampli} from "../../ampli";
import {IMessages} from '../Icons';

function MessageComponent(props: { userId: string, profileImageURL: string, name: string, subject?: string }) {
    const { userId, profileImageURL, name, subject } = props;
    const [t] = useTranslation();
    const { width } = useWindowSize();
    const { userProfile } = useAuth();
    let appId = '19408883-D8D1-4065-8ECB-8672B1DFD16C';
    const myColorSet = {
        '--sendbird-light-primary-500': '#F8A03A',
        '--sendbird-light-primary-400': '#F8A03A',
        '--sendbird-light-primary-300': '#F8A03A',
        '--sendbird-light-primary-200': '#F8A03A',
        '--sendbird-light-primary-100': '#FDF5EC',
    };

    const [channelSelected, setChannelSelected] = useState<any>();
    const [showSearch, setShowSearch] = useState<boolean>(false);
    const [showMessageList, setShowMessageList] = useState<boolean>(false);
    const [channelType, setChannelType] = useState<string>('recent_chats');
    const [isBlockedUser, setIsBlockedUser] = useState<boolean>(false);

    const defaultQueries = {
        applicationUserListQuery: {},
        channelListQuery: {
            order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
        },
    }
    const [queries, setQueries] = useState<ChannelListQueries>(defaultQueries);

    const updateShowSearch = useCallback((show: boolean) => setShowSearch(show), []);

    useEffect(() => {
        if (subject) {
            createOrReuseGroupChannel(userId, subject)
                .then((channel) => {
                    setChannelSelected(channel);
                });
        }
    }, [subject]);

    useEffect(() => {
        if (channelSelected?.url) {
            setShowMessageList(false);
            // Replace with Message UI update
            setTimeout(() => {
                document.querySelectorAll('.sendbird-message-content').forEach(elem => {
                    elem.innerHTML = elem.innerHTML.replace(/&nbsp;/g, ' ');
                })
            }, 1000);
        }
    }, [channelSelected?.url]);

    useEffect(() => {
        if (channelType === 'recent_chats') {
            setIsBlockedUser(false);
            setQueries(defaultQueries);
            return
        }
        if (channelType === 'hidden_chats') {
            setIsBlockedUser(false);
            setQueries({
                applicationUserListQuery: {},
                channelListQuery: {
                    order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
                    hiddenChannelFilter: HiddenChannelFilter.HIDDEN
                }
            })
            return
        }
    }, [channelType])

    return (
        <SendbirdProvider isVoiceMessageEnabled={false} appId={appId} userId={userId} colorSet={myColorSet}>
            <div className="App h-full max-h-full">
                {!showMessageList && <div className={' lg:hidden block'}><PrimaryButton onClick={() => setShowMessageList(!showMessageList)}>{showMessageList ? 'Show Messages' : 'Show Conversations'}</PrimaryButton></div>}
                <div className={'flex flex-row h-[45rem] lg:h-full'}>
                    {(showMessageList || (width || 0) > 1024) &&
                        <div >
                            {showSearch && <MessageSearch
                                channelUrl={channelSelected?.url || ''}
                                onCloseClick={() => setShowSearch(false)}
                                renderSearchItem={({ message, onResultClick }) => (
                                    <SearchItem message={message} onResultClick={onResultClick} />
                                )}
                            />}

                            {!showSearch && <ChannelListProvider
                                className={'lg:w-full h-full height-override'}
                                allowProfileEdit={false}
                                disableAutoSelect={true}
                                disableUserProfile={true}
                                onThemeChange={(theme: string) => { }}
                                onChannelSelect={(channel) => {
                                    setShowMessageList(false);
                                    if (channel.url && (!channelSelected?.url ||
                                        channelSelected.url !== channel.url)) {
                                        setChannelSelected(channel);
                                    }
                                    else {
                                    }
                                }
                                }
                                onProfileEditSuccess={(user) => { }}
                                queries={queries}
                            >
                                <ChannelListUI
                                    renderHeader={() => (
                                        <MessageHeader
                                            hasChannelSelected={channelSelected?.url}
                                            updateShowSearch={updateShowSearch}
                                            onChangeChanelType={setChannelType}
                                        />
                                    )}
                                    renderChannelPreview={({ channel }) => (
                                        <ChannelListItem
                                            channel={channel}
                                            channelSelected={channelSelected}
                                            isBlockedUser={isBlockedUser}
                                            channelType={channelType}
                                            setChannelSelected={setChannelSelected}
                                        />
                                    )}
                                    renderPlaceHolderEmptyList={() => <EmptyMessageContent placeholder={t('NO_CHANNELS')} />}
                                ></ChannelListUI>
                            </ChannelListProvider>
                            }
                        </div>
                    }
                    {(!showMessageList || (width || 0) > 1024) &&
                        <div className={'w-full text-left'}>
                            <ChannelProvider onBeforeSendFileMessage={(file, quotedMessage) => ({})} channelUrl={channelSelected?.url || ''} disableUserProfile={true}  >
                                <ChannelUI
                                    renderChannelHeader={() => <SendBirdHeaderProfile setChannelSelected={setChannelSelected} />}
                                    renderPlaceholderEmpty={() => <EmptyMessageContent placeholder={t('NO_MESSAGES')} />}
                                    renderPlaceholderInvalid={() => <EmptyMessageContent placeholder={t('NO_CHANNELS')} />}
                                ></ChannelUI>
                            </ChannelProvider>
                        </div>
                    }
                </div>
            </div>
        </SendbirdProvider>

    );
}

function SendBirdHeaderProfile(props: { setChannelSelected?: any }) {
    const { setChannelSelected } = props;
    const [t] = useTranslation();
    const { userProfile } = useAuth();
    const { currentGroupChannel } = useChannelContext();
    const otherMember = currentGroupChannel?.members.find((member: any) => member.userId !== userProfile);
    const { callOtherUser } = useVideoContext();
    const [actionState, setActionState] = useState({ loading: false, error: null });
    const [showDropdown, setShowDropdown] = useState<boolean>(false)

    const elementRef = useRef<any>(null)

    const handleChannelHide = async () => {
        try {
            setActionState({
                ...actionState,
                loading: true
            })
            const params: GroupChannelHideParams = {
                hidePreviousMessages: false,
                allowAutoUnhide: false
            };
            if (currentGroupChannel?.hiddenState === HiddenState.UNHIDDEN) {
                await currentGroupChannel?.hide(params);
                setChannelSelected(null);
                ampli.track({ event_type: "Hide chat" });

            } else {
                await currentGroupChannel?.unhide();
                setChannelSelected(null);
                ampli.track({ event_type: "Unhide chat" });

            }

            setActionState({
                loading: false,
                error: null
            })
        } catch (error: any) {
            setActionState({
                loading: false,
                error: error?.message
            })
        }
    }

    const handleUserBlock = async () => {
        try {
            setActionState({
                ...actionState,
                loading: true
            })
            if (otherMember?.isBlockedByMe) {
                await sendbird.unblockUserWithUserId(otherMember?.userId as string);
                sendbird.reconnect()
            } else {
                await sendbird.blockUserWithUserId(otherMember?.userId as string);
            }
            setActionState({
                loading: false,
                error: null
            })
        } catch (error: any) {
            setActionState({
                loading: false,
                error: error?.message
            })
        }
    }

    const handleSelect = (e: MouseEvent) => {
        if (e.target && !elementRef?.current?.contains(e.target)) {
            setShowDropdown(false)
        }
    }

    useEffect(() => {
        window.addEventListener('click', handleSelect)
        return () => window.removeEventListener('click', handleSelect)
    }, [showDropdown])

    return (
        <Summary otherMember={otherMember}>
            <div className={'flex flex-row justify-between'}>
                <UserPreviewProfile status={otherMember?.isActive || false} name={otherMember?.nickname || '(no name)'} />
                <div className={'flex items-center mr-4'}>
                    <button
                        className={'my-auto w-10 p-4'}
                        onClick={() => otherMember && callOtherUser && callOtherUser(otherMember.userId)}
                    >
                        <FontAwesomeIcon icon={faVideo} className={'text-[#818C99]'} />
                    </button>
                    <div className="relative inline-block ml-4">
                        <div
                            className='flex items-center'
                            ref={elementRef}
                            onClick={() => setShowDropdown(prev => !prev)}
                        >
                            <FontAwesomeIcon
                                icon={faEllipsis}
                                className={`w-6 h-6 cursor-pointer ${showDropdown ? 'text-brand-color' : 'text-[#818C99]'}`}
                            />
                        </div>

                        {showDropdown && (
                            <div className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none border-[1px] border-[#8C8C8C]">
                                <div className='py-[2px]'>
                                    <div className='px-5 cursor-pointer hover:bg-[#f8a03a0d]' onClick={() => handleChannelHide()}>
                                        <span
                                            className="py-[14px] block border-b border-b-neutral-10 h-full"
                                        >
                                            {currentGroupChannel?.hiddenState === HiddenState.UNHIDDEN ? t('HIDE_CHAT') : t('UNHIDE_CHAT')}
                                        </span>
                                    </div>
                                    {currentGroupChannel?.hiddenState === HiddenState.UNHIDDEN && (
                                        <div className='px-5 cursor-pointer hover:bg-[#f8a03a0d]' onClick={() => handleUserBlock()}>
                                            <span
                                                className="py-[14px] block h-full"
                                            >
                                                {otherMember?.isBlockedByMe ? t('UNBLOCK') : t('BLOCK')}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Summary>
    );
}
function UserPreviewProfile(props: { status: boolean, name: string }) {
    const { status, name } = props;
    const { document } = useDocument();
    const profilePhotoSrc = useMemo(() => parseDocumentPhoto(atob(document || '')), [document]);

    return (<div className={'flex flex-row px-6'}>
        <ProfileSummary src={profilePhotoSrc} status={status === true ? ProfileStatuses.active : (status === undefined ? undefined : ProfileStatuses.inactive)} name={name} description={null} />
    </div>)
}

export function Summary(props: { children: ReactNode, otherMember?: Member }) {
    const { children, otherMember } = props;
    const { userGroup, userProfile } = useAuth();

    const otherPersonProfileURL = otherMember?.profileUrl;

    if (userGroup === UserGroup.PRACTITIONER && otherPersonProfileURL) {
        return <DocumentProvider photoUrl={otherPersonProfileURL || ''}>
            {children}
        </DocumentProvider>
    } else if (userGroup === UserGroup.PATIENT && otherPersonProfileURL) {
        return <DocumentProvider photoUrl={otherPersonProfileURL || ''}>
            {children}
        </DocumentProvider>;
    } else if (!otherPersonProfileURL) {
        return <DocumentProvider photoUrl={''}>
            {children}
        </DocumentProvider>;
    } else return <div>Something</div>;

}

function EmptyMessageContent(props: { placeholder: string }) {
    const { placeholder } = props;

    return (
        <div className='w-full h-full flex justify-center items-center'>
            <div className='flex flex-col items-center text-[#00000080] [&>svg]:w-[64px] [&>svg]:h-[64px]'>
                <IMessages />
                <span className='mt-2'>{placeholder}</span>
            </div>
        </div>
    )
}
const SummaryMemo = memo(Summary);
const UserPreviewProfileMemo = memo(UserPreviewProfile);

export default memo(MessageComponent);