import React from 'react';
import TableMobile from "../TableMobile";
import {DocumentProvider} from "../../../contexts/DocumentProvider";
import PractitionerDocumentDownloadLink from "../../link/PractitionerDocumentDownloadLink";
import {useTranslation} from "react-i18next";
import {IDossierLine} from '../../Icons';

function DocumentMobile(props: { documents: any[] }) {
    const {documents} = props;
    const [t, i18n] = useTranslation();

    return (
        <TableMobile showPagination>
            {documents.map(document =>
                <DocumentProvider key={document.id} photoUrl={document.content?.[0].attachment.url}>
                    <PractitionerDocumentDownloadLink contentType={document?.content?.[0].attachment.contentType}>
                        <div className={'w-full py-4'}>
                            <div className={'inline-grid auto-cols-auto gap-2 '}>
                                <div className={'m-auto'}>
                                    <IDossierLine color={'#9662D7'} />
                                </div>
                                <div>
                                    <div className={'my-auto font-semibold text-base flex text-center '}>
                                    <span className={'my-auto '}>
                                        <b> {document.content?.[0]?.attachment?.title}</b>
                                    </span>
                                    </div>
                                </div>
                                <div className={'col-start-2'}>
                                    {[new Date(document.content?.[0].attachment?.creation)?.toLocaleDateString(i18n.resolvedLanguage), document.content?.[0].attachment?.title].join(' - ')}

                                </div>
                                <div className={'col-start-2'}>
                                    {t("DATE_UPLOADED")} : {new Date(document?.created?.date).toLocaleDateString(i18n.resolvedLanguage)}
                                </div>
                            </div>
                        </div>
                    </PractitionerDocumentDownloadLink>
                </DocumentProvider>
            )}
        </TableMobile>
    );
}

export default DocumentMobile;