import React from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock} from "@fortawesome/free-regular-svg-icons";
import {useSchedule} from "../../contexts/patient/ScheduleProvider";

function TimeSummary(props: {startTime?: string, endTime?: string}) {
    const {startTime, endTime} = props;
    const [t, i18n] = useTranslation();
    const {scheduleSlots} = useSchedule();
    const scheduleTimeStart = scheduleSlots?.find((slot) => startTime === slot.id);
    const scheduleTimeEnd = scheduleSlots?.find((slot) =>endTime === slot.id);

    return (
        <div className="flex gap-3">
            <div className="w-10 rounded-full p-2 flex justify-center items-center bg-white">
                  <span>
                    <FontAwesomeIcon
                        icon={faClock}
                        className="text-orange-500"
                    />
                  </span>
            </div>
            <div className="grid">
                <p className="text-slate-500 uppercase text-xs">
                    {t('AVAILABLE_TIME')}
                </p>
                {scheduleTimeStart && scheduleTimeEnd? (
                    <p className="text-sm">
                        {new Date(scheduleTimeStart.start).toLocaleTimeString(i18n.resolvedLanguage, {
                            hour: '2-digit',
                            minute: '2-digit'
                        }).toLowerCase()} - {new Date(scheduleTimeEnd.end).toLocaleTimeString(i18n.resolvedLanguage, {
                        hour: '2-digit',
                        minute: '2-digit'
                    }).toLowerCase()}
                    </p>
                ) : (
                    <p className="text-sm text-orange-500">{t('SELECT_TIME')}</p>
                )}
            </div>
        </div>
    );
}

export default TimeSummary;