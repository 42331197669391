import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {Controller, useForm} from "react-hook-form";
import useRegistration from "../../hooks/useRegistration";
import React, {useState} from "react";
import PrimaryButton from "../../components/button/PrimaryButton";

import DualSelect from "../../components/input/DualSelect";
import TextInput from "../../components/input/TextInput";
import {UserGroup} from "../../contexts/AmplifyApiProvider";
import {InputRadio} from "../input/InputRadio";
import PrimaryLink from "../../components/link/PrimaryLink";
import SubmitPrimaryButton from "../button/SubmitPrimaryButton";
import PhoneInput from 'react-phone-number-input/input'
import {isValidPhoneNumber} from "react-phone-number-input";

export default function RegistrationForm(props: {
    registrationFormComplete: (userEmail: string, userGroup: UserGroup, addressLn1: string, addressLn2: string, phoneNumber: string) => void
}) {
    const {registrationFormComplete} = props;
    const {register, handleSubmit, control, formState: {errors}} = useForm();
    const {registerNewUser} = useRegistration();
    const [loading, setLoading] = useState(false);
    const [submitError, setSubmitError] = useState<string>();

    function onSubmit(data: any): void {
        setLoading(true);
        setSubmitError(undefined);
        if (registerNewUser) {
            registerNewUser(data.fname, data.mname, data.lname, data.email, UserGroup[data['registration-type'] as keyof typeof UserGroup]).then(() => {
                setLoading(false);
                registrationFormComplete(data.email,
                    data['registration-type'],
                    data['address-ln-2'],
                    data['address'],
                    data.phone,);

            }).catch((reason: any) => {
                if (reason?.response?.status === 409) {
                    setLoading(false);
                    setSubmitError("User already exists with that email");
                    return;
                }
                console.error("Failed to create", reason);
                setLoading(false);
                setSubmitError(reason.message);
            })
        } else {
            setSubmitError("Unable to register user please try refreshing page");
            console.error("Provider not providing apis");
        }
    }


    return (
        <>
            <h2 className={'font-bold text-4xl mb-12 text-center lg:text-left'}>Registration</h2>
            <h3 className="mb-5 text-lg font-medium text-gray-900">I am registering as a:</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DualSelect>
                    <InputRadio inputLabel={'Parent/Guardian'}
                                optionValue={UserGroup.PATIENT} id={'patient'}
                                defaultChecked={true} {...register("registration-type", {
                        required: true,
                    })}/>
                    <InputRadio inputLabel={'Health Professional'}
                                optionValue={UserGroup.PRACTITIONER}
                                id={'professional'}     {...register("registration-type", {
                        required: true,
                    })}/>

                </DualSelect>

                <div className="grid lg:grid-cols-2 gap-4 mt-6">
                    <div>
                        <TextInput inputLabel={'First Name'} id={'fname'} required={true}
                                   aria-invalid={errors['fname'] ? "true" : "false"}
                                   type={'text'} {...register("fname", {
                            required: true,
                        })} />
                        <span className={'text-red-600'}>{errors['fname']?.type === 'required' &&
                            <p role="alert">Firstname is required</p>}</span>

                    </div>
                    <div>
                        <TextInput inputLabel={'Middle Name'} id={'mname'}
                                   type={'text'} {...register("mname", {
                            required: false,
                        })} />
                        <span className={'text-red-600'}>{errors['mname']?.type === 'required' &&
                            <p role="alert">Middle Name is required</p>}</span>

                    </div>
                    <div>
                        <TextInput inputLabel={'Last Name'} id={'lname'} required={true}
                                   type={'text'} {...register("lname", {
                            required: true,
                        })} />
                        <span className={'text-red-600'}>{errors['lname']?.type === 'required' &&
                            <p role="alert">Last Name is required</p>}</span>
                    </div>
                    <div className={'col-start-1 '}>
                        <TextInput inputLabel={'Email'} id={'email'} required={true}
                                   type={'email'} {...register("email", {
                            required: true,
                            pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: "Entered value does not match email format"
                            }
                        })} />
                        <span className={'text-red-600'}>{errors['email']?.type === 'required' &&
                            <p role="alert">Email is required</p>} {errors['email']?.type === 'pattern' &&
                            <p role="alert">{errors.email?.message?.toString()}</p>}</span>

                    </div>
                    <div>
                        <div className={'flex flex-col py-3'}>
                            <label htmlFor={'phone'} className={'font-medium text-xs pb-2'}>{'Phone'}
                                <span className="required text-red-600">*</span></label>
                            <Controller
                                name="phone"
                                control={control}
                                rules={{
                                    required: true,
                                    validate: (value) => isValidPhoneNumber(value)
                                }}
                                render={({field}) =>
                                    <PhoneInput
                                        country="US"
                                        className={'rounded-sm h-12 border-2 px-3 text-left font-normal text-sm'}
                                        {...field} />}/>

                        </div>
                        <span className={'text-red-600'}>{errors['phone']?.type === 'required' &&
                            <p role="alert">Phone Number is required</p>} {errors['phone']?.type === 'validate' &&
                            <p role="alert">Phone Number is invalid</p>}</span>

                    </div>
                    <div>
                        <TextInput inputLabel={'Address'} id={'address'} required={true}
                                   type={'address'} {...register("address", {
                            required: true,
                        })} />
                        <span className={'text-red-600'}>{errors['address']?.type === 'required' &&
                            <p role="alert">Address is required</p>}</span>

                    </div>
                    <div>
                        <TextInput inputLabel={'Apartment No.'} id={'address-ln-2'}
                                   type={'address'} {...register("address-ln-2", {
                            required: false,
                        })} />
                        <span className={'text-red-600'}>{errors['address-ln-2']?.type === 'required' &&
                            <p role="alert">Address is required</p>}</span>

                    </div>

                </div>
                <span className={'text-red-600'}>{submitError}</span>
                <div className={'flex justify-between mt-6'}>
                    <div className={'mt-4 text-center  font-medium hidden lg:block'}>
                        Have already an account?
                        <PrimaryLink to={'/login'}>
                            &nbsp;Login
                        </PrimaryLink>
                    </div>
                    <div className={'block lg:hidden w-52'}>
                        <PrimaryLink to={'/login'}>
                            <PrimaryButton>Back</PrimaryButton>
                        </PrimaryLink>

                    </div>
                    <div className={'w-52'}>
                        <SubmitPrimaryButton isLoading={loading}>
                            Continue <FontAwesomeIcon icon={faArrowRight}/>
                        </SubmitPrimaryButton>
                    </div>
                </div>
            </form>
        </>
    );
}