import {useContext} from "react";
import {AmplifyAPIContext} from "../contexts/AmplifyApiProvider";

export default function useRegistration() {
    const {
        services
    } = useContext(AmplifyAPIContext);

    return {
        registerNewUser: services?.registerNewUser,
        submitProfileInformation: services?.submitProfileInformation,
        resendVerificationEmail: services?.resendVerificationEmail
    };
}
