import React from 'react';
import {useTranslation} from 'react-i18next';
import InputGroup from "../../../components/group/InputGroup";
import {Coding} from "../../../types/database/SharedTypes";
import {ImmunizationProvider} from "../../../contexts/patient/ImmunizationProvider";
import ImmunizationView from "../../../components/immunization/ImmunizationView";
import {DocumentProvider} from "../../../contexts/patient/DocumentProvider";
import DocumentView from "../../../components/modal/DocumentView";

interface ImmunizationRecommendation {
    "status"?:string,
    "id"?:string,
    "vaccineCode":{coding:Coding[]},
}

function Immunization(props: {}) {
    const {} = props;
    const [t] = useTranslation();

    return (
        <div>
            <div className={'mb-5'}>
                <h1 className="font-semibold text-xl">{t('IMMUNIZATION_RECORDS')}</h1>
            </div>
                <ImmunizationProvider>
                        <ImmunizationView/>
                </ImmunizationProvider>
            <div className={'mt-4'}>
                <InputGroup>
                    <DocumentProvider>
                        <DocumentView/>
                    </DocumentProvider>
                </InputGroup>

            </div>
        </div>
    );
}

export default Immunization;