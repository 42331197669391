import {ContactPoint} from "../../../types/database/SharedTypes";

function GetTelecom(prop: { system: string, telecomArray: ContactPoint[] }) {
	const telecom = prop?.telecomArray.find((tel: ContactPoint) => tel?.system === prop.system)
	return (
		<>
			{telecom?.value ? (
				<div className="flex">
					<div className="mr-4">
						{prop?.system === 'PHONE' ? (
							<div className="bg-phone-icon w-4 h-4 bg-contain mt-1" />
						) : (
							<div className="bg-printer-icon w-4 h-4 bg-contain mt-1" />
						)}
					</div>
					<span className="text-base text-heading">{telecom?.value}</span>
				</div>
			) : ''}
		</>
	)
}
export default GetTelecom;
