import React, {useState} from 'react';
import CenterModal from "../dashboard-search/CenterModal";
import SendBirdCall from "sendbird-calls";
import PrimaryButton from "../button/PrimaryButton";
import {useVideoContext} from "../../contexts/VideoContextProvider";
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faWarning} from '@fortawesome/free-solid-svg-icons';

function VideoCallModal(props: { outgoingCallToUserId?: string, callEnded?: () => void }) {
    const { showVideoCallModal, setShowVideoCallModal, isRinging, isReceiving, currentCall, errorMessage, setErrorMessage } = useVideoContext();
    const [t] = useTranslation();

    const [isAudioEnabled, setIsAudioEnabled] = useState<boolean>(currentCall?.isLocalAudioEnabled || true);

    function toggleMicrophone() {
        isAudioEnabled ? currentCall?.muteMicrophone() : currentCall?.unmuteMicrophone();
        setIsAudioEnabled(!isAudioEnabled);

    }

    function acceptCall() {
        const acceptParams: SendBirdCall.AcceptParams = {
            callOption: {
                localMediaView: document.getElementById('local_video_element_id') as HTMLVideoElement,
                remoteMediaView: document.getElementById('remote_video_element_id') as HTMLVideoElement,
                audioEnabled: true,
                videoEnabled: true
            }
        };
        currentCall?.accept(acceptParams);

        if (!isAudioEnabled) {
            currentCall?.muteMicrophone();
        }
    }

    function handleClose() {
        currentCall?.end()
        !isAudioEnabled && toggleMicrophone()
        setShowVideoCallModal && setShowVideoCallModal(false)
        setErrorMessage && setErrorMessage('')
    }

    function handleDecline() {
        !isAudioEnabled && toggleMicrophone()
        currentCall?.end()
    }

    return (
        <CenterModal isOpen={true} hidden={!showVideoCallModal} onClose={handleClose}>
            {!!errorMessage ? (
                <div className='p-5 max-w-[400px] w-screen'>
                    <div className='flex justify-center mb-3'>
                        <FontAwesomeIcon
                            icon={faWarning}
                            className='text-[#FF3700] w-8 h-8'
                        />
                    </div>
                    <p className='text-base font-semibold text-center mb-5'>{errorMessage}</p>
                    <PrimaryButton onClick={handleClose}>{t('Accept')}</PrimaryButton>
                </div>
            ) : (
                <article
                    className={'flex flex-col h-full justify-between overflow-y-auto  lg:min-w-[40vw] min-w-[90vw]  w-full py-8'}
                >
                    <div className={'flex flex-row aspect-video bg-black'}>
                        <div className={'flex justify-center basis-1/2'}>
                            <video id="remote_video_element_id" playsInline autoPlay></video>
                        </div>
                        <div className={'flex justify-center basis-1/2'}>
                            <video id="local_video_element_id" playsInline autoPlay muted />
                        </div>

                    </div>
                    <div className={'call-controls'}>
                        <div className={' flex flex-row gap-4'}>
                            {isRinging && isReceiving && <>
                                <PrimaryButton onClick={() => acceptCall()} className={'bg-green-500'}>{t('ACCEPT')}</PrimaryButton>
                                <PrimaryButton onClick={() => handleDecline()} className={'bg-red-500'}>{t('DECLINE')}</PrimaryButton>
                            </>}
                            {(!isRinging) && <PrimaryButton onClick={() => handleDecline()} className={'bg-red-500'}>{t('HANGUP')}</PrimaryButton>}
                            <PrimaryButton onClick={() => toggleMicrophone()} className={'bg-blue-500'}> {isAudioEnabled ? t('MUTE') : t('UNMUTE')} </PrimaryButton>
                        </div>
                    </div>
                </article>
            )}
        </CenterModal>
    );
}

export default VideoCallModal;