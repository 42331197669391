import CenterModal from "../dashboard-search/CenterModal";
import {useTranslation} from 'react-i18next'

function Success(props: {
  open: boolean,
  content: string,
  onClose: () => void,
  onAccept: () => void,
  acceptBtnText?: string
}) {
  const { open, content, onClose, onAccept, acceptBtnText } = props;
  const [t] = useTranslation();

  return (
    <CenterModal
      isOpen={open}
      onClose={() => onClose()}
    >
      <div className='p-5 w-[300px]'>
        <div className='mb-3'>
          <span className="font-semibold">{t('SUCCESS!')}</span>
        </div>
        <p className='text-base text-center mb-8'>{content}</p>
        <div className='flex justify-center'>
          <button
            className='bg-brand-color text-white w-full p-2 text-base uppercase'
            onClick={() => onAccept()}
          >
            {acceptBtnText ?? 'Accept'}
          </button>
        </div>
      </div>
    </CenterModal>
  )
}

export default Success;
