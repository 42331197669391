import React from 'react';

function TextAreaInput(props: {
    [x: string]: any;
    id: string;
    inputLabel: string,
    required?: boolean
}, ref: React.ForwardedRef<any>) {
    const {inputLabel, id, required, ...extraProps} = props;
    return (
        <>
            <div className={'flex flex-col py-3'}>
                <label htmlFor={id} className={'font-medium text-xs pb-2'}>{inputLabel} {required ?
                    <span className="required text-red-600">*</span> : ''}</label>
                <textarea className={'rounded-sm  border-2 px-3 text-left font-normal text-sm'}
                       id={id} ref={ref}  {...extraProps} />
            </div>
        </>
    );
}

export default React.forwardRef(TextAreaInput);