import React, {useEffect, useState} from 'react';
import PatientProvider from "../../contexts/PatientProvider";
import CodeProvider from "../../contexts/CodeProvider";
import {ExtraUserInformation} from "../../components/login/RegisterPane";
import {useSearchParams} from "react-router-dom";
import StudentRegisterForm from "../../components/login/StudentRegisterForm";
import compose from "../../util/compose";
import withStudentCodeSets from "../../hocs/withStudentCodeSets";

const StudentRegisterInfo = compose(withStudentCodeSets)(StudentRegisterForm);

function StudentRegister() {
    const [searchParams] = useSearchParams();
    const [extraUserInformation, setExtraUserInformation] = useState<ExtraUserInformation>();

    useEffect(() => {
        const extraUserInformation: ExtraUserInformation = {
            addressLn1: searchParams.get("addressLn1") || "",
            addressLn2: searchParams.get("addressLn2") || "",
            phoneNumber: searchParams.get("phoneNumber") || ""
        };
        setExtraUserInformation(extraUserInformation);

    }, [searchParams]);

    return (
        <CodeProvider>
            <PatientProvider>
                <StudentRegisterInfo extraUserInformation={extraUserInformation}/>
            </PatientProvider>
        </CodeProvider>
    );
}

export default StudentRegister;