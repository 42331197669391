import React, {createContext, useEffect, useState} from 'react';
import {API} from 'aws-amplify';
import useProfessional from '../hooks/useProfessional';

interface PatientsProps {
  children: React.ReactElement<any, any>;
}

export const PractitionerSearchContext = createContext<{
  getPatients?: () => Promise<any>;
  patients?: any[];
  fetchingPatients?: boolean;
  filteredPatients?: any[];
  searchTerm?: string;
  filterParams?: Record<string,string| string[]>,
  setSearchTerm?: (searchTerm: string) => void;
  setFilterParams?: (params: Record<string, string| string[]> | undefined) => void;
  fetchPatients?: () => void;
  fetchSearchResults?: (searchTerm: string) => void;
  filterResults?: (filterParams: Record<string, string>) => void;
  searchError?: string;
}>({});
export const usePractitionerSearch = () => React.useContext(PractitionerSearchContext);
export interface ServerReply<T> {
  statusCode: number;
  body: T;
}

function PractitionerSearchProvider(props: PatientsProps) {
  const { children } = props;
  const [patients, setPatients] = useState<any[]>([]);
  const {  filterPatients } = useProfessional();
  const [fetchingPatients, setFetchingPatients] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filterParams, setFilterParams] = useState<Record<string,string|string[]>>();
  const [searchError, setSearchError] = useState<string>();

  async function getPatients(searchTerm?: string): Promise<ServerReply<any>> {
    const apiName = 'LAUSDPractitionerEndpoint';
    const path = `/practitioner/filter/patients${searchTerm? "?name:contains="+searchTerm : ""}`;
    const myInit = {
      body: {},
      headers: {},
    };
    return API.get(apiName, path, myInit);
  }

  function fetchPatients ()  {
    setFetchingPatients(true);
      setSearchError(undefined);
      setFetchingPatients(true);
      getPatients()
          .catch((err) => {
            setSearchError(err.message);
          })
          .then((value) => {
        if (value?.body) {
          setPatients(value?.body?.items);

        }
      }).finally(() => setFetchingPatients(false));

  }

  function fetchSearchResults  (searchTerm: string)  {
    setFetchingPatients(true);
    setSearchError(undefined);
      getPatients(searchTerm).then((value) => {
          if (value.body) {
            const patients = value.body.items;
            setPatients(patients);
            setFetchingPatients(false);
          }
        })
          .catch((err) => {
            setSearchError(err.message);
          })
          .finally(() => setFetchingPatients(false));


  }

  async function filterResults(filterParams?: Record<string,string| string[]>) {
      // Must have filter params to do a query of backend crashes
      if(filterParams && Object.keys(filterParams).length > 0) {
          setFetchingPatients(true);
          setSearchError(undefined);
          const value = filterPatients && await filterPatients(filterParams)
              .catch((err) => {
                  setSearchError(err.message);
              })
              .finally(() => setFetchingPatients(false));


          if (value?.body) {
              const patients = value.body.items;
              setPatients(patients);
              setFetchingPatients(false);

          }
      }

  };


  useEffect(() => {
    if(filterParams && searchTerm) {
      filterResults({"name:contains":searchTerm, ...filterParams});
    } else if(filterParams) {
      filterResults(filterParams);
    }
  }, [searchTerm, filterParams]);

  return (
    <PractitionerSearchContext.Provider
      value={{
        getPatients,
        patients,
        fetchingPatients,
        searchTerm,
        filterParams,
        searchError,
        setFilterParams,
        setSearchTerm,
        fetchSearchResults,
        filterResults,
        fetchPatients,
      }}
    >
      {children}
    </PractitionerSearchContext.Provider>
  );
}

export default PractitionerSearchProvider;
