import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-regular-svg-icons";

export enum ProfileStatuses {
    active,
    inactive
}

function ProfileImage(props: {src?: string, status?: ProfileStatuses}) {
    const {src, status} = props;

    return (
        <div className={'relative '}>
            <div className={ (status !== undefined ? (status === ProfileStatuses.active? 'bg-lime-500': ( status === ProfileStatuses.inactive? 'bg-red-500' : '') ) : 'hidden') + ' ' + 'status object-right-bottom rounded-full bg-lime-500 border-[.15rem] border-white w-3/12 absolute bottom-0 right-0  aspect-square'}/>
           <div className={'flex justify-center aspect-square bg-neutral-100 bg rounded-full overflow-hidden'}>
            {src ? (

                    <img alt={'profile-photo'} className={'object-center  object-cover'} src={src}/>

                ) :
                (
                    <div aria-label={'default-profile-image'} className={'flex justify-center'}>
                        <FontAwesomeIcon icon={faUser} className={'h-2/5 my-auto'}/>
                    </div>
                )}
        </div>
        </div>
    );
}

export default ProfileImage;