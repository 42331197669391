import {useTranslation} from "react-i18next";
import TimeView from "./TimeView";

function WorkingHours(props: { data: any }) {
  const [t] = useTranslation();

  const { data } = props;

  return (
    <div className="bg-white rounded-[4px]">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center pt-6 pl-10 mb-[22px]">
        <h2 className="text-lg font-semibold">{t('WORKING_HOURS')}</h2>
        <div className="bg-[#73c92d1a] text-sm py-3 px-5 mt-2 md:mt-0">{t('YOU_ARE_WORKED_BETTER_THAN_98_USERS')}</div>
      </div>
      <div className="pb-6 px-10">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="border border-[#73C92D] rounded-lg py-4 px-6 mt-[10px]">
            <p className="text-base mb-6">Average time in app per day</p>
            <TimeView time={data?.average_hours} />
          </div>
          <div className="border border-[#F8A03A] rounded-lg py-4 px-6 mt-[10px]">
            <p className="text-base mb-6">Total working hours</p>
            <TimeView time={data?.total_hours} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default WorkingHours;