import React from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faXmark} from "@fortawesome/free-solid-svg-icons";

function EvaluationStatus(props: {evaluation: any| undefined}) {
    const {evaluation} = props;
    const [t] = useTranslation();
    return (
        <div>
            {evaluation?.doseStatus.coding?.[0]?.code === 'notvalid' && (
                <div className='text-red-600'>
                    <FontAwesomeIcon icon={faXmark} className='w-4 mr-2' />
                    <span>{t('REJECTED')}</span>
                </div>
            )}
            {evaluation?.doseStatus.coding?.[0]?.code === 'valid' && (
                <div className='text-[#73C92D]'>
                    <FontAwesomeIcon icon={faCheck} className='w-4 mr-2' />
                    <span>{t('APPROVED')}</span>
                </div>
            )}
        </div>
    );
}

export default EvaluationStatus;
