import React, {useEffect, useState} from "react";
import useAuth from "../hooks/useAuth";
import SendBirdCall from "sendbird-calls";
import {ListenToCalls, sendAutomatedSendbirdMessage, VideoCallOtherUser} from "../util/sendBird";
import {UserGroup} from "./AmplifyApiProvider";
import {useTranslation} from "react-i18next";

type VideoContextContextType = {
    callOtherUser?: (userId: string) => void;
    callEnded?: () => void;
    isRinging?: boolean;
    isReceiving?: boolean;
    showVideoCallModal?: boolean;
    errorMessage?: string;
    setErrorMessage?: (message: string) => void;
    currentCall?: SendBirdCall.DirectCall | undefined;
    setShowVideoCallModal?: (show: boolean) => void;

};

const VideoContextContext = React.createContext<VideoContextContextType>({});
export const useVideoContext = () => React.useContext(VideoContextContext);

export function VideoContextProvider(props:{children: React.ReactNode}){
    const {} = props;
    const {userProfile, userGroup} = useAuth();
    const [t] = useTranslation();
    const [showVideoCallModal, setShowVideoCallModal] = useState<boolean>(false);
    const [currentCall, setCurrentCall] = useState<SendBirdCall.DirectCall>();
    const [isRinging, setIsRinging] = useState<boolean>(true);
    const [isReceiving, setIsReceiving] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('')

    useEffect(() => {
        if(userProfile)
            ListenToCalls(userProfile, (call) => {
                setShowVideoCallModal(true);
                setCurrentCall(call);
                console.log(call);
                setIsReceiving(true);
                setIsRinging(true);
                call.onConnected = () => {
                    setIsRinging(false);
                }
                call.onEnded = () => {
                    callEnded();
                    setShowVideoCallModal(false);
                }
            });
    }, [userProfile]);


    function callOtherUser(userId: string) {
        setShowVideoCallModal(true);

        const dialParams: SendBirdCall.DialParams = {
            userId: userId,
            isVideoCall: true,
            callOption: {
                localMediaView: document.getElementById('local_video_element_id') as HTMLVideoElement ,
                remoteMediaView: document.getElementById('remote_video_element_id') as HTMLVideoElement ,
                audioEnabled: true,
                videoEnabled: true
            }
        };
        VideoCallOtherUser(userId, dialParams).then((call) => {
            setErrorMessage('')
            setCurrentCall(call);
            console.log(call);
            let isConnected = false;
            call.onConnected = () => {
                setIsRinging(false);
                isConnected = true;
            }
            call.onEnded = () => {
                if(userProfile){
                    if (!isConnected ){
                        // call was not answered
                        sendAutomatedSendbirdMessage(userProfile, userId, 'You missed a call');
                    }else {
                        if(userGroup === UserGroup.PRACTITIONER)
                            sendAutomatedSendbirdMessage(userProfile, userId, 'You had a call');
                    }
                }
                callEnded();
                setShowVideoCallModal(false);
            }
            setIsRinging(false);
        }).catch((err) => {
            setErrorMessage(err.message)
        });

    }
    function callEnded(){
        setCurrentCall(undefined);
        setIsRinging(true);
        setIsReceiving(false);
    }
    return (
        <VideoContextContext.Provider value={{
            callOtherUser,
            showVideoCallModal,
            setShowVideoCallModal,
            currentCall,
            isRinging,
            isReceiving,
            errorMessage,
            setErrorMessage,
            callEnded
        }}>
            {props.children}
        </VideoContextContext.Provider>
    );
};