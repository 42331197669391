import {ApexOptions} from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import {useEffect, useMemo, useState} from 'react';

function ImmunizationEvaluations(props: { data: any }) {
  const { data } = props;

  const [chartId, setChartId] = useState('myChart');
  const [series, setSeries] = useState<number[]>([])

  const options: ApexOptions = {
    chart: {
      // height: 200,
      id: chartId,
      type: 'donut',
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            show: true,
            color: '#454545',
            fontSize: '14px',
            offsetY: 20
          },
          value: {
            show: true,
            fontSize: '40px',
            fontWeight: 700,
            offsetY: -20,
            formatter: (val: number) => {
              return `${data.approved + data.rejected}`;
            },
          },
        },
        hollow: {
          margin: 0,
          size: '65px',
        },
        track: {
          background: '#9662d71a'
        },
      }
    },
    labels: ['Evaluations'],
    fill: {
      colors: ['#9662D7']
    },
    stroke: {
      lineCap: 'round'
    },
  }

  const rejectedPercent = useMemo(() => Math.round(data?.rejected/(data?.rejected + data?.approved)*100), [data])
  const approvedPerecent = useMemo(() => Math.round(data?.approved/(data?.rejected + data?.approved)*100), [data])

  useEffect(() => {
    setSeries([parseInt(`${data.approved / (data.rejected + data.approved) * 100}`)])
    setChartId(`myChart-${new Date().getTime()}`);
  }, [data])

  return (
    <div className="bg-white rounded-[4px] h-full flex flex-col justify-between">
      <div className="py-6 px-10">
        <h2 className="text-lg font-semibold mb-8">Immunization Evaluations</h2>
        <div className="h-[250px]">
          <ReactApexChart options={options} series={series} type="radialBar" height='100%' />
        </div>
        <div>
          <div className="flex items-center mb-2">
            <div className="w-4 h-4 mr-3 bg-[#9662d71a] rounded-full" />
            <p className="text-sm mb-0 font-normal">
              <span className="font-medium">{data?.rejected} <span className='text-[#9662D7]'>({rejectedPercent}%)</span></span> - Immunization rejected
            </p>
          </div>
          <div className="flex items-center">
            <div className="w-4 h-4 mr-3 bg-[#9662D7] rounded-full" />
            <p className="text-sm mb-0 font-normal">
              <span className="font-medium">{data?.approved} <span className='text-[#9662D7]'>({approvedPerecent}%)</span></span> - Immunizations approved
            </p>
          </div>
        </div>
      </div>
      <div className="bg-[#73c92d33] py-3 px-10 text-sm">
      You are evaluated more immunizations than 86% of professionlas in our app.
      </div>
    </div>
  )
};

export default ImmunizationEvaluations;