import React, {useEffect} from 'react';
import InputGroup from "../group/InputGroup";
import {useTranslation} from "react-i18next";
import {usePractitionerSearch} from "../../contexts/PractitionerSearchProvider";
import {useForm} from "react-hook-form";
import SelectInput from "../input/SelectInput";
import useProfessional from "../../hooks/useProfessional";
import LoadingSplash from "../spinner/LoadingSplash";
import StudentsListTable from "./StudentsListTable";
import StudentDetailRightPane from "./StudentDetailRightPane";

function NonCompliantStudents(props: {}) {
    const [t] = useTranslation();
    const {fetchingPatients, patients, setFilterParams} = usePractitionerSearch();
    const {register, handleSubmit, watch, setValue, formState: {errors}} = useForm();
    const {schoolList} = useProfessional();

    const [showStudentDetail, setShowStudentDetail] = React.useState<boolean>(false);
    const [selectedStudentId, setSelectedStudentId] = React.useState<string>();

    useEffect(() => {
        if (schoolList && schoolList?.schools.length > 0) {
            setValue('schoolFilter', schoolList?.schools[0].id);
        }
    }, [schoolList]);
    const schoolValue = watch('schoolFilter');

    useEffect(() => {

        setFilterParams && setFilterParams({"organizationid:exact": schoolValue})

    }, [schoolValue]);

    function handleStudentClicked(studentId: string) {
        console.log("Student Clicked");
        setSelectedStudentId(studentId);
        setShowStudentDetail(true);
    }


    return (
        <div>
            <InputGroup>
                <div className={'min-h-[25rem]'}>
                    <h2 className={'text-2xl font-bold'}>{t("NON_COMPLIANT_STUDENTS")}</h2>
                    <SelectInput inputLabel={t("SCHOOL_FILTER")} {...register('schoolFilter')}>
                        {schoolList?.schools.map(school => <option key={school.id}
                                                                   value={school.id}>{school.name}</option>)}
                    </SelectInput>
                    {!fetchingPatients ?
                        <div>

                            <StudentsListTable
                                students={patients || []}
                                studentClicked={handleStudentClicked}
                            />

                        </div> : <LoadingSplash showLogo={false}/>}
                    {selectedStudentId &&
                        <StudentDetailRightPane studentId={selectedStudentId} showModal={showStudentDetail}
                                                setShowModal={setShowStudentDetail}/>}

                </div>

            </InputGroup>
        </div>
    );
}

export default NonCompliantStudents;