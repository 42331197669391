import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faClock, faXmark} from "@fortawesome/free-solid-svg-icons";
import {Status} from '../../types/database/SharedTypes';
import {getImmunizationStatus} from "../../util/immunization/immunization";


function UploadImmunizationStatus(props: { immunization: any }) {
  const { immunization } = props;
  const [t] = useTranslation();

  const vaccineStatus = useMemo(() => getImmunizationStatus(immunization), [immunization])

  function getCSSColor() {
    switch (vaccineStatus) {
      case Status.EditedAndPending:
      case Status.Pending:
        return 'text-blue-500';
      case Status.Approved:
        return 'text-green-500';
      default:
        return 'text-red-500';
    }
  }

  function getStatusName() {
    switch (vaccineStatus) {
      case Status.EditedAndPending:
        return t('PENDING_REVIEW');
      case Status.Pending:
        return t('PENDING_REVIEW');
      case Status.Approved:
        return t('APPROVED');
      default:
        return t('REJECTED');
    }
  }

  function getStatusIcon() {
    switch (vaccineStatus) {
      case Status.EditedAndPending:
      case Status.Pending:
        return faClock;
      case Status.Approved:
        return faCheck;
      default:
        return faXmark;
    }
  }

  return (
    <div className={`${getCSSColor()} flex items-center`}>
      <FontAwesomeIcon icon={getStatusIcon()} className='w-3 mr-2.5' />
      <span className='text-base'>{getStatusName()}</span>
    </div>
  );
}

export default UploadImmunizationStatus;