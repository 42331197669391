import React, {ReactNode, useEffect, useState} from "react";
import {ServerReply} from "../../types/database/SharedTypes";
import {API} from "aws-amplify";

export interface ScheduleSlot {
    resourceType: string;
    schedule: string;
    status: 'free' | 'busy' | 'busy-unavailable' | 'busy-tentative' | 'entered-in-error';
    created: string;
    end: string;
    id: string;
    start: string;
    tenant?: string;
}
type ScheduleContextType = {
    scheduleSlots?: ScheduleSlot[];
};

const ScheduleContext = React.createContext<ScheduleContextType>({});
export const useSchedule = () => React.useContext(ScheduleContext);

export function ScheduleProvider(props: {children: ReactNode, practitionerId: string, day?: Date}) {
    const [scheduleSlots, setScheduleSlots] = useState<any[]>();

    async function getPractitionerSchedule(day?: Date): Promise<ServerReply<any>> {
        const apiName = 'LAUSDEndpoint';
        const start = new Date(day?.toISOString() || new Date().toISOString());
        start.setUTCHours(0,0,0,0);
        const startDateFromNowISO = start.toISOString();
        const end = start;
        end.setUTCHours(23,59,59,999);
        const endDateFromNowISO = end.toISOString();

        const dateQuery = day ? `start=${encodeURIComponent(startDateFromNowISO)}&end=${encodeURIComponent(endDateFromNowISO)}` : '';
        const path = `/patient/practitioner/schedule/${props.practitionerId}?${dateQuery}`;
        const myInit = {
            body: {},
            headers: {}
        };
        return API.get(apiName, path, myInit);
    }

    //const startTimes = useMemo(() => scheduleSlots., [scheduleSlots]);

    useEffect(() => {
        if( props.day)
        getPractitionerSchedule(props.day).then((value) => {
            if (value.body) {
                setScheduleSlots(value.body.items)
            }
        });

    }, [props.day]);

    return (
        <ScheduleContext.Provider value={{
            scheduleSlots,
        }}>
            {props.children}
        </ScheduleContext.Provider>
    );
};