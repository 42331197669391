import {Navigate, useLocation} from "react-router-dom";
import React from "react";
import useAuth from "../hooks/useAuth";
import Spinner from "../components/spinner/Spinner";

export default function withBasicAuth(Component: typeof React.Component) {
    return (props: any) => {
        const {user, userLoading, signOut} = useAuth();

        const {pathname, search} = useLocation();


        if (!user && !userLoading && !pathname.includes('login') && !pathname.includes('reset-password')) {
            return (
                <Navigate
                    to="/login"
                    state={{from: pathname, search}}
                    replace
                />
            );
        }
        if (!user && userLoading) {
            return <Spinner/>

        } else if (user && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            return (
                <Navigate
                    to="/update-password"
                    state={{from: pathname, search}}
                    replace
                />
            );
        } else {
            return <Component {...props} signout={signOut}></Component>;
        }
    }
}
