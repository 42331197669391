import React, {useContext, useEffect, useState} from "react";
import {ServerReply} from "../../types/database/SharedTypes";
import {API} from "aws-amplify";
import {AuthContext} from "../AuthContext";
import {ProfessionalContext} from "../ProfessionalProvider";

type ScheduleContextType = {
    schedule?: any;
    refreshSchedule?: (serviceId: string) => Promise<void>;
};

const ScheduleContext = React.createContext<ScheduleContextType>({});
export const useSchedule = () => React.useContext(ScheduleContext);

export function ScheduleProvider(props: { children: React.ReactNode }) {
    const {userProfile} = useContext(AuthContext);
    const {services, refreshServices} = useContext(ProfessionalContext);
    const [schedule, setSchedule] = useState<any>();

    async function getSchedule(serviceId: string,profile = userProfile): Promise<ServerReply<any>> {
        const apiName = 'LAUSDPractitionerEndpoint';
        const path = `/practitioner/service/${profile}/${serviceId}`;
        const myInit = {
            body: {},
            headers: {}
        };
        return API.get(apiName, path, myInit);
    }
    function refreshSchedule(serviceId: string) {
        return getSchedule(serviceId).then((value) => {
            setSchedule(value.body);
        });

    }
    useEffect(() => {
        if (!userProfile || !services || services.length === 0) return;
        refreshSchedule(services[0].id);
    }, [userProfile, services]);
    return (
        <ScheduleContext.Provider value={{
            schedule,
            refreshSchedule,
        }}>
            {props.children}
        </ScheduleContext.Provider>
    );
};