import {useTranslation} from "react-i18next"
import {ICalendar} from "../Icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons'
import {useEffect, useRef, useState} from "react";
import {Calendar as SingleCalendar} from 'react-date-range';
import ImmunizationEvaluations from "./ImmunizationEvaluations";
import StudentImmunization from "./StudentImmunization";
import WorkingHours from "./WorkingHours";
import Appointments from "./Appointments";
import {addDays} from "../../util/date/convertDateString";
import ReviewingHistory from "./ReviewingHistory";
import {usePerformance} from "../../contexts/practitioner/PerformanceProvider";
import es from "date-fns/locale/es";
import enUS from "date-fns/locale/en-US";

export function PerformanceDashboard() {
    const [t, i18n] = useTranslation();
  const { reportDataList } = usePerformance();

  const [currentOption, setCurrentOption] = useState<string>('month');
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [date, setDate] = useState<Date>(new Date());
  const [reportData, setReportData] = useState({
    appointments: { new_patients: 68, old_patients: 56, duration: 28, reffered_appointments: 12 },
    workingHours: { average_hours: 88, total_hours: 4528 },
    immunizationEvaluations: { rejected: 102, approved: 660 },
    studentImmunization: [
      { name: 'Abraham Lincoln Senior High', count: 64 },
      { name: 'Excelsior School', count: 22 },
      { name: 'Gardena Senior High', count: 46}
    ]
  })

  const calendarRef = useRef<any>()

  const dateOptions = [
    { value: 'today', name: t('TODAY') },
    { value: 'week', name: t('WEEK') },
    { value: 'month', name: t('MONTH') },
  ]

  const handleSelect = (e: MouseEvent) => {
    if (e.target && !calendarRef?.current?.contains(e.target)) {
      setShowCalendar(false)
    }
  }

  function handleChangeDate(isAdd: boolean) {
    const updatedDate = addDays(date, isAdd ? 30 : -30)
    setDate(updatedDate)
  }

  useEffect(() => {
    if (!showCalendar) return
    window.addEventListener('click', handleSelect)
    return () => window.removeEventListener('click', handleSelect)
  }, [showCalendar])

  useEffect(() => {
    if (reportDataList) {
      const index: number = Math.floor(Math.random() * 5);
      setReportData(reportDataList[index])
    }
  }, [currentOption, date, reportDataList])

  return (
    <div>
      <h2 className="font-bold text-2xl mb-8">{t('PERFORMANCE_DASHBOARD')}</h2>
      <div className="flex justify-between flex-col md:flex-row">
        <div>
          <div className="flex items-center">
            <div className="relative" ref={calendarRef}>
              <button
                className="text-brand-color w-12 h-12 rounded-full bg-[#f8a03a1a] flex items-center justify-center"
                onClick={() => setShowCalendar(prev => !prev)}
              >
                <ICalendar />
              </button>
              {showCalendar && (
                <div className='inline-block absolute bg-white z-10 left-0 top-[100%] mt-2 border-[1px] rounded-[4px] border-[#EBEBEB]'>
                  <SingleCalendar
                    date={date}
                    onChange={(val) => setDate(val)}
                    minDate={new Date('1950-01-01')}
                    locale={i18n.resolvedLanguage?.startsWith('es') ? es : enUS}
                  />
                  <button
                    onClick={() => setShowCalendar(false)}
                    className='bg-brand-color text-white w-[calc(100%_-_20px)] box-border h-11 font-medium text-base rounded-sm my-1 mx-[10px] mb-2'
                  >
                    {t('CONTINUE')}
                  </button>
                </div>
              )}
            </div>
              <span className="ml-5 mr-6 text-xl font-semibold">{date.toLocaleDateString(i18n.resolvedLanguage, {
                  year: 'numeric',
                  month: 'long'
              })}</span>
            <FontAwesomeIcon
              icon={faChevronLeft}
              className={'h-4 w-4 mr-6 cursor-pointer text-brand-color '}
              onClick={() => handleChangeDate(false)}
            />
            <FontAwesomeIcon
              icon={faChevronRight}
              className={'h-4 w-4 cursor-pointer text-brand-color'}
              onClick={() => handleChangeDate(true)}
            />
          </div>
        </div>
        <div className="flex border border-[#EBEBEB] w-fit mt-4 md:mt-0">
          {dateOptions.map(option => (
            <div
              className={`px-[35px] py-[14px] cursor-pointer ${currentOption === option.value && 'bg-[#f8a03a0d] text-brand-color border border-brand-color'}`}
              onClick={() => setCurrentOption(option.value)}
            >
              {option.name}
            </div>
          ))}
        </div>
      </div>
      <div className="flex mb-7 overflow-hidden flex-col md:flex-row">
        <Appointments data={reportData?.appointments} />
        <div className="mt-6 flex-1">
          <WorkingHours data={reportData?.workingHours} />
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12 xl:col-span-5 mt-6">
              <ImmunizationEvaluations data={reportData?.immunizationEvaluations} />
            </div>
            <div className="col-span-12 xl:col-span-7">
              <StudentImmunization data={reportData?.studentImmunization} />
            </div>
          </div>
        </div>
      </div>
      <ReviewingHistory />
    </div>
  )
}
