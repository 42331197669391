import React from 'react';

function SubHeader(props: { title: string }) {
    const {title} = props;
    return (
        <div className={'sub-header'}>
            <h3 className={'mt-4 text-base   font-normal '}>{title}</h3>


        </div>

    );
}

export default SubHeader;
