import React, {useState} from 'react';
import InputGroup from "../group/InputGroup";
import SelectInput from "../input/SelectInput";
import TextInput from "../input/TextInput";
import {useForm} from "react-hook-form";
import useCodeSets from "../../hooks/useCodeSets";
import SubmitPrimaryButton from "../button/SubmitPrimaryButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import useProfessional from "../../hooks/useProfessional";
import {OnboardingSection} from "../../pages/professional/onboarding/SideNav";

function School(props: { onSectionCompleted: (section: OnboardingSection) => any }) {
    const {onSectionCompleted} = props;
    const {register, handleSubmit, control, formState: {errors}} = useForm();
    const {professionCodes} = useCodeSets();
    const {schoolList, setSchoolInformation} = useProfessional();
    const [loading, setLoading] = useState<boolean>(false);
    const [submitError, setSubmitError] = useState<string>();

    function submitForm(data: any) {
        setLoading(true);
        setSubmitError(undefined);
        setSchoolInformation && setSchoolInformation(data.school, data.office)
            .then(value => {
                setLoading(false);
                onSectionCompleted(OnboardingSection.School);
            }).catch(reason => {
                setLoading(false);
                setSubmitError(reason.message);
            })
    }

    return (
        <>
            <InputGroup>
                <form onSubmit={handleSubmit(submitForm)}>
                <div className="grid lg:grid-cols-2 gap-4">
                    <div className={''}>
                        <SelectInput inputLabel={'School'} id={'profession'}
                                     aria-invalid={errors.school ? "true" : "false"}
                                     placeholder={'Los Angeles Private school'} required={true} defaultValue={''}
                                     {...register("school", {
                                         required: true,
                                     })} >
                            <option disabled value={''}>Los Angeles Private school</option>
                            {schoolList?.schools?.map(({id, name}) => {
                                return (<option key={id} value={id}> {name} </option>);
                            })}

                        </SelectInput>
                        <span className={'text-red-600'}>{errors.school?.type === 'required' &&
                            <p role="alert">School are required</p>}</span>

                    </div>
                    <div className={'col-start-1'}>
                        <TextInput inputLabel={'Office / Room No'} id={'office'}
                                   placeholder={'405'} required={true}    aria-invalid={errors['office'] ? "true" : "false"}

                                   type={'text'} {...register("office", {
                            required: true,
                        })} />
                        <span className={'text-red-600'}>{errors['office']?.type === 'required' &&
                            <p role="alert">Office / Room No  is required</p>}</span>

                    </div>
                </div>
                    <span className={'text-red-600'}>{submitError}</span>

                    <div className={'w-40 ml-auto '}>
                        <SubmitPrimaryButton isLoading={loading}>Continue <FontAwesomeIcon
                            icon={faArrowRight}/></SubmitPrimaryButton>
                    </div>
                </form>
            </InputGroup>
        </>
    );
}

export default School;