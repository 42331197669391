import {ReactNode, useEffect} from 'react'
import {useTranslation} from 'react-i18next';
import {faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import InputGroup from "../group/InputGroup";
import PrimaryButton from "../button/PrimaryButton";
import {FieldValues, useForm} from "react-hook-form";
import {IFilter} from '../Icons';
import SaveSearch from './SaveSearh';

function SearchContainer(props: { onCriteriaChanged: (data: FieldValues) => void, updateVisibility?: (visibility: boolean) => void, searchValue?: string, isProfessional?: boolean, children?: ReactNode, searchError?: string, filterParams?: Record<string, string | string[]> }) {
    const { onCriteriaChanged, updateVisibility, children, searchError, searchValue, isProfessional, filterParams } = props;
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [t] = useTranslation();

    function onSubmit(data: FieldValues) {
        onCriteriaChanged(data);
    }

    useEffect(() => {
        if (!searchValue) {
            (document?.getElementById?.('searchTerm') as HTMLInputElement).value = '';
        }
    }, [searchValue])

    return (
        <div>
            <InputGroup>
                <div className={'flex gap-5 flex-col lg:flex-row '}>
                    <form onSubmit={handleSubmit(onSubmit)} className='flex gap-5 flex-col lg:flex-row grow'>
                        <div className={"relative grow flex gap-3 items-center bg-slate-50 rounded-sm border-0 p-3 outline-0"}>
                            <FontAwesomeIcon icon={faMagnifyingGlass} color={"#8c8c8c7f"} size="sm" />
                            <input
                                className={"grow w-auto bg-transparent border-0 outline-0"}
                                placeholder={t("SEARCH")}
                                id={'searchTerm'}
                                type={'text'} {...register("searchTerm", {
                                })}
                            />
                            {isProfessional && (
                                <button className='w-12 h-12 hidden lg:flex absolute right-0 top-0 bg-brand-color items-center justify-center'>
                                    <FontAwesomeIcon icon={faMagnifyingGlass} color={"white"} size="lg" />
                                </button>
                            )}
                        </div>

                        {isProfessional && (
                            <div className='w-[127px]'>
                                <PrimaryButton outLine={true} type={'button'}
                                    onClick={() => updateVisibility ? updateVisibility(true) : null}
                                >
                                    <div className='flex items-center justify-center h-full relative'>
                                        {filterParams && Object.keys(filterParams).length > 0 && (<div className='absolute top-[-8px] right-[-8px] rounded-full w-4 h-4 bg-lime-green' />)}
                                        <IFilter />
                                        <span className='ml-2'>{t("FILTERS")}</span>
                                    </div>
                                </PrimaryButton>
                            </div>
                        )}
                        {!isProfessional && (
                            <div className={'hidden lg:block lg:w-[130px]'}>
                                <PrimaryButton
                                >
                                    {t("SEARCH")}
                                </PrimaryButton>
                            </div>
                        )}
                    </form>
                    {isProfessional && filterParams && Object.keys(filterParams).length > 0 && <SaveSearch />}
                </div>
                <span className="text-red-500 ">{searchError}</span>
                <div>
                    {children}
                </div>
            </InputGroup>
        </div>
    );
}

export default SearchContainer;
