import React from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";

function MessageHeader(props: {updateShowSearch: (showSearch: boolean) => void, onChangeChanelType?: (val: string) => void, hasChannelSelected?: boolean}) {
    const {updateShowSearch, onChangeChanelType, hasChannelSelected} = props;
    const [t] = useTranslation();
    return (
        <div>
            <div><h2 className={'text-2xl font-bold text-left pl-12 py-6'}><b>{t('MESSAGES')}</b></h2></div>
            <div>
                <div className={"mx-12 mb-6 flex gap-3 items-center bg-slate-50 rounded-sm border-0 p-3 outline-0"}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} color={"#8c8c8c7f"} size="sm"/>
                    <input
                        className={"grow w-auto bg-transparent border-0 outline-0"}
                        placeholder={t('SEARCH')}
                        disabled={!hasChannelSelected}
                        onFocus={(e) => {
                            e.preventDefault();
                            updateShowSearch(true);
                        }}
                        id={'searchTerm'}
                        type={'text'}
                    />
                </div>
            </div>
            <div className='mx-12'>
                <select
                    className="bg-gray-50 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 outline-none"
                    onChange={e => onChangeChanelType && onChangeChanelType(e.target.value)}
                    defaultValue={'recent_chats'}
                >
                    <option value="recent_chats">{t('RECENT_CHATS')}</option>
                    <option value="hidden_chats">{t('HIDDEN_CHATS')}</option>
                </select>
            </div>
        </div>
    );
}

export default MessageHeader;