import React, {memo, useCallback, useEffect, useState} from 'react';
import SideNav, {OnboardingSection} from "./SideNav";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faXmark} from "@fortawesome/free-solid-svg-icons";
import {useWindowSize} from "../../../hooks/useWindowSize";
import OnboardingState from "./OnboardingState";
import useProfessional from "../../../hooks/useProfessional";


function Onboarding() {
    const {width} = useWindowSize();
    const [showNavbar, setShowNavbar] = useState<boolean>(false);
    const {onboardingStatuses} = useProfessional();
    const [completedSections, setCompletedSections] = useState<OnboardingSection[]>([]);
    const lg_breakpoint = 1024;

    const updateCompletedSections = useCallback((section: OnboardingSection) => completedSections.push(section), []);

    useEffect(() => {
        if (onboardingStatuses) {
            if (onboardingStatuses.identificationComplete) {
                completedSections.push(OnboardingSection.FacultyID);
            }
            if (onboardingStatuses.signatureComplete) {
                completedSections.push(OnboardingSection.ServiceAgreement);
            }
            if (onboardingStatuses.photoComplete) {
                completedSections.push(OnboardingSection.ProfilePhoto);
            }
            if (onboardingStatuses.profileComplete) {
                completedSections.push(OnboardingSection.ProfilePhoto)
            }
        }
    }, [onboardingStatuses]);

    const shouldDisplayNav = useCallback(() => {
        return showNavbar || (width || 0) > lg_breakpoint
    }, [width, showNavbar]);
    const shouldDisplayContent = useCallback(() => {
        return !showNavbar || (width || 0) > lg_breakpoint
    }, [width, showNavbar]);

    return (
        <div className={'flex flex-row'}>
            {(shouldDisplayNav()) &&
            <div className={'lg:max-w-sm bg-white shadow-lg min-h-screen sticky'}>

                <FontAwesomeIcon onClick={() => setShowNavbar(!showNavbar)} className={'ml-auto p-5 h-10 lg:hidden'}
                                 icon={faXmark}/>
                <SideNav completedSections={completedSections} navItemClicked={() => setShowNavbar(false)}/>

            </div>
            }
            {(shouldDisplayContent()) ?
            <div className={'px-10 pt-10 w-full bg-neutral-100 min-h-screen'}>

                <FontAwesomeIcon onClick={()=> setShowNavbar(!showNavbar)} className={'h-10 p-5 lg:hidden'} icon={faBars}/>

                <OnboardingState sectionMarkedComplete={updateCompletedSections}/>

            </div> : null
            }
        </div>
    );
}

export default memo(Onboarding);