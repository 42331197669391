import React from 'react';

interface ButtonPrimaryProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    children: any;
    outLine?: boolean
}

function PrimaryButton(props: ButtonPrimaryProps) {
    const {children,outLine = false, ...extraProps} = props;
    return (

        <button className={(outLine?'border-brand-color border bg-white text-brand-color': 'bg-brand-color text-white') + ' ' + 'h-12 font-medium text-base rounded-sm w-full'} {...extraProps}>
            {children}
        </button>
    );
}

export default PrimaryButton;