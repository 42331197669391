import React, {ReactNode, useEffect, useState} from 'react';
import {API} from 'aws-amplify';
import {Organization} from '../PatientProvider'
import {ServerReply} from "../../types/database/SharedTypes";
import {AuthContext} from '../AuthContext';

type ReferContextType = {
    getPractitioners?: () => Promise<any>;
    practitioners?: any[];
    fetchingPractitioners?: boolean;
    filteredPractitioners?: any[];
    professionalSearchTerm?: string;
    clinicsSearchTerm?: string;
    filterParams?: Record<string, string>,
    setProfessionalSearchTerm?: (searchTerm: string) => void;
    setClinicsSearchTerm?: (searchTerm: string) => void;
    setFilterParams?: (params: Record<string, string> | undefined) => void;
    fetchPractitioners?: () => void;
    fetchSearchResults?: (searchTerm: string) => void;
    filterResults?: (filterParams: Record<string, string>) => void;
    clinicList?: Organization[],
    fetchingClinics?: boolean,
    createRefer?: (subjectId: string, apptId: string) => Promise<any>;
};

const ReferContext = React.createContext<ReferContextType>({});
export const useRefer = () => React.useContext(ReferContext);

export default function ReferProvider(props: { children: ReactNode }) {
    const { children } = props;
    const { userProfile } = React.useContext(AuthContext);
    const [practitioners, setPractitioners] = useState<any[]>([]);
    const [fetchingPractitioners, setFetchingPractitioners] = useState<boolean>(false);
    const [clinicList, setClinicList] = useState<Organization[]>();
    const [fetchingClinics, setFetchingClincs] = useState<boolean>(false);
    const [professionalSearchTerm, setProfessionalSearchTerm] = useState<string>('');
    const [clinicsSearchTerm, setClinicsSearchTerm] = useState<string>('');
    const [filterParams, setFilterParams] = useState<Record<string, string>>();

    async function filterPractitioners(filterParams?: Record<string, string>): Promise<ServerReply<{ items: any[] }>> {
        const apiName = 'LAUSDPractitionerEndpoint';
        const path = `/practitioner/filter/practitioners?${new URLSearchParams(filterParams)}`;
        const myInit = {
            body: {},
            headers: {}
        };
        return API.get(apiName, path, myInit);
    }

    async function searchPractitioners(searchQuery?: string, profile = userProfile): Promise<ServerReply<{ items: any[] }>> {
        const apiName = 'LAUSDPractitionerEndpoint';
        const path = `/practitioner/filter/practitioners?name:contains=${searchQuery}`;
        const myInit = {
            body: {},
            headers: {}
        };
        return API.get(apiName, path, myInit);
    }

    async function getPractitioners(): Promise<ServerReply<any>> {
        const apiName = 'LAUSDPractitionerEndpoint';
        const path = `/practitioner/filter/practitioners?`;
        const myInit = {
            body: {},
            headers: {},
        };
        return API.get(apiName, path, myInit);
    }

    async function createRefer(otherSubjectId: string, apptId: string, profile = userProfile): Promise<ServerReply<any>> {
        const apiName = 'LAUSDPractitionerEndpoint';
        const path = `/practitioner/referral/${profile}/${apptId}`;
        const myInit = {
            body: {
                performer: otherSubjectId,
            },
            headers: {},
        };
        return API.post(apiName, path, myInit);
    }

    function fetchPractitioners() {
        setFetchingPractitioners(true);
        setFetchingPractitioners(true);
        getPractitioners().then((value) => {
            if (value?.body) {
                setPractitioners(value?.body?.items);
                setFetchingPractitioners(false);
            }
        });

    };

    function fetchSearchResults(searchTerm: string) {
        setFetchingPractitioners(true);
        if (searchTerm.length > 0) {
            searchPractitioners && searchPractitioners(searchTerm.toLowerCase()).then((value) => {
                    setPractitioners(value?.body?.items);
                    setFetchingPractitioners(false);

            });

        }
    };

    async function filterResults(filterParams?: Record<string, string>) {
        setFetchingPractitioners(true);
        const value = filterPractitioners && await filterPractitioners(filterParams);
        if (value?.body) {
            setPractitioners(value?.body?.items);
            setFetchingPractitioners(false);

        }
    }

    async function getOrganizations(query: string): Promise<{ body: { items: [Organization] }, statusCode: number }> {
        const apiName = 'LAUSDPractitionerEndpoint';
        const path = `/practitioner/organizations?${query}`;
        const myInit = {
            body: {},
            headers: {}
        };
        return API.get(apiName, path, myInit);
    }

    useEffect(() => {
        if (!filterParams) {
            if (professionalSearchTerm && professionalSearchTerm.length > 0) {
                fetchSearchResults(professionalSearchTerm);
            } else {
                fetchPractitioners();
            }
        } else if (filterParams) {
            filterResults(filterParams);
        }
    }, [professionalSearchTerm, filterParams]);

    async function fetchClinics() {
        setFetchingClincs(true);
        getOrganizations(clinicsSearchTerm ? `like=${clinicsSearchTerm.toLowerCase()}&type=prov` : 'type=prov').then(({ body: { items } }) => {
            setClinicList(items);
            setFetchingClincs(false);
        })
    }

    useEffect(() => {
        fetchClinics()
    }, [clinicsSearchTerm])

    return (
        <ReferContext.Provider value={{
            getPractitioners,
            practitioners,
            fetchingPractitioners,
            professionalSearchTerm,
            clinicsSearchTerm,
            filterParams,
            setFilterParams,
            setProfessionalSearchTerm,
            setClinicsSearchTerm,
            clinicList,
            fetchingClinics,
            fetchSearchResults,
            filterResults,
            createRefer
        }}>
            {props.children}
        </ReferContext.Provider>
    );
}