import {useContext} from "react";
import {CodeContext} from "../contexts/CodeProvider";

export default function useCodeSets() {
    const {
        pronounCodes,
        relationshipCodes,
        raceCodes,
        genderCodes,
        immunizationCodes,
        sexCodes,
        serviceProvisionCodes,
        professionCodes,
        languagesCodes,
    } = useContext(CodeContext);

    return {
        pronounCodes,
        relationshipCodes,
        raceCodes,
        sexCodes,
        genderCodes,
        immunizationCodes,
        professionCodes,
        serviceProvisionCodes,
        languagesCodes,
    };
}