import {memo, useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import CenterModal from '../dashboard-search/CenterModal';
import {Controller, FieldValues, useForm} from 'react-hook-form';
import Calendar from '../calendar';
import PrimaryButton from '../button/PrimaryButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleRight, faArrowLeft, faXmark,} from '@fortawesome/free-solid-svg-icons';
import ProfileSummary from '../status/ProfileSummary';
import {faCalendar} from '@fortawesome/free-regular-svg-icons';
import {ScheduleProvider} from "../../contexts/patient/ScheduleProvider";
import StartTime from "./StartTime";
import EndTime from "./EndTime";
import TimeSummary from "./TimeSummary";
import StartTimeSummary from "./StartTimeSummary";
import {addDays} from "../../util/date/convertDateString";
import usePatient from "../../hooks/usePatient";
import SubmitPrimaryButton from "../button/SubmitPrimaryButton";
import {ampli} from "../../ampli";
import {usePatientSearch} from "../../contexts/PatientSearchProvider";
import Success from '../modal/Success';
import {useNavigate} from "react-router-dom";

function BookAppointment(props: {
  displayModal: boolean;
  modalVisibilityChanged?: (visible: boolean) => void;
  practitioner: any;
  srcImg?: string;
}) {
  const {
    displayModal = false,
    modalVisibilityChanged,
    practitioner,
    srcImg,
  } = props;
    const [t, i18n] = useTranslation();
  const [showDate, setShowDate] = useState(false);
  const [showStartTime, setShowStartTime] = useState(false);
  const [showEndTime, setShowEndTime] = useState(false);
  const [submitError, setSubmitError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const form = useForm();
  const {scheduleAppointment} = usePatient();
  const {professionalSearchTerm, filterParams} = usePatientSearch();
  const navigate = useNavigate();

  function changeModalDisplayed(visibility: boolean) {
    modalVisibilityChanged && modalVisibilityChanged(visibility);
  }
  const localDate = form.watch('date');
  const date = useMemo(() => {localDate?.setHours(0, 0, 0, 0); return localDate}, [localDate]);

  function handleShowDate() {
    if(!showDate && !form.getValues('date')) {
      form.setValue('date', addDays(new Date(), 1), {shouldDirty: true});
    }
    setShowDate(!showDate);
  }

  function handleShowTime() {
    setShowStartTime(!showStartTime);
  }

  function handleShowEndTime() {
    setShowEndTime(!showEndTime);
  }

  const updateModal = useCallback(
      (visibility: boolean) => changeModalDisplayed(visibility),
      []
  );

  function onSubmit(data: FieldValues) {
    console.log('data', data);
    const date = form.getValues('date');
    const startTime = form.getValues('startTime');
    ampli.track({event_type:"Selected Practitioner"}, {extra:{practitionerId: practitioner?.subject, searchKeyword: professionalSearchTerm, filters: filterParams}});
    if(!date)
      setSubmitError('Please select a date');
    else if(!startTime)
      setSubmitError('Please select a start time');
    else {
      ampli.track({event_type:"Scheduled Appointment"});
      const [startSlotId, endSlotId] = startTime?.split('|');
      setSubmitError(undefined);
      if (!submitError) {
        setLoading(true);
        scheduleAppointment && scheduleAppointment([startSlotId, endSlotId], practitioner!.subject)
            .then(() => {
              setLoading(false);
              setIsSuccess(true)
            }).catch((err) => {
              setLoading(false);
              console.error("Unable to schedule appointment", err);
              setSubmitError("Unable to schedule appointment");
            });
      }
    }
  }


  // resetting all values each time modal is closed

  const handleUpdateModal = useCallback( () => {
    updateModal(false);
    setShowDate(false);
    setShowStartTime(false);
    setShowEndTime(false);
    setIsSuccess(false)
    form.reset();
  }, []);

  return (
      <CenterModal isOpen={displayModal} onClose={ handleUpdateModal}>
        <form onSubmit={form.handleSubmit(onSubmit)} className={'p-4'}>
          <ScheduleProvider practitionerId={practitioner!.subject} day={date}>
          {!showDate && !showStartTime && (
              <article className="flex flex-col h-[500px] px-5 w-96 justify-between overflow-y-auto">
                <div className="flex flex-col">
                  <div className="flex items-center justify-between py-4">
                    <h1 className="text-xl font-semibold">{t('SCHEDULE_APPOINTMENT')}</h1>

                    <button type="button" onClick={handleUpdateModal}>
                      <FontAwesomeIcon icon={faXmark}/>
                    </button>
                  </div>
                </div>

                <div className="flex flex-col  mt-5">
                  <div
                      className="h-full border-[0.15rem] bg-opacity-10 rounded-sm border-orange-300 bg-orange-200 px-2">
                    <ProfileSummary
                        src={srcImg}
                        name={practitioner?.name?.[0].text}
                        description={
                          practitioner?.organization
                              ? practitioner.organization.name
                              : '---'
                        }
                    />
                  </div>
                </div>

                <div className="flex flex-col mt-5">
                  <p className="text-sm font-bold">
                    {t('SCHEDULE_YOUR_VIRTUAL_APPOINTMENT')}
                  </p>
                </div>

                <div
                    onClick={() => handleShowDate()}
                    className="flex justify-between items-center px-2 py-2 mt-1 bg-gray-100 hover:cursor-pointer"
                >
                  <div className="flex gap-3">
                    <div className="w-10 rounded-full p-2 flex justify-center items-center bg-white">
                  <span>
                    <FontAwesomeIcon
                        icon={faCalendar}
                        className="text-orange-500"
                    />
                  </span>
                    </div>
                    <div className="grid">
                      <p className="text-slate-500 uppercase text-xs">{t('DATE')}</p>
                      {date ? (
                          <p className="text-sm">
                              {date?.toLocaleDateString(i18n.resolvedLanguage, {
                              weekday: 'short',
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                            })}
                          </p>
                      ) : (
                          <p className="text-sm text-orange-500">{t('SELECT_DATE')}</p>
                      )}
                    </div>
                  </div>

                  <div className="items-center grid justify-center">
                <span>
                  <FontAwesomeIcon icon={faAngleRight}/>
                </span>
                  </div>
                </div>

                <div
                    onClick={() => handleShowTime()}
                    className="flex justify-between items-center px-2 py-2 bg-gray-100 hover:cursor-pointer"
                >
                 <TimeSummary endTime={form.getValues('endTime')} startTime={form.getValues('startTime')} />

                  <div className="items-center grid justify-center">
                <span>
                  <FontAwesomeIcon icon={faAngleRight}/>
                </span>
                  </div>
                </div>

                <div className="flex mt-14 justify-end">
                  <PrimaryButton style={{width: '25%'}} >{t('SAVE')}</PrimaryButton>
                </div>
              </article>
          )}

          {showDate && (
              <article className="flex flex-col h-[500px] px-5 w-96 justify-between overflow-y-auto">
                <div className="flex flex-col">
                  <div className="flex items-center justify-between py-4">
                    <h1 className="text-xl font-semibold">{t('DATE')}</h1>

                    <button type="button" onClick={() => handleUpdateModal()}>
                      <FontAwesomeIcon icon={faXmark}/>
                    </button>
                  </div>
                </div>

                <div className="mt-5">
                  <p className="font-bold text-sm">
                    {t('Select date for an appointment')}
                  </p>
                </div>

                <div className="h-full mt-3">
                  <div className={'flex flex-col'}>
                    <div className={'my-auto'}>
                      <Controller
                          name="date"
                          control={form.control}
                          rules={{ required: true}}
                          render={({field}) => (
                            <Calendar
                              isModal
                              onChange={field.onChange}
                              minDate={addDays(new Date(), 1)}
                              maxDate={addDays(new Date(), 30)}
                              defaultDate={form.getValues('date')}
                            />
                          )}
                      />
                      <span className={'text-red-600'}>
                    {form.formState.errors['date']?.type === 'required' && (
                        <p role="alert">{t('Date is required')}</p>
                    )}
                  </span>
                    </div>
                  </div>
                </div>

                <div className="flex mt-14 justify-end gap-2">
                  <PrimaryButton
                      style={{width: '25%'}}
                      type={'button'}
                      onClick={() => handleShowDate()}
                      outLine={true}
                  >
                    <div className="flex justify-center gap-2">
                  <span>
                    <FontAwesomeIcon icon={faArrowLeft}/>
                  </span>
                      {t('BACK')}
                    </div>
                  </PrimaryButton>

                  <PrimaryButton
                      style={{width: '25%'}}
                      onClick={() =>  handleShowDate()}
                      type={'button'}
                  >
                    {t('SAVE')}
                  </PrimaryButton>
                </div>
              </article>
          )}

          {showStartTime && !showEndTime && (
              <article
                  className={`flex flex-col h-[500px] px-5 w-96 justify-between overflow-y-auto`}
              >
                <div className="flex flex-col">
                  <div className="flex items-center justify-between py-4">
                    <h1 className="text-xl font-semibold">{t('TIME')}</h1>

                    <button type="button" onClick={() => handleUpdateModal()}>
                      <FontAwesomeIcon icon={faXmark}/>
                    </button>
                  </div>
                </div>

                <div className="mt-5">
                  <p className="font-bold text-sm">
                    {t('SELECT_TIME_FOR_STARTING_AN_APPOINTMENT')}
                  </p>
                </div>

                <StartTime form={form}/>

                <div className="flex mt-5 justify-end gap-2">
                  <div className='w-[116px]'>
                    <PrimaryButton
                        onClick={() => handleShowTime()}
                        outLine={true}
                    >
                      <div className="flex justify-center gap-2">
                    <span>
                      <FontAwesomeIcon icon={faArrowLeft}/>
                    </span>
                        {t('BACK')}
                      </div>
                    </PrimaryButton>
                  </div>
                  <div className='w-[116px]'>
                    <SubmitPrimaryButton
                        isLoading={loading}
                    >
                      {t('SAVE')}
                    </SubmitPrimaryButton>
                  </div>
                </div>
              </article>
          )}

          {showEndTime && (
              <article className="flex flex-col h-[500px] px-5 w-96 justify-between overflow-y-auto">
                <div className="flex flex-col">
                  <div className="flex items-center justify-between py-4">
                    <h1 className="text-xl font-semibold">{t('TIME')}</h1>

                    <button type="button" onClick={() => handleUpdateModal()}>
                      <FontAwesomeIcon icon={faXmark}/>
                    </button>
                  </div>
                </div>

                <StartTimeSummary startTime={form.getValues('startTime')}/>

                <div className="mt-5">
                  <p className="font-bold text-sm">
                    {t('Select time for ending an appointment')}
                  </p>
                </div>

                <EndTime form={form} startTime={form.getValues('startTime')}/>

                <span className={'text-red-600'}> {submitError}</span>
                <div className="flex mt-14 justify-end gap-2">
                  <PrimaryButton
                      style={{width: '25%'}}
                      onClick={() => {
                        // handleShowEndTime();
                        setShowStartTime(true);
                      }}
                      outLine={true}
                  >
                    <div className="flex justify-center gap-2">
                  <span>
                    <FontAwesomeIcon icon={faArrowLeft}/>
                  </span>
                      {t('BACK')}
                    </div>
                  </PrimaryButton>
<div className={'w-24'}>
                  <SubmitPrimaryButton
                      isLoading={loading}
                  >
                    {t('SAVE')}
                  </SubmitPrimaryButton>
</div>
                </div>
              </article>
          )}
          </ScheduleProvider>
        </form>
        {isSuccess && (
          <Success
            open={isSuccess}
            onAccept={() => {
              handleUpdateModal();
              navigate('/patient/dashboard/appointments');
            }}
            content={t('APPOINTMENT_REQUESTED', 'Appointment requested')}
            acceptBtnText={t('OK')}
            onClose={handleUpdateModal}
          />
        )}
      </CenterModal>
  );
}

export default memo(BookAppointment);
