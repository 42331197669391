import React, {useState} from 'react';
import InputGroup from "../group/InputGroup";
import {useForm} from "react-hook-form";
import SelectInput from "../input/SelectInput";
import useCodeSets from "../../hooks/useCodeSets";
import {InputRadio} from "../input/InputRadio";
import DualSelect from "../input/DualSelect";
import TextAreaInput from "../input/TextAreaInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faPlus} from "@fortawesome/free-solid-svg-icons";
import SubmitPrimaryButton from "../button/SubmitPrimaryButton";
import useProfessional from "../../hooks/useProfessional";
import {Communication} from "../../contexts/ProfessionalProvider";
import {OnboardingSection} from "../../pages/professional/onboarding/SideNav";

function ProfessionalInformation(props: { onSectionCompleted: (section: OnboardingSection) => any }) {
    const {onSectionCompleted} = props;
    const {register, handleSubmit, control, formState: {errors}} = useForm();
    const {
        professionCodes,
        serviceProvisionCodes,
        sexCodes,
        genderCodes,
        pronounCodes,
        raceCodes,
        languagesCodes
    } = useCodeSets();
    const {setPractitionerProfile} = useProfessional();
    const [languageLength, setLanguageLength] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [submitError, setSubmitError] = useState<string>();

    function addLanguage() {
        setLanguageLength(languageLength + 1);
    }

    function submitForm(data: any) {
        const serviceKeyList = Object.keys(data).filter(value => value.startsWith('service-provided'));

        const languageKeyList = Object.keys(data).filter(value => value.startsWith('language'));
        const languageList = [];
        for (const languageKeyElement of languageKeyList) {
            languageList.push(data[languageKeyElement]);
        }
        const serviceList = [];
        for (const serviceKeyElement of serviceKeyList) {

            let item = data[serviceKeyElement];
            if(item === 'yes')
            serviceList.push(item);
        }
        if (professionCodes && languagesCodes && raceCodes && sexCodes && serviceProvisionCodes &&genderCodes && pronounCodes && raceCodes) {
            const languages = languageList.map( value => (languagesCodes.codes.find(value2 => value === value2.code) || languagesCodes.codes[0] ));
            const communication = {coding:languages, text: 'communication'} as  Communication;
            const services = serviceList.map( value => (serviceProvisionCodes.codes.find(value2 => value === value2.code ) || serviceProvisionCodes.codes[0] ));

            const profession = professionCodes?.codes.find(value => value.code === data.profession) || professionCodes?.codes[0];

            const pronouns = pronounCodes.codes.find(value => value.code === data.pronouns) || pronounCodes.codes[0];
            const gender = genderCodes.codes.find(value => value.code === data.gender) || genderCodes.codes[0];
            const race = raceCodes.codes.find(value => value.code === data.race) || raceCodes.codes[0];
            const aboutMe = data.aboutMe;
            const interestedActivities = data.interestedActivities;


            setLoading(true);
            setPractitionerProfile && setPractitionerProfile(profession,services,aboutMe,interestedActivities,gender,pronouns,race, communication)
                .then((anything)=> {
                    onSectionCompleted(OnboardingSection.ProfessionalInformation);
                    setLoading(false);
                }).catch(reason => {
                    setLoading(false);
                    setSubmitError(reason.message);
                   console.error("Unable to set practitioner profile", reason);
                });
        }


    }

    return (
        <>
            <form onSubmit={handleSubmit(submitForm)}>
            <div className={'px-12 py-3'}>
                <h2 className={'font-medium text-sm uppercase'}>Profession and experience</h2>
            </div>
            <InputGroup>
                <div className="grid lg:grid-cols-2 gap-4">
                <div className={' col-span-1 '}>
                    <SelectInput inputLabel={'Profession'} id={'profession'}
                                 aria-invalid={errors.profession ? "true" : "false"}
                                 placeholder={'Doctor of Medicine (MD)'} required={true} defaultValue={''}
                                 {...register("profession", {
                                     required: true,
                                 })} >
                        <option disabled value={''}>Select</option>
                        {professionCodes?.codes?.map(({code, display}) => {
                            return (<option className={'text-ellipsis '} key={code} value={code}> {display} ({code}) </option>);
                        })}

                    </SelectInput>
                    <span className={'text-red-600'}>{errors.pronouns?.type === 'required' &&
                        <p role="alert">Profession is required</p>}</span>

                </div>
                </div>
            </InputGroup>
            <div className={'px-12 py-3'}>
                <h2 className={'font-medium text-sm uppercase'}>Services Provided</h2>
            </div>
            <InputGroup>
                <div className="grid lg:grid-cols-2 gap-4">
                    { serviceProvisionCodes?.codes.map(({code, display})=>{
                        return (
                            <div key={code} className={'flex flex-row justify-between col-span-1'}>
                                <span className={'my-auto'}><b>{display}</b></span>
                                <div className={'w-50 '}>
                                    <DualSelect>
                                        <InputRadio inputLabel={'Yes'}
                                                    optionValue={'yes'} id={`${code}_yes`}
                                                   {...register(`service-provided-${code}`, {
                                            required: true,
                                        })}/>
                                        <InputRadio inputLabel={'No'}
                                                    optionValue={'no'} defaultChecked={true}
                                                    id={`${code}_no`}     {...register(`service-provided-${code}`, {
                                            required: true,
                                        })}/>

                                    </DualSelect>
                                </div>
                            </div>
                        );
                        })}

                </div>
            </InputGroup>
            <div className={'px-12 py-3'}>
                <h2 className={'font-medium text-sm uppercase'}>Overview</h2>
            </div>
            <InputGroup>
                <div className="grid lg:grid-cols-2 gap-4">
                <div className={' col-span-full'}>
                    <TextAreaInput inputLabel={'About Me'} id={'about-me'} rows={"4"}
                               placeholder={'Description'}
                               type={'text'} {...register("aboutMe", {
                        required: false,
                    })} />
                    <span className={'text-red-600'}>{errors['student-id']?.type === 'required' &&
                        <p role="alert">About Me is required</p>}</span>

                </div>
                <div className={' col-span-full'}>
                    <TextAreaInput inputLabel={'Interests and Activities'} id={'interest-and-activities'}
                               placeholder={'Description'} rows={"4"}
                               type={'text'} {...register("interestedActivities", {
                        required: false,
                    })} />
                    <span className={'text-red-600'}>{errors['interestedActivities']?.type === 'required' &&
                        <p role="alert">Interests and Activities is required</p>}</span>

                </div>
                    <div className={''}>
                        <SelectInput inputLabel={'Gender'} id={'gender'}
                                     aria-invalid={errors.gender ? "true" : "false"}
                                     placeholder={'Select'} required={true} defaultValue={''}
                                     {...register("gender", {
                                         required: true,
                                     })} >
                            <option disabled value={''}>Select</option>
                            {sexCodes?.codes?.map(({code, display}) => {
                                return (<option  key={code} value={code}> {display} </option>);
                            })}

                        </SelectInput>
                        <span className={'text-red-600'}>{errors.pronouns?.type === 'required' &&
                            <p role="alert">Gender is required</p>}</span>

                    </div>
                    <div className={' '}>
                        <SelectInput inputLabel={'Preferred Pronouns'} id={'pronouns'}
                                     aria-invalid={errors.pronounCodes ? "true" : "false"}
                                     placeholder={'Select'} required={true} defaultValue={''}
                                     arial-description={pronounCodes?.description}
                                     {...register("pronouns", {
                                         required: true,
                                     })} >
                            <option disabled value={''}>Select</option>
                            {pronounCodes?.codes?.map(({code, display}) => {
                                return (<option key={code} value={code}> {display} </option>);
                            })}

                        </SelectInput>
                        <span className={'text-red-600'}>{errors.pronouns?.type === 'required' &&
                            <p role="alert">Pronouns are required</p>}</span>

                    </div>
                    <div>
                        <SelectInput inputLabel={'Race'} id={'race'} placeholder={'Select'}
                                     arial-description={raceCodes?.description}
                                     required={true} defaultValue={''}
                                     aria-invalid={errors.raceCodes ? "true" : "false"}
                                     {...register("race", {
                                         required: true,
                                     })} >
                            <option disabled value={''}>Select</option>
                            {raceCodes?.codes?.map(({code, display}) => {
                                return (<option key={code} value={code}> {display} </option>);
                            })}

                        </SelectInput>
                        <span className={'text-red-600'}>{errors.race?.type === 'required' &&
                            <p role="alert">Race is required</p>}</span>

                    </div>

                    <div>
                        <div className={'flex flex-row '}>
                                <div className={'w-full '}>
                                {Array.from(Array(languageLength), (e,i)=>{
                                   return <SelectInput key={i} inputLabel={i ===0? 'Languages Spoken': ''} id={'race'} placeholder={'Select'}
                                                 arial-description={languagesCodes?.description}
                                                 required={i ===0} defaultValue={'en'}
                                                 aria-invalid={errors[`language-${1}`] ? "true" : "false"}
                                                 {...register(`language-${i+1}`, {
                                                     required: i ===0,
                                                 })} >
                                        <option disabled value={''}>Select</option>
                                        {languagesCodes?.codes?.map(({code, display}) => {
                                            return (<option  key={code+display} value={code}> {display} </option>);
                                        })}

                                    </SelectInput>
                                })}
                                </div>


                            <div className={'bg-brand-color ml-3 text-brand-color cursor-pointer text-center  p-3 mb-3 mt-auto bg-opacity-10'} onClick={()=>addLanguage()}>
                                <FontAwesomeIcon icon={faPlus} className={'w-5'}/>
                            </div>
                        </div>
                        <span className={'text-red-600'}>{errors['language-1']?.type === 'required' &&
                            <p role="alert">Language Spoken is required</p>}</span>

                    </div>
                </div>
                <span className={'text-red-600'}>{submitError}</span>
                <div className={'w-40 ml-auto border mt-14 '}>
                    <SubmitPrimaryButton isLoading={loading}>Continue <FontAwesomeIcon
                        icon={faArrowRight}/></SubmitPrimaryButton>
                </div>

            </InputGroup>
            </form>
        </>
    );
}

export default ProfessionalInformation;
