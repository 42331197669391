import {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import TutorialGuide from "../modal/TutorialGuide";
import {Tutorial} from "../../types/database/SharedTypes";
import {ampli} from "../../ampli";

function TutorialItem(props: { tutorial: Tutorial, updateVisitedItems: (item: number) => void, active?: boolean }) {
  const { tutorial, updateVisitedItems, active } = props;
  const [tutorialModalShow, setTutorialModalShow] = useState<boolean>();

  return (
    <div>
      <button
        onClick={() => {setTutorialModalShow(true); ampli.track({event_type:"Tutorial start"}, {extra:{Flow:tutorial?.title,"Steps total": tutorial?.steps?.length  }});}}
        className={`${active ? 'border-lime-green' : 'border-gray-100'} w-full border-solid border flex flex-row justify-between rounded px-3 py-5 hover:border-brand-color`}
      >
        <div className={'flex flex-row'}>
          <div className={`${active ? 'text-lime-green bg-lime-green' : 'text-brand-color bg-brand-color'} flex items-center justify-center w-12 min-w-[48px] h-12 rounded-full mr-3 bg-opacity-5 [&>svg]:w-6 [&>svg]:h-6`}>
            {tutorial?.icon}
          </div>
          <span className={'my-auto text-sm font-semibold align-top pr-1 text-left'}>
            <b>{tutorial.title}</b>
          </span>
        </div>
        <FontAwesomeIcon icon={faChevronRight} color='#8C8C8C' className={'ml-auto my-auto mr-3'} />
      </button>
      {tutorial.steps &&
        <TutorialGuide
          tutorial={tutorial}
          showModalInitial={tutorialModalShow}
          updateModal={setTutorialModalShow}
          updateVisitedItems={updateVisitedItems}
        />
      }
    </div>
  );
}

export default TutorialItem;