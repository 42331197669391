import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";

interface SelectInputProps extends React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
    [x: string]: any;
    inputName: string;
    id: string;
    inputLabel: string,
    children: any,
    required?: boolean
}

function SelectInput(props: SelectInputProps, ref: React.ForwardedRef<any>) {
    const { inputName, inputLabel, id, children, required, className, ...extraProps } = props;
    return (
        <>
            <div className={'flex flex-col py-3 text-ellipsis '}>
                <label htmlFor={id} className={`font-medium text-xs ${inputLabel && 'pb-2'}`}>{inputLabel} {required ?
                    <span className="required text-red-600">*</span> : ''}</label>
                    <div className='relative custom-select'>
                        <select className={className + ' ' + 'rounded-sm h-12  w-full overflow-ellipsis border-2 pl-3 pr-5 text-left disabled:text-inherit  font-normal text-sm border border-[#EBEBEB] border-[1px] rounded-sm'}
                            name={inputName}
                            id={id} ref={ref}  {...extraProps} >
                            {children}
                        </select>
                        <FontAwesomeIcon icon={faChevronDown} className='absolute w-3 h-3 right-3 top-[18px] text-brand-color' />
                    </div>
            </div>
        </>
    );
}

export default React.forwardRef(SelectInput);