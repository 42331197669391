import React, {ReactNode, useContext, useEffect, useState} from "react";
import {ServerReply} from "../../types/database/SharedTypes";
import {API} from "aws-amplify";
import {ProfessionalContext} from "../ProfessionalProvider";

type EvaluationContextType = {
  evaluations?: any[];
  evaluatePatientImmunization?: (studentSubject: string, body: object) => Promise<any>;
  handleGetImmunizationEvaluations?: () => void,
};

const EvaluationContext = React.createContext<EvaluationContextType>({});
export const useEvaluation = () => React.useContext(EvaluationContext);


export function EvaluationProvider(props: { children: ReactNode, immunizationId?: string }) {
  const { immunizationId } = props;
  const { professional } = useContext(ProfessionalContext);
  const [evaluations, setEvaluations] = useState<any[]>([]);

  async function getPatientImmunizationEvaluations(subjectID: string, immunizationId: string): Promise<ServerReply<any>> {
    const apiName = 'LAUSDPractitionerEndpoint';
    const path = `/practitioner/immunization/evaluations/${subjectID}/${immunizationId}`;
    const myInit = {
      body: {},
      headers: {}
    };
    return API.get(apiName, path, myInit);
  }

  async function evaluatePatientImmunization(studentSubject: string, body: object): Promise<ServerReply<any>> {
    const apiName = 'LAUSDPractitionerEndpoint';
    const path = `/practitioner/immunization/evaluation/${studentSubject}`;
    const myInit = {
      body: body,
      headers: {}
    };
    return API.post(apiName, path, myInit);
  }

  async function handleGetImmunizationEvaluations() {
    if (professional && professional.subject && immunizationId && evaluations.length === 0) {
      const value = await getPatientImmunizationEvaluations(professional.subject, immunizationId)
      setEvaluations(value.body.items);
    }
  }

  useEffect(() => {
    handleGetImmunizationEvaluations()
  }, [professional, immunizationId]);
  return (
    <EvaluationContext.Provider value={{
      evaluations,
      evaluatePatientImmunization,
      handleGetImmunizationEvaluations
    }}>
      {props.children}
    </EvaluationContext.Provider>
  );
};