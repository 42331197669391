import React, {createContext, useEffect, useState} from 'react';
import {API} from "aws-amplify";


export interface CodeValueSets {
    id: string;
    description: string;
    codes: TypedDisplayInfo[];
}

export interface TypedDisplayInfo {
    code: string;
    display: string;
    system: string;
}

export const CodeContext = createContext<{
    getGenderCodes?: () => Promise<{ body: CodeValueSets }>,
    getRaceCodes?: () => Promise<{ body: CodeValueSets }>,
    getPronouns?: () => Promise<{ body: CodeValueSets }>,
    getRelationships?: () => Promise<{ body: CodeValueSets }>,
    getImmunizationCodes?: () => Promise<{ body: CodeValueSets }>,
    genderCodes?: CodeValueSets,
    raceCodes?: CodeValueSets,
    pronounCodes?: CodeValueSets,
    immunizationCodes?: CodeValueSets,
    sexCodes?: CodeValueSets,
    relationshipCodes?: CodeValueSets,
    professionCodes?: CodeValueSets,
    languagesCodes?: CodeValueSets,
    serviceProvisionCodes?: CodeValueSets,
    patient?: any,
}>({});

function CodeProvider(props: { children: React.ReactElement<any, any> }) {
    const {children} = props;
    const [genderCodes, setGenderCodes] = useState<CodeValueSets>();
    const [raceCodes, setRaceCodes] = useState<CodeValueSets>();
    const [pronounCodes, setPronounCodes] = useState<CodeValueSets>();
    const [relationshipCodes, setRelationshipCodes] = useState<CodeValueSets>();
    const [professionCodes, setProfessionCodes] = useState<CodeValueSets>();
    const [languagesCodes, setLanguagesCodes] = useState<CodeValueSets>();
    const [sexCodes, setSexCodes] = useState<CodeValueSets>();
    const [immunizationCodes, setImmunizationCodes] = useState<CodeValueSets>();
    const [serviceProvisionCodes, setServiceProvisionCodes] = useState<CodeValueSets>();

    function getGenderCodes(): Promise<{ body: CodeValueSets }> {
        const system = "gender";
        const path = `/valueset/codes?system=${system}`;
        const apiName = 'LAUSDAppEndpoint';
        const myInit = {
            body: {}
        };
        return API.get(apiName, path, myInit);
    }

    function getImmunizationCodes(): Promise<{ body: CodeValueSets }> {
        const system = "immunizationRecommendationStatus";
        const path = `/valueset/codes?system=${system}`;
        const apiName = 'LAUSDAppEndpoint';
        const myInit = {
            body: {}
        };
        return API.get(apiName, path, myInit);
    }

    function getRaceCodes(): Promise<{ body: CodeValueSets }> {
        const system = "race";
        const path = `/valueset/codes?system=${system}`;
        const apiName = 'LAUSDAppEndpoint';
        const myInit = {
            body: {}
        };
        return API.get(apiName, path, myInit);
    }

    function getSexCodes(): Promise<{ body: CodeValueSets }> {
        const system = "sex";
        const path = `/valueset/codes?system=${system}`;
        const apiName = 'LAUSDAppEndpoint';
        const myInit = {
            body: {}
        };
        return API.get(apiName, path, myInit);
    }

    function getServiceProvisionCodes(): Promise<{ body: CodeValueSets }> {
        const system = "serviceProvisionCodes";
        const path = `/valueset/codes?system=${system}`;
        const apiName = 'LAUSDAppEndpoint';
        const myInit = {
            body: {}
        };
        return API.get(apiName, path, myInit);
    }

    function getPronouns(): Promise<{ body: CodeValueSets }> {
        const system = "pronouns";
        const path = `/valueset/codes?system=${system}`;
        const apiName = 'LAUSDAppEndpoint';
        const myInit = {
            body: {}
        };
        return API.get(apiName, path, myInit);
    }

    function getRelationships(): Promise<{ body: CodeValueSets }> {
        const system = "relatedPersonType";
        const path = `/valueset/codes?system=${system}`;
        const apiName = 'LAUSDAppEndpoint';
        const myInit = {
            body: {}
        };
        return API.get(apiName, path, myInit);
    }

    function getProfessions(): Promise<{ body: CodeValueSets }> {
        const system = "profession";
        const path = `/valueset/codes?system=${system}`;
        const apiName = 'LAUSDAppEndpoint';
        const myInit = {
            body: {}
        };
        return API.get(apiName, path, myInit);
    }
    function getLanguages(): Promise<{ body: CodeValueSets }> {
        const system = "languages";
        const path = `/valueset/codes?system=${system}`;
        const apiName = 'LAUSDAppEndpoint';
        const myInit = {
            body: {}
        };
        return API.get(apiName, path, myInit);
    }

    //const genderCode = useMemo(() => getGenderCodes(), [genderCodes]);

    useEffect(() => {
        if(!genderCodes) {
            getGenderCodes().then((value) => {
                setGenderCodes(value.body);
            });
        }
    }, [genderCodes]);
    useEffect(() => {

        if(!pronounCodes) {
            getPronouns().then(value => {
                setPronounCodes(value.body);
            });
        }


    }, [pronounCodes]);
    useEffect(() => {
        if(!raceCodes) {
            getRaceCodes().then(value => {
                setRaceCodes(value.body);
            })
        }

    }, [raceCodes]);
    useEffect(() => {
        if(!relationshipCodes) {
            getRelationships().then(value => {
                setRelationshipCodes(value.body);
            })
        }

    }, [relationshipCodes]);
    useEffect(() => {
        if (!professionCodes) {
            getProfessions().then(value => {
                setProfessionCodes(value.body);
            })
        }

    }, [professionCodes]);
    useEffect(() => {
        if (!immunizationCodes) {
            getImmunizationCodes().then(value => {
                setImmunizationCodes(value.body);
            })
        }

    }, [immunizationCodes]);
    useEffect(() => {
        if (!languagesCodes) {
            getLanguages().then(value => {
                setLanguagesCodes(value.body);
            })
        }

    }, [languagesCodes]);
    useEffect(() => {
        if (!sexCodes) {
            getSexCodes().then(value=>{
                setSexCodes(value.body);
            })
        }

    }, [sexCodes]);
    useEffect(() => {
        if(!serviceProvisionCodes){
            getServiceProvisionCodes().then(value => {
                setServiceProvisionCodes(value.body);
            })
        }
    }, [serviceProvisionCodes]);
    return (
        <CodeContext.Provider
            value={{
                getRelationships,
                getRaceCodes,
                getImmunizationCodes,
                getPronouns,
                getGenderCodes,
                raceCodes,
                immunizationCodes,
                genderCodes,
                relationshipCodes,
                pronounCodes,
                professionCodes,
                languagesCodes,
                sexCodes,
                serviceProvisionCodes,
            }}>
            {children}
        </CodeContext.Provider>
    );
}

export default CodeProvider;