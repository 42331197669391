import React, {useMemo} from 'react';
import {Immunization} from "../../types/database/SharedTypes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faSyringe} from "@fortawesome/free-solid-svg-icons";
import UploadImmunizationStatus from "./UploadImmunizationStatus";
import {getImmunizationStatus} from "../../util/immunization/immunization";

function ImmunizationItem(props:{immunization: Immunization, onClick: () => void, disabledOnMissingShot?: boolean}) {

    const {immunization,disabledOnMissingShot= false, onClick} = props;
    const immunizationStatus = useMemo(()=> getImmunizationStatus(immunization), [immunization]);
    const isMissingShot = immunizationStatus === "missingShot";


    return (
        <div>
            <div
                onClick={onClick}
                className={`${isMissingShot ? 'border-dashed border-brand-color text-brand-color' : 'border-light-grey border-solid'} ${disabledOnMissingShot && isMissingShot && 'border-solid border-light-grey text-grey !cursor-not-allowed	'} border cursor-pointer flex flex-col justify-center rounded-sm  px-5 py-4 h-full min-h-[82px] hover:bg-primary-hover`}
            >
                <div className={'flex flex-row items-center justify-between'}>
                    <div className='flex items-center'>
                        <FontAwesomeIcon icon={faSyringe} className={`${isMissingShot ? 'text-brand-color' : 'text-violet-500'} ${disabledOnMissingShot && isMissingShot && 'text-grey'} h-6 w-6 mr-3`} />
                        <span className={'text-base font-semibold'}>{immunization?.vaccineCode?.coding?.[0]?.display}</span>
                    </div>
                    <div>
                        {!isMissingShot && <FontAwesomeIcon icon={faChevronRight} className={'w-4 h-4 text-heading'} />}
                    </div>
                </div>
                {!isMissingShot && (
                    <div className='ml-9'>
                        <UploadImmunizationStatus immunization={immunization} />
                    </div>
                )}
            </div>
        </div>
    );
}

export default ImmunizationItem;
