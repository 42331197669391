import React, {createContext, ReactNode, useContext} from 'react';

type PerformanceContextType = {
  reviews?: any[];
  reportDataList?: any[];
};

const PerformanceContext = createContext<PerformanceContextType>({});

export const usePerformance = () => useContext(PerformanceContext);

export function PerformanceProvider(props: { children: ReactNode }) {
  const reviews = [
    { id: 'PA-1ac66f0b-1016-4125-853c-90139d14174f', name: 'Gary Wilson', managingOrganization: { id: '2372', name: "OCHOA LC" }, vaccine: 'dTap', evaluation: { doseStatus: { coding: [{ code: 'valid' }] } }, date: '2023-07-11T02:09:42.057693' },
    { id: 'PA-550276cf-792b-4ec3-b9a4-cdd797fe591c', name: 'Mary Quin', managingOrganization: { id: '7014', name: "SYLMAR EL" }, vaccine: 'Polio', evaluation: { doseStatus: { coding: [{ code: 'valid' }] } }, date: '2023-07-09T02:10:48.057693' },
    { id: 'PA-6a88ef48-75de-4298-817b-61a68100fe73', name: 'Gram Doc', managingOrganization: { id: '7390', name: "LAKE BALBOA CP K-1" }, vaccine: 'Hepatitis A and B', evaluation: { doseStatus: { coding: [{ code: 'notvalid' }] } }, date: '2023-07-08T02:02:18.057693' },
    { id: 'PA-6ce4e170-8125-478e-bdd6-d53f0ce9c2e9', name: 'Soufiane Adli', managingOrganization: { id: '7541', name: "SCIENCE AC STEM MAG" }, vaccine: 'dTap', evaluation: { doseStatus: { coding: [{ code: 'valid' }] } }, date: '2023-07-04T02:10:28.057693' },
    { id: 'PA-71a0d80e-6087-4644-b5d1-6e2a17fa63b1', name: 'NORBU LOPEL', managingOrganization: { id: '7921', name: "Excelsior School" }, vaccine: 'dTap', evaluation: { doseStatus: { coding: [{ code: 'notvalid' }] } }, date: '2023-07-03T02:11:48.057693' },
    { id: 'PA-74234117-90ca-4181-b58f-758cec43c628', name: 'ISAIAH RODRIGUEZ', managingOrganization: { id: '8170', name: "HARTE PREP MS" }, vaccine: 'dTap', evaluation: { doseStatus: { coding: [{ code: 'valid' }] } }, date: '2023-07-02T02:10:44.057693' },
  ]

  const reportDataList = [
    {
      appointments: { new_patients: 78, old_patients: 34, duration: 28, reffered_appointments: 12 },
      workingHours: { average_hours: 88, total_hours: 4528 },
      immunizationEvaluations: { rejected: 102, approved: 660 },
      studentImmunization: [
        { name: 'Abraham Lincoln Senior High', count: 64 },
        { name: 'Excelsior School', count: 22 },
        { name: 'Gardena Senior High', count: 46 },
        { name: 'Excelsior School', count: 19 },
        { name: 'Gardena Senior High', count: 17 },
        { name: 'Abraham Lincoln Senior High', count: 13 },
        { name: 'Abraham Lincoln Senior High', count: 9 },
        { name: 'Excelsior School', count: 7 },
        { name: 'Abraham Lincoln Senior High', count: 6 },
        { name: 'Gardena Senior High', count: 3 }
      ]
    },
    {
      appointments: { new_patients: 56, old_patients: 98, duration: 34, reffered_appointments: 23 },
      workingHours: { average_hours: 78, total_hours: 4400 },
      immunizationEvaluations: { rejected: 98, approved: 740 },
      studentImmunization: [
        { name: 'Abraham Lincoln Senior High', count: 56 },
        { name: 'Excelsior School', count: 76 },
        { name: 'Gardena Senior High', count: 23},
        { name: 'Excelsior School', count: 19 },
        { name: 'Gardena Senior High', count: 17 },
        { name: 'Abraham Lincoln Senior High', count: 13 },
        { name: 'Abraham Lincoln Senior High', count: 9 },
        { name: 'Excelsior School', count: 7 },
        { name: 'Abraham Lincoln Senior High', count: 6 },
        { name: 'Gardena Senior High', count: 3 }
      ]
    },
    {
      appointments: { new_patients: 23, old_patients: 78, duration: 43, reffered_appointments: 6 },
      workingHours: { average_hours: 90, total_hours: 4900 },
      immunizationEvaluations: { rejected: 135, approved: 450 },
      studentImmunization: [
        { name: 'Abraham Lincoln Senior High', count: 46 },
        { name: 'Excelsior School', count: 22 },
        { name: 'Gardena Senior High', count: 78},
        { name: 'Excelsior School', count: 19 },
        { name: 'Gardena Senior High', count: 17 },
        { name: 'Abraham Lincoln Senior High', count: 13 },
        { name: 'Abraham Lincoln Senior High', count: 9 },
        { name: 'Excelsior School', count: 7 },
        { name: 'Abraham Lincoln Senior High', count: 6 },
        { name: 'Gardena Senior High', count: 3 }
      ]
    },
    {
      appointments: { new_patients: 56, old_patients: 93, duration: 56, reffered_appointments: 23 },
      workingHours: { average_hours: 102, total_hours: 5200 },
      immunizationEvaluations: { rejected: 56, approved: 379 },
      studentImmunization: [
        { name: 'Abraham Lincoln Senior High', count: 64 },
        { name: 'Excelsior School', count: 45 },
        { name: 'Gardena Senior High', count: 90},
        { name: 'Excelsior School', count: 19 },
        { name: 'Gardena Senior High', count: 17 },
        { name: 'Abraham Lincoln Senior High', count: 13 },
        { name: 'Abraham Lincoln Senior High', count: 9 },
        { name: 'Excelsior School', count: 7 },
        { name: 'Abraham Lincoln Senior High', count: 6 },
        { name: 'Gardena Senior High', count: 3 }
      ]
    },
    {
      appointments: { new_patients: 45, old_patients: 78, duration: 38, reffered_appointments: 11 },
      workingHours: { average_hours: 112, total_hours: 4378 },
      immunizationEvaluations: { rejected: 23, approved: 245 },
      studentImmunization: [
        { name: 'Abraham Lincoln Senior High', count: 78 },
        { name: 'Excelsior School', count: 85 },
        { name: 'Gardena Senior High', count: 46},
        { name: 'Excelsior School', count: 19 },
        { name: 'Gardena Senior High', count: 17 },
        { name: 'Abraham Lincoln Senior High', count: 13 },
        { name: 'Abraham Lincoln Senior High', count: 9 },
        { name: 'Excelsior School', count: 7 },
        { name: 'Abraham Lincoln Senior High', count: 6 },
        { name: 'Gardena Senior High', count: 3 }
      ]
    },
  ]

  return (
    <PerformanceContext.Provider
      value={{
        reviews,
        reportDataList
      }}
    >
      {props.children}
    </PerformanceContext.Provider>
  )
}