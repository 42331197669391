import React, {useState} from 'react';
import {Immunization} from "../../types/database/SharedTypes";
import ImmunizationItem from "./ImmunizationItem";
import PatientImmunizationModal from "./PatientImmunizationModal";

function PatientImmunizationItem(props:{immunization: Immunization, canAddDose?: boolean}) {
    const {immunization, canAddDose= true} = props;
    const [showModal, setShowModal] = useState<boolean>(false);


    return (
        <div>
            <ImmunizationItem  immunization={immunization} onClick={()=>{setShowModal(true)}}/>
            <PatientImmunizationModal showModal={showModal} immunization={immunization} canAddDose={canAddDose} onModalVisibilityChange={setShowModal}/>
        </div>
    );
}

export default PatientImmunizationItem;