import React, {useState} from 'react';
import TextInput from "../input/TextInput";
import useAuth from "../../hooks/useAuth";
import {useForm} from "react-hook-form";
import {useLocation, useNavigate} from "react-router-dom";
import SubmitPrimaryButton from "../button/SubmitPrimaryButton";


function ResetPasswordPane(props: any) {

    const {completeNewPassword, user} = useAuth();
    const {register, handleSubmit, formState: {errors}} = useForm();
    const [submitError, setSubmitError] = useState<string>();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);

    function onSubmit(data: any) {
        setLoading(true);
        setSubmitError(undefined);
        return completeNewPassword ? completeNewPassword(data.new_password).then(() => {
                setLoading(false);
                navigate({pathname: location.state.from, search: location.state.search});
            }).catch((reason: Error) => {
                setLoading(false);
                setSubmitError(reason.message);
            }) :
            console.error('badcode')
            ;

    }


    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <TextInput  inputLabel={'New Password'} id={'new_password'}
                           type={'password'} {...register("new_password", {required: true,})}/>
                <span className={'text-red-600'}>{submitError}</span>
                <span className={'text-red-600'}>{errors['new_password']?.type === 'required' &&
                    <p role="alert">New Password is required</p>}</span>

                <div className={'mt-6'}>
                    <SubmitPrimaryButton isLoading={loading}>Reset Password</SubmitPrimaryButton>
                </div>

            </form>
        </>
    );
}

export default ResetPasswordPane;