import React, {useEffect, useState} from 'react';
import {UserGroup} from "../../contexts/AmplifyApiProvider";
import useAuth from "../../hooks/useAuth";
import {Link, useNavigate} from "react-router-dom";
import LoadingSplash from "../../components/spinner/LoadingSplash";
import Logo from "../../components/logo/Logo";
import {CognitoUser} from "amazon-cognito-identity-js";
import PrimaryButton from "../../components/button/PrimaryButton";
import {ampli} from "../../ampli";
import {useTranslation} from "react-i18next";

function OauthLogin(props:{}) {
    const {getUserGroup, user} = useAuth();
    const navigate = useNavigate();
    const [oauthError, setOauthError] = useState<string>();
    const [t] = useTranslation();

    useEffect(() => {
        if(getUserGroup && user)
        navigateToDashboard(user);

    }, [user, getUserGroup]);
    useEffect(() => {
        ampli.track({event_type:"Login Attempt"});
    }, []);

    function navigateToDashboard(cognitoUser: CognitoUser) {
        const userGroup = getUserGroup && getUserGroup(cognitoUser);
        switch (userGroup) {
            case UserGroup.PATIENT:
                navigate("/patient/dashboard");
                break;
            case UserGroup.PRACTITIONER:
                navigate("/professional/dashboard");
                break;
            default:
                setOauthError("Account: " + cognitoUser?.getUsername()+ " "+t("THERE_WAS_AN_ISSUE_ACCESSING_YOUR_ACCOUNT_PLEASE_CONTACT_LAUSD_FOR_MORE_INFORMATION"));
        break;
        }

    }
    return <>
        {oauthError ?  <div className={'h-full w-full flex justify-center  flex-col'}>
                <div className={'w-40 mx-auto mb-5'}>
                    <Logo/>
                </div>
                <div className={'mx-auto'}>
                    <span className={'text-red-500'}>{oauthError}</span>
                    <Link to={'/'}><div className={'mt-5'}><PrimaryButton>{t("RETURN_HOME")}</PrimaryButton></div></Link>
                </div>
            </div> : <LoadingSplash/>}
    </>

}

export default OauthLogin;