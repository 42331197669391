import React from "react";

interface RadioProps {
  label: string;
  name: string;
  id:string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Radio = ({ label, name, value, onChange, id }: RadioProps,  ref: React.ForwardedRef<any>) => {
  return (
    <label className="flex items-center gap-3 cursor-pointer">
      <input
          className={'shadow-brand-color'}
        type="radio"
        ref={ref}
        name={name}
        id={id}
        value={value}
        onChange={onChange}
      />
      <span>{label}</span>
    </label>
  );
};

export default React.forwardRef(Radio);
