import {useEffect, useRef, useState} from "react";
import {useTranslation} from 'react-i18next';
import FilterComponent from "./FilterComponent";
import {Controller, useForm} from "react-hook-form";
import Calendar from "../calendar";

function PatientAppointmentFilterComponent(props: { showFilter: boolean, updateVisibility: (visibility: boolean) => void, setFilterParams?: (params: Record<string,string>) => void; }) {
  const { showFilter, updateVisibility, setFilterParams } = props;

  const [t] = useTranslation()

  const form = useForm();
  const { setValue, getValues, control } = form;

  const [showDropdown, setShowDropdown] = useState<boolean>(false)
  const [_, setFilterDate] = useState<any>() // Dummy state variable for re-render after setValue in React Hook Form. Do not remove.

  const elementRef = useRef<any>()

  const handleUpdateFilterParams = (formData: any) => {
    const data = JSON.parse(JSON.stringify(formData || {}))
    Object.keys(data).forEach(key => {
      if (typeof data[`${key}`] === 'undefined' || data[`${key}`] === null) {
        delete data[`${key}`]
      }
    })
    setFilterParams && setFilterParams(data);
  }

  const sortOptions: { code: string, display: string }[] = [
    { code: 'newest', display: t('NEWEST') },
    { code: 'oldest', display: t('OLDEST') },
  ]

  const handleSelect = (e: MouseEvent) => {
    if (e.target && !elementRef?.current?.contains(e.target)) {
      setShowDropdown(false)
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleSelect)
    return () => window.removeEventListener('click', handleSelect)
  }, [showDropdown])

  return (
    <FilterComponent
      form={form}
      displayFilter={showFilter}
      onUpdateFilterParams={handleUpdateFilterParams}
      filterVisibilityChanged={updateVisibility}
    >
      <div className="relative">
        <div
          className="flex justify-between items-center border-b border-b-neutral-10 mt-3 py-5 cursor-pointer"
          ref={elementRef}
          onClick={() => setShowDropdown(true)}
        >
          <span className="font-semibold">{t('SORTED_BY')}</span>
          <span className="text-[#8C8C8C] capitalize">{getValues('sort') ?? t('NEWEST')}</span>
        </div>
        {showDropdown && (
          <div className="absolute right-0 z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none border-[1px] border-[#8C8C8C]">
            <Controller
              name="sort"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <div>
                  {sortOptions.map(({ code, display }) => (
                    <div
                      key={code}
                      onClick={() => field.onChange(code)}
                      className={`${getValues('sort') === code && 'text-brand-color'} px-5 cursor-pointer hover:bg-[#f8a03a0d]`}
                    >
                        <span
                            className="py-[14px] block border-b border-b-neutral-10 h-full last:border-b-0"
                        >
                            {display}
                        </span>
                    </div>
                  ))}
                </div>
              )}
            />
          </div>
        )}
      </div>
      <div
        className="flex justify-between items-center border-b border-b-neutral-10 py-1"
      >
        <span className="font-semibold">{t('DATE')}</span>
        <div className="cursor-pointer">
          <Calendar
            placeholder=""
            title='Created Date'
            onChange={(date) => {
              setValue('date', date)
              setFilterDate(date)
            }}
            defaultDate={getValues('date')}
          />
        </div>
      </div>
    </FilterComponent>
  );
}

export default PatientAppointmentFilterComponent;