import React from 'react';

export interface InputRadioParams {
  inputName: string;
  inputLabel: string;
  optionValue: string;
  checked?: boolean;
  id: string;
  name?: string;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;

  [x: string]: any;
}

function AlternateInputRadioPrototype(
    props: InputRadioParams,
    ref: React.ForwardedRef<any>
) {
  const {
    inputName,
    inputLabel,
    optionValue,
    checked = false,
    id,
    ...extraProps
  } = props;

  return (
      <>
        <input
            type="radio"
            id={id}
            value={optionValue}
            {...extraProps}
            ref={ref}
            className="hidden peer"

        />
        <label
            htmlFor={id}
            className={ 'peer-checked:border peer-checked:border-orange-400 peer-checked:bg-opacity-10 peer-checked:bg-orange-400 inline-flex items-center justify-center w-[100%] p-2 text-gray-500 border border-gray-200 rounded-sm cursor-pointer   hover:text-gray-600 hover:bg-gray-100'}
        >
          <div className="block">
            <div className="w-full">{inputLabel}</div>
          </div>
        </label>
      </>
  );
}

const AlternateInputRadio = React.forwardRef(AlternateInputRadioPrototype);
export {AlternateInputRadio};
