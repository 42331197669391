import React, {useMemo} from 'react';
import CenterModal from "../dashboard-search/CenterModal";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function StatusChangeModal(props:{showModal: boolean, setShowModal: (showModal: boolean) => void, originalStatus: string, newStatus: string}) {
    const {showModal, setShowModal, newStatus, originalStatus} = props;

    const statusChangeText = useMemo(() => {
        if( originalStatus === 'yellow:pending' && newStatus === 'green:evaluation'){
            return "Note: The status has been updated to ‘Approved’. Please review the details.";
        } else if (originalStatus === 'yellow:pending' && newStatus === 'red:evaluation'){
            return "Note: The status has been updated to ‘Rejected’. Please review the details.";
        } else if (originalStatus === 'red:recommendation' && newStatus === 'yellow:pending'){
            return "Note: A parent has submitted new information. The status is now ‘Pending’. Please review.";
        } else if (originalStatus === 'red:recommendation' && newStatus === 'green:evaluation'){
            return "Note: The status has been updated to ‘Approved’. Please review the details.";
        } else if (originalStatus === 'red:recommendation' && newStatus === 'red:evaluation'){
            return "Note: The status has been updated to ‘Rejected’. Please review the details.";
        } else if (originalStatus === 'red:evaluation' && newStatus === 'yellow:pending'){
            return "Note: A parent has resubmitted information. The status is now ‘Pending’. Please review.";
        } else if(originalStatus === 'red:evaluation' && newStatus === 'green:evaluation'){
            return "Note: The status has been updated to ‘Approved’. Please review the details.";
        }
       return "Note: The status has been updated . Please review the details"
    }, [newStatus, originalStatus]);

    return (
        <CenterModal
            isOpen={showModal}
            onClose={() => setShowModal(false)}
        >
            <div className="flex flex-col items-center justify-center px-12 py-12 w-[90vw] md:w-fit">
                <div className={'rounded-full bg-opacity-5 px-8 py-7 text-brand-color bg-[#4fcc00] aspect-square'}>
                    <FontAwesomeIcon icon={faCheck} color={"#4fcc00"}  className={'h-12'} />
                </div>
                <p className="max-w-[300px] text-center font-semibold text-xl mt-6">{statusChangeText}</p>
            </div>
        </CenterModal>
    );
}

export default StatusChangeModal;