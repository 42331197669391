import React, {ReactNode, useContext, useEffect, useState} from "react";
import {FileServerReply} from "../types/database/SharedTypes";
import {API} from "aws-amplify";
import {AuthContext} from "./AuthContext";
import userGroupToPath from "../util/config/api/userGroupToPath";

type DocumentContextType = {
    document?: string;
};

const DocumentContext = React.createContext<DocumentContextType>({});
export const useDocument = () => React.useContext(DocumentContext);

export function DocumentProvider(props: { children: ReactNode, photoUrl: string }) {
    const [document, setDocument] = useState<string>();
    const {userProfile, userGroup} = useContext(AuthContext);

    async function getPhoto(photoPath: string): Promise<FileServerReply<any>> {
        const apiName = 'LAUSDDocumentEndpoint';
        const path = `/${userGroupToPath(userGroup)}/document/${userProfile}?path=${encodeURIComponent(photoPath)}`;
        const myInit = {
            body: {},
            headers: {}
        };
        return API.get(apiName, path, myInit);
    }
    useEffect(() => {
        if(props.photoUrl) {
            getPhoto(props.photoUrl).then((value)=>{
                setDocument(btoa(value.file));
            });
        } else {
            setDocument('');
        }
    }, [props.photoUrl]);
    return (
        <DocumentContext.Provider value={{
            document,
        }}>
            {props.children}
        </DocumentContext.Provider>
    );
};