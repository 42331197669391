import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import routes from './routes';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import './i18n';


console.log(`%cLAUSD Page Starting %cv${process.env.REACT_APP_VERSION || 'unknown'}`, 'background: #0000bb; color: #ffffff','background: #F8A03A; color: #ffffff');
if (process.env.NODE_ENV !== "development")
    console.log = () => {};



const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter(routes());

root.render(
    <RouterProvider router={router}/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


