import React from 'react';
import PatientSearchProvider from "../../../contexts/PatientSearchProvider";
import ProfessionalSearch from "./ProfessionalSearch";

function PractitionerSearchView() {
    return (
       <PatientSearchProvider>
           <ProfessionalSearch/>
       </PatientSearchProvider>
    );
}

export default PractitionerSearchView;