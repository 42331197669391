import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from "react-router-dom";
import ProfileSummary from "../../status/ProfileSummary";
import VaccinationStatus from "../../status/VaccinationStatus";
import useAuth from "../../../hooks/useAuth";
import useProfessional from "../../../hooks/useProfessional";
import {updateBothUserProfiles} from "../../../util/sendBird";
import {parseDocumentPhoto} from '../../../util/photo/parseDatabasePhotos';
import {ISchool} from '../../Icons';
import MessageLoadingButton from '../../button/MessageLoadingButton';

function StudentMobileItem(props: {student: any}) {
    const {student: { name,
        pronounsdisplay,
        grade,
        id,
        organizationname,
        immunizationstatus,
        photo,}} = props;

    const [t] = useTranslation();
    const {userProfile} = useAuth();
    const [messageNavigateLoading, setMessageNavigateLoading] = useState<string>();
    const [profilePhotoSrc, setProfilePhotoSrc] = useState<any>();
    const {professional, getPhoto} = useProfessional();
    const navigate = useNavigate();
    function initiateMessagesView(id: string, name: string, photo: string) {
        setMessageNavigateLoading(`${t('LOADING_MESSAGES')}...`);
        updateBothUserProfiles(userProfile||'', professional?.name?.[0].text || '', professional?.photo?.[0].url || '',id,name, photo|| '' )
            .then(() => {
                navigate(`/professional/dashboard/messages/${id}`);
            }).finally(() =>  setMessageNavigateLoading(undefined));
    }

    useEffect(() => {
        if (userProfile && photo) {
            getPhoto && getPhoto(userProfile as string, photo).then((value: any) => {
                setProfilePhotoSrc(parseDocumentPhoto(value.file))
            }).catch((reason: Error) => {
                console.error('Unable to get the photo: ', reason);
            })
        }
    }, [])

    return (
        <tr
            className='border-b border-b-neutral-10'
            onClick={() => navigate(`/professional/dashboard/students/${id}`, {state: {immunizationstatus}})}
        >
            <td
                className="p-4"
            >
                <div className="flex flex-grow flex-col break-words items-top">
                    <div className={''}>
                        {/*TODO Fix Undefined*/}
                        <ProfileSummary src={profilePhotoSrc} name={name || 'no name'}
                                        description={pronounsdisplay}/>
                    </div>
                    <div className="ml-14 flex-grow flex-nowrap grid gap-1">
                        <div className="flex items-center gap-2">
                            <VaccinationStatus status={{
                                color: immunizationstatus?.split(':')[0],
                                display: immunizationstatus?.split(':')[1]
                            }}/>
                        </div>
                        <div className="flex items-center gap-2 mt-1">
                            <p className='text-brand-color mr-2'>
                                <ISchool />
                            </p>
                            <div>
                                <p className="text-sm ">{organizationname || '---'}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'flex-grow-0 mt-2 ml-14'}>
                    <MessageLoadingButton
                        handleNavigateMessage={() => initiateMessagesView(id, name, photo || '')}
                        loadingMessage={messageNavigateLoading}
                        isMobile
                    />
                </div>
            </td>
        </tr>
    );
}

export default StudentMobileItem;