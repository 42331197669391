import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ProfileSummary from "../../status/ProfileSummary";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import useAuth from "../../../hooks/useAuth";
import {faSyringe} from "@fortawesome/free-solid-svg-icons";
import useProfessional from "../../../hooks/useProfessional";
import {parseDocumentPhoto} from '../../../util/photo/parseDatabasePhotos';
import {ISchool} from '../../Icons';
import TableMobile from '../TableMobile';
import EvaluationStatus from '../../immunization/EvaluationStatus';

function ReviewingHistoryMobile(props: {students: any[]}) {
    const {students} = props;

    const [t] = useTranslation();

    return (
        <TableMobile showPagination>
            {students.map(student => (
                <div key={student.id} className='py-3'>
                    <div className='mb-3'>
                        <StudentProfile patient={student} />
                    </div>
                    <div className='flex items-center mb-3'>
                        <p className='text-brand-color mr-2'>
                        <ISchool />
                        </p>
                        <p className='text-base'>
                        {student?.managingOrganization?.name || '---'}
                        </p>
                    </div>
                    <div className='flex items-center mb-3'>
                        <FontAwesomeIcon icon={faSyringe} color='#9662D7' />
                        <span className='text-base ml-2'>{student?.vaccine || '---'}</span>
                    </div>
                    <div className='mb-3'>
                      <EvaluationStatus evaluation={student?.evaluation} />
                    </div>
                    <div>
                      <span>{new Date(student?.date)?.toLocaleString('en-CA')}</span>
                    </div>
                </div>
            ))}
        </TableMobile>
    );
}

function StudentProfile(props: { patient?: any }) {
    const { patient } = props;
    const { userProfile } = useAuth();
    const { getPhoto } = useProfessional();
    const [profilePhotoSrc, setProfilePhotoSrc] = useState<any>();
  
    useEffect(() => {
      if (userProfile && patient?.photo) {
        getPhoto && getPhoto(userProfile as string, patient?.photo).then((value: any) => {
          setProfilePhotoSrc(parseDocumentPhoto(value.file))
        }).catch((reason: Error) => {
          console.error('Unable to get the photo: ', reason);
        })
      }
    }, [])
  
  
    return <ProfileSummary src={profilePhotoSrc} name={String(patient?.name || 'no name')}
      description={props.patient?.pronounsdisplay} />
  }

export default ReviewingHistoryMobile;