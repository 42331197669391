import React, {useEffect, useMemo, useState} from 'react';
import {Immunization, ServerReply, Status} from "../../types/database/SharedTypes";
import PrimaryButton from "../button/PrimaryButton";
import Spinner from "../spinner/Spinner";
import ImmunizationEvaluat from "../modal/ImmunizationEvaluation";
import {getImmunizationStatus} from "../../util/immunization/immunization";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {usePatient} from "../../contexts/practitioner/PatientProvider";

function ImmunizationReview(props:{immunization: Immunization, reviewStep: number,onReviewLoading: (approvalLoading: boolean)=> void,setReviewStep: (reviewStep: number)=> void,onReview: ()=> void; immunizations: Immunization[]}) {
    const {immunization, onReview, immunizations, onReviewLoading,reviewStep, setReviewStep} = props;

    const [t] = useTranslation();
    const { studentId } = useParams();

    const [showEvaluationModal, setShowEvaluationModal] = useState<boolean>(false);
    const [approveLoading, setApproveLoading] = useState<boolean>(false);
    const [submitError, setSubmitError] = useState();

    const immunizationStatus = useMemo(()=> getImmunizationStatus(immunization), [immunization]);

    const {
        getPatient,
        setPatient,
        getImmunizations,
        reviewImmunizations,
        setReviewImmunizations,
        immunizationsRecommendations,
        evaluatePatientImmunization: evaluateImmunization
    } = usePatient()


    useEffect(() => {
        const immunizationsNotEvaluated = immunizations.filter((immunization: Immunization) => !reviewImmunizations.some((reviewImmunization: Immunization) => reviewImmunization.id === immunization.id))
        console.log(immunizationsNotEvaluated, "ImmunizationsNotEvaluated");
    }, [immunizations, reviewImmunizations])



    function handleEvaluation(approvalPressed: boolean)  {
        if (approvalPressed) {
            const updatedReviewImmunizations = reviewImmunizations.map((immunization: any, i: number) => {
                if (i === reviewStep) {
                    const tempEvaluation = { code: 'valid' }
                    return { ...immunization, temp_evaluation: tempEvaluation }
                }
                return immunization
            })
            setReviewImmunizations && setReviewImmunizations(updatedReviewImmunizations)

        } else {

            setShowEvaluationModal(true);
        }
    }

    function handleUpdateModal(modalState: boolean) {
        setShowEvaluationModal(modalState);
    }

    async function completeStep() {
        try {
            setApproveLoading(true)
            onReviewLoading && onReviewLoading(true);
            if (studentId && evaluateImmunization) {

                const immunizationsReadyPromises: Promise<ServerReply<any>>[] = [];
                // const anyImmunizationMarkedAsRejected = reviewImmunizations.some((reviewImmunization: Immunization)=> reviewImmunization?.temp_evaluation?.code === 'notvalid');
                // const anyImmunizationMissing = immunizations?.some((reviewImmunization: Immunization)=> getImmunizationStatus(reviewImmunization) === Status.MissingShot);


                await Promise.all(immunizationsReadyPromises);

                const evaluationPromises = reviewImmunizations.map((reviewImmunization: any) => {
                    let status = {}
                    let body = {}
                    if (reviewImmunization?.temp_evaluation?.code === 'valid') {
                        status = {
                            code: "valid",
                            display: "Valid",
                            system: "http://terminology.hl7.org/CodeSystem/immunization-evaluation-dose-status"
                        }

                        body = {
                            immunizationEvent: reviewImmunization.id,
                            doseStatus: status,
                        }
                    }
                    if (reviewImmunization?.temp_evaluation?.code === 'notvalid') {
                        status = {
                            code: "notvalid",
                            display: "Not valid",
                            system: "http://terminology.hl7.org/CodeSystem/immunization-evaluation-dose-status"
                        }
                        body = {
                            immunizationEvent: reviewImmunization.id,
                            doseStatus: status,
                            description: reviewImmunization?.temp_evaluation?.description
                        }
                    }
                    return  evaluateImmunization(studentId, body)
                });

                await Promise.all(evaluationPromises)

                const immunizationsNotEvaluated = immunizations.filter((immunization: Immunization) => getImmunizationStatus(immunization) !== Status.Approved && getImmunizationStatus(immunization) !== Status.Rejected && !reviewImmunizations.some((reviewImmunization: Immunization) => reviewImmunization.id === immunization.id))
                const immunizationsNotEvaluatedPromises = immunizationsNotEvaluated.map((immunization: Immunization) => {
                    const status = {
                        code: "notvalid",
                        display: "Not valid",
                        system: "http://terminology.hl7.org/CodeSystem/immunization-evaluation-dose-status"
                    }
                    const body = {
                        immunizationEvent: immunization.id,
                        doseStatus: status,
                        description: "Missing Documentation"
                    }
                    return evaluateImmunization(studentId, body)
                });
                await Promise.all(immunizationsNotEvaluatedPromises);

                if (getPatient) {
                    const value = await getPatient(studentId)
                    setPatient && setPatient(value.body);
                }
                setReviewImmunizations && setReviewImmunizations([])
                onReview();
            }
        } catch (err: any) {
            console.log(err?.message)
        } finally {
            setApproveLoading(false);
            onReviewLoading && onReviewLoading(false);

        }
    }


    return (
        <div>
            <div>



                { [Status.Pending, Status.EditedAndPending].includes(immunizationStatus) && !immunization?.temp_evaluation && (
                    <div className='flex justify-end px-12'>
                        <div className='mr-3 w-[110px]'>
                            <PrimaryButton outLine onClick={() => handleEvaluation(false)}>{t('REJECT')}</PrimaryButton>
                        </div>
                        <div className='w-[110px]'>
                            {approveLoading ? (<Spinner />) : (
                                <PrimaryButton onClick={() => handleEvaluation(true)}>{t('APPROVE')}</PrimaryButton>
                            )}
                        </div>
                    </div>
                )}

                    <div className='px-12 mt-[30px]'>
                        <div className={`ml-auto w-[228px] ${!immunization?.temp_evaluation && 'opacity-50'}`}>
                            {approveLoading ? (<PrimaryButton><Spinner /></PrimaryButton>) : (
                                <PrimaryButton
                                    onClick={() => reviewImmunizations?.length === reviewStep + 1 ? completeStep() : setReviewStep(reviewStep + 1)}
                                    disabled={!immunization?.temp_evaluation || approveLoading}
                                >
                                    {(reviewImmunizations?.length === reviewStep + 1)
                                        ? t('COMPLETE_AND_SAVE', 'Complete and save')
                                        : `${t('NEXT_VACCINE')}${(reviewStep || 0) + 1}/${reviewImmunizations?.length}`}
                                </PrimaryButton>
                            )}
                        </div>
                    </div>

                <span className='text-red-500'>{submitError}</span>
            </div>
            <div>
                {showEvaluationModal  && (
                    <ImmunizationEvaluat
                        isEvaluationForApprove={false}
                        immunizationId={immunization.id}
                        updateModal={handleUpdateModal}
                        showModalInitial={showEvaluationModal}
                        isReview={true}
                        step={reviewStep}
                    />
                )}
            </div>
        </div>
    );
}

export default ImmunizationReview;