import React, {memo, useCallback} from 'react';
import {OnboardingSection} from "./SideNav";
import ProfessionalInformation from "../../../components/onboarding/ProfessionalInformation";
import School from "../../../components/onboarding/School";
import ProfilePhoto from "../../../components/onboarding/ProfilePhoto";
import FacultyID from "../../../components/onboarding/FacultyID";
import ServiceAgreement from "../../../components/onboarding/ServiceAgreement";
import {useNavigate, useParams} from "react-router-dom";
import usePassiveEvents from "../../../hooks/usePassiveEvents";


function OnboardingState(props: { sectionMarkedComplete: (section: OnboardingSection) => any }) {
    const {sectionMarkedComplete} = props;
    const {stage} = useParams()
    const navigate = useNavigate();
    usePassiveEvents();
    const formCompleted = useCallback((section: OnboardingSection) => formCompletedObj(section), []);

    function formCompletedObj(section: OnboardingSection) {
        if (Object.keys(OnboardingSection).length < section + 1)
            navigate(`/professional/onboarding/${OnboardingSection[section + 1]}`)
        else
            navigate('/professional')
        sectionMarkedComplete(section);
    }

    const onboardingStage: OnboardingSection = +(stage || 0);
    switch (onboardingStage) {
        case OnboardingSection.ProfessionalInformation:
            return <ProfessionalInformation onSectionCompleted={formCompleted}/>;
        case OnboardingSection.School:
            return <School onSectionCompleted={formCompleted}/>;
        case OnboardingSection.ProfilePhoto:
            return <ProfilePhoto onSectionCompleted={formCompleted}/>;
        case OnboardingSection.FacultyID:
            return <FacultyID onSectionCompleted={formCompleted}/>;
        case OnboardingSection.ServiceAgreement:
            return <ServiceAgreement onSectionCompleted={formCompleted}/>;

        default:
            return <></>;

    }
}

export default memo(OnboardingState);