import {useState} from 'react';
import {useTranslation} from 'react-i18next'
import AppointmentProfileSummary from "../status/AppointmentProfileSummary";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClose, faWarning} from '@fortawesome/free-solid-svg-icons';
import {faClock} from '@fortawesome/free-regular-svg-icons';
import CenterModal from "../dashboard-search/CenterModal";
import Spinner from '../spinner/Spinner';
import {usePatientAppointment} from '../../contexts/patient/AppointmentProvider';

export function AppointmentDetail(props: { appointment: any, onClose: () => void, setIsSuccessCancel?: (visible: boolean) => void }) {
  const {
    appointment,
    onClose,
    setIsSuccessCancel
  } = props

    const [t, i18n] = useTranslation();
  const { cancelAppointment, fetchAppointments } = usePatientAppointment();
  const [showCancelConfirmModal, setShowCancelConfirmModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getStatusClass = () => {
    switch (appointment?.status) {
      case 'pending':
        return 'border border-blue-600 bg-[#007ac10d]';
      case 'accepted':
        return 'border border-yellow-400 bg-[#f8a03a0d]';
      case 'cancelled':
        return 'border border-red-600 bg-[#ff37000d]';
      case 'done':
        return 'border border-gray-400 bg-[#73c92d0d]';
      default:
        return 'border border-yellow-400 bg-[#f8a03a0d]';
    }
  };

  const handleAccept = async () => {
    setShowCancelConfirmModal(false)
    setIsLoading(true)
    try {
      if (cancelAppointment) {
        await cancelAppointment(appointment?.id);
        fetchAppointments && fetchAppointments()
        setIsLoading(false);
        onClose();
        setIsSuccessCancel && setIsSuccessCancel(true)
      }
    } catch (error) {
      console.log('unable to cancel the appointment: ', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="relative flex flex-col h-full pt-[15px]">
      <FontAwesomeIcon
        icon={faClose}
        className="absolute top-0 right-0 cursor-pointer w-5 h-5"
        onClick={() => onClose()}
      />
      <div className="flex-1 mt-4">
        <div className={getStatusClass()}>
          <AppointmentProfileSummary
            participant={appointment?.participant}
            appointmentstatus={appointment?.status}
            isDetail
          />
        </div>
        <div className='flex items-center mb-2 bg-[#FAFAFA] py-4 px-5 mt-5'>
          <div className="w-12 h-12 rounded-full bg-white flex items-center justify-center">
            <div className="bg-calendar-icon w-4 h-4 bg-contain" />
          </div>
          <div className='flex flex-col ml-3'>
            <span className='text-heading text-base font-normal'>{t('DATE')}</span>
            <span className='text-heading text-base font-semibold'>
              {new Date(appointment.start).toLocaleDateString(i18n.resolvedLanguage, {
                  weekday: 'short',
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric'
              })}
            </span>
          </div>
        </div>
        <div className='flex items-center bg-[#FAFAFA] py-4 px-5'>
          <div className="w-12 h-12 rounded-full bg-white flex items-center justify-center">
            <FontAwesomeIcon
              icon={faClock}
              className="h-4 w-4 text-brand-color"
            />
          </div>
          <div className='flex flex-col ml-3'>
            <span className='text-heading text-base font-normal'>{t('AVAILABLE_TIME')}</span>
            <span className='text-heading text-base font-semibold'>
              {new Date(appointment.start).toLocaleTimeString(i18n.resolvedLanguage, {
                  hour: '2-digit',
                  minute: '2-digit'
              })} - {new Date(appointment.end).toLocaleTimeString(i18n.resolvedLanguage, {
                hour: '2-digit',
                minute: '2-digit'
            })}
            </span>
          </div>
        </div>
      </div>
      {appointment?.status !== 'cancelled' && (
        <button
          className="flex justify-center items-center border border-brand-color text-brand-color p-4 w-full text-sm mt-10"
          onClick={() => setShowCancelConfirmModal(true)}
        >
          <span>{t('CANCEL_APPOINTMENT')}</span>
          {isLoading && (
            <div className='ml-2'>
              <Spinner />
            </div>
          )}
        </button>
      )}

      {showCancelConfirmModal && (
        <CenterModal
          isOpen={showCancelConfirmModal}
          onClose={() => setShowCancelConfirmModal(false)}
        >
          <div className='p-5'>
            <div className='flex justify-center mb-4'>
              <FontAwesomeIcon
                icon={faWarning}
                className='text-[#FF3700] w-8 h-8'
              />
            </div>
            <p className='text-base font-semibold text-center mb-5'>{t('APPOINTMENT_CANCEL_CONFIRM_MSG')}</p>
            <div className='flex justify-center'>
              <button
                className='border border-brand-color text-brand-color p-2 w-[150px] px-5 text-sm'
                onClick={() => setShowCancelConfirmModal(false)}
              >
                {t('CANCEL')}
              </button>
              <button
                className='bg-brand-color text-white w-[150px] p-2 mx-3 text-sm'
                onClick={() => handleAccept()}
              >
                {t('Accept')}
              </button>
            </div>
          </div>
        </CenterModal>
      )}
    </div>
  )
}

export default AppointmentDetail