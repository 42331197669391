import SendbirdChat from "@sendbird/chat";
import {GroupChannelCreateParams, GroupChannelModule} from "@sendbird/chat/groupChannel";
import {updateBothUserProfiles, updateSendBirdMetadata} from "./updateUserInformation";
import SendBirdCall from 'sendbird-calls';
import {SendableMessage} from "@sendbird/chat/lib/__definition";


export const appId = '19408883-D8D1-4065-8ECB-8672B1DFD16C';
const sendbird = SendbirdChat.init({
    appId,
    modules: [
        new GroupChannelModule(),
    ],
});

SendBirdCall.init(appId);

async function createOrReuseGroupChannel(myUserId: string, otherUserId: string) {
    const user = await sendbird.connect(myUserId);
    const params: GroupChannelCreateParams = {
        invitedUserIds: [myUserId, otherUserId],
        isDistinct: true,
    };
    return sendbird.groupChannel.createChannel(params);
}

const uuid = require("uuid");
const id = uuid.v4();
async function sendAutomatedSendbirdMessage(fromUserId: string, toUserId: string, message: string) {
    const channel = await createOrReuseGroupChannel(fromUserId, toUserId);
    return new Promise<SendableMessage>((resolve, reject) => {
        channel.sendUserMessage({ message })
            .onSucceeded((message) => {
                // A text message with detailed configuration is successfully sent to the channel.
                // By using userMessage.messageId, userMessage.message, userMessage.customType, and so on,
                // you can access the result object from the Sendbird server to check your UserMessageCreateParams configuration.
                // The current user can receive messages from other users through the onMessageReceived() method of an event handler.

                resolve(message);
                // ...
            }).onFailed((error) => {
                reject(error);
            });
    });
}
function VideoCallOtherUser(whoToCallId: string, dialParams: SendBirdCall.DialParams) {

    return new Promise<SendBirdCall.DirectCall>((resolve, reject) => {
        SendBirdCall.dial(dialParams, (call, error) => {
            if (error) {
                reject(error);
            }
            resolve(call!);
        });
    })
}
async function ListenToCalls(myUserId: string, acceptCall: (call: SendBirdCall.DirectCall) => void) {
    const authOption = { userId: myUserId };

    await SendBirdCall.authenticate(authOption);

    // Establishing websocket connection.
    SendBirdCall.connectWebSocket()
        .then(() => {
            SendBirdCall.addListener(id, {
                onRinging: (call) => {
                    acceptCall(call)
                }
            });
        })
        .catch(error => console.error("Unable to connect to SendBird Calls.", error));

}

export { sendbird, updateSendBirdMetadata, updateBothUserProfiles, sendAutomatedSendbirdMessage, createOrReuseGroupChannel, VideoCallOtherUser, ListenToCalls };