import {useTranslation} from 'react-i18next';
import {useEffect, useState} from 'react'
import TableView from "../TableView";
import ExpandableTableRow from "../ExpandableTableRow";
import useAuth from "../../../hooks/useAuth";
import useProfessional from "../../../hooks/useProfessional";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ProfileSummary from '../../status/ProfileSummary';
import {faSyringe} from "@fortawesome/free-solid-svg-icons";
import {parseDocumentPhoto} from "../../../util/photo/parseDatabasePhotos";
import {ISchool} from '../../Icons';
import EvaluationStatus from '../../immunization/EvaluationStatus';

function ReviewingHistoryTable(props: { students: any[] }) {
  const { students } = props;
  const [t] = useTranslation();

  const tableHeaders: string[] = [
    t('NAME'), t('SCHOOL'), t('VACCINE'), t('STATUS'), t('DATE')
  ];

  return (
    <>
      <TableView tableHeaders={tableHeaders} showPagination={true}>
        {students?.map(student => (
          <ExpandableTableRow key={student.id}>
            <td>
              <StudentProfile patient={student} />
            </td>
            <td>
              <div className='flex items-center'>
                <p className='text-brand-color mr-2'>
                  <ISchool />
                </p>
                <p className='text-base'>
                  {student?.managingOrganization?.name || '---'}
                </p>
              </div>
            </td>
            <td>
              <div className='flex items-center'>
                <FontAwesomeIcon icon={faSyringe} color='#9662D7' />
                <span className='text-base ml-2'>{student?.vaccine || '---'}</span>
              </div>
            </td>
            <td>
              <EvaluationStatus evaluation={student?.evaluation} />
            </td>
            <td>
              <span>{new Date(student?.date)?.toLocaleString('en-CA')}</span>
            </td>
          </ExpandableTableRow>
        ))}
      </TableView>
    </>
  );
}

function StudentProfile(props: { patient?: any }) {
  const { patient } = props;
  const { userProfile } = useAuth();
  const { getPhoto } = useProfessional();
  const [profilePhotoSrc, setProfilePhotoSrc] = useState<any>();

  useEffect(() => {
    if (userProfile && patient?.photo) {
      getPhoto && getPhoto(userProfile as string, patient?.photo).then((value: any) => {
        setProfilePhotoSrc(parseDocumentPhoto(value.file))
      }).catch((reason: Error) => {
        console.error('Unable to get the photo: ', reason);
      })
    }
  }, [])


  return <ProfileSummary src={profilePhotoSrc} name={String(patient?.name || 'no name')}
    description={props.patient?.pronounsdisplay} />
}

export default ReviewingHistoryTable;