import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ExpandableTableRow from "../ExpandableTableRow";
import {Link, useNavigate} from "react-router-dom";
import VaccinationStatus from "../../status/VaccinationStatus";
import {parseDocumentPhoto} from "../../../util/photo/parseDatabasePhotos";
import ProfileSummary from "../../status/ProfileSummary";
import useAuth from "../../../hooks/useAuth";
import useProfessional from "../../../hooks/useProfessional";
import {updateBothUserProfiles} from "../../../util/sendBird";
import {ISchool} from '../../Icons';
import MessageLoadingButton from '../../button/MessageLoadingButton';

function StudentRow(props: {student: any}) {
    const {student} = props;

    const {userProfile} = useAuth();
    const [t] = useTranslation()
    const [messageNavigateLoading, setMessageNavigateLoading] = useState<string>();
    const {professional} = useProfessional();
    const navigate = useNavigate();
    function initiateMessagesView(id: string, name: string, photo: string) {
        setMessageNavigateLoading(`${t('LOADING_MESSAGES')}...`);
        updateBothUserProfiles(userProfile||'', professional?.name?.[0].text || '', professional?.photo?.[0].url || '',id,name, photo|| '' )
            .then(() => {
                navigate(`/professional/dashboard/messages/${id}`);
            }).finally(() =>  setMessageNavigateLoading(undefined));
    }

    return (
        <ExpandableTableRow >
            <td>
                <Link to={`/professional/dashboard/students/${student.id}`} state={{immunizationstatus: student.immunizationstatus}}><StudentProfile
                    patient={student}/></Link>
            </td>
            <td>
                <div className={'mr-2'}>
                    <VaccinationStatus status={student.immunizationstatus ? {
                        color: student.immunizationstatus?.split(':')[0],
                        display: student.immunizationstatus?.split(':')[1]
                    } : undefined}/>
                </div>
            </td>
            <td>
                <div className='flex items-center'>
                    <p className='text-brand-color mr-2'>
                        <ISchool />
                    </p>
                    <p className='text-base'>
                        {student?.organizationname || '---'}
                    </p>
                </div>
            </td>
            <td>
                <MessageLoadingButton
                    handleNavigateMessage={() => initiateMessagesView(student.id, student.name, student.photo)}
                    loadingMessage={messageNavigateLoading}
                />
            </td>
        </ExpandableTableRow>
    );
}

export function StudentProfile(props: { patient?: any }) {
    const { patient } = props;
    const { userProfile } = useAuth();
    const { getPhoto } = useProfessional();
    const [profilePhotoSrc, setProfilePhotoSrc] = useState<any>();

    useEffect(() => {
        if (userProfile && patient?.photo) {
            getPhoto && getPhoto(userProfile as string, patient?.photo).then((value: any) => {
                setProfilePhotoSrc(parseDocumentPhoto(value.file))
            }).catch((reason: Error) => {
                console.error('Unable to get the photo: ', reason);
            })
        }
    }, [])


    return <ProfileSummary src={profilePhotoSrc} name={String(patient?.name || 'no name')}
                           description={props.patient?.pronounsdisplay}/>
}

export default StudentRow;