import React, {ReactNode, useEffect, useState} from 'react';
import {ICalendar} from "../Icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {addDays, addMonths, addWeeks, datesAreOnSameDay} from "../../util/date/convertDateString";

export type DateRanges = "day" | "week" | "month";

function DateRangeSelector(props: { newDatePeriodUpdated?: (date: Date, period: DateRanges) => void }) {
    const [t, i18n] = useTranslation();
    const {newDatePeriodUpdated} = props;

    const [datePeriod, setDatePeriod] = useState<DateRanges>('month');
    const [date, setDate] = useState<Date>(new Date());

    useEffect(() => {
        newDatePeriodUpdated && newDatePeriodUpdated(date, datePeriod);
    }, [datePeriod, date]);

    const dateOptions: { value: DateRanges, name: ReactNode }[] = [
        {value: 'day', name: t('DAY')},
        {value: 'week', name: t('WEEK')},
        {value: 'month', name: t('MONTH')},
    ]

    function getLocalDateOptionsForRange(range: DateRanges): Intl.DateTimeFormatOptions {
        switch (range) {
            case "day":
                return {year: 'numeric', month: 'long', day: 'numeric'};
            case "week":
                return {year: 'numeric', month: 'long', day: 'numeric',};
            case "month":
            default:
                return {year: 'numeric', month: 'long'};
        }

    }


    function handleChangeDate(increasing: boolean) {

        switch (datePeriod) {
            case "day":
            default:
                setDate(increasing ? addDays(date, 1) : addDays(date, -1));
                break;
            case "week":
                setDate(increasing ? addWeeks(date, 1) : addWeeks(date, -1));
                break;
            case "month":
                setDate(increasing ? addMonths(date, 1) : addMonths(date, -1));
                break;
        }

    }

    return (
        <div className={'flex  lg:justify-between flex-col lg:flex-row'}>
            <div className="flex mx-auto lg:mx-0">

                <div className="flex items-center justify-between lg:justify-normal">
                    <div className={"relative"}>
                        <div
                            className="text-brand-color w-12 h-12 rounded-full bg-[#f8a03a1a] flex items-center justify-center">
                            <ICalendar/>
                        </div>

                    </div>
                    <span
                        className="ml-5 mr-6 text-xl min-w-[10rem] font-semibold">{date.toLocaleDateString(i18n.resolvedLanguage, getLocalDateOptionsForRange(datePeriod))}</span>
                    <button className={'p-4'} onClick={() => handleChangeDate(false)}>
                        <FontAwesomeIcon
                            icon={faChevronLeft}
                            className={'h-6 w-6  text-brand-color '}

                        />
                    </button>
                    <button disabled={datesAreOnSameDay(date, new Date()) || date > new Date()} className={'p-4'}
                            onClick={() => handleChangeDate(true)}>
                        <FontAwesomeIcon
                            icon={faChevronRight}
                            className={'h-6 w-6  text-brand-color'}
                        />
                    </button>
                </div>
            </div>
            <div className="flex border border-[#EBEBEB] w-fit mx-auto lg:mx-0 mt-4 md:mt-0">
                {dateOptions.map(option => (
                    <button key={option.value}
                            className={`px-[35px] py-[14px] cursor-pointer ${datePeriod === option.value && 'bg-[#f8a03a0d] text-brand-color border border-brand-color'}`}
                            onClick={() => setDatePeriod(option.value)}
                    >
                        {option.name}
                    </button>
                ))}
            </div>


        </div>
    );
}

export default DateRangeSelector;