import React, {useState} from 'react';
import InputGroup from "../group/InputGroup";
import TextInput from "../input/TextInput";
import {Controller, useForm} from "react-hook-form";
import SubmitPrimaryButton from "../button/SubmitPrimaryButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faFileArrowUp} from "@fortawesome/free-solid-svg-icons";
import {ImageListType} from "react-images-uploading";
import UploadImageInput from "../input/UploadImageInput";
import useProfessional from "../../hooks/useProfessional";
import {OnboardingSection} from "../../pages/professional/onboarding/SideNav";

function FacultyID(props: { onSectionCompleted: (section: OnboardingSection) => any }) {
    const {onSectionCompleted} = props;
    const {register, handleSubmit, control, formState: {errors}} = useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const [fidImg, setFidImg] = useState<File>();
    const {addFIdMetadata, uploadFId} = useProfessional();
    const [submitError, setSubmitError] = useState<string>();

    function studentIdAdded(image: ImageListType) {
        setFidImg(image?.[0].file);
    }

    function submitForm(data: any) {
        setSubmitError(undefined);
        setLoading(true)
        if (fidImg) {
            addFIdMetadata && addFIdMetadata(data.fid).then(({body}) => {
                uploadFId && uploadFId(fidImg, body.path).then(() => {
                    setLoading(false)
                    onSectionCompleted(OnboardingSection.FacultyID);
                }).catch((reason) => {
                    setLoading(false);
                    setSubmitError(reason.message);
                })

            }).catch((reason) => {
                setLoading(false);
                setSubmitError(reason.message);
            });
        }
    }

    return (
        <>
            <InputGroup>
                <form onSubmit={handleSubmit(submitForm)}>
                <div className={''}>
                    <h2 className={'mx-auto mb-3 mt-5 font-semibold text-xl text-center'}>Upload Faculty ID</h2>
                    <h3 className={'mx-auto font-medium text-sm text-center mb-10'}>Please select file to upload (zip, txt, pdf, png, jpg)</h3>
                    <div className="grid lg:grid-cols-2 gap-4">
                        <div>
                        <Controller
                            name="fid_img"
                            control={control}
                            rules={{required: true}}
                            render={({field}) =>
                                <UploadImageInput
                                    icon={<FontAwesomeIcon icon={faFileArrowUp} className={'h-20 text-brand-color'}/>}
                                    imageAdded={(image) => {
                                        studentIdAdded(image);
                                        field.onChange(image)
                                    }}
                                    title={'Edit your ID photo'}/>}/>
                        <span className={'text-red-600'}>{errors['fid_img']?.type === 'required' &&
                            <p role="alert">Faculty ID  is required</p>}</span>
                        </div>
                        <div className={''}>
                            <TextInput inputLabel={'Faculty ID number*'} id={'fid'}
                                       placeholder={'0000000000'} required={true}
                                       type={'text'} {...register("fid", {
                                required: false,
                            })} />
                            <span className={'text-red-600'}>{errors['fid']?.type === 'required' &&
                                <p role="alert">Faculty ID number is required</p>}</span>

                        </div>
                    </div>
                </div>
                    <span className={'text-red-600'}>{submitError}</span>
                <div className={'w-40 ml-auto'}>
                    <SubmitPrimaryButton isLoading={loading}>Continue <FontAwesomeIcon
                        icon={faArrowRight}/></SubmitPrimaryButton>
                </div>
                </form>
            </InputGroup>
        </>
    );
}

export default FacultyID;