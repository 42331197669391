import React, {ReactElement, ReactNode, useCallback, useMemo, useState} from 'react';
import LoadingSplash from "../spinner/LoadingSplash";
import Pagination from "../pagination/SimplePagination";
import {useTranslation} from 'react-i18next';

function TableView(props: {
    tableHeaders?: ReactNode[];
    children?: ReactElement[];
    itemsPerPage?: number;
    onChangePage?: (page: number) => void;
    showPagination?: boolean;
    loading?: boolean;
}) {
    const {tableHeaders, onChangePage, showPagination, itemsPerPage = (showPagination? 10: 400), loading, children} = props;
    const [t] = useTranslation()

    function updateDisplayedItems(values: ReactNode[] | ReactNode): ReactNode[] {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        if (Array.isArray(values)) {
            if (values && values.length > 0) {
                return values?.slice(startIndex, endIndex);
            } else {
                return [];
            }
        } else return [values];
    }

    const updatePageClicked = useCallback((page: number) => {
        setCurrentPage(page);
        onChangePage && onChangePage(page);
    }, []);

    const [currentPage, setCurrentPage] = useState(1);

    const displayedItems = useMemo(() => updateDisplayedItems(children), [children, currentPage]);
    return (
        <div className={'overflow-auto'}>
            {loading ? (
                <LoadingSplash showLogo={false}/>
            ) : (
                <>
                    <table className="w-full mt-5 table-auto border-spacing-x-px">
                        {tableHeaders && (
                            <thead className="border-b">
                                <tr>
                                    {tableHeaders?.map((value, index) =>
                                        <th key={index} className="pr-4 py-2 text-gray-400 text-xs text-left">
                                            {value}
                                        </th>
                                    )}
                                </tr>
                            </thead>
                        )}

                        <tbody>
                        {children && Array.isArray(children) && children.length === 0 ? (
                            <tr className="border-b">
                                <td className="px-4 py-2 text-sm text-gray-500">
                                    {t('NO_ITEMS_FOUND')}
                                </td>
                            </tr>
                        ) : (
                            children &&
                            displayedItems ? displayedItems.map((tableRowData, index) =>
                                tableRowData
                            ) : null

                        )
                        }
                        </tbody>
                    </table>

                    {showPagination && (
                        <Pagination
                            items={children}
                            itemsPerPage={itemsPerPage}
                            onPageChange={updatePageClicked}
                        />
                    )}
                </>
            )}
        </div>
    );
}

export default TableView;