import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import SelectInput from '../input/SelectInput';
import {FieldValues, useFieldArray, useForm} from 'react-hook-form';
import DualSelect from '../input/DualSelect';
import {InputRadio} from '../input/InputRadio';
import TableView from "./TableView";
import ExpandableTableRow from "./ExpandableTableRow";
import SubmitPrimaryButton from "../button/SubmitPrimaryButton";
import {getDateFromHours} from "../../util/date/convertDateString";
import PrimaryButton from '../button/PrimaryButton';

export interface AvailabilityProps {
  id: number;
  name: string;
  time_from: string;
  time_to: string;
  status: string;
}

function ManageAppointmentsTable(props: { schedule: AvailabilityProps[], loading: boolean, timeOptions: string[], submitError?: string, submit: (data: FieldValues) => void }) {
  const { schedule, submit, loading, submitError, timeOptions } = props;
  const [t] = useTranslation()
  const DayOfWeek = {
    mon: t('MONDAY'),
    tue: t('TUESDAY'),
    wed: t('WEDNESDAY'),
    thu: t('THURSDAY'),
    fri: t('FRIDAY'),
    sat: t('SATURDAY'),
    sun: t('SUNDAY'),
    unk: t('UNKNOWN')
  }
  const documentHeader: string[] = [t('NAME'), t('TIME_FROM'), t('TIME_TO'), t('STATUS')];

  const form = useForm(
    {
      defaultValues: { availability: schedule },
    }
  );
  const { register, handleSubmit, control, reset, watch, formState: { errors } } = form;
  const { fields, append, remove } = useFieldArray({ control, name: "availability" });
  /*  useEffect(() => {
      const subscription = watch(() => handleSubmit(onSubmit)());
  
      return () => subscription.unsubscribe();
    }, [handleSubmit, watch]);*/

  function checkValidTime(time1: string, time2: string) {
    const parsedTime1 = parseInt(time1.split(':')[0]) + parseInt(time1.split(':')[1]) / 100;
    const parsedTime2 = parseInt(time2.split(':')[0]) + parseInt(time2.split(':')[1]) / 100;
    return parsedTime1 <= parsedTime2;
  }

  useEffect(() => {

    if (schedule) {
      console.log('resetting table', schedule);
      reset({ availability: schedule });
    }
  }, [schedule]);

  return (
    <>
      <form onSubmit={handleSubmit(submit)}>
        <TableView showPagination={false}
          tableHeaders={documentHeader}
        >
          {fields.map((field, index) =>
            <ExpandableTableRow key={field.id}>
              <td>
                <div className="py-2 pr-3" >
                  <div>
                    <span className="font-semibold">{DayOfWeek[(Object.keys(DayOfWeek)[index] || 'unk') as keyof typeof DayOfWeek]}</span>
                  </div>
                </div>
              </td>
              <td>
                <div className="py-2 pr-3">
                  {watch(`availability.${index}.status`) !== 'out' && (
                    <SelectInput
                      id={`availability.${index}.time_from`}
                      placeholder={t('SELECT')}

                      disabled={watch(`availability.${index}.status`) === 'out'}
                      inputName={`availability.${index}.time_from`}
                      {...register(`availability.${index}.time_from`)}
                    >
                      <option value="">{t('SELECT')}</option>
                      {timeOptions.map((time) => (
                        <option value={time} key={time}>
                          {getDateFromHours(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                        </option>
                      ))}
                    </SelectInput>
                  )}
                </div>
              </td>
              <td>
                <div className="pr-3">
                  {watch(`availability.${index}.status`) !== 'out' && (
                    <SelectInput
                      id={`availability.${index}.time_to`}
                      placeholder={t('SELECT')}
                      disabled={watch(`availability.${index}.status`) === 'out'}
                      inputName={`${index}.time_to`}
                      {...register(`availability.${index}.time_to`)}
                    >
                      <option value="">{t('SELECT')}</option>
                      {timeOptions.map((time) => (
                        <option value={time} key={time} disabled={checkValidTime(time, watch(`availability.${index}.time_from`))}>
                          {getDateFromHours(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                        </option>
                      ))}
                    </SelectInput>
                  )}
                </div>
              </td>
              <td className='w-[240px]'>
                <div className="py-4 [&>ul>li>label]:py-[11px]">
                  <DualSelect>
                    <InputRadio
                      inputLabel={t("IN")}
                      id={`availability.${index}.status.in`}
                      optionValue={'in'}
                      {...register(`availability.${index}.status`)}
                    />

                    <InputRadio
                      inputLabel={t("OUT")}
                      optionValue={'out'}
                      id={`availability.${index}.status.out`}
                      {...register(`availability.${index}.status`)}
                      style={{ width: '64px' }}
                    />
                  </DualSelect>
                </div>
              </td>
            </ExpandableTableRow>)}
        </TableView>
        <span className={'text-red-600'}>{submitError}</span>
        <div className={'mt-10 flex justify-end'}>
          <div className='w-[130px] h-[60px]'>
            <PrimaryButton
              type='button'
              outLine
              onClick={() => reset({ availability: schedule })}
              >
                {t('CANCEL')}
              </PrimaryButton>
          </div>
          <div className={`ml-3 w-[130px] h-[60px]`}>
            <SubmitPrimaryButton isLoading={loading}> {t('SAVE')}</SubmitPrimaryButton>
          </div>
        </div>
      </form>
    </>
  );
}

export default ManageAppointmentsTable;
