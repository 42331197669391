function Switch(props: { active: boolean, setActive: (active: boolean) => void }) {
  const { active, setActive } = props;

  return (
    <div className={`relative w-9 h-[20px] cursor-pointer rounded-full p-[2px] bg-light-grey flex items-center ${active && 'border border-brand-color'}`} onClick={() => setActive(!active)}>
      <div className={`rounded-full absolute transition-all ${active ? 'bg-brand-color right-[2px]' : 'left-[2px] bg-white'} w-[15px] h-[15px]`} />
    </div>
  )
}

export default Switch;