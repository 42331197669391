import React from 'react';
import {useTranslation} from 'react-i18next'
import {useSchedule} from "../../contexts/patient/ScheduleProvider";

function StartTimeSummary(props: {startTime?: string}) {
    const {startTime} = props;
    const [t, i18n] = useTranslation();
    const {scheduleSlots} = useSchedule();
    const scheduleTimeStart = scheduleSlots?.find((slot) => startTime === slot.id);

    return ( <>{scheduleTimeStart && (
                <div className={`mt-5 bg-slate-100 p-3`}>
                    <div className="flex gap-1 text-sm">
                        <p className="font-thin">{t('Starting time')}: </p>
                        <p className="font-bold">{new Date(scheduleTimeStart.start).toLocaleTimeString(i18n.resolvedLanguage, {
                            hour: '2-digit',
                            minute: '2-digit'
                        }).toLowerCase()}</p>
                    </div>
                </div>
            )} </>
    );
}

export default StartTimeSummary;