import React, {createContext, useContext, useEffect, useState} from "react";
import {AuthContext} from "./AuthContext";

import {API} from "aws-amplify";
import axios from "axios";
import {TypedDisplayInfo} from "./CodeProvider";
import {Address, Coding, ContactPoint, DocumentPathReference, ServerReply} from "../types/database/SharedTypes";
import {updateSendBirdMetadata} from "../util/sendBird";
import {detectImageMimeType} from "../util/photo/parseDatabasePhotos";
import {AWSConfigS} from "../App";
import {useTranslation} from "react-i18next";


interface SubmitStudentInformation {
    grade: number;
    race: TypedDisplayInfo;
    gender: TypedDisplayInfo;
    firstName: string;
    middleName: string;
    lastName: string;
    pronouns: TypedDisplayInfo;
    relationshipToUser: TypedDisplayInfo;
    address_ln1: string;
    address_ln2: string;
    dateOfBirth: Date;
    schoolId: string;
    phoneNumber: string;
    studentId: string;
    studentIdFile: File;
    profilePhoto: File;
}

export interface Organization {
    id: string;
    name: string;
    address?: Address[];
    text?: string;
    telecom?: ContactPoint[];
}
export const PatientContext = createContext<{
    getPatientInfo?: () => Promise<any>,
    getOrganizations?: (query: string) => Promise<{ body: { items: Organization[] }, statusCode: number }>,
    submitStudent?: (data: SubmitStudentInformation) => Promise<any>,
    getPhoto?: (subjectID: string, photoPath: string) => Promise<any>,
    schoolList?: Organization[],
    profilePhoto?: string,
    errorMessage?: string,
    patient?: any,
    searchPractitioners?: (searchQuery?: string) => Promise<ServerReply<{ items: any[] }>>,
    scheduleAppointment?: (slotIds: string[], practitionerId: string,) => Promise<ServerReply<any>>,
    filterPractitioners?: (filterQuery?: Record<string, string>) => Promise<ServerReply<{ items: any[] }>>,
    uploadImmunizationDocumentMetadata?: (fileName: string, mimeType: string, category?: Coding) => Promise<ServerReply<DocumentPathReference>>,
    uploadDocument?: (file: File, documentPath: string) => Promise<ServerReply<any>>,
    parentStudents?: any[],
    getPractitionerInfo?: (practitionerId: string,) => Promise<ServerReply<any>>,
    uploadStudentProfilePhoto?: (studentId: File, profilePhotoPath: string, profile?: string) => Promise<any>,
    updateStudentProfilePhoto?: (studentId: string, profilePhotoPath: string, profile?: string) => Promise<any>,
    addStudentProfileMetadata?: (profile?: string) => Promise<ServerReply<DocumentPathReference>>,
    updatePatientPhoto?: (path?: string) => void
}>({});
export const usePatient = () => React.useContext(PatientContext);

function PatientProvider(props: { children: React.ReactElement<any, any> }) {
    const {children} = props;
    const {userProfile, refreshAuth, setDefaultUserProfile} = useContext(AuthContext);
    const [t] = useTranslation();
    const [schoolList, setSchoolList] = useState<Organization[]>();
    const [patient, setPatient] = useState<any>();
    const [profilePhoto, setProfilePhoto] = useState<string>();
    const [parentStudents, setParentStudents] = useState<any[]>();
    const [errorMessage, setErrorMessage] = useState<string>();

    async function getPatientInfo(profile = userProfile): Promise<any> {
        const apiName = 'LAUSDEndpoint';
        const path = `/patient/patient/${profile}`;
        const myInit = {
            body: {},
            headers: {}
        };
        return API.get(apiName, path, myInit);
    }

    async function getPractitionerInfo(practitionerId: string): Promise<ServerReply<any>> {
        const apiName = 'LAUSDEndpoint';
        const path = `/patient/practitioner/${practitionerId}`;
        const myInit = {
            body: {},
            headers: {}
        };
        return API.get(apiName, path, myInit);
    }

    async function getPhoto(subjectID: string, photoPath: string): Promise<any> {
        const apiName = 'LAUSDDocumentEndpoint';
        const path = `/patient/document/${subjectID}?path=${photoPath}`;
        const myInit = {
            body: {},
            headers: {
                Accept: "image/*"
            }
        };
        return API.get(apiName, path, myInit).catch((err) => {
            //Unable to get photo
        });
    }
    async function uploadImmunizationDocumentMetadata(fileName: string, mimeType: string, category?: Coding, profile= userProfile): Promise<ServerReply<DocumentPathReference>>{
        const apiName = 'LAUSDDocumentEndpoint';
        const path = `/patient/document/reference/${profile}`;
        const myInit = {
            body: {
                 category,
                "contentType": mimeType,
                "title": fileName,
            },
            headers: {}
        };
        return API.post(apiName, path, myInit);

    }


    async function searchPractitioners(searchQuery?: string, profile = userProfile): Promise<ServerReply<{ items: any[] }>> {
        const apiName = 'LAUSDEndpoint';
        const path = `/patient/filter/practitioners?name:contains=${encodeURIComponent(`${searchQuery}`)}`;
        const myInit = {
            body: {},
            headers: {}
        };
        return API.get(apiName, path, myInit);
    }
    async function filterPractitioners(filterParams?: Record<string,string>): Promise<ServerReply<{ items: any[] }>> {

        const apiName = 'LAUSDEndpoint';
        const path = `/patient/filter/practitioners?${new URLSearchParams(filterParams)}`;
        const myInit = {
            body: {},
            headers: {}
        };
        return API.get(apiName, path, myInit);
    }
    function submitStudent(submitStudentInformation: SubmitStudentInformation) {
        // Add patient to user
        // Refresh user
        // add metadata for patient id
        // add metadata for patient profile photo
        // upload patient profile photo
        // upload patient id
        return addNewStudentToUser(submitStudentInformation.firstName,
            submitStudentInformation.middleName,
            submitStudentInformation.lastName,
            submitStudentInformation.pronouns,
            submitStudentInformation.relationshipToUser,
            submitStudentInformation.schoolId,
            submitStudentInformation.dateOfBirth,
            submitStudentInformation.phoneNumber,
            submitStudentInformation.studentId,
            submitStudentInformation.gender,
            submitStudentInformation.race,
            submitStudentInformation.grade,
            submitStudentInformation.address_ln1,
            submitStudentInformation.address_ln2).then(({body}) => {
            setDefaultUserProfile && setDefaultUserProfile(body.subject);
            return refreshAuth && refreshAuth().finally(() => {
                return addStudentIdMetadata(submitStudentInformation.studentId, body.subject).then((studentIdMetadata) => {
                    return addStudentProfileMetadata(body.subject).then(async (studentProfileMetadata) => {

                        await uploadStudentId(submitStudentInformation.studentIdFile, studentIdMetadata.body.path, body.subject)
                        await uploadStudentProfilePhoto(submitStudentInformation.profilePhoto, studentProfileMetadata.body.path, body.subject);
                        const {body: patientInfo} = await getPatientInfo(body.subject);
                        return patientInfo;

                    })
                })
            })
        })

    }

    async function addNewStudentToUser(firstName: string, middleName: string, lastName: string, pronouns: TypedDisplayInfo, relationshipToUser: TypedDisplayInfo, schoolIds: string, dateOfBirth: Date, phoneNumber: string, studentId: string, gender: TypedDisplayInfo, race: TypedDisplayInfo, grade: number, addressln1: string, addressln2: string): Promise<any> {
        const apiName = 'LAUSDEndpoint';
        const path = `/patient/onboarding/patient`;
        const myInit = {
            body: {
                "firstName": firstName,
                "middleName": middleName,
                "lastName": lastName,
                "managingOrganization": schoolIds,
                "tenant": "lausd",
                "studentId": studentId,
                gender,
                grade,
                race,
                "address": {use: "home", type: "physical", line: [addressln1, addressln2]},
                "pronouns": {...pronouns, "system": "http://loinc.org"},
                "relationToUser": {
                    ...relationshipToUser,
                    "system": "http://terminology.hl7.org/CodeSystem/v3-RoleCode"
                },
                "birthDate": dateOfBirth.toISOString().slice(0, 10),
                "telecom": {"rank": 1, "system": "PHONE", "use": "home", "value": phoneNumber}
            },
            headers: {}
        };
        return API.post(apiName, path, myInit);
    }

    async function addStudentIdMetadata(studentId: string, profile = userProfile): Promise<ServerReply<DocumentPathReference>> {
        const apiName = 'LAUSDEndpoint';
        const path = `/patient/onboarding/identification/${profile}`;
        const myInit = {
            body: {number: studentId, type: "STU", "issuer": "LAUSD",},
            headers: {}
        };
        return API.post(apiName, path, myInit);
    }


    async function scheduleAppointment(slotIds: string[], practitionerId: string, profile = userProfile): Promise<ServerReply<DocumentPathReference>> {
        const apiName = 'LAUSDEndpoint';
        const path = `/patient/appointment/${profile}`;
        const myInit = {
            body: {slot: slotIds, participant:practitionerId},
            headers: {}
        };
        return API.post(apiName, path, myInit);
    }

    async function addStudentProfileMetadata(profile = userProfile): Promise<ServerReply<DocumentPathReference>> {
        const apiName = 'LAUSDEndpoint';
        const path = `/patient/onboarding/photo/${profile}`;
        const myInit = {
            body: {},
            headers: {}
        };
        return API.post(apiName, path, myInit);
    }

    async function uploadStudentId(studentId: File, studentIdPath: string, profile = userProfile): Promise<any> {
        const apiName = 'LAUSDDocumentEndpoint';
        const path = `/patient/document/${profile}?path=${studentIdPath}`;

        const amplifyEndpoint = AWSConfigS.API.endpoints.find((endpoint: { name: string }) => endpoint.name === apiName);
        const url = (amplifyEndpoint?.endpoint || '') + path;
        const headers: any = amplifyEndpoint?.custom_header && await amplifyEndpoint?.custom_header();
        if (headers) {
            headers['Content-Type'] = studentId.type;
        }
        return axios.put(url, studentId, {headers});
    }


    async function uploadDocument(document: File, documentPath: string, profile = userProfile): Promise<any> {
        const apiName = 'LAUSDDocumentEndpoint';
        const path = `/patient/document/${profile}?path=${encodeURIComponent(documentPath)}`;

        const amplifyEndpoint = AWSConfigS.API.endpoints.find((endpoint: { name: string }) => endpoint.name === apiName);
        const url = (amplifyEndpoint?.endpoint || '') + path;
        const headers: any = amplifyEndpoint?.custom_header && await amplifyEndpoint?.custom_header();
        if (headers) {
            headers['Content-Type'] = document.type;
        }
        return axios.put(url, await file2Base64(document), {headers});
    }
    const file2Base64 = (file:File):Promise<string> => {
        return new Promise<string> ((resolve,reject)=> {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result?.toString().replace('data:', '')
                .replace(/^.+,/, '') || '');
            reader.onerror = error => reject(error);
        })
    }
    async function uploadStudentProfilePhoto(studentId: File, profilePhotoPath: string, profile = userProfile): Promise<any> {
        const apiName = 'LAUSDDocumentEndpoint';
        const path = `/patient/document/${profile}?path=${profilePhotoPath}`;

        const amplifyEndpoint = AWSConfigS.API.endpoints.find((endpoint: { name: string }) => endpoint.name === apiName);
        const url = (amplifyEndpoint?.endpoint || '') + path;
        let headers: any = amplifyEndpoint?.custom_header && await amplifyEndpoint?.custom_header();
        if (headers) {
            headers['Content-Type'] = studentId.type;
        }

        return axios.put(url, studentId, {headers});
    }

    async function updateStudentProfilePhoto(studentId: string, profilePhotoPath: string, profile = userProfile): Promise<any> {
        const apiName = 'LAUSDDocumentEndpoint';
        const path = `/patient/document/${profile}?path=${profilePhotoPath}`;
        const amplifyEndpoint = AWSConfigS.API.endpoints.find((endpoint: { name: string }) => endpoint.name === apiName);
        const url = (amplifyEndpoint?.endpoint || '') + path;
        let headers: any = amplifyEndpoint?.custom_header && await amplifyEndpoint?.custom_header();
        if (headers) {
            const type = detectImageMimeType(studentId);
            headers['Content-Type'] = type;
        }
        return axios.put(url, studentId, {headers});
    }

    async function getOrganizations(query: string): Promise<{ body: { items: [Organization] }, statusCode: number }> {
        const apiName = 'LAUSDEndpoint';
        const path = `/patient/organizations?${query}`;
        const myInit = {
            body: {},
            headers: {}
        };
        return API.get(apiName, path, myInit);
    }

    async function getStudents() {
        const apiName = 'LAUSDEndpoint';
        const path = `/patient/patients`;
        const myInit = {
            body: {},
            headers: {}
        };
        return API.get(apiName, path, myInit);
    }

    function updatePatientPhoto(path?: string) {
        patient?.photo?.sort((a: any, b: any) => Number(new Date(b?.creation)) - Number(new Date(a?.creation)))
        getPhoto(patient.subject, path ?? patient.photo?.[0].url).then(value => {
            setProfilePhoto(value?.file);
        })
    }

    useEffect(() => {
        getStudents().then(({ body: { items } }) => {
            setParentStudents(items)
        })
    }, [])

    useEffect(() => {
        if(!schoolList) {
            getOrganizations('type=011&type=016&type=017&type=021&type=031&type=036&type=041&type=046&type=048&type=051&type=056&type=061&type=071').then(({body: {items}}) => {
                setSchoolList(items);
            })
        }

    }, [schoolList]);

    useEffect(() => {
        if (userProfile) {
            getPatientInfo().then((value) => {
                setPatient(value.body);
                updateSendBirdMetadata(userProfile, value.body.name?.[0].text, value.body.photo?.[0].url);

            }).catch((error) => {
                console.error("Unable to get Patient", error);
                setErrorMessage(t("THERE_WAS_AN_ISSUE_ACCESSING_YOUR_ACCOUNT_PLEASE_CONTACT_LAUSD_FOR_MORE_INFORMATION"));
            });
        }
    }, [userProfile]);
    useEffect(() => {
        if(patient){
            updatePatientPhoto()
        }
    }, [patient, profilePhoto]);
    return (
        <PatientContext.Provider
            value={{
                profilePhoto,
                getPatientInfo,
                getPractitionerInfo,
                getOrganizations,
                getPhoto,
                filterPractitioners,
                uploadDocument,
                uploadImmunizationDocumentMetadata,
                searchPractitioners,
                scheduleAppointment,
                schoolList,
                submitStudent,
                patient,
                errorMessage,
                parentStudents,
                uploadStudentProfilePhoto,
                updateStudentProfilePhoto,
                updatePatientPhoto,
                addStudentProfileMetadata
            }}>
            {children}
        </PatientContext.Provider>
    );
}

export default PatientProvider;