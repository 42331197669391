import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';

import translationEn from './translation/en/translation.json'
import translationEs from './translation/es/translation.json'

const resources = {
  en: {
    translation: translationEn
  },
  es: {
    translation: translationEs
  }
}

i18next
.use(initReactI18next)
.init({
  resources,
    lng: window.localStorage.getItem('lang_key') || "en-us"
})

export default i18next;