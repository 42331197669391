import React, {useMemo} from 'react';
import {GroupChannel} from "@sendbird/chat/groupChannel";
import {Summary} from './MessageComponent';
import ProfileImage from '../status/ProfileImage';
import {parseDocumentPhoto} from "../../util/photo/parseDatabasePhotos";
import {useDocument} from "../../contexts/DocumentProvider";
import useAuth from '../../hooks/useAuth';

function ChannelListItemUI(props: {
  channel: any,
  otherMember: any,
  setChannelSelected?: any,
  channelSelected: any
}) {

  const {
    channel,
    otherMember,
    setChannelSelected,
    channelSelected
  } = props;
  const { document } = useDocument();
  const profilePhotoSrc = useMemo(() => parseDocumentPhoto(atob(document || '')), [document]);

  function getDate(timestamp: number) {
    const date = new Date(timestamp);
    return date.toLocaleDateString('en-US');
  }

  return (
    <div
      className={`flex items-center cursor-pointer border-b border-b-neutral-10 py-5 px-12 ${channelSelected?.url === channel?.url && 'border-r-2 border-r-brand-color bg-primary-hover'}`}
      onClick={() => setChannelSelected(channel)}
    >
      <div className='w-10 h-10'>
        <ProfileImage src={profilePhotoSrc} status={otherMember?.isActive} />
      </div>
      <div className='flex flex-col ml-3 items-start flex-1'>
        <div className='flex justify-between items-center w-full'>
          <span className='font-semibold text-base truncate max-w-[100px]'>{otherMember?.nickname || 'No name'}</span>
          <span className='text-xs text-color-[#8C8C8C]'>{getDate(channel?.lastMessage?.createdAt)}</span>
        </div>
        <div className='flex  justify-between items-center w-full mt-2'>
          <span className='text-sm text-color-[#8C8C8C] truncate max-w-[150px]'>{channel?.lastMessage?.message}</span>
          {!!channel?.unreadMessageCount && (
            <div className='rounded-full w-5 h-5 bg-brand-color flex items-center justify-center px-[6px]'>
              <span className='text-xs text-white font-semibold'>{channel?.unreadMessageCount}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function ChannelListItem(props: { channel: GroupChannel, setChannelSelected: any, channelSelected: any, isBlockedUser?: boolean, channelType?: string }) {
  const {
    channel
  } = props;
  const { userProfile } = useAuth();
  const otherMember = channel?.members.find((member: any) => member.userId !== userProfile);
  return (
    <Summary otherMember={otherMember}>
      <ChannelListItemUI
        {...props}
        otherMember={otherMember}
      />
    </Summary>
  )
}

export default ChannelListItem;