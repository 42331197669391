import React, {ReactNode} from 'react';

function SelectableNavButton(props: { icon: ReactNode, title: ReactNode, clicked: () => any, active?: boolean, visited?: boolean }) {
    const {icon, title, clicked, active = false, visited = false} = props;
    return (
        <>
            <a role={'button'} onClick={(!visited) ? clicked : undefined}
               className={(visited ? ' border-blue-500 ' : '') + (active ? ' border-brand-color ' : '') + 'flex flex-row  rounded-sm border py-4 px-5'}>
                <div
                    className={(visited ? ' bg-blue-500 text-blue-500 ' : ' bg-brand-color text-brand-color ') + 'rounded-full  bg-opacity-5  px-2.5 py-2 '}>
                    {icon}
                </div>
                <div className={'ml-4 font-semibold text-base my-auto'}>
                    <span>{title}</span>
                </div>

            </a>
        </>
    );
}

export default SelectableNavButton;