import React, {useState} from 'react';
import {UserGroup} from "../../contexts/AmplifyApiProvider";
import UserConfirmation from "./UserConfirmation";
import {useNavigate} from "react-router-dom";
import RegistrationForm from "./RegistrationForm";

enum RegistrationPathway {
    REGISTER_BASIC,
    REGISTER_CONFIRM,
    REGISTER_STUDENT,
    REGISTER_PRACTITIONER,
    COMPLETE
}

export interface ExtraUserInformation {
    addressLn1: string;
    addressLn2: string;
    phoneNumber: string
}

export default function RegisterPane() {

    const [registration, setRegistration] = useState(RegistrationPathway.REGISTER_BASIC);
    const navigate = useNavigate();
    const [userGroup, setUserGroup] = useState(UserGroup.PATIENT);
    const [userEmail, setUserEmail] = useState<string>();
    const [extraUserInformation, setExtraUserInformation] = useState<ExtraUserInformation>();


    function onEmailVerified() {
        if (userGroup === UserGroup.PRACTITIONER) {
            setRegistration(RegistrationPathway.COMPLETE);
            navigate('/professional/onboarding')
        } else if (userGroup === UserGroup.PATIENT) {
            navigate({
                pathname: '/register/patient',
                search: `?addressLn1=${extraUserInformation?.addressLn1}&addressLn2=${extraUserInformation?.addressLn2}&phoneNumber=${extraUserInformation?.phoneNumber}`
            })
        }

    }

    function studentRegistrationComplete() {
        setRegistration(RegistrationPathway.COMPLETE);
        navigate('/patient/onboarding')
    }


    function registrationFormCompleted(userEmail: string, userGroup: UserGroup, addressLn1: string, addressLn2: string, phone: string) {
        setUserEmail(userEmail);
        setUserGroup(userGroup);
        setExtraUserInformation({addressLn1, addressLn2, phoneNumber: phone});

        setRegistration(RegistrationPathway.REGISTER_CONFIRM);

        setRegistration(RegistrationPathway.REGISTER_CONFIRM)


    }

    function RegistrationState() {
        switch (registration) {
            case RegistrationPathway.REGISTER_BASIC:
                return <RegistrationForm registrationFormComplete={registrationFormCompleted}/>;
            case RegistrationPathway.REGISTER_CONFIRM:
                return <UserConfirmation email={userEmail!} onEmailVerified={onEmailVerified}/>;
            default:
                return <></>;

        }
    }

    return (<RegistrationState/>);


}
