import React from 'react';
import logo from '../../assets/images/logo.svg'

function Logo() {
    return (
        <img src={logo} alt="Company logo"/>

    );
}

export default Logo;
