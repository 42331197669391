import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Controller, FieldValues, useForm} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CenterModal from "../dashboard-search/CenterModal";
import SubmitPrimaryButton from "../button/SubmitPrimaryButton";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import TextInput from "../input/TextInput";
import DocumentUploadInput from "../input/DocumentUploadInput";
import usePatient from "../../hooks/usePatient";
import {useDocument} from "../../contexts/patient/DocumentProvider";
import {ampli} from "../../ampli";
import {splitFileName} from '../../util';

function DocumentUpload(props: { updateModal?: (modalState: boolean) => void, showModalInitial?: boolean }) {
    const { updateModal, showModalInitial = false } = props;
    const [t, i18n] = useTranslation();
    const [loading, setLoading] = useState(false);
    const [submitError, setSubmitError] = useState<string>();
    const [fileExtension, setFileExtension] = useState<string>('');
    const { uploadDocument, uploadImmunizationDocumentMetadata } = usePatient();
    const { updateDocuments } = useDocument();

    const {
        register,
        handleSubmit,
        control,
        reset,
        watch,
        setValue,
        formState: { errors, touchedFields },
    } = useForm();

    const documentsWatch: File[] = watch('document');

    useEffect(() => {
        updateDocumentName();
    }, [documentsWatch]);

    function updateDocumentName() {
        if (!touchedFields['documentName'] && documentsWatch?.[0]) {
            const fileNameWithExtension = documentsWatch?.[0].name;
            const split = splitFileName(fileNameWithExtension);
            setFileExtension(split.extension);
            setValue('documentName', new Date().toLocaleDateString(i18n.resolvedLanguage) + " - " + split.name, {shouldDirty: true});
        }
    }

    function onSubmit(data: FieldValues) {
        setLoading(true);
        setSubmitError(undefined);

        const submittingDocument: File = data.document[0];
        const fullFileName = data.documentName + '.' + fileExtension;

        uploadImmunizationDocumentMetadata && uploadImmunizationDocumentMetadata(fullFileName, submittingDocument.type).then((value) => {
            uploadDocument && uploadDocument(submittingDocument, value.body.path).then((value) => {
                updateDocuments && updateDocuments();
                reset();
                setLoading(false);
                setFileExtension('');
                ampli.track({event_type:"Upload document success"});
                updateModal && updateModal(false);
            }).catch((error) => {
                setLoading(false);
                setSubmitError(error.message);
                ampli.track({event_type:"Upload document fail"});
                console.error("Error uploading document: ", error);
            })
        }).catch((error) => {
            setLoading(false);
            setSubmitError(error.message);
            console.error("Error adding document Metadata: ", error);
        });
    }
    function closeModal() {
        updateModal && updateModal(false); reset();
    }

    return (
        <CenterModal isOpen={showModalInitial} onClose={() => closeModal()}>
            <form onSubmit={handleSubmit(onSubmit)} className={'p-4'} >
                <article
                    className={'flex flex-col h-full justify-between overflow-y-auto  lg:px-8 lg:min-w-[40vw] min-w-[90vw]  w-full'}
                >
                    <div className={'flex flex-row justify-end'}><FontAwesomeIcon icon={faClose} className={' h-4 p-4'} onClick={() => closeModal()} /></div>
                    <div className="flex flex-col mb-8">
                        <div className="flex items-center justify-between py-3">
                            <h1 className="text-xl font-semibold">{t('ADD_NEW_DOCUMENT')}</h1>
                        </div>
                        <span>{t('PLEASE_SELECT_FILE_TO_UPLOAD_PDF_DOC_JPG_PNG')}</span>
                    </div>

                    <div className={'h-50'}>
                        <Controller
                            control={control}
                            name="document"
                            rules={{ required: true }}
                            render={({
                                field,
                                fieldState: { invalid, isTouched, isDirty, error },
                                formState,
                            }) => (
                                <DocumentUploadInput ref={field.ref} onChange={field.onChange} onBlur={field.onBlur} />
                            )}
                        />
                        <span className={'text-red-600'}>{errors['document']?.type === 'required' &&
                            <p role="alert">{t('DOCUMENT_IS_REQUIRED')}</p>}</span>
                    </div>
                    <div>
                        <TextInput inputLabel={t('DOCUMENT_NAME')} id={'documentsWatch-name'} required={true}
                            placeholder={'ex. MMR immunization'}
                            type={'text'} {...register("documentName", {
                                required: true,
                            })} />
                        <TextInput
                            inputLabel={t('DOCUMENT_EXTENSION')}
                            type={'text'}
                            disabled
                            required
                            value={fileExtension}
                        />
                        <span className={'text-red-600'}>{errors['documentName']?.type === 'required' &&
                            <p role="alert">{t('DOCUMENT_NAME_IS_REQUIRED')}</p>}</span>
                    </div>
                    <span className={'text-red-600'}>{submitError}</span>
                    <div className={'w-40 ml-auto border mt-14 '}>
                        <SubmitPrimaryButton isLoading={loading}>{t('SAVE')}</SubmitPrimaryButton>
                    </div>
                </article>
            </form>
        </CenterModal>
    );
}

export default DocumentUpload;