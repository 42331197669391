import {useNavigate} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import AppointmentStatus from '../../status/AppointmentStatus';
import TableView from "../TableView";
import StudentProfileSummary from '../../status/StudentProfileSummary';
import {ICalendar, ISchool} from '../../Icons';
import {
    AppointmentDetailProvider,
    useAppointmentDetail
} from "../../../contexts/practitioner/AppointmentDetailProvider";
import Spinner from "../../spinner/Spinner";

function AppointmentsTable(props: { appointments: any[] }) {
  const { appointments } = props;

    const [t, i18n] = useTranslation();

  const documentHeader: string[] = [t('NAME'), t('SCHOOL'), t('DATE'), t('STATUS')];

  return (
    <>
      <TableView
        tableHeaders={documentHeader}
        showPagination
      >
        {appointments.map(appointment =>
            <AppointmentDetailProvider appointmentId={appointment?.id} key={appointment?.id}>
                <AppointmentRowTable/>
            </AppointmentDetailProvider>
        )}
      </TableView>
    </>
  );
}
function AppointmentRowTable() {
    const {appointment} = useAppointmentDetail();
    const navigate = useNavigate();
    const [t, i18n] = useTranslation();

    return(
        <>
            {!!appointment?.loading ?
                <tr className={'flex row-span-full justify-center py-10'}>
                    <Spinner />
                </tr>

                :
        <tr
            key={appointment?.appointment?.id}
            className='cursor-pointer border-b border-b-neutral-100 hover:bg-[#f8a03a0d]'
            onClick={() => navigate(`/professional/dashboard/appointments/student/${appointment?.appointment?.subject?.subject}`, {
                state: { appointment:appointment?.appointment }
            })}
        >
            <td>
                <StudentProfileSummary patient={appointment?.appointment?.subject} />
            </td>
            <td>
                <div className='flex items-center'>
                <span className="text-brand-color mr-3">
                  <ISchool />
                </span>
                    <span className='text-base text-heading'>{appointment?.appointment?.subject?.managingOrganization?.name || '---'}</span>
                </div>
            </td>
            <td>
                <div className='flex'>
                    <span className="mr-3 mt-0.5 text-brand-color"><ICalendar /></span>
                    <div className='flex flex-col'>
                  <span className='text-heading text-base font-semibold'>
                    {new Date(appointment?.appointment?.start).toLocaleDateString(i18n.resolvedLanguage, {
                        weekday: 'short',
                        month: 'short',
                        day: 'numeric'
                    })},
                    <span className='text-heading text-base font-normal'>
                      {` ${new Date(appointment?.appointment?.start).toLocaleTimeString(i18n.resolvedLanguage, {
                          hour: '2-digit',
                          minute: '2-digit'
                      })}`}
                    </span>
                  </span>
                    </div>
                </div>
            </td>
            <td>
                <AppointmentStatus
                    appointmentstatus={appointment?.appointment?.referrals?.length > 0 ? 'referred' : appointment?.appointment?.status}
                    isProfessional
                />
            </td>
        </tr>
            }
            </>
    )
}

export default AppointmentsTable;
