import {useTranslation} from 'react-i18next';
import {IBookMarkLine, ITrash} from '../Icons';
import Switch from '../input/Switch';
import {useEffect, useState} from 'react';
import {useSavedSearch} from '../../contexts/practitioner/SaveSearchProvider';
import {SavedSearch} from '../../types/database/SharedTypes';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons';
import Spinner from '../spinner/Spinner';


function SavedSearches() {
  const [t] = useTranslation();
  const { savedSearchList, updateSavedSearch, handleUpdateSavedSearch } = useSavedSearch()

  const [searchList, setSearchList] = useState<SavedSearch[]>([])
  const [showSavedSearch, setShowSavedSearch] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function handleUpdateDefault(id: string, active: boolean) {
    const updatedSavedSearchList = searchList.map((item: SavedSearch) => {
      if (item.id === id) {
        return {
          ...item,
          isDefault: active
        }
      }
      if (active) {
        return {
          ...item,
          isDefault: false
        }
      }
      return item
    });
    setSearchList(updatedSavedSearchList);
  }

  function handleDeleteSavedSearch(e: React.MouseEvent, id: string) {
    e.stopPropagation();
    const updatedSavedSearchList = searchList.filter((item: SavedSearch) => item.id !== id);
    setSearchList(updatedSavedSearchList);
  }

  const handleSelect = (e: MouseEvent) => {
    const targetElement = e.target as HTMLElement;
    if (targetElement && !targetElement.closest('.saved-search')) {
      setShowSavedSearch(false)
      const clonedSearches = JSON.parse(JSON.stringify(savedSearchList?.searches))
      setSearchList(clonedSearches)
    }
  }

  async function handleSave() {
    try {
      if (updateSavedSearch) {
        setIsLoading(true);
        await updateSavedSearch([...searchList]);
        if (savedSearchList && handleUpdateSavedSearch) handleUpdateSavedSearch([...searchList]);
        setShowSavedSearch(false);
      }
    } catch (err: any) {
      console.log(err?.message || 'Unable to save search list');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleSelect)
    return () => window.removeEventListener('click', handleSelect)
  }, [showSavedSearch, savedSearchList?.searches])

  useEffect(() => {
    if (savedSearchList) {
      const clonedSearches = JSON.parse(JSON.stringify(savedSearchList?.searches))
      setSearchList(clonedSearches)
    }
  }, [savedSearchList?.searches])

  return (
      <div className='relative saved-search '>
      <div
        className={`p-[10px] text-sm flex items-center justify-between bg-orange-400 bg-opacity-10 rounded-sm cursor-pointer ${showSavedSearch && 'bg-brand-color bg-opacity-100 text-white'}`}
        onClick={() => setShowSavedSearch(true)}
      >
        <span>{t('SAVED_SEARCHES')}</span>
        <FontAwesomeIcon icon={faChevronDown} className={`h-3 w-3 ml-2 transition-all ${showSavedSearch && 'rotate-180'}`} />
      </div>
      {showSavedSearch && (
          <div className='absolute top-[100%] left-0 pt-3 lg:w-[368px] z-10'>
          <div className="rounded-[5px] bg-white py-7 shadow-lg">
            <h3 className='mb-6 text-xl font-semibold px-9'>{t('SAVED_SEARCHES')}</h3>
            <div>
              <div className='max-h-[400px] overflow-auto'>
                {searchList?.map((item: SavedSearch) => (
                  <div className={`px-9 ${item.isDefault && 'bg-light-grey'}`} key={item.id}>
                    <div className='py-4 border-b border-light-grey'>
                      <p className='text-base font-medimum mb-[6px]'>{item.title}</p>
                      <span className='text-sm text-grey'>{t('SCHOOL')} ({item?.schools?.length}), {t('VACCINATION_STATUS')} ({item?.status ? 1 : 0})</span>
                      <div className='mt-4 flex items-center justify-between'>
                        <div className='flex items-center'>
                          <span className='text-sm mr-2'>{t('DEFAULT_SEARCH')}</span>
                          <Switch active={item.isDefault} setActive={(value: boolean) => handleUpdateDefault(item.id, value)} />
                        </div>
                        <span onClick={(e: React.MouseEvent) => handleDeleteSavedSearch(e, item.id)} className='cursor-pointer block'>
                          <ITrash />
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className='px-9 mt-6'>
                <button className='h-10 w-full flex items-center justify-center border border-brand-color' onClick={handleSave}>
                  <IBookMarkLine />
                  <span className='ml-3 font-medimum text-sm text-brand-color'>{t('APPLY_SAVED_SEARCH')}</span>
                  {isLoading && <Spinner />}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      </div>



  )
}

export default SavedSearches;