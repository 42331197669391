import {ScheduleSlot} from "../../contexts/patient/ScheduleProvider";
import {DateRanges} from "../../components/admin-dashboard/DateRangeSelector";

const daysOfWeek: string[] = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
];
const monthsOfYear: string[] = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export function getMinutesBetweenDates(startDate: Date, endDate: Date) {
  const diff = endDate.getTime() - startDate.getTime();

  return (diff / 60000);
}
export function getSlotDuration(slot: ScheduleSlot) {
  return getMinutesBetweenDates(new Date(slot.start), new Date(slot.end));
}
export function getDateFromHours(time: string) {
  const time_v = time.split(':').map((v) => +v);
  let now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate(), ...time_v);
}
export function addDays(date:  number | string | Date, days: number) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}
export function getDateFromString(dateString: string | string[]) {
  const splitDate = Array.isArray(dateString) ? dateString[0]?.split('-') : dateString?.split('-');
  return new Date(Number(splitDate[0]), Number(splitDate[1]) - 1, Number(splitDate[2]));
}

export function addWeeks(date: Date, weeks: number) {
    return addDays(date, weeks * 7);
}

export function addMonths(date: Date, months: number) {
    const result = new Date(date);
    result.setMonth(result.getMonth() + months);
    return result;
}

export function subtractPeriodToDate(date: Date, period: DateRanges) {
    switch (period) {
        case "day":
            return addDays(date, -1);
        case "week":
            return addWeeks(date, -1);
        case "month":
        default:
            return addMonths(date, -1);
    }
}

export function datesAreOnSameDay(first:Date, second:Date) {
  return first.getUTCFullYear() === second.getUTCFullYear() &&
  first.getUTCMonth() === second.getUTCMonth() &&
  first.getUTCDate() === second.getUTCDate();
}
/*  @param {string} s - an ISO 8001 format date and time string
**                      with all components, e.g. 2015-11-24T19:40:00
**  @returns {Date} - Date instance from parsing the string. May be NaN.
*/
export function parseISOLocal(s: string) {
    const b = s.split(/\D/);
    return new Date(Number(b[0]), Number(b[1]) - 1, Number(b[2]));
}
