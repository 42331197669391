import React from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStethoscope, faSyringe} from "@fortawesome/free-solid-svg-icons";
import PrimaryLink from "../../../components/link/PrimaryLink";
import {FAQ, Tutorial} from "../../../types/database/SharedTypes";
import HelpCenterView from "../../../components/help-center/HelpCenterView";
import {ICalendar, IDossierLine, IMessages, ISchool, IStudent} from '../../../components/Icons';

function HelpCenter(props: {}) {
    const { } = props;
    const [t] = useTranslation();
    // "shadow-3xl shadow-lime-500 border border-lime-500"
    const tutorials: Tutorial[] = [
        {
            index: 1,
            title: t('HOW_TO_ADD_IMMUNIZATION_DOCUMENTS'), icon: <IDossierLine />,
            steps: [
                { title: t('OPEN_IMMUNIZATION_RECORDS_FROM_THE_HOMEPAGE'), image: require('../../../assets/images/help-center/patient-steps/add-immunization/add-immunization-document-1.png') },
                { title: t('CLICK_ON_BUTTON_TO_ADD_DOCUMENTS_OF_YOUR_IMMUNIZATION'), image: require('../../../assets/images/help-center/patient-steps/add-immunization/add-immunization-document-2.png') },
                { title: t('UPLOAD_PDF_DOC_JPG_FILE_FROM_YOUR_PHONE'), image: require('../../../assets/images/help-center/patient-steps/add-immunization/add-immunization-document-3.png') },
                { title: t('CHECK_THE_FILE_AND_SAVE_DOCUMENT'), image: require('../../../assets/images/help-center/patient-steps/add-immunization/add-immunization-document-4.png') },
                { title: t('CLICK_TO_THE_VACCINE_FRAME_TO_ADD_SPECIFIC_VACCINE'), image: require('../../../assets/images/help-center/patient-steps/add-immunization/add-immunization-document-5.png') },
                { title: t('ADD_THE_ADMINISTERED_DATE_AND_SELECT_VACCINATION_DOCUMENT'), image: require('../../../assets/images/help-center/patient-steps/add-immunization/add-immunization-document-6.png') },
            ],
        },
        {
            index: 2,
            title: t('HOW_TO_REVIEW_IMMUNIZATION_STATUS'),
            icon: <FontAwesomeIcon icon={faSyringe} />,
            steps: [
                { title: t('OPEN_IMMUNIZATION_RECORDS_FROM_THE_HOMEPAGE'), image: require('../../../assets/images/help-center/patient-steps/review-immunization/review-immunization-status-1.png') },
                { title: t('SELECT_VACCINES_IN_THE_TOP_BAR'), image: require('../../../assets/images/help-center/patient-steps/review-immunization/review-immunization-status-2.png') },
                { title: t('CLICK_ON_THE_VACCINE_NAME_TO_SEE_DETAILS'), image: require('../../../assets/images/help-center/patient-steps/review-immunization/review-immunization-status-3.png') },
                { title: t('REVIEW_THE_STATUS_OF_VACCINATION_PROFESSIONAL_NAME_AND_MESSAGE_FROM_THEM'), image: require('../../../assets/images/help-center/patient-steps/review-immunization/review-immunization-status-4.png') },
            ],
        },
        {
            index: 3,
            title: t('HOW_TO_BOOK_AN_APPOINTMENT'),
            icon: <ICalendar />,
            steps: [
                { title: t('OPEN_GET_CARE_FROM_THE_HOMEPAGE'), image: require('../../../assets/images/help-center/patient-steps/booking-appointment/img.png') },
                { title: t('FIND_PROFESSIONALS_FROM_THE_LIST_OR_BY_SEARCH_AND_CLICK_ON_BOOK_BUTTON'), image: require('../../../assets/images/help-center/patient-steps/booking-appointment/img_1.png') },
                { title: t('SELECT_THE_DATE_TO_SET_AN_APPOINTMENT_DATE'), image: require('../../../assets/images/help-center/patient-steps/booking-appointment/img_2.png') },
                { title: t('CHOOSE_A_DATE_CONVENIENT_FOR_YOU_AND_CLICK_ON_THE_SAVE_BUTTON'), image: require('../../../assets/images/help-center/patient-steps/booking-appointment/img_3.png') },
                { title: t('SELECT_THE_AVAILABLE_TIME_TO_SET_AN_APPOINTMENT_TIME_AND_DURATION'), image: require('../../../assets/images/help-center/patient-steps/booking-appointment/img_4.png') },
                { title: t('SELECT_THE_TIME_FOR_STARTING_APPOINTMENT_AND_CLICK_ON_SAVE_BUTTON'), image: require('../../../assets/images/help-center/patient-steps/booking-appointment/img_5.png') },
                { title: t('TRACK_YOUR_CREATED_APPOINTMENT_IN_THE_APPOINTMENT_PAGE_AND_WAIT_FOR_CONFIRMATION_OR_STATUS_CHANGING'), image: require('../../../assets/images/help-center/patient-steps/booking-appointment/img_6.png') },

            ],
        }, // "shadow-3xl shadow-lime-500 border border-lime-500"
        {
            index: 4,
            title: t('HOW_TO_SEND_MESSAGE'),
            icon: <IMessages />,
            steps: [
                { title: t('CLICK_ON_MESSAGE_BUTTON_TO_SEND_A_MESSAGE_TO_PROFESSIONAL'), image: require('../../../assets/images/help-center/patient-steps/send-message/img.png') },
                { title: t('WRITE_A_MESSAGE_OR_UPLOAD_MEDIA_HERE'), image: require('../../../assets/images/help-center/patient-steps/send-message/img_1.png') },
                { title: t('OPEN_MESSAGES_FROM_THE_HOMEPAGE_TO_CHECK_THE_NEW_INCOMING_MESSAGES'), image: require('../../../assets/images/help-center/patient-steps/send-message/img_2.png') },
                { title: t('SELECT_A_CHAT_FROM_YOUR_LIST'), image: require('../../../assets/images/help-center/patient-steps/send-message/img_3.png') },
            ],
        },
        {
            index: 5,
            title: t('HOW_TO_FIND_HEALTH_PROFESSIONALS'),
            icon: <FontAwesomeIcon icon={faStethoscope} />,
            steps: [
                { title: t('OPEN_GET_CARE_FROM_THE_HOMEPAGE'), image: require('../../../assets/images/help-center/patient-steps/find-hp/img.png') },
                { title: t('SEARCH_AND_REVIEW_THE_LIST_OF_PROFESSIONALS'), image: require('../../../assets/images/help-center/patient-steps/find-hp/img_1.png') },
            ],

        },
        { // "shadow-3xl shadow-lime-500 border border-lime-500"
            index: 6,
            title: t('HOW_TO_FIND_CLINICS'),
            icon: <ISchool />,
            steps: [
                {
                    title: t('OPEN_GET_CARE_FROM_THE_HOMEPAGE'),
                    image: require('../../../assets/images/help-center/patient-steps/find-clinic/img.png')
                }, {
                    title: t('SELECT_CLINICS_IN_THE_TOP_BAR'),
                    image: require('../../../assets/images/help-center/patient-steps/find-clinic/img_1.png')
                },
                {
                    title: t('REVIEW_THE_LIST_OF_CLINICS_BY_ADDRESS_AND_OPERATIONS_TIME'),
                    image: require('../../../assets/images/help-center/patient-steps/find-clinic/img_2.png')
                },
                {
                    title: t('EXPAND_CLINICS_OVERVIEW_TO_GETTING_PHONE_AND_FAX_NUMBERS'),
                    image: require('../../../assets/images/help-center/patient-steps/find-clinic/img_3.png')
                }
            ],
        },
        {
            index: 7,
            title: t('HOW_TO_SWITCH_STUDENT'),
            icon: <IStudent />,
            steps: [
                { title: t('CLICK_ON_SWITCH_STUDENT_TO_MANAGE_YOUR_CHILDS_ACCOUNTS'), image: require('../../../assets/images/help-center/patient-steps/switch-student/img.png') },
                { title: t('CLICK_ON_NAME_TO_SWITCH'), image: require('../../../assets/images/help-center/patient-steps/switch-student/img_1.png') },
            ],
        },
    ];

    const faq: FAQ[] = [
        { title: t('PARENTFAQONETITLE'), description: t('PARENTFAQONETEXT') },
        { title: t('PARENTFAQTWOTITLE'), description: t('PARENTFAQTWOTEXT') },
        {
            title: t('PARENTFAQTHREETITLE'), description: (<>
                <p>{t('PARENTFAQTHREETEXT')}</p>
                <p><PrimaryLink to={'https://www.cdph.ca.gov/Programs/CID/DCDC/Pages/Immunization/School/shotsforschool.aspx'}>{t('PARENTFAQTHREELINKSTRING')}</PrimaryLink></p></>)
        },
        {
            title: t('PARENTFAQFOURTITLE'), description: (<> <p>{t('PARENTFAQFOURTEXT')}</p></>)
        },
        { title: t('PARENTFAQFIVETITLE'), description: t('PARENTFAQFIVETEXT') },
        {
            title: t('PARENTFAQSIXTITLE'),
            description: (<p>{t('PARENTFAQSIXTEXT')}</p>)
        },
        {
            title: t('PARENTFAQSEVENTITLE'), description: (<>
                <p>{t('PARENTFAQSEVENTEXT')}</p>
            </>)
        },
        {
            title: t('PARENTFAQEIGHTTITLE'),
            description: <p>{t('PARENTFAQEIGHTTEXT')}</p>
        },
        {
            title: t('PARENTFAQNINETITLE'),
            description:
                (
                    <>
                        <p>{t('PARENTFAQNINETEXT')}</p>
                        <p><PrimaryLink to={'https://cdph-school-assessment-mcorp-test.azurewebsites.net/training/school-immunization-requirements/story.html'}>{t('PARENTFAQNINELINKSTRING')}</PrimaryLink> </p>
                    </>
                )
        },
        {
            title: t('PARENTFAQTENTITLE'),
            description: <p>{t('PARENTFAQTENTEXT')}</p>
        },
        {
            title: t('PARENTFAQELEVENTITLE'),
            description: t('PARENTFAQELEVENTEXT')
        }, {
            title: t('PARENTFAQTWELVETITLE'),
            description: t('PARENTFAQTWELVETEXT')
        }, {
            title: t('PARENTFAQTHIRTEENTITLE'),
            description: <p>{t('PARENTFAQTHIRTEENTEXT')}</p>
        },

    ];
    return (
        <div className={'help-center'}>
            <HelpCenterView tutorials={tutorials} faqs={faq} />
        </div>
    );
}

export default HelpCenter;