import React, {ReactNode} from "react";

export interface InputRadioParams {
    inputName: string;
    inputLabel: string | ReactNode;
    optionValue: string;
    defaultChecked?: boolean;
    id: string;

    [x: string]: any;
}

function InputRadioPrototype(props: InputRadioParams, ref: React.ForwardedRef<any>) {
    const {inputLabel, optionValue, id, defaultChecked = false, ...extraProps} = props;
    return <>
        <input type="radio" id={id} value={optionValue} {...extraProps} ref={ref}
               className="hidden peer"
                defaultChecked={defaultChecked}/>
        <label htmlFor={id}
               className="inline-flex items-center justify-between w-full p-4 text-gray-500 bg-white border border-gray-200 rounded-sm cursor-pointer  peer-checked:border-brand-color peer-checked:bg-opacity-10 peer-checked:bg-brand-color peer-checked:text-brand-color hover:text-gray-600 hover:bg-gray-100 ">
            <div className="block">
                <div className="w-full">{inputLabel}</div>
            </div>

        </label>
    </>;
}

const InputRadio = React.forwardRef(InputRadioPrototype);
export {InputRadio};