import {ApexOptions} from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

function StudentImmunizationDetail(props: { data: any }) {
    const { data } = props;

    const [t] = useTranslation();
    const [chartId, setChartId] = useState('myChart');
    const [series, setSeries] = useState<any[]>([]);

    const options: ApexOptions = {
        chart: {
            type: 'bar',
            id: chartId,
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            }
        },
        plotOptions: {
            bar: {
                barHeight: '14px',
                distributed: true,
                horizontal: true,
                dataLabels: {
                    position: 'right'
                },
            }
        },
        dataLabels: {
            enabled: true,
            textAnchor: 'start',
            style: {
                colors: ['#454545']
            },
            formatter(val, opts) {
                return `${val}%`
            },
            offsetX: 0
        },
        xaxis: {
            categories: data.map((item: { name: string, count: number }) => item?.name),
        },
        yaxis: {
            labels: {
                show: false
            }
        },
        legend: {
            show: true,
            fontSize: '12px',
            horizontalAlign: 'left',
            markers: {
                radius: 50,
                offsetY: 1.5
            },
            labels: {
                colors: '#454545'
            },
            formatter(legendName: string) {
                return ` - ${legendName}`;
            }
        }
    };

    useEffect(() => {
        const values = data.map((item: { name: string, count: number }) => item?.count)
        setSeries([{ data: values }])
        setChartId(`myChart-${new Date().getTime()}`);
    }, [data])

    return (
        <div className="py-4 px-5 mt-6 w-screen sm:w-[300px] md:w-[500px] lg:w-[768px]">
            <h2 className="text-lg font-semibold mb-[6px] text-xl text-center">Students immunization by school</h2>
            <div>
                <ReactApexChart options={options} series={series} type="bar" height={500} />
            </div>
        </div>
    )
};

export default StudentImmunizationDetail;