import React, {useState} from 'react';
import {Immunization} from "../../types/database/SharedTypes";
import ImmunizationItem from "./ImmunizationItem";
import PractitionerImmunizationModal from "./PractitionerImmunizationModal";

function PractitionerImmunizationItem(props:{immunization: Immunization}) {
    const {immunization} = props;
    const [showModal, setShowModal] = useState<boolean>(false);


    return (
        <div>
            <ImmunizationItem  immunization={immunization} onClick={()=>{setShowModal(true)}}/>
            <PractitionerImmunizationModal showModal={showModal} immunization={immunization} onModalVisibilityChange={setShowModal}/>
        </div>
    );
}

export default PractitionerImmunizationItem;