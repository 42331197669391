import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import TextInput from "../input/TextInput";
import PrimaryButton from "../button/PrimaryButton";
import {useForm} from "react-hook-form";
import useAuth from "../../hooks/useAuth";
import {Auth} from "aws-amplify";
import {useLocation, useNavigate} from "react-router-dom";
import SubmitPrimaryButton from "../button/SubmitPrimaryButton";
import {UserGroup} from "../../contexts/AmplifyApiProvider";
import isDev from "../../util/config/envDetect";
import PrimaryLink from "../link/PrimaryLink";
import {LAUNCH_CONFIG} from "../../App";


function LoginPane() {
    const [t] = useTranslation();
    const {signIn, getUserGroup, user} = useAuth();
    const {register, handleSubmit} = useForm();
    const [submitError, setSubmitError] = useState<string>();
    const {pathname, state} = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    function onSubmit(data: any) {

        setLoading(true);
        setSubmitError(undefined);
        return signIn ? signIn(data.email, data.password).then((result) => {
                setLoading(false);
                if (result.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    navigate('/update-password', {state: {from: pathname}, replace: true})
                } else {
                    navigate( (getUserGroup && getUserGroup(result) === UserGroup.PATIENT ? "/patient/dashboard" : "/professional/dashboard"));
                }
            }).catch((reason: Error) => {
                setLoading(false);
                setSubmitError(reason.message);
                console.error('Unable to login', reason);
            }) :
            undefined
            ;

    }
    function shouldShowPasswordLogin() {
        return LAUNCH_CONFIG.auth.authType === 'dual' || LAUNCH_CONFIG.auth.authType === 'vive-auth' || isDev();
    }
    function shouldShowSSO() {
        return  isDev() || LAUNCH_CONFIG.auth.authType === 'dual' || LAUNCH_CONFIG.auth.authType === 'sso';
    }

    return (
        <> {shouldShowPasswordLogin() && <form onSubmit={handleSubmit(onSubmit)}>
            <TextInput inputLabel={t('EMAIL')} id={'email'} type={'email'} {...register("email", {
                required: true,
            })} />
            <TextInput inputLabel={t('PASSWORD')} id={'password'}
                       type={'password'} {...register("password", {required: true,})}/>
            <span className={'text-red-600'}>{submitError}</span>
            <div className={'mt-6'}>
                <SubmitPrimaryButton isLoading={loading}>{t('LOGIN')}</SubmitPrimaryButton>
            </div>

        </form> }
            {shouldShowSSO() && <>
                <div className={'mt-6'}>
                    <PrimaryButton onClick={() => Auth.federatedSignIn({customProvider: 'LAUSDParentIdp'})}>{t('LOGIN_AS_PARENT')}</PrimaryButton>
                </div>
                <div className={'mt-6'}>
                    <PrimaryButton onClick={() => Auth.federatedSignIn({customProvider: 'LAUSDStaffIdp'})}>{t('LAUSD_Staff_Login')}</PrimaryButton>
                </div>
            </>}
            {shouldShowPasswordLogin() &&
                <div className={'mt-8 text-center font-medium'}>
                    <PrimaryLink to={'/forgot-password'}>
                        {t('FORGOT_YOUR_PASSWORD')}
                    </PrimaryLink>
                </div>
            }
        </>
    );
}

export default LoginPane;