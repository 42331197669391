import React, {createContext, useContext, useEffect, useState} from 'react';
import {API} from 'aws-amplify';
import {AuthContext} from '../AuthContext';

export interface ServerReply<T> {
  statusCode: number;
  body: T;
}

interface appointmentListType {
  loading: boolean,
  appointments: any[],
  error: any
}

export const AppointmentContext = createContext<{
  getAppointments?: () => Promise<any>;
  appointmentList?: appointmentListType,
  cancelAppointment?: (apptId: string) => Promise<any>;
  fetchAppointments?: () => void;
}>({});

export const usePatientAppointment = () => React.useContext(AppointmentContext);

function AppointmentProvider(props: {
  children: React.ReactElement<any, any>;
}) {
  const { children } = props;
  const [appointmentList, setAppointmentList] = useState<appointmentListType>({ loading: false, appointments: [], error: null });
  const { userProfile } = useContext(AuthContext);

  async function cancelAppointment(apptId: string): Promise<ServerReply<any>> {
    const apiName = 'LAUSDEndpoint';
    const path = `/patient/appointment/${userProfile}/${apptId}`;
    const myInit = {
      body: {},
      headers: {},
    };
    return API.del(apiName, path, myInit);
  }

  async function getAppointments(): Promise<ServerReply<any>> {
    const apiName = 'LAUSDEndpoint';
    const path = `/patient/appointments/${userProfile}`;
    const myInit = {
      body: {},
      headers: {},
    };
    return API.get(apiName, path, myInit);
  }

  function fetchAppointments() {
    setAppointmentList(prevState => ({
      ...prevState,
      loading: true
    }));
    getAppointments().then((value) => {
      if (value?.body) {
        setAppointmentList({
          loading: false,
          appointments: value.body.items,
          error: null
        });
      }
    }).catch((error: any) => {
      setAppointmentList({
        loading: false,
        appointments: [],
        error: error?.message || 'Unable to get the school list'
      });
    })
  }

  useEffect(() => {
    fetchAppointments();
  }, []);

  return (
    <AppointmentContext.Provider
      value={{
        getAppointments,
        appointmentList,
        cancelAppointment,
        fetchAppointments
      }}
    >
      {children}
    </AppointmentContext.Provider>
  );
}

export default AppointmentProvider;
