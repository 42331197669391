import React, {useState} from 'react';

import TextInput from "../input/TextInput";
import {useForm} from "react-hook-form";
import useAuth from "../../hooks/useAuth";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import SubmitPrimaryButton from "../button/SubmitPrimaryButton";
import useRegistration from "../../hooks/useRegistration";
import {useTranslation} from "react-i18next";


function UserConfirmation(props: { email: string; onEmailVerified: () => any }) {
    const {email, onEmailVerified} = props;
    const [loading, setLoading] = useState(false);
    const [submitError, setSubmitError] = useState<string>();
    const [resentDate, setResentDate] = useState<Date>();
    const [t, i18n] = useTranslation();


    const {register, handleSubmit, formState: {errors}} = useForm();
    const {signIn} = useAuth();
    const {resendVerificationEmail} = useRegistration();

    function onSubmit(data: any) {
        const tempPassword = data['temp-password'];
        setSubmitError(undefined);
        setLoading(true);
        if (signIn) {
            signIn(email, tempPassword).then((value) => {
                onEmailVerified();
                setLoading(false);
            })
                .catch((reason) => {
                    setSubmitError(reason.message);
                    setLoading(false);
                })
        }
    }


    function resendCode() {
        setSubmitError(undefined);
        resendVerificationEmail && resendVerificationEmail(email).then(() => {
            setResentDate(new Date());
        }).catch((reason) => {
            setSubmitError(reason.message);
            console.error("Unable to resend email", reason);
        });
    }

    return (
        <>
            <h2 className={'font-bold text-4xl mb-12'}>Email Verifying</h2>
            <h3 className="mb-5 text-lg font-medium text-gray-900">Mail with a verification code sent on your
                email:<b> {email}</b></h3>
            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="grid lg:grid-cols-2 gap-4 mt-6">
                    <div>
                        <TextInput  inputLabel={'Temporary Passcode'} id={'temp-password'}
                                   type={'password'} {...register("temp-password", {
                            required: true,
                        })} />
                        <span className={'text-red-600'}>{errors['temp-password']?.type === 'required' &&
                            <p role="alert">Temporary Password is required</p>}</span>

                    </div>
                </div>
                <span className={'text-red-600'}>{submitError}</span>
                <span
                    className={'text-green-600'}>{resentDate ? 'Resent at ' : ''}{resentDate?.toLocaleTimeString(i18n.resolvedLanguage)}</span>

                <div className={'flex justify-between mt-6'}>
                    <div className={'mt-4 text-center  font-medium'}>
                        Didn't get the code?
                        <a className={'cursor-pointer'} onClick={() => resendCode()}>
                            <span className={'text-brand-color'}> Send Code Again</span>
                        </a>
                    </div>
                    <div className={'w-52'}>
                        <SubmitPrimaryButton isLoading={loading}> Continue <FontAwesomeIcon
                            icon={faArrowRight}/></SubmitPrimaryButton>
                    </div>
                </div>
            </form>
        </>
    );
}

export default UserConfirmation;
