import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faCheckCircle, faClose, faDoorClosed} from "@fortawesome/free-solid-svg-icons";
import CenterModal from "../dashboard-search/CenterModal";
import PrimaryButton from "../button/PrimaryButton";
import {Tutorial} from "../../types/database/SharedTypes";
import {ampli} from "../../ampli";

function TutorialGuide(props: { tutorial: Tutorial, updateModal?: (modalState: boolean) => void, showModalInitial?: boolean, updateVisitedItems?: (item: number) => void }) {
    const { updateModal, showModalInitial = false, tutorial, updateVisitedItems } = props;
    const [t] = useTranslation();
    const [currentStep, setCurrentStep] = useState<number>(0);

    function closeModal() {
        if (currentStep === tutorial?.steps?.length) {
            updateVisitedItems && updateVisitedItems(tutorial.index)
        }
        setCurrentStep(0);
        updateModal && updateModal(false);
    }

    function handleCompleteStep() {
        updateVisitedItems && updateVisitedItems(tutorial.index)
        closeModal()
    }

    return (
        <CenterModal isOpen={showModalInitial} onClose={() => closeModal()}>
            <div className='h-screen sm:h-auto flex flex-col max-h-[90vh]'>
                <div className={'flex flex-row justify-end pt-4 pr-4'}>
                    <FontAwesomeIcon icon={faClose} className={'h-4 w-4 px-1 py-1 cursor-pointer'} onClick={() => closeModal()} />
                </div>
                {tutorial.steps && currentStep < tutorial.steps.length ? (
                    <article
                        className={'flex flex-col w-screen flex-1 justify-between overflow-y-auto md:w-[700px] lg:w-[800px] sm:w-full px-6 sm:px-12 pb-12'}
                    >
                        <div className="flex flex-col mb-10">
                            <div className="flex items-center justify-between pb-4">
                                <h1 className="text-2xl font-semibold">{tutorial.title}</h1>
                            </div>
                            <div className={'flex flex-row justify-between mb-8'}>
                                <span className='text-base'>{tutorial.steps?.[currentStep]?.title}</span>
                                <span className={'ml-4 whitespace-pre text-base'}>
                                    <b>{currentStep + 1}</b> <span className='text-[#8C8C8C]'>/ {tutorial.steps.length}</span>
                                </span>
                            </div>
                            <div>
                                <img src={tutorial.steps?.[currentStep]?.image} alt={tutorial.steps?.[currentStep]?.title} className={'w-full h-auto'} />
                            </div>
                        </div>
                        <div className={'w-40 ml-auto'}>
                            <PrimaryButton type={'button'} onClick={() => {setCurrentStep(currentStep + 1);  ampli.track({event_type:"Tutorial step completed"}, {extra:{Flow:tutorial?.title,"Steps total": tutorial?.steps?.length, "Step completed":currentStep  }});}}>
                                {t('NEXT')} <FontAwesomeIcon icon={faArrowRight} /></PrimaryButton>
                        </div>
                    </article>
                ) : (
                    <div className='w-screen flex-1 sm:w-[440px] flex flex-col items-center pt-2 py-3 pb-[60px]'>
                        <div className='relative w-[140px] h-[140px]'>
                            <div className='flex justify-center items-center w-full h-full text-[#73C92D] bg-[#73c92d1a] rounded-full [&>svg]:w-10 [&>svg]:h-10'>
                                {tutorial?.icon ? tutorial.icon : (
                                    <FontAwesomeIcon icon={faDoorClosed} className={'h-10 w-10 text-lime-green'} />
                                )}
                            </div>
                            <FontAwesomeIcon icon={faCheckCircle} className={'absolute bottom-0 right-0 h-10 w-10 text-lime-green'} />
                        </div>
                        <h2 className='mt-14 text-2xl font-semibold'>{t('TUTORIAL_COMPLETED')}</h2>
                        <span className='mt-3 text-lg'>{tutorial.title}</span>
                        <div className={'w-[200px] mt-[50px]'}>
                            <PrimaryButton type={'button'} onClick={handleCompleteStep}>
                                {t('NEXT_TUTORIAL')} <FontAwesomeIcon icon={faArrowRight} />
                            </PrimaryButton>
                        </div>
                    </div>
                )}
            </div>
        </CenterModal>
    );
}

export default TutorialGuide;