import React, {ReactNode} from 'react';
import {useTranslation} from "react-i18next";
import TableView from "../TableView";
import VaccinationStatus from "../../status/VaccinationStatus";
import useProfessional from "../../../hooks/useProfessional";
import ExpandableTableRow from "../ExpandableTableRow";
import PercentChange from "../../admin-dashboard/PercentChange";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {SchoolReport} from "../../admin-dashboard/ImmunizationOverview";

function SchoolImmunization(props: {
    schoolData?: SchoolReport[],
    schoolClicked?: (id: string) => void,
    showPagination?: boolean
}) {
    const {schoolClicked, schoolData, showPagination = false} = props;

    const [t] = useTranslation();
    const {schoolList} = useProfessional();


    const studentHeader: ReactNode[] = [
        t('SCHOOL'), <VaccinationStatus status={{color: "red", display: "recommendation"}}
                                        isPatient={false}/>, t('COMPLIANCE_RATE_CHANGE') + ", (%)",
    ]

    function round(v: number) {
        return Math.sign(v) * Math.round(Math.sign(v) * v)
    }


    return (
        <>
            <TableView tableHeaders={studentHeader} loading={schoolList?.loading} itemsPerPage={7}
                       showPagination={showPagination}>
                {schoolData?.map(school =>
                    <ExpandableTableRow key={school.school_id}
                                        onClick={() => schoolClicked && schoolClicked(school.school_id)}>
                        <td>
                            <button>
                                <div className="flex items-center py-2 pr-4">
                                    <div
                                        className="flex items-center justify-center bg-neutral-100 w-[52px] h-[52px] rounded-full mr-4">
                                        <div className="bg-clinic-icon w-6 h-6"/>
                                    </div>
                                    <p className="flex-1 text-base font-semibold text-heading whitespace-nowrap">{school.school_name}</p>
                                </div>
                            </button>
                        </td>
                        <td>
                            <span>{school.non_compliant_students} ({(100 - school.non_compliant_rate).toFixed(2)}%)</span>
                        </td>
                        <td>
                            <div className={'justify-between flex flex-row'}>
                                <PercentChange percentChange={school.compliance_rate_change_percent || 0}/>
                                <FontAwesomeIcon icon={faChevronRight} className={'h-4 w-4 text-gray-500'}/>
                            </div>
                        </td>
                    </ExpandableTableRow>
                )}
            </TableView>
        </>
    );
}

export default SchoolImmunization;