import {Ref, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Calendar as SingleCalendar} from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import CenterModal from '../dashboard-search/CenterModal';
import {ICalendar} from '../Icons';
import es from "date-fns/locale/es";
import enUS from "date-fns/locale/en-US";


interface Props {
  title?: string,
  minDate?: Date,
  maxDate?: Date,
  defaultDate?: Date,
  inputRef?: Ref<HTMLInputElement>
  onChange?: (date: Date) => void,
  isModal?: boolean,
  placeholder?: string,
  noBorder?: boolean,
  disabledDates?: Date[],
  onClickEvent?: () => void
}
function Calendar(props: Props) {
  const { title, minDate,inputRef, maxDate, defaultDate, onChange, isModal, placeholder, noBorder, disabledDates, onClickEvent } = props;

  const [t, i18n] = useTranslation();

  const [date, setDate] = useState<Date | undefined>(defaultDate);
  const [showCalendar, setShowCalendar] = useState<boolean>(false);

  const calendarRef = useRef<any>()

  const handleSelect = (e: MouseEvent) => {
    if (e.target && !calendarRef?.current?.contains(e.target)) {
      setShowCalendar(false)
    }
  }

  function handleDateChange(value: Date) {
    setDate(value)
    onChange && onChange(value)
  }

  useEffect(() => {
    if (isModal) return
    window.addEventListener('click', handleSelect)
    return () => window.removeEventListener('click', handleSelect)
  }, [showCalendar])

  useEffect(() => {
    setDate(defaultDate)
  }, [defaultDate])

  return (
    <div className='relative'>
      <div
        className={`flex items-center justify-between px-4 py-3 h-[46px] rounded-[2px] ${!noBorder && 'border-[1px] border-[#EBEBEB]'}`}
        onClick={(evt) => {
          evt.stopPropagation()
          setShowCalendar(true)
        }}
      >
      <input ref={inputRef} readOnly className={'text-sm font-medium'+ !date ?? "text-[#8C8C8C]"} value={date?date.toLocaleDateString() :placeholder ?? 'MM.DD.YYYY' }></input>
        <span className='text-brand-color'>
          <ICalendar />
        </span>
      </div>
      {isModal ? (
        <>
          {showCalendar && (
            <CenterModal
              isOpen={showCalendar}
              onClose={() => setShowCalendar(false)}
            >
              <div className='p-3 flex flex-col'>
                {title && <p className='text-center font-semibold mt-2'>{title}</p>}
                <SingleCalendar
                  date={date}
                  onChange={(val) => {
                    handleDateChange(val)
                    onClickEvent && onClickEvent()
                  }}
                  locale={i18n.resolvedLanguage?.startsWith('es') ? es : enUS}
                  minDate={minDate || new Date('1950-01-01')}
                  maxDate={maxDate}
                  disabledDates={disabledDates}
                />
                <button
                  onClick={() => setShowCalendar(false)}
                  className='bg-brand-color text-white h-11 font-medium text-base rounded-sm my-1 mx-[10px]'
                >
                  {t('CONTINUE')}
                </button>
              </div>
            </CenterModal>
          )}
        </>
      ) : (
        <>
          {showCalendar && (
            <div ref={calendarRef} className='inline-block absolute bg-white z-10 right-0 top-[100%] mt-2 border-[1px] rounded-[4px] border-[#EBEBEB]'>
              <SingleCalendar
                date={date}
                onChange={(val) => {
                  handleDateChange(val)
                  onClickEvent && onClickEvent()
                }}
                minDate={minDate || new Date('1950-01-01')}
                locale={i18n.resolvedLanguage?.startsWith('es') ? es : enUS}
                maxDate={maxDate}
                disabledDates={disabledDates}
              />
              <button
                onClick={() => setShowCalendar(false)}
                className='bg-brand-color text-white w-[calc(100%_-_20px)] box-border h-11 font-medium text-base rounded-sm my-1 mx-[10px] mb-2'
              >
                {t('CONTINUE')}
              </button>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default Calendar;