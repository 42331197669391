import React from 'react';
import '../App.css';
import Logo from "../components/logo/Logo";
import {Link} from "react-router-dom";
import useAuth from "../hooks/useAuth";

function LandingPage() {
    const {user, signOut} = useAuth();
    return (
        <div className="App">
            <Link to={'/login'}>Login</Link><br/>
            <span>Signed in as: {user?.getUsername()}</span> <br/>
            <span onClick={() => signOut && signOut()}>Logout</span>
            <header className="App-header">
                <Logo/>

            </header>
        </div>
    );
}

export default LandingPage;
