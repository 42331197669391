import React, {ReactNode} from "react";
import {ServerReply} from "../../types/database/SharedTypes";
import {API} from "aws-amplify";

export interface ReportContextType {
    getOverallCompliance?: (startDate?: Date, endDate?: Date) => Promise<ServerReply<any>>;
    getSchoolCompliance?: (startDate?: Date, endDate?: Date, pageSize?: number, searchTerm?: string) => Promise<ServerReply<any>>;
}


const ReportContext = React.createContext<ReportContextType>({});
export const useReports = () => React.useContext(ReportContext);

export function ReportProvider(props: { children: ReactNode, startDate?: Date, endDate?: Date }) {


    async function getNonComplianceReport(startDate: Date | undefined = props.startDate, endDate: Date | undefined = props.endDate): Promise<ServerReply<any>> {
        const apiName = 'LAUSDAdminEndpoint';
        const path = `/reports/immunizations/overall`;
        const myInit = {
            body: {},
            headers: {},
            queryStringParameters: {
                start: startDate?.toISOString(),
                end: endDate?.toISOString(),
            }
        };
        return API.get(apiName, path, myInit);
    }

    async function getOverallCompliance(startDate: Date | undefined = props.startDate, endDate: Date | undefined = props.endDate): Promise<ServerReply<any>> {
        const apiName = 'LAUSDPractitionerEndpoint';
        const path = `/reports/immunization/overall`;
        const startDateISO = startDate?.toISOString().slice(0, -1);
        const endDateISO = endDate?.toISOString().slice(0, -1);
        const myInit = {
            body: {},
            headers: {},
            queryStringParameters: {
                ...(startDateISO && {start: startDateISO}),
                ...(endDateISO && {end: endDateISO}),
            }
        };
        return API.get(apiName, path, myInit);
    }

    async function getSchoolCompliance(startDate: Date | undefined = props.startDate, endDate: Date | undefined = props.endDate, pageSize?: number, searchTerm?: string): Promise<ServerReply<any>> {
        const apiName = 'LAUSDPractitionerEndpoint';
        const path = `/reports/immunization/school`;
        const startDateISO = startDate?.toISOString().slice(0, -1);
        const endDateISO = endDate?.toISOString().slice(0, -1);
        const myInit = {
            body: {},
            headers: {},
            queryStringParameters: {
                ...(pageSize && {pageSize: pageSize}),
                ...(searchTerm && {"name:contains": searchTerm}),
                ...(startDateISO && {start: startDateISO}),
                ...(endDateISO && {end: endDateISO}),
            }
        };
        return API.get(apiName, path, myInit);
    }

    async function getStudentReport(startDate: Date | undefined = props.startDate, endDate: Date | undefined = props.endDate): Promise<ServerReply<any>> {
        const apiName = 'LAUSDAdminEndpoint';
        const path = `/admin/report/tenant_custom/students_lausd`;
        const myInit = {
            body: {},
            headers: {},
            queryStringParameters: {
                index: 'datetime',
                indexType: 'datetime',
                types: ['mean', 'df'],
                mean: 'total',
                start: startDate?.toISOString(),
                end: endDate?.toISOString(),
                fill: 0,
                fillType: 'int'
            }
        };
        return API.get(apiName, path, myInit);
    }


    return (
        <ReportContext.Provider value={{
            getOverallCompliance,
            getSchoolCompliance,
        }}>
            {props.children}
        </ReportContext.Provider>
    );
};