import React, {ReactNode, useEffect, useState} from "react";
import {DocumentAttachment, Immunization, ServerReply} from "../../types/database/SharedTypes";
import {API} from "aws-amplify";
import usePatient from "../../hooks/usePatient";
import useAuth from "../../hooks/useAuth";

type ImmunizationContextType = {
    immunizations?: Immunization[];
    getPatientImmunization?: (immunizationId: string) => Promise<ServerReply<any>>;
    loadingImmunizations: boolean;
    updateImmunizations?: () => void;
    markImmunizationAsCompleted?: (immunizationId: string) => Promise<ServerReply<any>>;
    editImmunization?: (immunizationId: string, occurrences: Date[], attachment: DocumentAttachment, occurrenceNumber: number, replaceAttachments?:boolean, replaceOccurrences?:boolean) => Promise<ServerReply<any>>;
};

const ImmunizationContext = React.createContext<ImmunizationContextType>({loadingImmunizations: true});
export const useImmunization = () => React.useContext(ImmunizationContext);

export function ImmunizationProvider(props: { children: ReactNode }) {
    const {patient} = usePatient();
    const [immunizations, setImmunizations] = useState<any[]>();
    const [loadingImmunizations, setLoadingImmunizations] = useState<boolean>(true);
    const {userProfile} = useAuth();

    async function getPatientImmunizations(patientId: string): Promise<ServerReply<{items: any[]}>> {
        const apiName = 'LAUSDEndpoint';
        const path = `/patient/immunizations/${patientId}`;
        const myInit = {
            body: {},
            headers: {}
        };
        return API.get(apiName, path, myInit);
    }

    async function getPatientImmunization(immunizationId: string): Promise<ServerReply<any>> {
        const patientId = patient.subject;
        const apiName = 'LAUSDEndpoint';
        const path = `/patient/immunization/${patientId}?id=${immunizationId}`;
        const myInit = {
            body: {},
            headers: {}
        };
        return API.get(apiName, path, myInit);
    }

    async function markImmunizationAsCompleted(immunizationId: string): Promise<ServerReply<any>> {
        const apiName = 'LAUSDEndpoint';
        const path = `/patient/immunization/${userProfile}`;
        const myInit = {
            body: {
                id: immunizationId,
                patch: [
                    { op: "replace", path: "/status", value: "completed" },
                ]
            },
            headers: {}
        };
        return API.patch(apiName, path, myInit);
    }
    async function editImmunization(immunizationId: string, occurrences: Date[], attachment: DocumentAttachment, occurrenceNumber: number, replaceAttachments= true, replaceOccurrences=true): Promise<ServerReply<any>> {
        const apiName = 'LAUSDEndpoint';
        const path = `/patient/immunization/${userProfile}`;
        const myInit = {
            body: {
                id: immunizationId,
                patch: [
                    { op: replaceOccurrences ? "replace" : "add", path: `/occurrence${occurrenceNumber}`, value: {occurrenceDateTime:occurrences.map((occurrence) => occurrence.toISOString().split('T')[0])[0]} },
                    { op: replaceAttachments? "replace" : "add", path: "/attachment", value: [attachment] },
                ]
            },
            headers: {}
        };
        return API.patch(apiName, path, myInit);
    }

    function updateImmunizations(){
        if( patient.subject) {
            setLoadingImmunizations(true);
            return getPatientImmunizations(patient.subject).then((documents) => {
                setImmunizations(documents.body.items);
            }).finally(() => setLoadingImmunizations(false));
        }
    }

    useEffect(() => {
        if( patient.subject) {
            getPatientImmunizations(patient.subject).then((immunizations) => {
                setImmunizations(immunizations.body.items);
            }).finally(() => setLoadingImmunizations(false));
        }
    }, [patient]);

    return (
        <ImmunizationContext.Provider value={{
            immunizations,
            getPatientImmunization,
            markImmunizationAsCompleted,
            loadingImmunizations,
            updateImmunizations,
            editImmunization
        }}>
            {props.children}
        </ImmunizationContext.Provider>
    );
};