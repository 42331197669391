import {memo, ReactNode, useEffect, useRef} from 'react';
import {createPortal} from 'react-dom';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  hidden?: boolean;
}

function CenterModal({ isOpen, onClose, children, hidden=false }: ModalProps) {
  const modalRoot = document.getElementById('root') as HTMLElement;
  const elRef = useRef(document.createElement('aside'));

  useEffect(() => {
    modalRoot.appendChild(elRef.current);

    return () => {
      modalRoot.removeChild(elRef.current);
    };
  }, [elRef, modalRoot]);

  if (!isOpen) return null;

  return createPortal(
    <div className={hidden ? 'hidden' :'flex'+ ' '+ 'absolute h-full '}>
      <div
        className={'fixed inset-0 bg-black opacity-50 z-50'}
        onClick={onClose}
      ></div>

      <div
        className={
          'fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  z-50  max-h-screen bg-white shadow-md rounded-[5px]'
        }
      >
        <div className={'h-full max-h-full'}>
          {children}
        </div>
      </div>
    </div>,
    elRef.current
  );
}

export default memo(CenterModal);
