import React from 'react';
import {useForm} from "react-hook-form";
import FilterComponent from "./FilterComponent";
import {Accordion, AccordionItem} from './Accordion';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import Radio from './RadioButtons';
import usePatient from '../../hooks/usePatient';
import useCodeSets from '../../hooks/useCodeSets';
import {usePatientSearch} from '../../contexts/PatientSearchProvider';

function PractitionerFilterComponent(props: { showFilter: boolean, updateVisibility: (visibility: boolean) => void }) {
	const { showFilter, updateVisibility } = props;
	const form = useForm();
	const { register } = form;
	const { schoolList } = usePatient();
	const { professionCodes, genderCodes, languagesCodes, raceCodes } = useCodeSets();
	const { setFilterParams } = usePatientSearch();

	const handleUpdateFilterParams = (formData: any) => {
		const data = JSON.parse(JSON.stringify(formData || {}))
		Object.keys(data).forEach(key => {
			if (typeof data[`${key}`] === 'undefined' || data[`${key}`] === null) {
				delete data[`${key}`]
			}
		})
		setFilterParams && setFilterParams(data)
	}

	return (
		<FilterComponent
			form={form}
			displayFilter={showFilter}
			filterVisibilityChanged={updateVisibility}
			onUpdateFilterParams={handleUpdateFilterParams}
		>
			<div className='py-3 border-b border-neutral-100 border-b-neutral-10'>
				<Accordion>
					<AccordionItem
						title="Profession"
						openIcon={<FontAwesomeIcon icon={faChevronDown} />}
						closeIcon={<FontAwesomeIcon icon={faChevronUp} />}
					>
						<div className="flex flex-col gap-3 max-h-[30rem] overflow-x-hidden">
							{professionCodes?.codes?.map((code) => (
								<Radio
									label={code?.display}
									value={code?.code}
									id={code?.code}
									key={code?.code}
									{...register("professioncode", {
										required: false,
									})}
								/>
							))}
						</div>
					</AccordionItem>
				</Accordion>
			</div>
			<div className='py-3 border-b border-neutral-100 border-b-neutral-10'>
				<Accordion>
					<AccordionItem
						title="Schools"
						openIcon={<FontAwesomeIcon icon={faChevronDown} />}
						closeIcon={<FontAwesomeIcon icon={faChevronUp} />}
						isDefaultOpen={false}
					>
						<div className="flex flex-col gap-3 max-h-[30rem] overflow-x-hidden">
							{schoolList?.map((school) => (
								<Radio
									label={school?.name}
									value={school?.id}
									id={school?.id}
									key={school?.id}
									{...register("organizationid", {
										required: false,
									})}
								/>
							))}
						</div>
					</AccordionItem>
				</Accordion>
			</div>
			<div className='py-3 border-b border-neutral-100 border-b-neutral-10'>
				<Accordion>
					<AccordionItem
						title="Gender"
						openIcon={<FontAwesomeIcon icon={faChevronDown} />}
						closeIcon={<FontAwesomeIcon icon={faChevronUp} />}
						isDefaultOpen={false}
					>
						<div className="flex flex-col gap-3 max-h-[30rem] overflow-x-hidden">
							{genderCodes?.codes?.map((code) => (
								<Radio
									label={code?.display}
									value={code?.code}
									id={code?.code}
									key={code?.code}
									{...register("gender", {
										required: false,
									})}
								/>
							))}
						</div>
					</AccordionItem>
				</Accordion>
			</div>
			<div className='py-3 border-b border-neutral-100 border-b-neutral-10'>
				<Accordion>
					<AccordionItem
						title="Language"
						openIcon={<FontAwesomeIcon icon={faChevronDown} />}
						closeIcon={<FontAwesomeIcon icon={faChevronUp} />}
						isDefaultOpen={false}
					>
						<div className="flex flex-col gap-3 max-h-[30rem] overflow-x-hidden">
							{languagesCodes?.codes?.map((code, index) => (
								<Radio
									label={code?.display}
									value={code?.code}
									id={code?.code}
									key={`${code?.code}_${index}`}
									{...register("languagecode", {
										required: false,
									})}
								/>
							))}
						</div>
					</AccordionItem>
				</Accordion>
			</div>
			<div className='py-3 border-b border-neutral-100 border-b-neutral-10'>
				<Accordion>
					<AccordionItem
						title="Race"
						openIcon={<FontAwesomeIcon icon={faChevronDown} />}
						closeIcon={<FontAwesomeIcon icon={faChevronUp} />}
						isDefaultOpen={false}
					>
						<div className="flex flex-col gap-3 max-h-[30rem] overflow-x-hidden">
							{raceCodes?.codes?.map((code) => (
								<Radio
									label={code?.display}
									value={code?.code}
									id={code?.code}
									key={code?.code}
									{...register("racecode", {
										required: false,
									})}
								/>
							))}
						</div>
					</AccordionItem>
				</Accordion>
			</div>
		</FilterComponent>
	);
}

export default PractitionerFilterComponent;