import {memo, ReactNode, useEffect, useRef} from 'react';
import {createPortal} from 'react-dom';

interface FilterModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

function RightModal ({ isOpen, onClose, children }: FilterModalProps) {
  const modalRoot = document.getElementById('root') as HTMLElement;
  const elRef = useRef(document.createElement('div'));

  useEffect(() => {
    modalRoot.appendChild(elRef.current);

    return () => {
      modalRoot.removeChild(elRef.current);
    };
  }, [elRef, modalRoot]);

  if (!isOpen) return null;

  return createPortal(
    <div className={"flex  absolute h-full "}>
      <div
        className={"fixed inset-0 bg-black opacity-50 z-50"}
        onClick={onClose}
      ></div>

      <div
          className={"fixed top-0 right-0 transform h-full min-w-[24rem] max-w-[98vw] z-50 overflow-hidden max-h-screen"}>
        <div className={"bg-white h-full max-h-full shadow-md p-4 overflow-auto"}>
          {children}
        </div>
      </div>
    </div>,
    elRef.current
  );
};

export default memo(RightModal);
